import axios from "axios"
import { v4 as uuidv4 } from 'uuid';
import { analyticsIdentityEvent } from "../components/Analytics";
import appboy from 'appboy-web-sdk';
const BASE_URL = process.env.REACT_APP_BASE_URL;
let LANG_URL = '/en-US/';
const lang = localStorage.getItem("LANG")
if(lang && lang.length){
  if(lang === "ar"){
    LANG_URL = '/ar-SA/';
  }
}
const API_URL = BASE_URL+LANG_URL;

var axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(function (response) {
  const statusCode = response.data.StatusCode;
  if(statusCode === 1019 || statusCode === 1020){
    localStorage.removeItem("authToken")
    localStorage.removeItem("guestToken")
    localStorage.removeItem("identifier")
    localStorage.removeItem("userinfo")
    localStorage.removeItem("userinfo")
    window.location = "/"
    return response;
  }
  else{
    return response;
  }
}, function (error) {
  return Promise.reject(error);
});

export const generateKey = (keyOnly = false) => {
  const key = localStorage.getItem("identifier")
  if(key && key.length){
    return keyOnly ? key : "deviceId="+key;
  }
  else{
    const identifier = uuidv4()
    localStorage.setItem("identifier",identifier)
    localStorage.removeItem("cart");
    analyticsIdentityEvent("Entered as Guest",{ screen_name: "Welcome", guest_id: identifier })
    return keyOnly ? identifier : "deviceId="+identifier;
  }
}
export async function fetchUserToken(url) {
  const guestToken = localStorage.getItem("guestToken")
  if(!guestToken){
    return await axiosApi.post(url+"?"+generateKey());
  }
}


export async function apiWrapper(callback , url, data = {},config = {}){
  const accessToken = localStorage.getItem("authToken")
  const guestToken = localStorage.getItem("guestToken")
  if(accessToken && accessToken.length){
    return callback(url,data,config, "Bearer "+accessToken)
  }
  else if(guestToken && guestToken.length){
    return callback(url,data,config, "Bearer "+guestToken)
  }
  else{
    if(!guestToken){
      return fetchUserToken("web/guestUserLogin").then((response)=>{
        localStorage.setItem("guestToken",response.data.Data.Token.Access_Token);
        localStorage.setItem("guestCustomerGuid",response.data.Data.UserInfo.CustomerGuid);
        appboy.changeUser(response.data.Data.UserInfo.CustomerGuid);
        return callback(url,data,config, "Bearer "+response.data.Data.Token.Access_Token); 
      })
    }
  }
}


export async function get(url,params = {}, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  let p = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return axiosApi.get(url+"?"+p, { ...config });
}

export async function post(url, data = {}, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  return axiosApi.post(url, { ...data }, { ...config });
}
export async function postJSON(url, data = {}, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  return axiosApi.post(url, data, { ...config });
}

export async function postForm(url, formData, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  let configuration = { headers: { 'content-type': 'multipart/form-data' } }
  return axiosApi.post(url, formData, configuration);
}

export async function put(url, data, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  return axiosApi.put(url, { ...data }, { ...config });
}

export async function putForm(url, formData, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  let configuration = { headers: { 'content-type': 'multipart/form-data' } }
  return axiosApi.put(url, formData, configuration);
}

export async function del(url, data = {}, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  return await axiosApi.delete(url, { data : { ...data } , ...config });
}