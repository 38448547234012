const translations ={
    "Profile": {
      "en": "Profile",
      "ar": "الملف الشخصي"
    },
    "Orders": {
      "en": "Orders",
      "ar": "الطلبات"
    },
    "Returns": {
      "en": "Returns",
      "ar": "عودة"
    },
    "Addresses": {
      "en": "Addresses",
      "ar": "العناوين"
    },
    "Payment Method": {
      "en": "Payment Method",
      "ar": "طريقة الدفع"
    },
    "Wishlist": {
      "en": "Wishlist",
      "ar": "قائمة الأمنيات"
    },
    "Reviews": {
      "en": "Reviews",
      "ar": "التقييمات"
    },
    "Occasions": {
      "en": "Occasions",
      "ar": "المناسبات"
    },
    "Logout": {
      "en": "Logout",
      "ar": "تسجيل الخروج"
    },
    "Only jpg, jpeg, png files are allowed.": {
      "en": "Only jpg, jpeg, png files are allowed.",
      "ar": "يُسمح فقط بملفات jpg و png."
    }
  }
  export default translations