export const getUserGUID = () => {
    let userObject = localStorage.getItem("userinfo");
    let guestCustomerGuid = localStorage.getItem("guestCustomerGuid");
    if(userObject && userObject.length){
        try {
            userObject = JSON.parse(userObject);
            if(userObject && userObject.CustomerGuid){
                return userObject.CustomerGuid
            }
            else{
                if(guestCustomerGuid){
                    return guestCustomerGuid
                }
                else{
                    return "";
                }
            }
        } catch (error) {
            if(guestCustomerGuid){
                return guestCustomerGuid
            }
            else{
                return "";
            }
        }
    }
    else{
        if(guestCustomerGuid){
            return guestCustomerGuid
        }
        else{
            return "";
        }
    }
}