import React from "react";
import { useState } from "react";
import { useEffect } from 'react';
import '../../assets/css/--component-account-profile.css'
import ProfileCard from "./profile-card";
import { getUserData } from "../../helper/backend-methods";
import { analyticsPageEvent } from "../../components/Analytics";
import T from "../../locale/translations-main";

function Profile(props) {
    const [userData, setUserData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [language, setLanguage] = useState("en")
    const [response, setResponse] = useState(true)

    const dataUpdate = (item) => {
        setUserData(item)
    }
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Profile", link: "" },
    ]

    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("Account Profile");
        }
    },[props.consent.page])

    useEffect(() => {
        configurePageTitle("Profile")
        configureBreadcrumb(breadcrumbItems)
        setResponse(true)
        getUserData().then(({ data }) => {
            if(data.Status){
                setUserData(data.Data)
            }
            else{
                setResponse(false)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
        })
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }
    }, []);

    return (
        <>
            <div className='profile-body-header mb-4'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'><T>Personal Profile</T></h1>
                <p className='fs-16 text-gray-400 fw-400 mt-0 mb-0'><T>You can edit and update your profile information</T></p>
            </div>

            {
                loading ? 
                <span className="btn btn-alternative w-100"><T>Fetching profile data. Please wait...</T></span> : 
                !response ? 
                <span className="btn btn-alternative w-100"><i className="ri-error-warning-line fs-22 mr-2"></i><T>Enable to fetch user profile data. Please try again!</T></span> : 
                <ProfileCard item={userData} methodUpdate={dataUpdate} />
            }
        </>

    )
}

export default Profile;