import React, { useState, useEffect } from "react";
import '../../assets/css/--component-account-address.css'
import { getCities, updateAddress } from "../../helper/backend-methods";
import T from "../../locale/translations-main";
// import Places from "./map-component";
function AddressCard({ item, cityList, regionList, methodDefault, methodUpdate, methodDelete, methodSetIsEditable, isEditable, methodItemClicked }) {
    const props = item;
    const [cities, setCities] = useState([])
    const [regions, setRegions] = useState(regionList)
    const [titleSelection, setTitleSelection] = useState(item.Title)
    const [title, setTitle] = useState(item.Title)
    const [address, setAddress] = useState(item.HouseNo)
    const [street, setStreet] = useState(item.StreetAddress)
    const [zipcode, setZipcode] = useState(item.ZipCode)
    const [regionId , setRegionId] = useState(item.RegionId);
    const [cityId, setCityId] = useState(item.CityId)
    const [errors, setErrors] = useState({ title: "", address: "", street: "", zipcode: "", city: "", province: "" })
    // const [isEditable, setIsEditable] = useState(false)
    const [ language, setLanguage ] = useState("en")
    const [ loading, setLoading ] = useState(true)

    const addressTitles = {
        "home": {
            "en": "Home",
            "ar": "بيت",
        },
        "partner": {
            "en": "Partner",
            "ar": "شريك",
        },
        "work": {
            "en": "Work",
            "ar": "عمل",
        }
    }
    
    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }
    },[])

    
    const validateSubmit = (e) => {
        e.preventDefault();
        var zipcodeRE = new RegExp("^\\d{5}(-{1}\\d{4})?$");
        const errors = { title: "", address: "", street: "", zipcode: "", city: "", province: "" };
        let flag = false;
        if (!title || title.replaceAll(/\s/g,'').length < 1) {
            errors.title = <T>Address title is required</T>;
            flag = true
        }
        if (!address || address.replaceAll(/\s/g,'').length < 1) {
            errors.address = <T>Please provide a valid Address</T>;
            flag = true
        }
        if (!zipcode || zipcode.replaceAll(/\s/g,'').length < 1 || !zipcodeRE.test(zipcode)) {
            errors.zipcode = <T>Please provide a valid ZipCode</T>;
            flag = true
        }
        if (!regionId || parseInt(regionId) === 0) {
            errors.province = <T>Please select event State/Province</T>;
            flag = true;
        }
        if (!cityId || parseInt(cityId) === 0) {
            errors.city = <T>Please select event City/Town</T>;
            flag = true;
        }
        if (!street || street.replaceAll(/\s/g,'').length < 1) {
            errors.street = <T>Please provide a valid street address</T>;
            flag = true
        }
        setErrors(errors);
        if(!flag){
            updateAddress({ id: props.Id, Title: title, HouseNo: address, StreetAddress: street , ZipCode: zipcode, CityId: cityId, regionId: regionId, IsDefault: item.IsDefault }, "?addressId=" + item.Id).then(({ data }) => {
                methodUpdate()
                methodSetIsEditable(false)
            }).catch((e) => {
                console.log(e);
                methodSetIsEditable(false)
            })
        }
    }

    useEffect(()=>{
        if(isEditable){
            setLoading(true)
            setCityId(0)
            getCities({regionId: regionId}).then(({data})=>{
                setCities(data.Data)
                setCityId(item.CityId)
                setLoading(false)
            }).catch((e)=>{
                setLoading(false)
            })
        }
    },[isEditable])

    const fetchCities = (value) =>{
        setLoading(true)
        setCityId(0)
        setRegionId(value)
        getCities({regionId: value}).then(({data})=>{
            setCityId(data.Data[0].CityId)
            setCities(data.Data)
            setLoading(false)
        }).catch((e)=>{
            setLoading(false)
            console.log(e);
        })
    }

    const resetForm = () => {
        setTitleSelection(item.Title)
        setTitle(item.Title)
        setAddress(item.HouseNo)
        setStreet(item.StreetAddress)
        setZipcode(item.ZipCode)
        setCityId(item.CityId)
        setRegionId(item.RegionId)
        setErrors({ title: "", address: "", street: "", zipcode: "", city: "", province: "" })
    }

    return (

        <section className={isEditable ? "address-card-wrapper w-100 pb-7   " : "address-card-wrapper"}>
            <div className="address-card">
                <div className="d-flex justify-between align-items-center">
                    <div className="address-card-title mb-3">
                        <h2 className="fs-22 fw-500 text-gray-500 m-0 text-capitalize">{addressTitles[item.Title.toLowerCase()] ? addressTitles[item.Title.toLowerCase()][language] : item.Title}</h2>
                    </div>
                </div>
                {
                    // isEditable ?
                        // <form onSubmit={validateSubmit}>
                        //     <div className="save-cancel">
                        //         <button type="button" className="btn btn-alternative mr-3" onClick={() => { methodSetIsEditable(false); resetForm() }}>{translations.ProfileTab[language].cancelBtn}</button>
                        //         <button className={ loading ? "btn btn-disabled" : "btn btn-primary"} disabled={loading} type="submit">{translations.ProfileTab[language].updateBtn}</button>
                        //     </div>
                        //     {/* <Places/> */}
                        //     <div className="d-flex justify-between align-items-center flex-wrap">
                        //         <div className="address-form-content mb-3 w-100 title-selector">
                        //             <input id="preDefinedTitle01" type={"radio"} value="Home" onChange={(e)=>{ if(e.target.checked){ setTitleSelection("Home"); setTitle("Home") } }} checked={titleSelection === "Home"} name="addressTitle" className="d-none"/>
                        //             <label className="cursor-pointer mr-2" htmlFor="preDefinedTitle01">
                        //                 <i className="ri-home-4-line"></i> 
                        //             </label>
                        //             <input id="preDefinedTitle02" type={"radio"} value="Work" onChange={(e)=>{ if(e.target.checked){ setTitleSelection("Work"); setTitle("Work") } }} checked={titleSelection === "Work"} name="addressTitle" className="d-none"/>
                        //             <label className="cursor-pointer mr-2" htmlFor="preDefinedTitle02">
                        //                 <i className="ri-briefcase-3-line"></i> 
                        //             </label>
                        //             <input id="preDefinedTitle03" type={"radio"} value="Partner" onChange={(e)=>{ if(e.target.checked){ setTitleSelection("Partner"); setTitle("Partner") } }} checked={titleSelection === "Partner"} name="addressTitle" className="d-none"/>
                        //             <label className="cursor-pointer mr-2" htmlFor="preDefinedTitle03">
                        //                 <i className="ri-heart-3-line"></i> 
                        //             </label>
                        //             <input id="preDefinedTitle04" type={"radio"} value="new" onChange={(e)=>{ if(e.target.checked){ setTitleSelection("new"); setTitle("") } }} checked={titleSelection === "new"} name="addressTitle" className="d-none"/>
                        //             <label className="cursor-pointer mr-2" htmlFor="preDefinedTitle04">
                        //                 <i className="ri-add-line"></i> 
                        //             </label>
                        //         </div>
                        //         <div className={  titleSelection !== "Home" && titleSelection !== "Work" && titleSelection !== "Partner" ? "address-form-content mb-2" : "address-form-content mb-2 d-none"}>
                        //             <label htmlFor="address" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{translations.MyAddress[language].titleLabel}<span className="text-primary">*</span></label>
                        //             <input type="text" name="title" id="title" onChange={(e)=>{setTitle(e.target.value.slice(0,20))}} value={title} className="w-100" />
                        //             {errors.title ? <span className="text-primary fs-12 fw-400">{errors.title}</span> : null}
                        //         </div>
                        //         <div className="address-form-content mb-2">
                        //             <label htmlFor="address" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{translations.MyAddress[language].houseNoLabel}<span className="text-primary">*</span></label>
                        //             <input type="text" name="address" id="address" onChange={(e)=>{setAddress(e.target.value.slice(0,50))}} value={address} placeholder = {translations.MyAddress[language].houseNoLabel} />
                        //             {errors.address ? <span className="text-primary fs-12 fw-400">{errors.address}</span> : null}
                        //         </div>
                        //         <div className="address-form-content mb-2">
                        //             <label htmlFor="street" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{translations.MyAddress[language].streetAddressLabel}<span className="text-primary">*</span></label>
                        //             <input type="text" name="street" id="street" onChange={(e)=>{setStreet(e.target.value.slice(0,100))}} value={street} placeholder={translations.MyAddress[language].streetAddressLabel} />
                        //             {errors.street ? <span className="text-primary fs-12 fw-400">{errors.street}</span> : null}
                        //         </div>
                                
                        //         <div className="address-form-content mb-2">
                        //             <label htmlFor="province" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{translations.MyAddress[language].AreaLabel}<span className="text-primary">*</span></label>
                        //             <select name="province" id="province" onChange={(e)=>{fetchCities(e.target.value)}} value={regionId} className="w-100">
                        //                 <option value={0}>{translations.MyAddress[language].selectArea}</option>
                        //                 {
                        //                     regions.map((i,index)=>{
                        //                         return <option key={index} value={i.RegionId}>{i.RegionName}</option>
                        //                     })
                        //                 }
                        //             </select>
                        //             {errors.province ? <span className="text-primary fs-12 fw-400">{errors.province}</span> : null}
                        //         </div>
                        //         <div className="address-form-content mb-2">
                        //             <label htmlFor="city" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{translations.MyAddress[language].cityLabel}<span className="text-primary">*</span></label>
                        //             <select name="city" id="city" onChange={(e)=>{setCityId(e.target.value)}} value={cityId} className="w-100">
                        //             <option value={0}>{translations.MyAddress[language].selectCity}</option>
                        //                 {
                        //                     cities.map((i,index)=>{
                        //                         return <option key={index} value={i.CityId}>{i.CityName}</option>
                        //                     })
                        //                 }
                        //             </select>
                        //             {errors.city ? <span className="text-primary fs-12 fw-400">{errors.city}</span> : null}
                        //         </div>
                        //         <div className="address-form-content mb-2">
                        //             <label htmlFor="zipcode" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{translations.MyAddress[language].zipcodeLabel}<span className="text-primary">*</span></label>
                        //             <input type="text" name="zipcode" id="zipcode" onChange={(e)=>{setZipcode(e.target.value.slice(0,10).replace(/[^0-9-]+/g, ''))}} value={zipcode} placeholder={translations.MyAddress[language].zipcodeLabel} />
                        //             {errors.zipcode ? <span className="text-primary fs-12 fw-400">{errors.zipcode}</span> : null}
                        //         </div>
                        //     </div>
                        // </form> 
                        // :
                        <>
                            <div className="address-card-details mb-4">
                                <h3 className="text-secondary fs-14 fw-500 mt-1 mb-2"><T>Address</T></h3>
                                <p className="fw-500 text-gray-500 fs-14 mt-0 mb-4 d-inline-block word-wrap">{item.HouseNo}, {item.StreetAddress}, {item.CityName}, {item.RegionName}, {item.ZipCode} </p>
                            </div>
                            <div className="address-card-edit-btn">
                                <button className="link-secondary fs-14 align-items-center d-inline-flex" onClick={() => { methodSetIsEditable(true); methodItemClicked(item) }} ><i className="ri-settings-3-line icon-position mr-1 mb-1 fs-20"></i><T>Update</T></button>
                            </div>
                            {
                                props.IsDefault ?
                                    <div className="address-card-tag">
                                        <span><T>Default</T></span>
                                    </div> :
                                    <div className="add-default-label">
                                        <button onClick={() => { methodDefault(props) }} className="link-secondary"><T>Make Default</T></button>
                                    </div>
                            }
                            <div className="delete">
                                <span onClick={()=>{methodSetIsEditable(false); methodDelete(item)}} className="text-primary fs-14 d-inline-flex align-items-center cursor-pointer"><i className="ri-delete-bin-line fs-18 mr-1"></i><T>Delete</T></span>
                            </div>
                        </>
                }

            </div>

        </section>
    )
} 

export default AddressCard;