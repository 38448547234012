import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import '../../assets/css/--component-account-occasion.css'
import ReactStars from "react-rating-stars-component";
import { getOccasionDetails, deleteOccasionProduct, getCities, updateOccasion, getRegions } from "../../helper/backend-methods";
import OccasionProduct from "./occasion-product-card";
import warningIcon from "../../assets/images/failed-icon.png"
import noImage from "../../assets/images/no-image.jpg"
import { analyticsPageEvent } from "../../components/Analytics";
import T, { _T } from "../../locale/translations-main";


function ViewOccasions(props) {
    let params = useParams();
    const occasionId = params.id
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [occasion, setOccasion] = useState(null);
    const [remaining, setRemaining] = useState([]);
    const [purchased, setPurchased] = useState([]);
    const [isEditable, setIsEditable] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showTab, setTabShow] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [productDelPopup, setProductDelPopup] = useState(false);
    const [update, setUpdate] = useState(0);
    const [occasionCart, setOccasionCart] = useState(0);
    const [eventMinDateTime, setEventMinDateTime] = useState("");
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [occasionTypes, setOccasionTypes] = useState([]);
    const [occasionImage, setOccasionImage] = useState('');
    const [occasionImagePost, setOccasionImagePost] = useState(null);
    const [occasionPreview, setOccasionPreview] = useState(null);
    const [occasionTitle, setOccasionTitle] = useState("");
    const [occasionType, setOccasionType] = useState(0);
    const [fullname, setFullname] = useState("");
    const [eventDateTime, setEventDateTime] = useState("");
    const [eventFlat, setEventFlat] = useState("");
    const [eventStreet, setEventStreet] = useState("");
    const [eventState, setEventState] = useState(0);
    const [eventCity, setEventCity] = useState(0);
    const [apiLoading, setApiLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [copiedToggle, setCopiedToggle] = useState(0);
    const [errors, setErrors] = useState({ occasionImage: "", occasionType: "", occasionTitle: "", fullname: "", eventDateTime: "", eventFlat: "", eventStreet: "", eventState: "", eventCity: "" })
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Occasions", link: "/account/occasions" },
        { title: "Occasion Details", link: "" },
    ]

    useEffect(() => {
        if (copiedToggle && copied) {
            setTimeout(() => {
                setCopied(false)
            }, 2000);
        }
    }, [copiedToggle])

    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("My Occasion Details");
        }
    },[props.consent.page])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        configurePageTitle("Occasion Details")
        configureBreadcrumb(breadcrumbItems)
    }, [])


    useEffect(() => {
        const occasion = localStorage.getItem("occasion")
        setOccasionCart(occasion ? parseInt(occasion) : 0)
        getOccasionDetails({ occasionId: occasionId, check: true }).then(({ data }) => {
            if (data.Status) {
                var now = new Date(data.Data.Occasion.EventDateTimestamp * 1000);
                now = new Date(now.setMinutes(now.getMinutes() + now.getTimezoneOffset()));
                var year = now.getFullYear();
                var month = now.getMonth() + 1;
                var day = now.getDate();
                // var hour = now.getHours();
                // var minute = now.getMinutes();
                var localDatetime = year + "-" + (month < 10 ? "0" + month.toString() : month) + "-" + (day < 10 ? "0" + day.toString() : day) ;

                // for minDate limit
                var minDate = new Date();
                minDate.setHours(0, 0, 0, 0)
                minDate.setDate(minDate.getDate() + 4)
                var minDateYear = minDate.getFullYear();
                var minDateMonth = minDate.getMonth() + 1;
                var minDateDay = minDate.getDate();
                // var minDateHour = minDate.getHours();
                // var minDateMinute = minDate.getMinutes();
                var minDateLocalDatetime = minDateYear + "-" + (minDateMonth < 10 ? "0" + minDateMonth.toString() : minDateMonth) + "-" + (minDateDay < 10 ? "0" + minDateDay.toString() : minDateDay) ;

                // end minDate limit

                setEventDateTime(localDatetime)
                setEventMinDateTime(minDateLocalDatetime)
                setOccasion(data.Data.Occasion)
                setOccasionTypes(data.Data.OccasionTypes)
                setRemaining(data.Data.GiftsRemaining)
                setPurchased(data.Data.GiftsPurchased)
                setOccasionImage(data.Data.Occasion.Picture)
                setOccasionPreview(data.Data.Occasion.Picture)
                setOccasionTitle(data.Data.Occasion.OccasionAddress.Title)
                setFullname(data.Data.Occasion.FullName)
                setOccasionType(data.Data.Occasion.EventId)
                setEventFlat(data.Data.Occasion.OccasionAddress.HouseNo)
                setEventStreet(data.Data.Occasion.OccasionAddress.StreetAddress)
                setEventState(data.Data.Occasion.OccasionAddress.RegionId)
                const regionID = data.Data.Occasion.OccasionAddress.RegionId
                const cityId = data.Data.Occasion.OccasionAddress.CityId
                if (regions.length === 0) {
                    getRegions().then(({ data }) => {
                        setRegions(data.Data)
                        getCities({ regionId: regionID }).then(({ data }) => {
                            setEventCity(cityId)
                            setCities(data.Data)
                            setApiLoading(false)
                        }).catch((e) => {
                            setApiLoading(false)
                        })
                    }).catch((e) => {
                        setApiLoading(false)
                        console.log(e);
                    })
                }
            }
            setLoading(false)
        }).catch((e) => {
            setLoading(false)
            console.log(e)
        })
    }, [occasionId, update]);

    const resetForm = () => {
        var now = new Date(occasion.EventDateTimestamp * 1000);
        now = new Date(now.setMinutes(now.getMinutes() + now.getTimezoneOffset()));
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        // var hour = now.getHours();
        // var minute = now.getMinutes();
        var localDatetime = year + "-" + (month < 10 ? "0" + month.toString() : month) + "-" + (day < 10 ? "0" + day.toString() : day);
        setEventDateTime(localDatetime)
        setOccasionImage(occasion.Picture)
        setOccasionPreview(occasion.Picture)
        setOccasionTitle(occasion.Title)
        setFullname(occasion.FullName)
        setEventFlat(occasion.OccasionAddress.HouseNo)
        setEventStreet(occasion.OccasionAddress.StreetAddress)
        setEventState(occasion.OccasionAddress.RegionId)
        setEventCity(occasion.OccasionAddress.CityId)
        setOccasionType(occasion.EventId)
        setOccasionImagePost(null)
    }

    const removeProduct = (id) => {
        setUpdate(update + 1)
    }

    const fetchCities = (value) => {
        setApiLoading(true)
        setEventCity(0)
        setEventState(value)
        getCities({ regionId: value }).then(({ data }) => {
            setEventCity(data.Data.CityId)
            setCities(data.Data)
            setApiLoading(false)
        }).catch((e) => {
            setApiLoading(false)
            console.log(e);
        })
    }

    const validateSubmit = (e) => {
        e.preventDefault();
        var allowedExtension = ['jpeg', 'jpg', 'png'];
        const date = new Date(eventDateTime);
        const now = new Date();
        const tomorrow = new Date(now)
        tomorrow.setDate(tomorrow.getDate() + 1)
        tomorrow.setHours(0, 0, 0, 0)
        tomorrow.setDate(now.getDate() + 4)
        const errors = { occasionImage: "", occasionType: "", occasionTitle: "", fullname: "", eventDateTime: "", eventFlat: "", eventStreet: "", eventState: "", eventCity: "" };
        let flag = false;
        if (occasionImage !== occasion.Picture) {
            if (!allowedExtension.includes(occasionImage.split('.').pop().toLowerCase())) {
                errors.occasionImage = <T>Allowed image types are jpeg, jpg, png only.</T>;
                flag = true;
            }
            if (!occasionImage || occasionImage.replaceAll(/\s/g, '').length < 1) {
                errors.occasionImage = <T>Please provide a valid image.</T>;
                flag = true;
            }
        }
        if (!occasionType || parseInt(occasionType) === 0) {
            errors.occasionType = <T>Please select an occasion category.</T>;
            flag = true;
        }
        if (!occasionTitle || occasionTitle.replaceAll(/\s/g, '').length < 1) {
            errors.occasionTitle = <T>Please provide an occasion title.</T>;
            flag = true;
        }
        if (!fullname || fullname.replaceAll(/\s/g,'').length < 2) {
            errors.fullname = <T>Please provide full name of host.</T>;
            flag = true;
        } 
        // else if (!/^[A-Za-z\s]*$/.test(fullname)) {
        //     errors.fullname =  translations.Occasions[language].numErrorMsg;
        //     flag = true;
        // }
        if (date < tomorrow) {
            errors.eventDateTime = <T>Please select a date/time greater than today.</T>;
            flag = true;
        }
        if (isNaN(date.getTime())) {
            errors.eventDateTime = <T>Please select a valid date.</T>;
            flag = true;
        }
        if (!eventFlat || eventFlat.replaceAll(/\s/g, '').length < 1) {
            errors.eventFlat = <T>Please provide event house/flat no.</T>;
            flag = true;
        }
        if (!eventStreet || eventStreet.replaceAll(/\s/g, '').length < 1) {
            errors.eventStreet = <T>Please provide event street address.</T>;
            flag = true;
        }
        if (!eventState || parseInt(eventState) === 0) {
            errors.eventState = <T>Please select event State/Province.</T>;
            flag = true;
        }
        if (!eventCity || parseInt(eventCity) === 0) {
            errors.eventCity = <T>Please select event City/Town.</T>;
            flag = true;
        }
        setErrors(errors);
        if (!flag) {
            setApiLoading(true)
            const formdata = new FormData();
            if (occasionImage !== occasion.Picture) {
                formdata.append("OccasionPicture", occasionImagePost)
            }
            formdata.append("id", occasionId)
            formdata.append("fullName", fullname)
            formdata.append("title", occasionTitle)
            formdata.append("eventTypeId", occasionType)
            formdata.append("houseNo", eventFlat)
            formdata.append("streetAddress", eventStreet)
            formdata.append("cityId", eventCity)
            formdata.append("regionId", eventState)
            formdata.append("eventDate", eventDateTime)

            updateOccasion(formdata).then(({ data }) => {
                if (data.Status) {
                    resetForm()
                    setUpdate(update + 1)
                }
                setIsEditable(false)
                setApiLoading(false)
            }).catch((e) => {
                setIsEditable(false)
                setApiLoading(false)
                console.log(e)
            })
        }
    }

    const updateImage = (e) => {
        var allowedExtension = ['jpeg', 'jpg', 'png'];
        const err = { occasionImage: "" }
        if (e.target.files && e.target.files[0]) {
            if (!allowedExtension.includes(e.target.value.split('.').pop().toLowerCase())) {
                err.occasionImage = <T>Allowed image types are jpeg, jpg, png only.</T>;
                setErrors({ ...errors, ...err })
                setTimeout(() => {
                    setErrors({ occasionImage: "", ...errors })
                }, 5000);
            }
            else {
                setErrors({ ...errors, ...err })
                var file = e.target.files[0];
                setOccasionImagePost(file)
                setOccasionImage(e.target.value)
                var reader = new FileReader();
                if (file) {
                    reader.readAsDataURL(file);
                } else {
                    setOccasionPreview('')
                }
                reader.onloadend = function () {
                    setOccasionPreview(reader.result)
                }
            }
        }
    }

    return (
        <section>
            {
                loading ?
                    <p className="btn btn-alternative w-100 justify-center"><T>Fetching your occasion details. Please wait...</T></p> :
                    occasion ?
                        <>
                            {
                                isEditable ?
                                    <form onSubmit={validateSubmit} id="updateSingleOccasion">
                                        <div className="occasion-card w-100">
                                            <div className='profile-body-header mb-4'>
                                                <h1 className='fs-22 text-gray-500 fw-500 mt-0 mb-0'><T>Update Occasion</T></h1>
                                                <p className='fs-14 text-gray-400 fw-400 mt-0 mb-0'><T>You may chose to update the following information,</T></p>
                                            </div>
                                            <div className="d-flex justify-between flex-wrap">
                                                <div className="occasion-list-content mb-3 w-100 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500 d-block"><T>Occasion Image</T> <span className="text-primary">*</span></label>
                                                    <label className="occasionImageSelector">
                                                        {
                                                            occasionPreview ?
                                                                <img src={occasionPreview} alt="" /> :
                                                                <i className="ri-add-line"></i>
                                                        }
                                                        <input className="d-none" name="occasionImage" id="occasionImage" type={"file"} onChange={(e) => { updateImage(e) }} />
                                                    </label>
                                                    {errors.occasionImage ? <span className="text-primary fs-12 fw-400 d-block">{errors.occasionImage}</span> : null}
                                                </div>
                                                <div className="occasion-list-content mb-3 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500" htmlFor="occasionType"><T>Occasion Category</T> <span className="text-primary">*</span></label>
                                                    <select name="occasionType" id="occasionType" onChange={(e) => { setOccasionType(e.target.value) }} value={occasionType} className="w-100">
                                                        {
                                                            occasionTypes.map((item, index) => {
                                                                return <option key={index} value={item.Id}>{item.Name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.occasionType ? <span className="text-primary fs-12 fw-400">{errors.occasionType}</span> : null}
                                                </div>
                                                <div className="occasion-list-content mb-3 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500" htmlFor="occasionTitle"><T>Occasion Title</T> <span className="text-primary">*</span></label>
                                                    <input type="text" name="occasionTitle" id="occasionTitle" onChange={(e) => { setOccasionTitle(e.target.value) }} value={occasionTitle} placeholder={_T("Enter occasion title")} className="w-100" />
                                                    {errors.occasionTitle ? <span className="text-primary fs-12 fw-400">{errors.occasionTitle}</span> : null}
                                                </div>
                                                <div className="occasion-list-content mb-3 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500" htmlFor="fullname"><T>Your first & last name (Parent/Guardian)</T><span className="text-primary">*</span></label>
                                                    <input type="text" name="fullname" id="fullname" onChange={(e) => { setFullname(e.target.value) }} value={fullname} placeholder={_T("Enter full name")} className="w-100" />
                                                    {errors.fullname ? <span className="text-primary fs-12 fw-400">{errors.fullname}</span> : null}
                                                </div>
                                                <div className="occasion-list-content mb-3 w-100">
                                                    <label className="fs-16 fw-500 text-gray-500"><T>When and where is your event?</T></label>
                                                </div>
                                                <div className="occasion-list-content mb-3 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500" htmlFor="eventDateTime"><T>Event Date</T> <span className="text-primary">*</span></label>
                                                    <input type="date" id="eventDateTime" name="eventDateTime" onChange={(e) => { setEventDateTime(e.target.value) }} value={eventDateTime} min={eventMinDateTime} placeholder={_T("Enter location of event")} className="w-100" />
                                                    {errors.eventDateTime ? <span className="text-primary fs-12 fw-400">{errors.eventDateTime}</span> : null}
                                                </div>
                                                <div className="occasion-list-content mb-3 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500" htmlFor="eventFlat"><T>House No/Flat No</T><span className="text-primary">*</span></label>
                                                    <input type="text" name="eventFlat" id="eventFlat" onChange={(e) => { setEventFlat(e.target.value) }} value={eventFlat} placeholder={_T("Enter House No/Flat No")} className="w-100" />
                                                    {errors.eventFlat ? <span className="text-primary fs-12 fw-400">{errors.eventFlat}</span> : null}
                                                </div>
                                                <div className="occasion-list-content mb-3 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500" htmlFor="eventStreet"><T>Street Address</T> <span className="text-primary">*</span></label>
                                                    <input type="text" name="eventStreet" id="eventStreet" onChange={(e) => { setEventStreet(e.target.value) }} value={eventStreet} placeholder={_T("Enter Street Address")} className="w-100" />
                                                    {errors.eventStreet ? <span className="text-primary fs-12 fw-400">{errors.eventStreet}</span> : null}
                                                </div>
                                                <div className="occasion-list-content mb-3 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500" htmlFor="eventState"><T>Area</T><span className="text-primary">*</span></label>
                                                    <select name="eventState" id="eventState" onChange={(e) => { fetchCities(e.target.value) }} value={eventState} className="w-100">
                                                        <option value={0}><T>Select Area</T></option>
                                                        {
                                                            regions.map((item, index) => {
                                                                return <option key={index} value={item.RegionId}>{item.RegionName}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.eventState ? <span className="text-primary fs-12 fw-400">{errors.eventState}</span> : null}
                                                </div>
                                                <div className="occasion-list-content mb-3 occasion-form-field">
                                                    <label className="fs-14 fw-400 text-gray-500" htmlFor="eventCity"><T>City/Town</T><span className="text-primary">*</span></label>
                                                    <select name="eventCity" id="eventCity" onChange={(e) => { setEventCity(e.target.value) }} value={eventCity} className="w-100">
                                                        <option value={0}><T>Select City/Town</T></option>
                                                        {
                                                            cities.map((item, index) => {
                                                                return <option key={index} value={item.CityId}>{item.CityName}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.eventCity ? <span className="text-primary fs-12 fw-400">{errors.eventCity}</span> : null}
                                                </div>
                                                <div className="occasion-list-content occasion-form-field"></div>
                                            </div>
                                            <div className="save-cancel text-right">
                                                <button className="btn btn-alternative mr-3" onClick={() => { setIsEditable(false); resetForm() }}><T>Cancel</T></button>
                                                <button className="btn btn-primary" disabled={apiLoading} type="submit">{apiLoading ? <T>Please Wait...</T>: <T>Update Occasion</T>}</button>
                                            </div>
                                        </div>
                                    </form> :
                                    <div className="occasion-card single-occasion p-equal w-100">
                                        <div className="days-remaining">
                                            <p className="text-gray-200 fw-500 fs-12 m-0 LTR">{occasion.EventDate}</p>
                                        </div>
                                        <button className="link occasion-edit d-inline-flex align-items-center" onClick={() => { setIsEditable(true) }}><i className="ri-settings-4-line fs-22 mr-2"></i><T>Update</T></button>
                                        <button className="link occasion-share d-inline-flex align-items-center" onClick={() => { setShowPopup(true) }}><i className="ri-share-forward-line fs-22 mr-2"></i><T>Share</T></button>
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="left">
                                                <div className="occasion-img">
                                                    <img src={occasion.Picture} alt="" />
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="occasion-user-name">
                                                    <p className="text-gray-500 fw-500 fs-16 m-0">{occasion.Title}</p>
                                                    <p className="text-gray-400 fw-400 fs-14 m-0"><T>Guardian: </T><span className="text-gray-500 fw-500 fs-14 m-0">{occasion.FullName}</span></p>
                                                    <p className="text-gray-400 fw-400 fs-14 m-0"><T>Occasion: </T> <span className="text-gray-500 fw-500 fs-14 m-0">{occasion.Event}</span></p>
                                                    <p className="text-gray-400 fw-400 fs-14 m-0"><T>Address: </T> <span className="text-gray-500 fw-500 fs-14 m-0">{occasion.OccasionAddress.HouseNo}, {occasion.OccasionAddress.StreetAddress}, {occasion.OccasionAddress.CityName}, {occasion.OccasionAddress.RegionName}</span></p>
                                                    <p className="text-primary fw-400 fs-14 m-0">{occasion.DeliveryInfo} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                            <div className="tabs w-100">
                                <div className="d-flex">
                                    <button className={showTab === 1 ? "btn-occasion active" : "btn-occasion"} onClick={() => { setTabShow(1) }}><i className={showTab === 1 ? "active mr-2" : "mr-2"}>{remaining.length}</i> <T>Gift Remaining</T></button>
                                    <button className={showTab === 2 ? "btn-occasion active" : "btn-occasion"} onClick={() => { setTabShow(2) }}><i className={showTab === 2 ? "active mr-2" : "mr-2"}>{purchased.length}</i> <T>Gift Purchased</T></button>
                                </div>
                            </div>
                            <div className={showTab === 1 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                                <div className="d-flex justify-between flex-wrap" >
                                    {
                                        remaining.length ?
                                            remaining.map((item, i) => {
                                                return (
                                                    <OccasionProduct key={i} item={item} occasionId={parseInt(occasionId)} cartOccasion={parseInt(occasionCart)} setCartOccasion={setOccasionCart} removeMethod={removeProduct} />
                                                )
                                            }) : purchased.length > 0 ? <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>All remaining gifts were purchased.</T></p> : <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>You haven't added any gifts for your occasion.</T></p>
                                    }
                                </div>
                            </div>
                            <div className={showTab === 2 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                                <div className="d-flex justify-between flex-wrap" >
                                    {
                                        purchased.length ?
                                            purchased.map((item, i) => {
                                                return (
                                                    <div key={i} className="occasion-card-container pt-4 pb-4">
                                                        <div className="occasion-product-list  d-flex justify-between flex-wrap align-items-center">
                                                            <Link className="product-image" to={"/product/" + item.ProductId}>
                                                                <img src={item.ProductPicture ? item.ProductPicture : noImage} alt={item.ProductName} onError={(e) => { e.target.onerror = null; e.target.src = noImage }}/>
                                                            </Link>
                                                            <Link className="product-title" to={"/product/" + item.ProductId}>
                                                                <p className="m-0">{item.ProductName}</p>
                                                            </Link>
                                                            <div className="product-rating">
                                                                <span className="fs-12 mb-1 text-gray-400 fw-400"><T>Rating </T></span>
                                                                <div className="single-product-rating d-flex align-items-center mb-0">
                                                                    <ReactStars size={18} count={5} edit={false} color="#FFB300" activeColor="#FFB300" value={item.ProductRating} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />} />
                                                                </div>
                                                            </div>
                                                            <div className="product-price-container">
                                                                <span className="fs-12 mb-1 text-gray-400 fw-400"><T>Price </T></span>
                                                                <div className="product-price d-flex align-items-center">
                                                                    <p className="single-product-price text-secondary mt-0 mb-0 mr-1"><span className="text-gray-500">SAR</span> {item.UnitPriceDiscount ? (item.ProductPrice - item.UnitPriceDiscount).toFixed(2) : item.ProductPrice.toFixed(2)}</p>
                                                                    { item.UnitPriceDiscount ? <span className="price-drop text-gray-500 fs-14 fw-400 text-left"><s>SAR { (item.ProductPrice).toFixed(2) }</s></span> : <></> }
                                                                </div>
                                                            </div>
                                                            {/* <div className="product-purchased-container pl-5 pr-5">
                                                                <span className="fs-12 mb-1 text-gray-400 fw-400">{translations.viewOccasion[language].purchasedByLabel}</span>
                                                                <div className="product-purchased d-flex align-items-center">
                                                                    <p className="single-product-purchased text-secondary m-0">{item.PurchasedBy.Name}</p>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                )
                                            }) : <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>There are currently no gifts purchased for your occasion.</T></p>
                                    }
                                </div>
                            </div>
                            <div id="occasionSharePopup" className={showPopup ? "active" : ""}>
                                <div className="popup-overlay" onClick={() => { setShowPopup(false) }}></div>
                                <div className="popup-wrapper">
                                    <div className="popup-header">
                                        <h2 className="m-0 text-gray-500 fw-500 fs-20"><T>Share Occasion</T></h2>
                                        <span className="cursor-pointer btn-close" onClick={() => { setShowPopup(false) }}><i className="ri-close-line fs-22"></i></span>
                                    </div>
                                    <div className="popup-body">
                                        <p className="mt-3 mb-3 text-gray-400 fw-400 fs-14"><T>Please share the link with people you would like to invite to </T> <span className="text-primary">{occasion.Title}</span>.</p>
                                        <div className="share-link"><p className="fs-14 fw-400 text-gray-500" onClick={() => { navigator.clipboard.writeText(process.env.REACT_APP_OCCASION_SHARE_URL + occasion.Id); setCopied(true); setCopiedToggle(copiedToggle + 1) }}>{process.env.REACT_APP_OCCASION_SHARE_URL + occasion.Id}</p></div>
                                        {copied ? <p className="text-right text-secondary fs-14 mt-1 mb-0"><T>Copied to clipboard!</T></p> : <></>}
                                    </div>
                                </div>
                            </div>

                        </> :
                        <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>Occasion has expired or does not exist.</T></p>
            }
        </section>

    )
}
export default ViewOccasions;