import { useContext, useEffect } from "react";
import { Link } from "react-router-dom"
import { getProductCart } from "../helper/backend-methods";
import { GlobalContext } from "../context";
function Cart(){
    const { cartCount, updateCartCount } = useContext(GlobalContext);

    useEffect(() => {
        if(cartCount === -1){
            getProductCart().then(({data})=>{
                if(data.Status){
                    updateCartCount(data.Data.CartItemCount);
                    localStorage.setItem("occasion",data.Data.OccasionId)
                    localStorage.setItem("cartItemCount",1)
                }
                else{
                    updateCartCount(0);
                    localStorage.setItem("occasion",0)
                    localStorage.removeItem("cartItemCount")
                }
            }).catch((e)=>{
                updateCartCount(0);
                console.log(e)
            })
        }
    },[]);

    return (
        <Link to={"/cart"} className="header-cart decoration-none d-inline-flex" data-cart={cartCount === -1 ? 0 : cartCount}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.62" height="19.227" viewBox="0 0 19.62 19.227"><g transform="translate(0.867 0.867)"><g><path d="M.743,0A.744.744,0,1,1,0,.744.745.745,0,0,1,.743,0Z" transform="translate(3.803 16.122)" fill="none" stroke="#F37B31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M.744,0A.744.744,0,1,1,0,.744.745.745,0,0,1,.744,0Z" transform="translate(14.75 16.122)" fill="none" stroke="#F37B31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M0,0,2.024.35l.937,11.163a1.754,1.754,0,0,0,1.748,1.608H15.326a1.755,1.755,0,0,0,1.737-1.5l.923-6.381A1.3,1.3,0,0,0,16.7,3.745H2.349" fill="none" stroke="#F37B31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M0,.5H2.7" transform="translate(11.068 6.841)" fill="none" stroke="#F37B31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></g></svg>
        </Link>
    )
}
export default Cart;