import React, { useState } from "react";
import { useEffect } from "react";
import '../../assets/css/--component-account-review.css'
import { getUserReview } from "../../helper/backend-methods";
import Reviewed from "./reviewed-card";
import ToBeReviewed from './to-be-reviewed-card'
import { analyticsPageEvent } from "../../components/Analytics";
import { useSearchParams } from "react-router-dom";
import T from "../../locale/translations-main";

function Review(props) {
    const [search, setSearch] = useSearchParams();
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [ language, setLanguage ] = useState("en")
    const [reviewData, setReviewData] = useState([])
    const [toBeReviewedData, setToBeReviewedData] = useState([])
    const [showTab, setTabShow] = useState(1);
    const [update, setUpdate] = useState(0);
    const [loading , setLoading ] = useState(true);

    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Reviews", link: "" },
    ]

    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("Account Reviews");
        }
    },[props.consent.page])

    useEffect(() => {
        configurePageTitle("Reviews")
        configureBreadcrumb(breadcrumbItems)
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }
    }, []);

    useEffect(() => {
        getUserReview().then(({data}) =>{
            if(data.Status){
                setReviewData(data.Data.ProductsWithReviews)
                setToBeReviewedData(data.Data.ProductsWithoutReviews)
                setLoading(false)
            }
        }).catch((e)=>{
            setLoading(false)
            console.log(e)
        })
    }, [update])


    useEffect(() => {
        if(search){
            if(search.get('pending') && search.get('pending') === "true"){
                setTabShow(2);
            }
        }
    },[])

    const updateReview = () => {
        setUpdate(update+1)
    }

    return (
        <section id="product-review">
            <div className='profile-body-header mb-4'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'><T>My Reviews</T></h1>
                <p className='fs-16 text-gray-400 fw-400 mt-0 mb-0'><T>My reviews about the product</T></p>
            </div>
            {
                loading ?
                    <p className="btn btn-alternative w-100"><T>Fetching your reviews. Please wait...</T></p> :
                <>
                    <div className="review-cards-header">
                        <div className="review-btn-group d-flex">
                            <button className={showTab === 1 ? " btn-review  active" : "btn-review "} onClick={() => { setTabShow(1) }}><i className={showTab === 1 ? "ri-star-fill active mr-2" : "ri-star-fill mr-2"}></i><T>Reviewed</T></button>
                            <button className={showTab === 2 ? " btn-review  active" : "btn-review "} onClick={() => { setTabShow(2) }}> <i className={showTab === 2 ? "ri-star-half-line active mr-2" : "ri-star-half-line mr-2"}></i><T>Pending Reviews</T></button>
                        </div>
                    </div>
                    <div className={showTab === 1 ? "reviewed-section" : "reviewed-section d-none"}>
                        <div className="d-flex justify-between flex-wrap">
                            {
                                reviewData.length ?
                                reviewData.reverse().map((item, index) => {
                                    return <Reviewed key={index} item={item} />
                                }) :                             
                                <p className="btn btn-alternative fw-400 w-100" ><T>You haven't bought any products yet.</T></p>
                            }
                        </div>
                    </div>
                    <div className={showTab === 2 ? "to-be-reviewed-section" : "to-be-reviewed-section d-none"}>
                        <div className="d-flex justify-between flex-wrap">
                            {
                                toBeReviewedData.length ?
                                toBeReviewedData.reverse().map((item, index) => {
                                    return <ToBeReviewed key={index} item={item} method={updateReview}/>
                                }) : reviewData.length ? <p className="btn btn-alternative fw-400 w-100"><T>You currently have no pending reviews.</T></p> : <p className="btn btn-alternative fw-400 w-100"><T>You haven't bought any products yet.</T></p>
                            }
                        </div>
                    </div>
                </>
            }
        </section>
    )
}

export default Review;