import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import InvitedOccasionProduct from "./invited-occasion-product-card";
import '../../assets/css/--component-account-occasion.css'
import ReactStars from "react-rating-stars-component";
import noImage from "../../assets/images/no-image.jpg"
import { getOccasionDetails } from "../../helper/backend-methods";
import { analyticsPageEvent } from "../../components/Analytics";
import T from "../../locale/translations-main";

function ViewInvitedOccasions(props) {
    let params = useParams();
    const occasionId = parseInt(params.id)
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [occasion, setOccasion] = useState(null);
    const [occasionCart, setOccasionCart] = useState(0);
    const [remaining, setRemaining] = useState([]);
    const [purchased, setPurchased] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showTab, setTabShow] = useState(1);
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Occasions", link: "/account/occasions" },
        { title: "Occasion Details", link: "" },
    ]

    
    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("Invited Occasion Details");
        }
    },[props.consent.page])

    useEffect(() => {
        configurePageTitle("Occasion Details")
        configureBreadcrumb(breadcrumbItems)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [])

    useEffect(() => {
        const occasion = localStorage.getItem("occasion")
        setOccasionCart(occasion ? parseInt(occasion) : 0)
        getOccasionDetails({ occasionId: occasionId, check: false , accepted : true }).then(({ data }) => {
            if (data.Status) {
                setOccasion(data.Data.Occasion)
                setRemaining(data.Data.GiftsRemaining)
                setPurchased(data.Data.GiftsPurchased)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
        })
    }, [occasionId]);




    return (
        <section id="singleOccasion">
            {
                loading ?
                    <p className="btn btn-alternative w-100 justify-center"><T>Fetching invited occasion details. Please wait...</T></p> :
                    occasion ?
                        <>
                            <div className="occasion-card single-occasion p-equal w-100 mt-0">
                                <div className="days-remaining">
                                    <p className="text-gray-200 fw-500 fs-12 m-0 LTR">{occasion.EventDate}</p>
                                </div>
                                <div className="d-flex align-items-center gap-1">
                                    <div className="left">
                                        <div className="occasion-img">
                                            <img src={occasion.Picture} alt="" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="occasion-user-name">
                                            <p className="text-gray-500 fw-500 fs-16 m-0">{occasion.Title}</p>
                                            <p className="text-gray-400 fw-400 fs-14 m-0"><T>Guardian: </T> <span className="text-gray-500 fw-500 fs-14 m-0">{occasion.FullName}</span></p>
                                            <p className="text-gray-400 fw-400 fs-14 m-0"><T>Occasion: </T> <span className="text-gray-500 fw-500 fs-14 m-0">{occasion.Event}</span></p>
                                            <p className="text-primary fw-400 fs-14 m-0">{occasion.DeliveryInfo} </p>
                                            {
                                                occasion.OccasionAddress ?
                                                    <p className="text-gray-400 fw-400 fs-14 m-0"><T>Address </T> <span className="text-gray-500 fw-500 fs-14 m-0"> {occasion.OccasionAddress.HouseNo}, {occasion.OccasionAddress.StreetAddress}, {occasion.OccasionAddress.CityName}, {occasion.OccasionAddress.RegionName}</span></p>
                                                    : <p className="text-gray-400 fw-400 fs-14 m-0"><T>N/A</T></p>
                                            }
                                        </div>
                                        <div className="d-flex gap-1">
                                            <p className="text-gray-400 fw-400 fs-14 mt-2"><span className="text-primary">{remaining.length}</span> : <T>Gift Remaining</T></p>
                                            <p className="text-gray-400 fw-400 fs-14 mt-2"><span className="text-primary">{purchased.length}</span> : <T>Gift Purchased</T></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="tabs w-100">
                        <div className="d-flex">
                            <button className={showTab === 1 ? "btn-occasion active" : "btn-occasion"} onClick={() => { setTabShow(1) }}><i className={showTab === 1 ? "active mr-2" : "mr-2"}>{remaining.length}</i>  {translations.invitedViewOccasion[language].giftremainingTab} </button>
                            <button className={showTab === 2 ? "btn-occasion active" : "btn-occasion"} onClick={() => { setTabShow(2) }}><i className={showTab === 2 ? "active mr-2" : "mr-2"}>{purchased.length}</i>  {translations.invitedViewOccasion[language].giftPurchasedTab} </button>
                        </div>
                    </div> */}
                     <div className="profile-body-header mb-4">
                        <h1 className="fs-24 text-gray-500 fw-500 mt-0 mb-0"><T>Gift Remaining</T></h1>
                        <p className="fs-16 text-gray-400 fw-400 mt-0 mb-0"><T>Gift Purchased</T></p>
                    </div>
                            <div className={showTab === 1 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                                <div className="d-flex justify-between flex-wrap" >
                                    {
                                        remaining.length ?
                                            remaining.map((item, i) => {
                                                return (
                                                    <InvitedOccasionProduct key={i} item={item} occasionId={parseInt(occasionId)} cartOccasion={parseInt(occasionCart)} setCartOccasion={setOccasionCart} />
                                                )
                                            }) : purchased.length > 0 ? <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>All remaining gifts were purchased for the occasion.</T></p> : <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>No gifts were added for the occasion..</T></p>
                                    }
                                </div>
                            </div>
                            <div className={showTab === 2 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                                <div className="d-flex justify-between flex-wrap" >
                                    {
                                        purchased.length ?
                                            purchased.map((item, i) => {
                                                return (
                                                    <div key={i} className="occasion-card-container pt-4 pb-4">
                                                        <div className="occasion-product-list  d-flex justify-between flex-wrap align-items-center">
                                                            <Link className="product-image" to={"/product/" + item.ProductId}>
                                                                <img src={item.ProductPicture ? item.ProductPicture : noImage} alt={item.ProductName} onError={(e) => { e.target.onerror = null; e.target.src = noImage }}/>
                                                            </Link>
                                                            <Link className="product-title" to={"/product/" + item.ProductId}>
                                                                <p className="m-0">{item.ProductName}</p>
                                                            </Link>
                                                            <div className="product-rating">
                                                                <span className="fs-12 mb-1 text-gray-400 fw-400"><T>Rating </T></span>
                                                                <div className="single-product-rating d-flex align-items-center mb-0">
                                                                    <ReactStars size={18} count={5} edit={false} color="#FFB300" activeColor="#FFB300" value={item.ProductRating} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />} />
                                                                </div>
                                                            </div>
                                                            <div className="product-price-container">
                                                                <span className="fs-12 mb-1 text-gray-400 fw-400"><T>Price </T></span>
                                                                <div className="product-price d-flex align-items-center">
                                                                    <p className="single-product-price text-secondary mt-0 mb-0 mr-1"><span className="text-gray-500">SAR</span> {item.UnitPriceDiscount ? (item.ProductPrice - item.UnitPriceDiscount).toFixed(2) : item.ProductPrice.toFixed(2)}</p>
                                                                    { item.UnitPriceDiscount ? <span className="price-drop text-gray-500 fs-14 fw-400 text-left"><s>SAR { (item.ProductPrice).toFixed(2) }</s></span> : <></> }
                                                                </div>
                                                            </div>
                                                            <div className="text-primary w-10">
                                                                <span className="fs-14 fw-400"><T>Purchased By</T></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>There are currently no gifts purchased for your occasion.</T></p>
                                    }
                                </div>
                            </div>
                        </> :
                        <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>Occasion has expired or does not exist.</T></p>
            }
        </section>

    )
}
export default ViewInvitedOccasions;
