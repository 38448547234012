import a from '../../assets/images/payment-cards/american-express.png'
import b from '../../assets/images/payment-cards/cirrus.png'
import c from '../../assets/images/payment-cards/delta.png'
import d from '../../assets/images/payment-cards/diners-club.png'
import e from '../../assets/images/payment-cards/direct-debit.png'
import f from '../../assets/images/payment-cards/discover.png'
import g from '../../assets/images/payment-cards/ebay.png'
import h from '../../assets/images/payment-cards/google-checkout.png'
import i from '../../assets/images/payment-cards/jcb.png'
import j from '../../assets/images/payment-cards/maestro.png'
import k from '../../assets/images/payment-cards/mastercard.png'
import l from '../../assets/images/payment-cards/moneybookers.png'
import m from '../../assets/images/payment-cards/paypal.png'
import n from '../../assets/images/payment-cards/sagepay.png'
import o from '../../assets/images/payment-cards/solo.png'
import p from '../../assets/images/payment-cards/switch.png'
import q from '../../assets/images/payment-cards/visa.png'
import r from '../../assets/images/payment-cards/visa-electron.png'
import s from '../../assets/images/payment-cards/western-union.png'
import t from '../../assets/images/payment-cards/undefined.png'

export const cardIcons = {
    "american-express" : a,
    "cirrus" : b,
    "delta" : c,
    "diners-club" : d,
    "direct-debit" : e,
    "discover" : f,
    "ebay" : g,
    "google-checkout" : h,
    "jcb" : i,
    "maestro" : j,
    "mastercard" : k,
    "moneybookers" : l,
    "paypal" : m,
    "sagepay" : n,
    "solo" : o,
    "switch" : p,
    "visa" : q,
    "visa-electron" : r,
    "western-union" : s,
    "undefined" : t,
}