import "../assets/css/--component-loader.css"
function Loader(){
    return (
        <div id="primaryLoader">
            <div className="boxes">
                <div className="box"><div></div><div></div><div></div><div></div></div>
                <div className="box"><div></div><div></div><div></div><div></div></div>
                <div className="box"><div></div><div></div><div></div><div></div></div>
                <div className="box"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    )
}
export default Loader;