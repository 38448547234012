const translations = {
  "Saudi Arabia": {
    "en": "Saudi Arabia",
    "ar": "المملكة العربية السعودية"
  },
  "Something you love is now on sale!": {
    "en": "Something you love is now on sale!",
    "ar": "شيء تحبه معروض للبيع الآن!"
  },
  "Buy Now!": {
    "en": "Buy Now!",
    "ar": "اشتري الآن!"
  },
  "24/7 Delivery": {
    "en": "24/7 Delivery",
    "ar": "توصيل ٢٤ ساعة خلال الاسبوع"
  },
  "For": {
    "en": "For",
    "ar": "إلى"
  },
  "Home": {
    "en": "Home",
    "ar": "الصفحة الرئيسية"
  },
  "For Boys": {
    "en": "For Boys",
    "ar": "للأولاد"
  },
  "For Girls": {
    "en": "For Girls",
    "ar": "للبنات"
  },
  "Shop": {
    "en": "Shop",
    "ar": "تسوق"
  },
  "Brand": {
    "en": "Brand",
    "ar": "الماركات"
  },
  "Contact": {
    "en": "Contact",
    "ar": "للتواصل"
  },
  "All Categories": {
    "en": "All Categories",
    "ar": "كل الفئات"
  },
  "Occasions": {
    "en": "Occasions",
    "ar": "مناسبات"
  },
  "Sorry, we could not find any toys for you.": {
    "en": "Sorry, we could not find any toys for you.",
    "ar": "معذرةً  لم يتم العثور على أي ألعاب لك."
  },
  "Search our store for toys": {
    "en": "Search our store for toys",
    "ar": "ابحث في متجرنا عن لعب الأطفال"
  }
}
export default translations