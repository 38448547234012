import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import noImage from "../../assets/images/no-image.jpg"
import ConfirmImage from "../../assets/images/confirmation.png"
import '../../assets/css/--page-return-details.css'
import { cancelReturnRequest, getReturnOrderDetail, removeReturnItems } from "../../helper/backend-methods"
import T from '../../locale/translations-main';

const ReturnDetails = (props) => {
    const statuses = {
        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
        "Out For Delivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "Processing": {
            "en": "Processing",
            "ar": "يعالج"
        },
        "Order placed": {
            "en": "Order placed",
            "ar": "تم الطلب"
        },
        "Pending": {
            "en": "Pending",
            "ar": "قيد الانتظار"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "ألغيت"
        },
        "PartiallyDelivered_Processing": {
            "en": "Partially Delivered Processing",
            "ar": "تسليم جزئي للمعالجة",
        },
        "PartiallyDelivered_Completed": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا",
        },
        "Received": {
            "en": "Received",
            "ar": "تلقى"
        },
        "ProcessedForPicking": {
            "en": "Processed For Picking",
            "ar": "المصنعة للقطف"
        },
        "ProcessedForShipping": {
            "en": "Processed For Shipping",
            "ar": "معالجتها للشحن"
        },
        "HandedOverToCourier": {
            "en": "Handed Over To Courier",
            "ar": "تسليمه لمندوب الشحن"
        },
        "InTransit": {
            "en": "In Transit",
            "ar": "في مرحلة انتقالية"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "Attempted": {
            "en": "Attempted",
            "ar": "حاول"
        },
        "PartiallyAttempted": {
            "en": "Partially Attempted",
            "ar": "محاولة جزئية"
        },
        "ReturnToOrigin": {
            "en": "Return To Origin",
            "ar": "العودة إلى الأصل"
        },
        "PartialDeliveryInProgress": {
            "en": "Partial Delivery In Progress",
            "ar": "التسليم الجزئي قيد التنفيذ"
        },
        "PartiallyDelivered": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا"
        },
        "CancelledByCustomer": {
            "en": "Cancelled By Customer",
            "ar": "تم الإلغاء بواسطة العميل"
        },
        "Requested": {
            "en": "Requested",
            "ar": "مطلوب"
        },
        "Rejected": {
            "en": "Rejected",
            "ar": "مرفوض"
        },
        "Approved": {
            "en": "Approved",
            "ar": "موافقة"
        },
        "Refunded": {
            "en": "Refunded",
            "ar": "معاد"
        },
        "PartiallyFulfilled": {
            "en": "Partially Fulfilled",
            "ar": "جزئيا تم تحقيقه"
        },
        "Fulfilled": {
            "en": "Fulfilled",
            "ar": "تم الوفاء"
        },
        "PartiallyApproved": {
            "en": "Partially Approved",
            "ar": "تمت الموافقة جزئيًا"
        },
        "RequestedSPL": {
            "en": "Requested SPL",
            "ar": "SPL المطلوبة"
        },

        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
        "Out For Delivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "PartiallyOutForDelivery": {
            "en": "Partially Out For Delivery",
            "ar": "جزئيًا في طريقه للتسليم" 
        },
        "Processing": {
            "en": "Processing",
            "ar": "يعالج"
        },
        "Order placed": {
            "en": "Order placed",
            "ar": "تم الطلب"
        },
        "Pending": {
            "en": "Pending",
            "ar": "قيد الانتظار"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "ألغيت"
        },
        "PartiallyDelivered_Processing": {
            "en": "Partially Delivered Processing",
            "ar": "تسليم جزئي للمعالجة",
        },
        "PartiallyDelivered_Completed": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا",
        },
        "Received": {
            "en": "Received",
            "ar": "تلقى"
        },
        "ProcessedForPicking": {
            "en": "Processed For Picking",
            "ar": "المصنعة للقطف"
        },
        "PartiallyProcessedForPicking": {
            "en": "Partially Processed For Picking",
            "ar": "تمت معالجته جزئيًا للاختيار"
        },
        "ProcessedForShipping": {
            "en": "Processed For Shipping",
            "ar": "معالجتها للشحن"
        },
        "PartiallyProcessedForShipping": {
            "en": "Partially Processed For Shipping",
            "ar": "تمت المعالجة جزئياً للشحن"
        },
        "HandedOverToCourier": {
            "en": "Handed Over To Courier",
            "ar": "تسليمه لمندوب الشحن"
        },
        "PartiallyHandedOverToCourier": {
            "en": "Partially Handed Over To Courier",
            "ar": "تم تسليمها جزئيًا إلى شركة الشحن"
        },
        "InTransit": {
            "en": "In Transit",
            "ar": "في مرحلة انتقالية"
        },
        "PartiallyInTransit": {
            "en": "Partially In Transit",
            "ar": "جزئيًا في النقل"
        },
        "Attempted": {
            "en": "Attempted",
            "ar": "حاول"
        },
        "PartiallyAttempted": {
            "en": "Partially Attempted",
            "ar": "محاولة جزئية"
        },
        "ReturnToOrigin": {
            "en": "Return To Origin",
            "ar": "العودة إلى الأصل"
        },
        "PartiallyReturnToOrigin": {
            "en": "Partially Return To Origin",
            "ar": "العودة جزئيًا إلى الأصل"
        },
        "PartialDeliveryInProgress": {
            "en": "Partial Delivery In Progress",
            "ar": "التسليم الجزئي قيد التنفيذ"
        },
        "PartiallyDelivered": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا"
        },
        "CancelledByCustomer": {
            "en": "Cancelled By Customer",
            "ar": "ألغى العميل"
        },
        "0": {
            "en": "Requested",
            "ar": "مطلوب"
        },
        "1": {
            "en": "Approved",
            "ar": "موافقة"
        },
        "2": {
            "en": "Rejected",
            "ar": "مرفوض"
        },
        "3": {
            "en": "Requested SPL",
            "ar": "SPL المطلوبة"
        },
        "4": {
            "en": "Cancelled By Customer",
            "ar": "تم الإلغاء بواسطة العميل"
        },
        "5": {
            "en": "Partially Fulfilled",
            "ar": "جزئيا تم تحقيقه"
        },
        "6": {
            "en": "Fulfilled",
            "ar": "تم الوفاء"
        },
        "7": {
            "en": "Partially Approved",
            "ar": "تمت الموافقة جزئيًا"
        },
    }

    let params = useParams();
    const returnOrderId = params.id
    const [loading, setLoading] = useState(true);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [returnDetailList, setReturnDetailList] = useState([]);
    const [returnDetailShow, setReturnDetailShow] = useState(false);
    const [qrPopupData, setQRPopupData] = useState({ ProductName: "", ProductImage: "", QrData: null });
    const [update, setupdate] = useState(1);
    const [reasonPopupData, setReasonPopupData] = useState({ ReasonStatus: "", ReasonDetail: "", ReasonImage: null });

    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [language, setLanguage] = useState("en")
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Returns", link: "/account/returns" },
        { title: "Return Details", link: "" },
    ]
    useEffect(() => {
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            setLanguage(lang)
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        configurePageTitle("Return Details")
        configureBreadcrumb(breadcrumbItems)
    }, [])

    useEffect(() => {
        setLoading(true)
        setReturnDetailShow(false)
        setReturnDetailList(null)
        getReturnOrderDetail({ returnOrderId: returnOrderId }).then(({ data }) => {
            if (data.Status) {
                setReturnDetailList(data.Data)
                setReturnDetailShow(true)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        });
    }, [returnOrderId, update]);



    const cancelReturnHandler = () => {
        cancelReturnRequest({ returnOrderId: returnOrderId }).then(({ data }) => {
            if (data.Status) {
                closeCancelReturnModal();
                setCancelSuccess(true)
                setupdate(prev => prev + 1)
                setTimeout(() => {
                    setCancelSuccess(false)
                }, 3000);
            }
        }).catch((e) => {
            console.log(e)
        });
    }


    const ReasonModelHandler = (status, reason, image) => {
        setReasonPopupData({ ReasonStatus: status, ReasonDetail: reason, ReasonImage: image })
        openRejectReasonModal()
    }


    // Modal states and methods
    const [returnRejectReason, setReturnRejectReason] = useState(false);
    const openRejectReasonModal = () => {
        setReturnRejectReason(true);
    };
    const closeRejectReasonModal = () => {
        setReturnRejectReason(false);
    };

    const [qrCodeModal, setQRCodeModal] = useState(false);
    const openQRCodeModal = (productName, productImage, qrData = null) => {
        setQRPopupData({ ProductName: productName, ProductImage: productImage, QrData: qrData })
        setQRCodeModal(true);
    };
    const closeQRCodeModal = () => {
        setQRCodeModal(false);
    };

    const [cancelReturnModal, setCancelReturnModal] = useState(false);
    const openCancelReturnModal = () => {
        setCancelReturnModal(true);
    };
    const closeCancelReturnModal = () => {
        setCancelReturnModal(false);
    };

    const [cancelSingleReturnModal, setCancelSingleReturnModal] = useState(false);
    const [cancelSingleReturnItem, setCancelSingleReturnItem] = useState(0);
    const openCancelSingleReturnModal = (id) => {
        setCancelSingleReturnModal(true);
        setCancelSingleReturnItem(id);
    };
    const closeCancelSingleReturnModal = () => {
        setCancelSingleReturnModal(false);
    };

    const cancelSingleReturnHandler = () => {
        removeReturnItems(`?returnOrderId=${returnOrderId}&returnOrderItemId=${cancelSingleReturnItem}`).then(({ data }) => {
            if (data.Status) {
                closeCancelSingleReturnModal();
                setCancelSuccess(true)
                setupdate(prev => prev + 1)
                setTimeout(() => {
                    setCancelSuccess(false)
                }, 3000);
            }
        }).catch((e) => {
            console.log(e)
        });
    }

    return (
        <section id="product-returns">
            {
                loading ?
                    <p className="btn btn-alternative w-100"><T>Please wait while the return list is loading......</T></p> :
                    <>
                        <>
                            {returnRejectReason && (
                                <div className="modal-overlay">
                                    <div className="modal return-request-success-modal">
                                        <div className="modal-content">
                                            <h2 className="fs-14 text-gray-500 fw-500 text-center">{reasonPopupData.ReasonStatus}</h2>
                                            <p className="mt-1 mb-2 fs-14 fw-400 text-gray-500 text-center">
                                                <span className="text-primary fw-500"><T>Reason</T>: </span>
                                                {reasonPopupData.ReasonDetail}
                                            </p>
                                            {
                                                reasonPopupData.ReasonImage ?
                                                    <div className="return-request-image text-center">
                                                        <img src={reasonPopupData.ReasonImage} alt="" className="mt-2 mb-2" />
                                                    </div> :
                                                    <></>
                                            }
                                            <div className="action-btn-container mt-2">
                                                <button className="btn btn-primary w-100 mb-2" onClick={closeRejectReasonModal}><T>Ok</T></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        <>
                            {qrCodeModal && (
                                <div className="modal-overlay">
                                    <div className="modal return-request-success-modal">
                                        <div className="modal-content">
                                            <div className="product-detail d-flex align-items-center justify-center gap-20 flex-wrap">
                                                <img src={qrPopupData.ProductImage ? qrPopupData.ProductImage : noImage} alt="" style={{ width: "80px", height: "80px" }} draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                                <p className="fs-14 fw-500 text-center">{qrPopupData.ProductName}</p>
                                            </div>

                                            <p className="mt-3 mb-3 fs-16 fw-500 text-center">
                                                <T>Please show this QR code to Saudi Post driver</T>
                                            </p>
                                            <div className="return-request-image text-center">
                                                <div className="mt-2 mb-3 ml-auto mr-auto" style={{ width: "200px", height: "200px" }} >
                                                    <QRCode
                                                        size={200}
                                                        value={qrPopupData.QrData}
                                                        
                                                    />
                                                </div>
                                            </div>
                                            <div className="action-btn-container">
                                                <button className="btn btn-primary w-100 mb-2" onClick={closeQRCodeModal}><T>Ok</T></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        <>
                            {cancelReturnModal && (
                                <div className="modal-overlay">
                                    <div className="modal return-request-success-modal">
                                        <div className="modal-content">
                                            <h4 className="fs-20 fw-500 text-center"><T>Cancel Return</T></h4>
                                            <p className="mt-3 mb-3 fs-16 fw-500 text-center">
                                                <T>Are you sure you want to cancel the return request?</T>
                                            </p>
                                            <div className="return-request-image text-center">
                                                <img src={ConfirmImage} alt="" className="mt-2 mb-2" style={{ width: "250px", height: "200px" }} />
                                            </div>
                                            <div className="action-btn-container">
                                                <button className="btn btn-primary w-100 mb-2" onClick={cancelReturnHandler}><T>Yes</T></button>
                                                <button className="btn btn-outline-secondary w-100 mb-2" onClick={closeCancelReturnModal}><T>No</T></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        <>
                            {cancelSingleReturnModal && (
                                <div className="modal-overlay">
                                    <div className="modal return-request-success-modal">
                                        <div className="modal-content">
                                            <h4 className="fs-20 fw-500 text-center"><T>Cancel Return</T></h4>
                                            <p className="mt-3 mb-3 fs-16 fw-500 text-center">
                                                <T>Are you sure you want to cancel the return request?</T>
                                            </p>
                                            <div className="return-request-image text-center">
                                                <img src={ConfirmImage} alt="" className="mt-2 mb-2" style={{ width: "250px", height: "200px" }} />
                                            </div>
                                            <div className="action-btn-container">
                                                <button className="btn btn-primary w-100 mb-2" onClick={cancelSingleReturnHandler}><T>Yes</T></button>
                                                <button className="btn btn-outline-secondary w-100 mb-2" onClick={closeCancelSingleReturnModal}><T>No</T></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        {returnDetailShow ?
                            <div className="returns-section return-details-section">
                                <h1 className='fs-20 fw-500 text-gray-500 mt-1 mb-1'><T>Return Requests</T></h1>
                                {cancelSuccess ? <p className="btn btn-success w-100 mt-2 mb-2"><T>Cancel return request submitted successfully.</T></p> : <></>}
                                <div className="d-flex justify-between flex-wrap">
                                    <div className="order-details-container order-product-container d-flex justify-between flex-wrap w-100">
                                        <div className="returns-heading">
                                            <h2 className='fs-14 fw-500 mt-0 mb-0'><T>Return Order No</T>: {`${returnDetailList.ReturnOrderNumber}`}</h2>
                                        </div>
                                        <div className="order-card mb-0">
                                            <div className="order-card-wrapper">
                                                <div className="d-flex align-items-center justify-between flex-wrap position-relative mb-3">
                                                    <div className="">
                                                        <p className='fs-14 text-secondary fw-400 mt-1 mb-1'><T>Order Number</T></p>
                                                        <p className='text-gray-500 fs-14 fw-500 mt-1 mb-1'> {returnDetailList.OrderNumber}</p>
                                                    </div>
                                                    {/* <div className="">
                                                        <p className='fs-14 text-secondary fw-400 mt-1 mb-1'>{translations.returnDetail[language].status}</p>
                                                        <p className='text-gray-500 fs-14 fw-500 mt-1 mb-1'><span className="status-indicator status-processing"></span> {statuses[returnDetailList.Status][language]}</p>
                                                    </div> */}
                                                    <div className="">
                                                        <p className='fs-14 text-secondary fw-400 mt-1 mb-1 text-center'><T>Request Date</T></p>
                                                        <p className='text-gray-500 fs-14 fw-500 mt-1 mb-1 text-center LTR'>{returnDetailList.RequestDate}</p>
                                                    </div>
                                                    <div className="">
                                                        <p className='fs-14 text-secondary fw-400 mt-1 mb-1 text-center'><T>Items</T></p>
                                                        <p className='text-gray-500 fs-14 fw-500 mt-1 mb-1 text-center'>{returnDetailList.Items}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="track-return-timeline-container mt-3 mb-4 d-none">
                                    <div className="track-return-timeline">
                                        <div className="track-return-timeline-card">
                                            <div className="track-return-status-current d-flex justify-center align-items-center">
                                                <i className="ri-shopping-cart-2-line fs-24 fw-500 text-light"></i>
                                            </div>
                                            <div className="track-return-timeline-meta">
                                                <div className="track-return-timeline-heading fs-14 text-primary fw-600 mt-1 mb-1 text-center">Return Initiated</div>
                                            </div>
                                        </div>
                                        <div className="track-return-timeline-card">
                                            <div className="track-return-status-past">
                                                <i className="ri-box-3-line fs-24 fw-500 text-light"></i>
                                            </div>
                                            <div className="track-return-timeline-meta">
                                                <div className="track-return-timeline-heading fs-14 text-primary fw-600 mt-1 mb-1 text-center">Return Processed</div>
                                            </div>
                                        </div>
                                        <div className="track-return-timeline-card">
                                            <div className="track-return-status-past">
                                                <i className="ri-truck-line fs-24 fw-500 text-light"></i>
                                            </div>
                                            <div className="track-return-timeline-meta">
                                                <div className="track-return-timeline-heading fs-14 text-primary fw-600 mt-1 mb-1 text-center">Items Picked</div>
                                            </div>
                                        </div>
                                        <div className="track-return-timeline-card">
                                            <div className="track-return-status-past">
                                                <i className="ri-check-double-line fs-24 fw-500 text-light"></i>
                                            </div>
                                            <div className="track-return-timeline-meta">
                                                <div className="track-return-timeline-heading fs-14 text-primary fw-600 mt-1 mb-1 text-center">Refunded</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="return-items-container">
                                    {
                                        returnDetailList.ReturnProducts.map((rp, index) => {
                                            return (
                                                <div key={index} className="return-item-card-container mb-3">
                                                    <div className="return-item-card d-flex align-items-center justify-start position-relative flex-wrap gap-20">
                                                        {
                                                            !returnDetailList.IsCancelled && rp.Status === "Requested" ?
                                                            <div className='return-item-remove-option' onClick={()=>{openCancelSingleReturnModal(rp.ReturnOrderItemId)}}>
                                                                <i className="ri-delete-bin-line"></i> 
                                                            </div> : null
                                                        }
                                                        <div className="return-item-image">
                                                            <img src={rp.PictureUrl ? rp.PictureUrl : noImage} alt="Return Item Image" draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                                        </div>
                                                        <div className="return-item-description">
                                                            <div className="return-item-title">
                                                                <h4 className='fs-16 text-gray-500 fw-500 mt-1 mb-1'>{rp.Name}</h4>
                                                            </div>
                                                            <div className="return-detail-card-meta d-flex align-items-center justify-between flex-wrap">
                                                                <div className="">
                                                                    {/* <div className="return-item-price">
                                                                        <p className='fs-14 text-primary fw-400 mt-1 mb-1'>SAR {rp.Price.toFixed(2)}</p>
                                                                    </div>
                                                                    <div className="return-item-discount-price">
                                                                        <p className='fs-14 text-primary fw-400 mt-1 mb-1'>{translations.returnDetail[language].discount}: SAR {rp.Discount.toFixed(2)}</p>
                                                                    </div> */}
                                                                    {rp.RefundedAmount ?
                                                                        <div className="return-item-price">
                                                                            <p className='fs-14 text-primary fw-400 mt-1 mb-1'><T>Refunded Amount</T> SAR {rp.RefundedAmount.toFixed(2)}</p>
                                                                        </div>
                                                                        : <></>
                                                                    }
                                                                    {
                                                                        !rp.RefundedAmount ?
                                                                        <div className="return-single-item-status mt-1 mb-1">
                                                                            <span className='fs-12 fw-500 text-secondary' style={{ cursor: "pointer" }} onClick={() => ReasonModelHandler(rp.Status ? (statuses[rp.Status] ? statuses[rp.Status][language] : rp.Status) : statuses["Requested"][language], rp.Reason, rp.ImageURL && rp.ImageURL.length ? rp.ImageURL : null)}>
                                                                                <T>Return has been</T> {rp.Status ? (statuses[rp.Status] ? statuses[rp.Status][language] : rp.Status) : statuses["Requested"][language]}, <T>click here to view the reason</T>.
                                                                            </span>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                                <div className="detail-meta-total-status">
                                                                    {/* <div className="return-item-total-price">
                                                                        <p className='fs-14 text-gray-500 fw-500 mt-1 mb-1'>{translations.returnDetail[language].total}: SAR {rp.Total.toFixed(2)}</p>
                                                                    </div>
                                                                    <div className="return-single-item-status mt-1 mb-1">
                                                                        <span className='fs-12 fw-500 text-secondary' style={{ cursor: "pointer" }} onClick={() => ReasonModelHandler(rp.Status, rp.Reason, null)}>Return has been {rp.Status}, click here to view the reason.</span>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            {/* <div className="return-item-qty-total d-flex align-items-center justify-between gap-40">
                                                <div className="return-item-qty">
                                                    <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>Qty: 2</p>
                                                </div>
                                                <div className="return-item-subtotal">
                                                    <p className='fs-14 text-gray-500 fw-500 mt-1 mb-1'>SAR 400.00</p>
                                                </div>
                                            </div> */}
                                                        </div>
                                                    </div>

                                                    {
                                                        (rp.SPLBarcode != null) ?
                                                            <div className="qrcode-container">
                                                                <button className="btn btn-primary w-25" onClick={() => openQRCodeModal(rp.Name, rp.PictureUrl, rp.SPLBarcode)}><T>View QR Code</T></button>
                                                            </div> : <></>
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                    {/* <div className="return-item-card-container">
                            <div className="return-item-card d-flex align-items-center justify-start gap-20">
                                <div className="return-item-image">
                                    <img src={noImage} alt="Return Item Image" />
                                </div>
                                <div className="return-item-description">
                                    <div className="return-item-title">
                                        <h4 className='fs-16 text-gray-500 fw-400 mt-1 mb-1'>Hip Hop Football</h4>
                                    </div>
                                    <div className="return-item-price">
                                        <p className='fs-14 text-primary fw-400 mt-1 mb-1'>SAR 100.00</p>
                                    </div>
                                    <div className="return-item-qty-total d-flex align-items-center justify-between gap-40">
                                        <div className="return-item-qty">
                                            <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>Qty: 2</p>
                                        </div>
                                        <div className="return-item-subtotal">
                                            <p className='fs-14 text-gray-500 fw-500 mt-1 mb-1'>SAR 400.00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="return-single-item-status mt-2 mb-2">
                                <span className='fs-12 text-primary fw-500'>Amount Refunded Successfully</span>
                            </div>
                            <div className="qrcode-container">
                                <button className="btn btn-primary w-25" onClick={openQRCodeModal}>View QR Code</button>
                            </div>
                        </div> */}
                                </div>
                                <div className="return-action-btn d-flex align-items-center justify-end gap-20 flex-wrap mt-3">
                                    {returnDetailList.IsReversible ? <button className="btn btn-outline-secondary" onClick={openCancelReturnModal}><T>Cancel Return</T></button> : <></>}
                                    <Link to={`/account/returns/track-return/${returnOrderId}`} className="btn btn-primary d-none"><T>Track Return</T></Link>
                                </div>
                            </div> :
                            <p className="btn btn-alternative w-100"><T>Sorry! No Data Found</T></p>
                        }
                    </>
            }
        </section>
    )
}

export default ReturnDetails
