import React, { useState, useEffect } from "react";
import '../../assets/css/--component-account-reviewed-card.css'
import ReactStars from "react-rating-stars-component";
import noImage from "../../assets/images/no-image.jpg"
import { Link } from "react-router-dom";
import T from "../../locale/translations-main";

function Reviewed(revieweddata) {
 
    const props = revieweddata.item;
    return (
            <div className="review-cards d-flex justify-between flex-wrap align-items-center ">
                <Link to={"/product/"+props.ProductId} className="review-image decoration-none">
                    <img src={props.ProductPictureUrl ? props.ProductPictureUrl : noImage} alt="" />
                </Link>
                <div className="review-product-details">
                    <Link to={"/product/"+props.ProductId} className="fs-18 fw-500 text-gray-500 mt-0 mb-3 product-name decoration-none">{props.ProductName.length > 45 ? props.ProductName.slice(0, 42) + "..." : props.ProductName}</Link>
                    <p className="fs-16 fw-400 m-0 product-brand"><T>Brand: </T><span className="text-primary" >{props.ProductManufacturerName}</span></p>
                    <p className="fs-16 fw-400 m-0 product-review-date"><T>Reviewed: </T><span className="text-gray-400">{props.ReviewCreatedOnUtc}</span></p>
                </div>
                
                <div className="review-details">
                    <div className="d-flex align-items-center react-stars"><ReactStars size={18} count={5} edit={false} color="#FFB300" activeColor="#FFB300" value={props.ReviewRating} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />}/><span className="ml-2 mb-1 text-gray-500 fw-500 fs-16">{props.ReviewRating.toFixed(2)}</span></div>
                    <p className="fs-18 fw-600 mt-0 mb-1 product-generated-label text-gray-500">{props.ReviewRating < 2 ? "Not Recommended" : props.ReviewRating < 3 ? "Just Okay" : props.ReviewRating < 4 ? "Satisfactory" : props.ReviewRating < 5 ? "Recommended" : "Highly Recommended"}</p>
                    {/* { props.IsApproved ? <span className="pt-1 pb-1 pl-2 pr-2 bg-alternative text-secondary fs-14 review-approved" style={{"borderRadius": "5px"}}>{translations.MyReviews[language].reviewApproved} </span> : <span className="pt-1 pb-1 pl-2 pr-2 bg-alternative text-secondary fs-14" style={{"borderRadius": "5px"}}>{translations.MyReviews[language].pendingApprovalLabel} </span> } */}
                    <p className="mb-0 text-gray-500 fs-14 fw-400">{props.ReviewUserText}</p>
                </div>
            </div>
    )
}

export default Reviewed;