import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import '../../assets/css/--component-account-profile.css'
import { postUpdateProfile } from "../../helper/backend-methods";
import nationalityTranslation from "../../data/nationalities";
import appboy from 'appboy-web-sdk';
import T, { _T } from "../../locale/translations-main";
import { analyticsTrackEvent } from "../../components/Analytics";

function ProfileCard({ item, methodUpdate, hasHeading = false }) {
    const [isEditable, setIsEditable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [ language, setLanguage ] = useState("en")
    const [ enableEmail, setEnableEmail ] = useState(false)
    const [ emailError, setEmailError ] = useState("");

    useEffect(()=>{
        if(item){
            setEnableEmail(item.Email && item.Email.length ? false : true)
        }
    },[item])
    
    const formik = useFormik({
        initialValues: {
            firstname: item.FirstName ? item.FirstName : "",
            lastname: item.LastName ? item.LastName : "",
            email: item.Email ? item.Email : "",
            number: item.PhoneNo ? item.PhoneNo : "",
            gender: item.Gender ? item.Gender : "",
            nationality: item.Nationality !== "0" ? nationalityTranslation("Saudi")[language] : 0
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: values => {
            const errors = {};
            const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
            if (!values.firstname) {
                errors.firstname = <T>Your First Name is required</T>;
            }
            if (!values.lastname) {
                errors.lastname = <T>Your Last Name is required</T>;
            }
            if (!emailRegex.test(values.email)) {
                errors.email = <T>Please provide a valid email address</T>
            }
            // if (!values.gender) {
            //     errors.gender = translations.ProfileTab[language].genderError;
            // }
            // if (!values.nationality) {
            //     errors.nationality = translations.ProfileTab[language].nationalityError;
            // }

            return errors;
        },
        onSubmit: values => {
            setLoading(true)
            setEmailError("");
            postUpdateProfile({ FirstName: values.firstname, LastName: values.lastname, Gender: values.gender, Nationality: values.nationality, Email: values.email }).then(({ data }) => {
                if(data.Status){
                    if(item.FirstName === "" || item.FirstName === undefined || item.FirstName === null){
                        analyticsTrackEvent("First Time Profile Update", { screen_name: "Account Profile", first_name: values.firstname, last_name: values.lastname, email_address: values.email },true)
                    }
                    methodUpdate({ ...item, FirstName: values.firstname, LastName: values.lastname, Gender: values.gender, Nationality: values.nationality, Email: values.email })
                    setIsEditable(false)
                    document.getElementById("profileName").innerHTML = values.firstname+" "+values.lastname
                    document.getElementById("profileEmail").innerHTML = values.email;
                    if(values.firstname){
                        appboy.getUser().setFirstName(values.firstname);
                    }
                    if(values.lastname){
                        appboy.getUser().setLastName(values.lastname);
                    }
                    if(values.gender){
                        appboy.getUser().setGender( values.gender === "F" || values.gender === "female" ? appboy.User.Genders.FEMALE : appboy.User.Genders.MALE);
                    }
                    if(values.email){
                        appboy.getUser().setEmail(values.email);
                    }
                    if(values.PhoneNo){
                        appboy.getUser().setPhoneNumber(values.PhoneNo);
                    }
                }
                else{
                    if(data.StatusCode === 1007){
                        setEmailError(_T("The email you have provided is already associated with another account."))
                    }
                }
                setLoading(false)
            }).catch((e) => {
                console.log(e)
                setIsEditable(false)
                setLoading(false)
            })
        },
    });

    return (
        <section className="profile-section">

            {
                hasHeading ? <div className="section-header d-flex"><h2 className="fs-24 text-gray-500 fw-500 border-line  mt-0">Profile</h2></div> : <></>
            }
            {

                isEditable ?
                    <form onSubmit={formik.handleSubmit}>
                        <div className="inputfields-container d-flex flex-wrap mb-4">

                            <div className="form-content mb-3 pr-2 pl-2">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>First Name</T><span className="text-primary">*</span></label>
                                <input className="" id="firstname" type="text" name="firstname" onChange={formik.handleChange} value={formik.values.firstname} placeholder= {_T("First Name")} />
                                {formik.errors.firstname ? <span className="text-primary fs-12 fw-400">{formik.errors.firstname}</span> : null}

                            </div>
                            <div className="form-content mb-3 pr-2 pl-2">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>Last Name</T><span className="text-primary">*</span></label>
                                <input className="" id="lastname" type="text" name="lastname" onChange={formik.handleChange} value={formik.values.lastname} placeholder= {_T("Last Name")} />
                                {formik.errors.lastname ? <span className="text-primary fs-12 fw-400">{formik.errors.lastname}</span> : null}

                            </div>

                            <div className="form-content mb-3 pr-2 pl-2">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>Email Address</T></label>
                                <input className="" id="email" type="email" name="email" onChange={formik.handleChange} value={formik.values.email.slice(0,50)} placeholder="example@clicktoys.com" readOnly={!enableEmail}/>
                                {formik.errors.email ? <span className="text-primary fs-12 fw-400">{formik.errors.email}</span> : emailError ? <span className="text-primary fs-12 fw-400">{emailError}</span> : null}
                            </div>

                            <div className="form-content mb-3 pr-2 pl-2">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>Mobile</T></label>
                                <input className="" id="number" type="tel" name="number" onChange={formik.handleChange} value={formik.values.number} placeholder="+966-XXX-XXXX"  readOnly={true}/>
                            </div>
                             {/* <div className="form-content mb-3 ">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="nationality">{translations.ProfileTab[language].nationalityLabel}</label>
                                <select  onChange={formik.handleChange} value={nationalityTranslation(formik.values.nationality) ? formik.values.nationality : 0 }  name="nationality" id="nationality">
                                    <option value={0}>{translations.ProfileTab[language].selectNationality}</option>
                                    {
                                        Object.keys(nationalityTranslation()).map((c,i)=>(
                                            <option key={i} value={c}>{nationalityTranslation()[c][language]}</option>
                                        ))
                                    }
                                </select>
                                {formik.errors.nationality ? <span className="text-primary fs-12 fw-400">{formik.errors.nationality}</span> : null}
                            </div> */}

                            <div className="form-content mb-3 pr-2 pl-2">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>Gender</T></label>
                                <div className="toggle-btn mt-2">
                                    <input onChange={formik.handleChange} className="input-radio" type="radio" name="gender" value="M" id="male" checked={formik.values.gender === "M" || formik.values.gender === "male"} /><label className="mr-3 text-gray-400 fw-500 fs-14" htmlFor="male">{_T("Male")}</label>
                                    <input onChange={formik.handleChange} className="input-radio" type="radio" name="gender" value="F" id="female" checked={formik.values.gender === "F" || formik.values.gender === "female"} /><label className="text-gray-400 fw-500 fs-14 " htmlFor="female">{_T("Female")}</label>
                                </div>
                                    {formik.errors.gender ? <span className="text-primary fs-12 fw-400">{formik.errors.gender}</span> : null}
                            </div>
                        </div>
                        
                        <div className="save-cancel justify-end d-flex pb-0">
                            <button type="button" className="cancel-btn btn btn-alternative fs-18 mr-2" onClick={() => { setIsEditable(false) }}><T>Cancel</T></button>
                            { loading ? <button className="save-btn btn btn-primary fs-18" type="button"><T>Please Wait</T></button> : <button className="save-btn btn btn-primary fs-18" type="submit"><T>Save</T></button> }
                        </div>
                    </form> :
                    <>
                        <div className="d-flex  flex-wrap">
                            <div className="form-content mb-3 ">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>First Name</T></label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap">{item.FirstName ? item.FirstName : <T>N/A</T>}</p>
                            </div>
                            <div className="form-content mb-3 ">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>Last Name</T></label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap">{item.LastName ? item.LastName : <T>N/A</T>}</p>
                            </div>
                            <div className="form-content mb-3 ">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>Email Address</T></label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap">{item.Email ? item.Email : <T>N/A</T>}</p>
                            </div>
                            <div className="form-content mb-3 ">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>Mobile</T></label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap">{item.PhoneNo ? item.PhoneNo : <T>N/A</T>}</p>
                            </div>
                            {/* <div className="form-content mb-3">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{translations.ProfileTab[language].nationalityLabel}</label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap">{item.Nationality && item.Nationality !== "0" ? nationalityTranslation(item.Nationality)[language] : translations.ProfileTab[language].notAvailable}</p>
                            </div> */}
                            <div className="form-content mb-3">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block"><T>Gender</T></label>
                                <p className="fw-400 text-gray-400 m-0 word-wrap">{item.Gender ? item.Gender === "M" || item.Gender === "male" ? <T>Male</T> : <T>Female</T> : <T>N/A</T> }</p>
                            </div>
                        </div>
                        <div className="edit-btn"><button type="button" className="link-secondary fs-14 align-items-center d-inline-flex" onClick={() => { setIsEditable(true) }}><i className="ri-settings-3-line icon-position mr-1 mb-1 fs-20"></i><T>Update</T></button></div>
                    </>

            }


        </section>
    )
}
export default ProfileCard;