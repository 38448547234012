import React from "react";
import '../../assets/css/--component-checkout.css'
import noImage from "../../assets/images/no-image.jpg"
import { deleteCart } from "../../helper/backend-methods";
import T, { _T } from "../../locale/translations-main";

function CheckoutProduct({ item, methodDelete }) {
    const props = item.Product;
   

    const removeCartProduct = (id) => {
        deleteCart("?cartItemId="+id).then(({data})=>{
            if(data.Status){
                methodDelete()
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    return (
        <div className={ props.InStock && !props.IsAlreadyPurchased ? "checkout-product" : "checkout-product out-of-stock"}>
            { !props.InStock || props.IsAlreadyPurchased ? <button type="button" onClick={()=>{removeCartProduct(item.Id)}} className="remove-item"><i className="ri-close-line fs-16"></i></button> : <></> }
            <div className="d-flex justify-between align-items-center">
                <div className="left-checkout-product">
                    <div className="product-img">
                        <img src={props.PicturURL ? props.PicturURL : noImage} alt={props.Name} />
                    </div>
                </div>
                <div className="right-checkout-product">
                    <div className="product-title">
                        <p className="text-gray-500 fw-500 fs-14 m-0">{props.Name.length > 40 ? props.Name.slice(0, 37) + "..." : props.Name}</p>
                    </div>
                    {
                        !props.InStock ?
                        <div className="product-out-of-stock d-inline-flex align-items-center text-primary">
                            <i className="ri-error-warning-line mr-1 fs-16"></i> <span className="fs-14 fw-500"><T>Out of Stock</T></span>
                        </div> : props.InStock && props.StockManagement && props.StockManagement.MinStockQuantity > props.StockManagement.StockQuantity ? 
                        <div className="product-out-of-stock d-inline-flex align-items-center text-secondary">
                            <i className="ri-error-warning-line mr-1 fs-14"></i> <span className="fs-12 fw-500">{ props.StockManagement.StockQuantity +" "+ _T("Stock Available") }</span>
                        </div> : <></>
                    }
                    {
                        props.IsAlreadyPurchased ?
                        <div className="product-out-of-stock d-inline-flex align-items-center text-primary">
                            <i className="ri-error-warning-line mr-1 fs-16"></i> <span className="fs-14 fw-500"><T>Already Purchased</T></span>
                        </div> : <></>
                    }
                </div>
                <div className="ror-checkout-product">
                    <div className="product-quantity">
                        <p className="text-right fs-12 fw-500 text-gray-500 m-0"><T>Qty</T>: {props.Quantity}</p>
                    </div>
                    <div className="checkout-product-price">
                        <h2 className="text-right fs-14 fw-500 text-secondary m-0"><span className="fs-14 fw-500 text-gray-500">SAR</span> { Number(props.Total).toFixed(2) }</h2>
                        { props.UnitPriceDiscount ? <h2 className="text-right fs-12 fw-400 text-primary m-0"><s><span>SAR</span> { ((props.Quantity*props.UnitPriceDiscount)+props.Total).toFixed(2) }</s></h2> : <></> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutProduct;