import React, { useEffect, useState } from 'react'
import '../../assets/css/--page-account-track-return.css'
import OrderReturned from '../../assets/images/Return Items.jpg'
import noImage from "../../assets/images/no-image.jpg"
import { trackReturnShipment } from "../../helper/backend-methods"
import { useParams } from 'react-router-dom'

const TrackReturn = (props) => {

    let params = useParams();
    const returnOrderId = params.id
    const [loading, setLoading] = useState(true);
    const [trackReturn, setTrackReturn] = useState([]);
    const [iscopied, setIsCopied] = useState(false);

    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [language, setLanguage] = useState("en")
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Returns", link: "/account/returns" },
        { title: "Return Details", link: `/account/returns/return-details/${returnOrderId}` },
        { title: "Track Return", link: "" },
    ]
    useEffect(() => {
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            setLanguage(lang)
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        configurePageTitle("Track Return")
        configureBreadcrumb(breadcrumbItems)
    }, [])

    useEffect(() => {
        setLoading(true)
        trackReturnShipment({ returnOrderId: returnOrderId }).then(({ data }) => {
            if (data.Status) {
                setTrackReturn(data.Data)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        });
    }, [returnOrderId]);


    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        loading ? <p className="btn btn-alternative w-100">Please wait while the return list is loading...</p> :
            <section id="track-return-section">
                <div className="track-header-container">
                    <div className="track-status-card d-flex justify-start align-items-center">
                        <div className="card-img">
                            <img src={OrderReturned} alt="Order Status Image" />
                        </div>
                        <div className="card-meta">
                            <div className="card-status">
                                <h4 className='fs-16 text-primary fw-600 mt-1 mb-1'>Return Initiated</h4>
                            </div>
                            <div className="card-status-description">
                                <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>Your request will be processed soon.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shipment-info-container mt-5">
                    <div className="shipment-info d-flex align-items-center justify-between flex-wrap mt-4 mb-4">
                        <div className="courier-logo-container">
                            <img src={trackReturn.CourierImage ? trackReturn.CourierImage : noImage} alt="courier-logo" draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                        </div>
                        <div className="courier-title-container">
                            <span className="courier-label text-secondary fs-14 fw-400">Courier</span>
                            <p className="courier-info mt-1 mb-1">{trackReturn.CourierName}</p>
                        </div>
                        <div className="tracking-number-container">
                            <span className="courier-label text-secondary fs-14 fw-400">Tracking Number</span>
                            <p className="courier-info mt-1 mb-1">{trackReturn.TrackingNumber} {iscopied ? <i className="ri-checkbox-circle-fill text-primary"></i> : <i style={{ cursor: "pointer" }} onClick={() => handleCopyClick(trackReturn.TrackingNumber)} className="ri-file-copy-line text-primary"></i>}</p>
                        </div>
                        <div className="shipment-status-container">
                            <span className="courier-label text-secondary fs-14 fw-400">Shipping Address</span>
                            <p className="courier-info mt-1 mb-1">605, Abu Shaghara, near Madinah Supermarket, Sharjah.</p>
                        </div>
                    </div>
                </div>

                <div className="track-refund-info-container mt-5">
                    <div className="track-refund-info">
                        <div className="return-order-number">
                            <h4 className='fs-14 text-gray-600 fw-500'>Return Order No: <span className='fs-14 text-secondary fw-400'>{trackReturn.ReturnOrderNumber}</span></h4>
                        </div>
                        <div className="track-refund-amount d-flex align-items-center justify-between">
                            <p className='fs-14 text-gray-500 fw-400'>Refund Amount:</p>
                            <p className='fs-14 text-gray-500 fw-400'>SAR <span className='fs-14 text-secondary fw-400'>{trackReturn.RefundAmount}</span></p>
                        </div>
                        <div className="track-requested-date d-flex align-items-center justify-between">
                            <p className='fs-14 text-gray-500 fw-400'>Requested Date:</p>
                            <p className='fs-14 text-gray-500 fw-400'>{trackReturn.RequestedDate}</p>
                        </div>
                    </div>
                </div>

                <div className="track-return-current-status mt-5">
                    <div className="track-timeline">
                        <div className="track-timeline-card d-flex justify-start align-items-center">
                            <div className="track-status-current d-flex justify-center align-items-center">
                                <i className="ri-check-line fs-22 fw-600 text-light"></i>
                            </div>
                            <div className="track-timeline-meta">
                                <div className="track-timeline-heading fs-14 text-primary fw-600 mt-1 mb-1">Return Initiated</div>
                                <div className="track-timeline-description">
                                    <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>The request has been initiated</p>
                                </div>
                                <div className="track-timeline-date">
                                    <p className='fs-12 text-gray-300 fw-400 mt-1 mb-1'>June 15 2023, 15:30</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {trackReturn.ProductDetails.length ?
                    <>
                        <div className="return-items-container mt-5">
                            <div className="returns-heading">
                                <h2 className='fs-14 fw-500 mt-0 mb-0'>{"Product Details"}</h2>
                            </div>
                            {trackReturn.ProductDetails.map((pd, index) => {
                                return (
                                    <div key={pd.ProductId} className="return-item-card-container m-4">
                                        <div className="return-item-card d-flex align-items-center justify-start gap-20">
                                            <div className="return-item-image">
                                                <img src={pd.PictureUrl ? pd.PictureUrl : noImage} alt="Return Item Image" draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                            </div>
                                            <div className="return-item-description">
                                                <div className="return-item-title">
                                                    <h4 className='fs-16 text-gray-500 fw-400 mt-1 mb-1'>{pd.Name}</h4>
                                                </div>
                                                <div className="return-item-price">
                                                    <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>Refunded Amount <span className='text-primary'>SAR {pd.RefundedAmount}</span></p>
                                                </div>
                                                <div className="return-item-qty">
                                                    <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>Qty: {pd.Quantity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </> :
                    <></>
                }
            </section>
    )
}

export default TrackReturn
