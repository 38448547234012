const translations = {
    "Payment Methods": {
      "en": "Payment Methods",
      "ar": "طرق الدفع"
    },
    "Your payment card details": {
      "en": "Your payment card details",
      "ar": "تفاصيل بطاقة الدفع الخاصة بك"
    },
    "Fetching your saved debit/credit cards.": {
      "en": "Fetching your saved debit/credit cards.",
      "ar": "تحميل بيانات بطاقات الخصم / الائتمان المحفوظة الخاصة بك."
    },
    "You currently have no debit/credit cards saved.": {
      "en": "You currently have no debit/credit cards saved.",
      "ar": "ليس لديك حاليًا أي بطاقات خصم / ائتمان محفوظة."
    },
    "CARDHOLDER": {
      "en": "CARDHOLDER",
      "ar": "حامل البطاقة"
    },
    "EXPIRY": {
      "en": "EXPIRY",
      "ar": "تاريخ انتهاء الصلاحية"
    },
    "Delete Card": {
      "en": "Delete Card",
      "ar": "حذف البطاقة"
    },
    "Add New Card": {
      "en": "Add New Card",
      "ar": "إضافة بطاقة جديدة"
    },
    "Please provide the following information": {
      "en": "Please provide the following information",
      "ar": "يرجى تقديم المعلومات التالية"
    }
  }
  export default translations