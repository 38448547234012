import { useEffect, useState } from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import '../../assets/css/--page-categories.css'
import { analyticsPageEvent } from "../../components/Analytics";
import { getCategories } from "../../helper/backend-methods";
import Skeleton from "react-loading-skeleton";
import T from "../../locale/translations-main";
import { Link } from "react-router-dom";

function Categories(props) {
    const breadcrumbItems = [ { title: "Home", link: "/" }, { title: "Categories", link: "" } ];
    const [categories,setCategories] = useState([]);
    const [loading,setLoading] = useState(true);


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (props.consent.page) {
            analyticsPageEvent("Categories");
        }
        getCategories().then(({data})=>{
            if(data.Status){
                setCategories(data.Data.CategoryItems)
            }
            setLoading(false)
        }).catch((e)=>{
            setLoading(false)
        })
    }, [])


    return (
        <>
            <BreadCrumbs pageTile="Categories" breadcrumbItems={breadcrumbItems} />
            <section id="categories" className="pt-4 pb-5">
                <div className="wrapper">
                    <T tagName={"h1"} className="fs-26 fw-600 text-gray-500 mt-4 mb-0">Categories</T>
                    <T tagName={"p"} className="fs-18 fw-400 text-gray-400 mt-0 mb-5">Find products based on a category</T>
                    <div className="category-grid">
                        {
                            loading ?
                            [...Array(40)].map((c,k)=>{
                                return <div className="category-card" key={k}>
                                            <Skeleton height="130px" />
                                    </div>
                            }) :
                            categories.length ?
                            categories.map(cat => {
                                return (
                                    <Link to={`/products?category=${cat.Id}`} className="category-card enabled" key={cat.Id} draggable="false">
                                        <div className="category-card-container" draggable="false">
                                            <div className="category-card-heading" draggable="false">
                                                <h3 className="text-light fw-600 fs-16" draggable="false">{cat.Name}</h3>
                                            </div>
                                            <div className="category-card-images" draggable="false">
                                                <img src={cat.PictureURL} alt="" draggable="false"/>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            }) :
                            <button className="btn btn-alternative w-100"><T>There are currently no categories</T></button>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Categories