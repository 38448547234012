import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { analyticsPageEvent } from "../../components/Analytics";
import Breadcrumbs from "../../components/BreadCrumbs";
import { getHowToRi } from "../../helper/backend-methods";
import Skeleton from 'react-loading-skeleton'
import '../../assets/css/--component-content-pages.css'
import { useSearchParams } from "react-router-dom";

const ReturnItemPolicy = () => {
    const [pageContent, setPageContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ search, setSearch] = useSearchParams();

    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Return Item Policy", link: "" },
    ];

    useEffect(() => {
        if (search) {
            if (search.get('locale')) {
                localStorage.setItem("LANG", search.get('locale'))
                window.location = "/return-item-policy/app"
            }
            else {
                getData();
            }
        }
        else {
            getData();
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);


    const getData = () => {
        getHowToRi().then(({ data }) => {
            if (data.Status) {
                setPageContent({ __html: data.Data.Body })
            }
            setLoading(false)
        });
        // if (props.consent.page) {
        //     analyticsPageEvent("Return Item Policy");
        // }
    }
    return (
        <>
            <Breadcrumbs pageTile="Return Item Policy" breadcrumbItems={breadcrumbItems} />
            <section id="refundPolicy" className="pt-4 pb-5">
                <div className="wrapper">
                    {
                        loading ?
                            <>
                                <Skeleton className="mb-3 mt-4" style={{ height: "38px", width: "200px" }} />
                                <Skeleton className="mb-3" style={{ width: "280px" }} />
                                <Skeleton className="w-50 mb-3" />
                                <Skeleton className="mb-3" count={5} />
                                <Skeleton className="mb-3" style={{ height: "34px", width: "200px" }} />
                                <Skeleton className="w-60 mb-3" />
                                <Skeleton className="w-80 mb-3" />
                                <Skeleton className="w-100 mb-3" />
                                <Skeleton className="mb-3" style={{ height: "34px", width: "280px" }} />
                                <Skeleton className="w-50 mb-3" />
                                <Skeleton count={8} className="mb-3" />
                            </> :
                            <>
                                <div className="page-text" dangerouslySetInnerHTML={pageContent} />
                            </>
                    }
                </div>
            </section>
        </>
    )
}

export default ReturnItemPolicy
