const translations = {
    "Manage Preferences": {
      "en": "Manage Preferences",
      "ar": "إدارة التفضيلات"
    },
    "Please configure the options to enable or disable your tracking preferences on the website.": {
      "en": "Please configure the options to enable or disable your tracking preferences on the website.",
      "ar": "يرجى تكوين الخيارات لتمكين أو تعطيل تفضيلات التتبع الخاصة بك على موقع الويب."
    },
    "This website tracks your activities to enhance your shopping experience. The website tracks your activities, such as page views, product searches, and purchases, to provide you with a personalized experience and better understand how you use the website. All data collected is anonymous and will be used solely for the purpose of improving the website and it's services. By clicking 'Accept,' you consent to the collection and use of this information.": {
      "en": "This website tracks your activities to enhance your shopping experience. The website tracks your activities, such as page views, product searches, and purchases, to provide you with a personalized experience and better understand how you use the website. All data collected is anonymous and will be used solely for the purpose of improving the website and it's services. By clicking 'Accept,' you consent to the collection and use of this information.",
      "ar": "هذا الموقع يتتبع أنشطتك لتحسين تجربة التسوق الخاصة بك. يتتبع موقع الويب أنشطتك مثل مشاهدات الصفحة وعمليات البحث عن المنتجات والمشتريات لتزويدك بتجربة مخصصة وفهم أفضل لكيفية استخدامك للموقع. جميع البيانات التي يتم جمعها مجهولة المصدر وسيتم استخدامها فقط لغرض تحسين الموقع وخدماته. بالنقر فوق \"قبول\" ، فإنك توافق على جمع واستخدام هذه المعلومات."
    },
    "Allow tracking my identity on the website": {
      "en": "Allow tracking my identity on the website",
      "ar": "السماح بتتبع هويتي على الموقع"
    },
    "Allow tracking my interactions on the website": {
      "en": "Allow tracking my interactions on the website",
      "ar": "السماح بتتبع تفاعلاتي على الموقع"
    },
    "Allow tracking my page visits on the website": {
      "en": "Allow tracking my page visits on the website",
      "ar": "السماح بتتبع زيارات صفحتي على موقع الويب"
    },
    "Accept": {
      "en": "Accept",
      "ar": "قبول"
    },
    "Remove items from the order": {
      "en": "Remove items from the order",
      "ar": "إزالة العناصر من الطلب"
    }
  }
  export default translations