import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setDefaultLanguage } from "../helper/backend-methods";
import T from "../locale/translations-main";
function Topbar() {


    const [language,setLanguage] = useState("en")

    const changeLang = (e) => {
        const lang = e;
        localStorage.setItem("LANG",lang);
        setDefaultLanguage("?culture="+lang).then(({data})=>{
            if(data.Status){
                window.location.reload(); 
            }
        }).catch((e)=>{
            window.location.reload(); 
        })
    }

    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
            if(lang === "ar"){
                document.getElementsByTagName("body")[0].classList.add("RTL")
            }
        }
    },[])

    return (
    <section id="topbar" className="bg-primary">
        <div className="wrapper d-flex align-items-center justify-between">
            {/* <div className="top-bar-location">
                <a href="https://goo.gl/maps/1JyCrSSB6gewf7Am8" rel="noreferrer" target={"_blank"} className="decoration-none d-flex align-items-center text-light"><svg xmlns="http://www.w3.org/2000/svg" width="16.857" height="19.836" viewBox="0 0 16.857 19.836"><g transform="translate(0.011 0.011)"><path d="M0,7.652A7.678,7.678,0,1,1,15.357,7.7v.087a11.338,11.338,0,0,1-3.478,7.3,20.183,20.183,0,0,1-3.591,2.957.93.93,0,0,1-1.217,0,19.817,19.817,0,0,1-5.052-4.73A9.826,9.826,0,0,1,0,7.678Z" transform="translate(0.739 0.739)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><circle cx="2.461" cy="2.461" r="2.461" transform="translate(5.957 6.078)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></svg>{translations.navigation[language].location}</a>
            </div> */}
            <div className="top-bar-headline text-light">
               <T>Something you love is now on sale!</T> <Link to={"/products"} className="text-light"><T>Buy Now!</T></Link>
            </div>
            <div className="top-bar-right text-right d-inline-flex justify-end">
                <div className="top-bar-language">
                    <span id="languageIcon"></span>
                    {
                        language === "en" ?
                        <span className="fs-18 fw-500 text-light cursor-pointer text-ar" onClick={()=>{changeLang("ar")}}>عربي</span> :
                        <span className="fs-14 fw-500 text-light cursor-pointer text-en" onClick={()=>{changeLang("en")}}>English</span>
                    }
                </div>
            </div>
        </div>
    </section>
    );
}

export default Topbar;