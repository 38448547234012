import homeTranslations from "./home";
import cartTranslations from "./cart";
import checkoutTranslations from "./checkout";
import error404Translations from "./error404";
import contactpageTranslations from "./contact page";
import myAddressTranslations from './my address'
import profileTabTranslations from './profile tab'
import orderTranslations from './my orders'
import occasionTranslations from './occasions'
import paymentTranslations from './payment method'
import reviewTranslations from './my reviews'
import returnTranslations from './returns'
import wishlistTranslations from './my wishlist'
import trackShipmentTranslations from './trackshipment'
import profileSidebarTranslations from './profile sidebar'
import productCardPopupTranslations from './product cardpopup'
import productSingleTranslations from './product single'
import productListingTranslations from './product list'
import productCardTranslations from './product card'
import maintenanceTranslations from './maintenance'
import trackingTranslations from './tracking'
import authenticationTranslations from './authentication'
import navigationTranslations from './navigation'
import footerTranslations from './footer'
const T = ({ children, tagName = null, className = "", onclick = ()=>{} }) => {
    const translations = { ...homeTranslations , ...cartTranslations , ...checkoutTranslations , ...error404Translations , ...contactpageTranslations, ...navigationTranslations , ...footerTranslations , ...myAddressTranslations , ...orderTranslations , ...profileTabTranslations , ...occasionTranslations , ...paymentTranslations , ...reviewTranslations , ...returnTranslations , ...wishlistTranslations , ...trackShipmentTranslations , ...profileSidebarTranslations , ...productCardPopupTranslations , ...productSingleTranslations , ...productCardTranslations , ...error404Translations, ...maintenanceTranslations, ...trackingTranslations, ...authenticationTranslations, ...productListingTranslations};
    let locale = localStorage.getItem("LANG");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    switch (tagName) {
        case "div":
            return <div onClick={()=>{ onclick()}} className={className}>{translations[children] ? translations[children][locale] : children}</div>
            break;
        case "p":
            return <p onClick={()=>{ onclick() }} className={className}>{translations[children] ? translations[children][locale] : children}</p>
            break;
        case "span":
            return <span onClick={()=>{ onclick() }} className={className}>{translations[children] ? translations[children][locale] : children}</span>
            break;
        case "h1":
            return <h1 onClick={()=>{ onclick() }} className={className}>{translations[children] ? translations[children][locale] : children}</h1>
            break;
        case "h2":
            return <h2 onClick={()=>{ onclick ()}} className={className}>{translations[children] ? translations[children][locale] : children}</h2>
            break;
        case "h3":
            return <h3 onClick={()=>{ onclick ()}} className={className}>{translations[children] ? translations[children][locale] : children}</h3>
            break;
        case "h4":
            return <h4 onClick={()=>{ onclick ()}} className={className}>{translations[children] ? translations[children][locale] : children}</h4>
            break;
        case "h5":
            return <h5 onClick={()=>{ onclick() }} className={className}>{translations[children] ? translations[children][locale] : children}</h5>
            break;
        case "h6":
            return <h6 onClick={()=>{ onclick() }} className={className}>{translations[children] ? translations[children][locale] : children}</h6>
            break;
        case "i":
            return <i onClick={()=>{ onclick() }} className={className}>{translations[children] ? translations[children][locale] : children}</i>
            break;
        case "strong":
            return <strong onClick={()=>{ onclick() }} className={className}>{translations[children] ? translations[children][locale] : children}</strong>
            break;
        case "li":
            return <li onClick={()=>{ onclick() }} className={className}>{translations[children] ? translations[children][locale] : children}</li>
            break;
        default:
            return translations[children] ? translations[children][locale] : children;
            break;
    }
}
export default T;

export const _T = (text) => {
    const translations = { ...homeTranslations , ...cartTranslations , ...checkoutTranslations , ...error404Translations , ...contactpageTranslations, ...navigationTranslations , ...footerTranslations , ...myAddressTranslations , ...profileTabTranslations, ...occasionTranslations , ...paymentTranslations, ...reviewTranslations , ...returnTranslations , ...wishlistTranslations , ...trackShipmentTranslations , ...profileSidebarTranslations , ...productCardPopupTranslations , ...productSingleTranslations , ...productCardTranslations, ...error404Translations, ...maintenanceTranslations, ...trackingTranslations, ...authenticationTranslations, ...productListingTranslations};
    let locale = localStorage.getItem("LANG");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    return translations[text] ? translations[text][locale] : text;
}