// Authentication
export const LOGIN = "web/login"
export const REGISTER = "web/customer"
export const SOCIAL_LOGIN = "web/social"
export const FORGET_PASSWORD = "web/passwordRecovery"
export const RESET_PASSWORD = "web/confirmRecovery"
export const UPDATE_PROFILE = "web/updateProfile"
export const GENERATE_OTP = "/genotp"
export const VERIFY_OTP = "/verotp"
export const ASSIGN_PASSWORD = "/inspass"
export const LOGOUT = "web/logout"
// Products
export const PRODUCTS = "web/filterProducts"
export const PRODUCTFILTERS = "web/filteroptions"
export const PRODUCT = "web/product"
export const CATEGORIES = "web/categoryListing"
export const PRODUCTS_BY_TAG = "web/productListByTagId"
export const RECENT_PRODUCTS = "web/recentProducts"
export const ADD_WISHLIST = "web/addWishlist"
export const GET_WISHLIST = "web/getWishlist"
export const ADD_CART = "web/post"
export const GET_PRODUCT_CART = "web/getCart"
export const GET_RECENT_PRODUCT = "web/recentProducts"
export const GET_GIFT_WRAP = "web/getGiftWrappings"
export const UPDATE_CART = "web/updateCart"
export const DELETE_CART = "web/deleteCart"
export const APPLY_COUPON = "/applyCouponCode"
export const GET_CHECKOUT_DETAILS = "web/checkoutItems"
export const CHECKOUT = "web/checkout"
export const REMOVE_COUPON = "/removeCouponCode"
export const ADD_REVIEW = "web/addReview"
export const GET_PARENT_CATEGORY = "web/parentCategories"
export const GET_MANUFACTURERS = "web/manufacturers"
export const GET_BANNERS = "web/promoSlides"
export const GET_TAGGED_PRODUCTS = "web/productTags"


// User Data
export const USER_DATA = "web/getProfile"
export const USER_ADDRESS = "/getAddresses"
export const UPDATE_ADDRESS = "/address"
export const POST_ADDRESS = "/address"
export const DELETE_ADDRESS = "/address"
export const GET_ORDER_LIST = "web/orderListing"
export const GET_ORDER_TRACKING = "web/orderTracking"
export const SEND_ORDER_INVOCIE = "/sendInvoice"
export const DELETE_CARD = "web/deleteCardById"
export const MAKE_CARD_DEFAULT = "web/setDefaultCard"
export const ADD_CARD_DETAILS = "web/addCardDetails"

export const GET_SHIPMENT_LIST = "/shipmentList"
export const GET_PENDING_ITEMS = "/pendingItems"
export const GET_TRACK_SHIPMENT = "/shipmentTrack"
export const GET_RETURN_LIST = "/returnOrderList"
export const GET_RETURN_ORDER_DETAIL = "/returnOrderDetails"
export const TRACK_RETURN_SHIPMENT = "/trackReturnShipment"
export const CANCEL_RETURN_REQUEST = "/cancelReturnOrder"
export const GET_RETURNABLE_SHIPMENT_ITEMS = "/returnShipmentItems"
export const POST_RETURN_ORDER_REQUEST = "/returnOrderRequest"

//User Profile
export const GET_USER_REVIEW = "web/userReviews"
export const UPDATE_PROFILE_PICTURE = "web/profilePicture"
export const GET_CARDS = "web/getAllCardDetails"
export const DEFAULT_LANGUAGE = "setDefaultCustomerLanguage"


export const GETREGIONS = "/regions"
export const GETCITIES = "/cities"
export const GETOCCASIONS = "web/getoccasions"
export const OCCASION = "web/occasions"
export const GETOCCASIONDETAILS = "web/getoccasiondetails"
export const OCCASIONINVITE = "web/occasionInvitation"
export const OCCASIONPRODUCT = "web/occasionproduct"
export const CANCELORDER = "/cancelOrder"
export const GETORDERREASONS = "/getOrderReasons"
export const GETORDERREASONTYPES = "/getReasonTypes"
export const POSTRETURNORDER = "/returnOrderRequest"

export const GETRETURNPOLICY = "/returnPolicy"
export const GETPRIVACYPOLICY = "/privacyPolicy"
export const GETTERMSOFUSE = "/termsAndConditions"
export const GET_HOW_TO_RI_POLICY = "/howToRI"
export const REMOVE_RETURN_ITEMS = "/removeReturnItem"
export const SAVE_RETURN_IMAGE = "/saveReturnImage"
