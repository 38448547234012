import { Link } from "react-router-dom"
import React, { useState } from 'react';
import "../../assets/css/--component-cart-product.css"
import { updateCart, deleteCart } from "../../helper/backend-methods";
import noImage from "../../assets/images/no-image.jpg"
import noWrap from "../../assets/images/wraps/no-wrap.png"
import { analyticsTrackEvent } from "../../components/Analytics";
import moment from "moment";
import T, { _T } from "../../locale/translations-main";

function CartProduct({ item, isOccasion, methodUpdate, methodDelete, index, wraps , itemCount, cartError }) {
    const props = item;
    const [quantity, setQuantity] = useState(props.Quantity);
    const [wrap, setWrap] = useState(props.GiftCard ? true : false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initialWrap, setInitialWrap] = useState(props.GiftCard ? props.GiftCardDetails.GiftWrappingImage : null)
    const [initialFrom, setInitialFrom] = useState(props.GiftCard ? props.GiftCardDetails.From : "")
    const [initialTo, setInitialTo] = useState(props.GiftCard ? props.GiftCardDetails.To : "")
    const [initialMessage, setInitialMessage] = useState(props.GiftCard ? props.GiftCardDetails.Message : "")
    const [initialCard, setInitialCard] = useState(props.GiftCard ? props.GiftCardDetails.HasCard : false)
    const [errors, setErrors] = useState({ initialWrap: "", initialFrom: "", initialTo: "", initialMessage: "" })
    const [wrapForAll , setWrapForAll] = useState(false)
    const [cardForAll , setCardForAll] = useState(false)
    const [loading, setLoading]= useState(false);
    const [qtyLoading, setQtyLoading]= useState(false);

    const validateSubmit = (e) => {
        e.preventDefault();
        analyticsTrackEvent("CTA Clicked", { cta_name: "Apply Gift Wrap", screen_name: "Cart Page" })
        const errors = { initialWrap: "", initialFrom: "", initialTo: "", initialMessage: "" };
        let flag = false;
        if(initialCard){
            // if (!initialWrap || initialWrap.replaceAll(/\s/g, '').length < 2) {
            //     errors.initialWrap = 'Please choose a gift wrapping sheet.';
            //     flag = true
            // }
            if (!initialFrom || initialFrom.replaceAll(/\s/g, '').length < 2) {
                errors.initialFrom = <T>Sender name is required.</T>;
                flag = true
            }
            // else if (!/^[A-Za-z\s]*$/.test(initialFrom)) {
            //     errors.initialFrom = translation.fromError;
            //     flag = true
            // }
            if (!initialTo || initialTo.replaceAll(/\s/g, '').length < 2) {
                errors.initialTo = <T>Receiver name is required.</T>;
                flag = true
            }
            // else if (!/^[A-Za-z\s]*$/.test(initialTo)) {
            //     errors.initialTo = translation.toError;
            //     flag = true
            // }
            if (!initialMessage || initialMessage.replaceAll(/\s/g, '').length < 2) {
                errors.initialMessage = <T>Please type a message for the receiver.</T>;
                flag = true
            }
        }
        setErrors(errors);
        if(!flag){
            setLoading(true)
            updateCart({CartItemId: props.Id, Quantity: quantity, GiftCard: (initialWrap || initialCard) ? true : false, wrapForAll: wrapForAll, GiftCardDetails:  (initialWrap || initialCard) ? { From: initialFrom, To: initialTo, Message: initialMessage, HasCard:initialCard , GiftWrappingImage: initialWrap, cardForAll: cardForAll } : null}).then(({data})=>{
                if(data.Status){
                    updateCartItems({...props, GiftCard: (initialWrap || initialCard) ? true : false, wrapForAll: wrapForAll , GiftCardDetails:  (initialWrap || initialCard) ? { From: initialFrom, To: initialTo, Message: initialMessage, HasCard:initialCard , GiftWrappingImage: initialWrap, cardForAll: cardForAll } : null});
                    setIsModalOpen(false);
                    setWrap(initialWrap !== null || initialCard)
                }
                setLoading(false)
            }).catch((e)=>{
                setLoading(false)
                console.log(e)
            })
        }
    }
    const updateCartItems = (item) => {
        methodUpdate(item)
    }
    const removeCartItem = (item) => {
        deleteCart("?cartItemId="+item.Id).then(({data})=>{
            if(data.Status){
                analyticsTrackEvent("Removed from Cart", { screen_name: "Cart Page", product_id: item.Id, brand: item.ManufacturerName, category: item.CategoryName })
                methodDelete()
            }
        }).catch((e)=>{
            console.log(e)
        })
    }
    const updateQuantity = (n) => {
        setQtyLoading(true)
        updateCart({CartItemId: props.Id, increment: n}).then(({data})=>{
            setQtyLoading(false)
            if(data.Status){
                if(n){
                    analyticsTrackEvent("CTA Clicked", { cta_name: "Increase Quantity", screen_name: "Cart Page" })
                    setQuantity(quantity+1)
                    updateCartItems({ ...props, Quantity: quantity+1 })
                }
                else{
                    analyticsTrackEvent("CTA Clicked", { cta_name: "Decrease Quantity", screen_name: "Cart Page" })
                    setQuantity(quantity-1)
                    updateCartItems({ ...props, Quantity: quantity-1 })
                }
            }
            else{
                if( data.StatusCode === 1063 || data.StatusCode === 1064 ){
                    cartError(true)
                }
            }
            setQtyLoading(false)
        }).catch((e)=>{
            setQtyLoading(false)
            console.log(e)
        })
    }
    const removeWrap = () => {
        analyticsTrackEvent("CTA Clicked", { cta_name: "Remove Wrap", screen_name: "Cart Page" })
        updateCart({CartItemId: props.Id, Quantity: quantity, GiftCard: false, GiftCardDetails: null}).then(({data})=>{
            if(data.Status){
                setInitialWrap(null)
                setInitialFrom("")
                setInitialTo("")
                setInitialMessage("")
                setInitialCard(false)
                setCardForAll(false)
                setWrapForAll(false)
                setErrors({ initialWrap: "", initialFrom: "", initialTo: "", initialMessage: "" })
                updateCartItems({...props, GiftCard: false, GiftCardDetails: null });
                setIsModalOpen(false);
                setWrap(false)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }
    const resetForm = () => {
        setInitialWrap(props.GiftCard ? props.GiftCardDetails.GiftWrappingImage : null)
        setInitialFrom(props.GiftCard ? props.GiftCardDetails.From : "")
        setInitialTo(props.GiftCard ? props.GiftCardDetails.To : "")
        setInitialMessage(props.GiftCard ? props.GiftCardDetails.Message : "")
        setInitialCard(props.GiftCard ? props.GiftCardDetails.HasCard : false)
        setCardForAll(false)
        setWrapForAll(false)
        setErrors({ initialWrap: "", initialFrom: "", initialTo: "", initialMessage: "" })
    }


    return (
        <div className="single-card-product-container">
            <div className={ props.Product.InStock && !props.Product.IsAlreadyPurchased ? "cart-product" : "cart-product out-of-stock" }>
                { props.Product.InStock && !props.Product.IsAlreadyPurchased ? <></> : <span className="product-delivery fw-500"><i className="ri-error-warning-line"></i> { !props.Product.InStock ? <T>Out of Stock</T> : <T>Already Purchased</T>}</span>}
                { props.Product.InStock && props.Product.StockManagement && props.Product.StockManagement.MinStockQuantity > props.Product.StockManagement.StockQuantity ? <span className="product-delivery fw-500"><i className="ri-error-warning-line"></i> { props.Product.StockManagement.StockQuantity +" "+ _T("Stock Available") }</span> : <></>}
                <button className="btn btn-primary" onClick={() => { removeCartItem(props) }}><i className="ri-close-line"></i></button>
                <div className="product-image-wrapper">
                    <Link to={`/product/${props.Product.Id}`} className="product-image">
                        <img src={props.Product.PicturURL ? props.Product.PicturURL : noImage} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} alt={props.Name} draggable={false} />
                    </Link>
                </div>
                <div className="product-title-container">
                    <Link to={`/product/${props.Product.Id}`} className="product-title" title={props.Product.Name}>{props.Product.Name.length > 40 ? props.Product.Name.slice(0, 37) + "..." : props.Product.Name}</Link>
                    {
                        // props.Product.Category ?
                        //     <div className="d-flex align-items-center fs-14 flex-wrap mb-2">
                        //         <span className="mr-2">Brand: </span> <Link to={"/products"} className="text-primary decoration-none mr-2">{props.Product.Category}</Link>
                        //     </div> : null
                    }
                </div>
                <div className="product-price-container d-inline-flex flex-column">
                    <span className="fs-12 mb-1 text-gray-400 fw-400"><T>Price</T></span>
                    <div className="product-price">
                        <h2 className="single-product-price text-secondary m-0 w-100"><span className="text-gray-500">SAR</span> {(props.Product.Price).toFixed(2)}</h2>
                        { props.Product.Discount ? <span className="single-product-discount"><T>Saved</T>: SAR {(props.Product.Discount).toFixed(2)}</span> : <></> }
                    </div>
                </div>
                <div className="product-quantity-container">
                    <span className="fs-12 mb-2 text-gray-400 w-400 d-block"><T>Quantity</T></span>
                    <div className="d-inline-flex align-items-center">
                        {
                            parseInt(isOccasion) === 0 && props.Product.InStock && !props.Product.IsAlreadyPurchased ?
                            <>
                                <button className="product-quantity-btn" disabled={qtyLoading} onClick={() => { quantity <= 1 ? console.log("Can not reduce quantity") : updateQuantity(false) }}>-</button>
                                {/* <button className="product-quantity-btn" onClick={() => { quantity <= 1 ? console.log("Can not reduce quantity") : updateQuantity(quantity - 1) }}>-</button> */}
                                <input className="product-quantity-field" value={quantity} placeholder={props.Quantity} readOnly />
                                <button className="product-quantity-btn" disabled={qtyLoading} onClick={() => { updateQuantity(true) }}>+</button>
                                {/* <button className="product-quantity-btn" onClick={() => { updateQuantity(quantity + 1) }}>+</button> */}
                            </> :
                            <>
                                <input className="product-quantity-field" value={1} placeholder={props.Quantity} readOnly />
                            </>
                        }
                        
                    </div>
                </div>
                <div className="product-total-container d-inline-flex flex-column">
                    <span className="fs-12 mb-1 text-gray-400 fw-400"><T>Total</T></span>
                    <div className="product-price d-flex align-items-center">
                        <h2 className="single-product-price text-secondary m-0"><span className="text-gray-500">SAR</span> {Number(props.Product.Total).toFixed(2)}</h2>
                        { props.Product.DiscountedAmount ? <s className="fs-14 fw-400 text-primary ml-2">SAR {(props.Product.Quantity*props.Product.Price).toFixed(2)}</s> : <></> }
                        { props.Product.DiscountedAmount === 0 && props.Product.Discount ? <s className="fs-14 fw-400 text-primary ml-2">SAR {(props.Product.Quantity*props.Product.Price).toFixed(2)}</s> : <></> }
                    </div>
                    {
                        wraps.length && props.Product.InStock ?
                        wrap ?
                            <button onClick={() => { setIsModalOpen(true) }} className="btn btn-alternative"><i className="ri-gift-2-line"></i><T>Rewrap</T></button> :
                            <button onClick={() => { setIsModalOpen(true) }} className="btn btn-primary"><i className="ri-gift-2-line"></i><T>Gift Wrap</T></button>
                        : <></>
                    }

                </div>
            </div>
            {
                props.Product.ExpectedDelivery ?
                <span className="fs-12 fw-500 expected-delivery-date">
                    <T>Expected Delivery</T>: <span className="LTR d-inline-block"><span className="LTR">{moment(props.Product.ExpectedDelivery).format("DD")}</span> <span className="LTR">{moment(props.Product.ExpectedDelivery).format("MMM")}</span> <span className="LTR">{moment(props.Product.ExpectedDelivery).format("YYYY")}</span></span>
                </span> : <></>
            }
            <div className={isModalOpen ? "cart-product-modal active" : "cart-product-modal"}>
                <form className="modal-content" onSubmit={validateSubmit}>
                    <div className="modal-header d-flex align-items-center justify-between">
                        <h2><T>Gift Wrapping & Gift Card</T></h2>
                        <button onClick={() => { setIsModalOpen(false); resetForm(); }} type="button"><i className="ri-close-line"></i></button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-section-header">
                            <h2><T>Select Sheet</T></h2>
                        </div>
                        <div className="modal-section-body wrap-options mb-1">
                            <div className="wrap-option">
                                <input type={"radio"} name={`wrap${index}`} onChange={(e)=>{setInitialWrap(null)}} checked={initialWrap === null ? true : false} value={""} id={`wrap000${index}`} />
                                <label htmlFor={`wrap000${index}`}>
                                    <img src={noWrap} alt={"None"} />
                                    <span><T>None</T></span>
                                </label>
                            </div>
                            {
                                wraps.map((w,i)=>{
                                    return <div key={i} className="wrap-option">
                                        <input type={"radio"} name={`wrap${index}`} onChange={(e)=>{setInitialWrap(e.target.value)}} checked={initialWrap === w.ImageUrl ? true : false} value={w.ImageUrl} id={`wrap0${i}${index}`} />
                                        <label htmlFor={`wrap0${i}${index}`}>
                                            <img src={w.ImageUrl} alt={w.Name} />
                                            <span>{w.Name}</span>
                                        </label>
                                    </div>
                                })
                            }
                        </div>
                        {
                            itemCount > 1 ?
                            <div className="mb-4">
                                <input className="input-checkbox fs-14" onChange={(e)=>{setWrapForAll(e.target.checked)}} checked={wrapForAll} value={true} type={"checkbox"} id={`wrapForAll${index}`}/>
                                <label htmlFor={`wrapForAll${index}`} className="fs-14"><T>All gift sheet would be same</T></label>
                            </div> : <></>
                        }
                        {/* <div className="wrap-options">
                            {errors.initialWrap ? <span className="text-primary fs-12 fw-400">{errors.initialWrap}</span> : null}
                        </div> */}
                        <div className="modal-section-header d-flex align-items-center justify-between">
                            <h2><T>Gift Card?</T></h2>
                            <div>
                                <input className="input-checkbox" onChange={(e)=>{setInitialCard(e.target.checked)}} checked={initialCard} value={true} type={"checkbox"} name={`card${index}`} id={`card${index}`}/>
                                <label htmlFor={`card${index}`}><T>Attach Gift Card?</T></label>
                            </div>
                        </div>
                        {
                            initialCard ?
                            <><div className="modal-section-body card-options">
                                <div className="form-group">
                                    <label htmlFor={`from${index}`}><T>From</T><span className="text-primary">*</span></label>
                                    <input type={"text"} placeholder={_T("Sender Name")} onChange={(e)=>{setInitialFrom(e.target.value.slice(0,30))}} value={initialFrom} name={`from${index}`} id={`from${index}`} />
                                    {errors.initialFrom ? <span className="text-primary fs-12 fw-400">{errors.initialFrom}</span> : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor={`to${index}`}><T>To</T> <span className="text-primary">*</span></label>
                                    <input type={"text"} placeholder={_T("Receiver name")} onChange={(e)=>{setInitialTo(e.target.value.slice(0,30))}} value={initialTo} name={`to${index}`} id={`to${index}`} />
                                    {errors.initialTo ? <span className="text-primary fs-12 fw-400">{errors.initialTo}</span> : null}
                                </div>
                                <div className="form-group w-100">
                                    <label htmlFor={`message${index}`}><T>Message</T><span className="text-primary">*</span></label>
                                    <textarea placeholder={_T("Your message here")} name={`message${index}`} onChange={(e)=>{setInitialMessage(e.target.value)}} value={initialMessage} id={`message${index}`}></textarea>
                                    {errors.initialMessage ? <span className="text-primary fs-12 fw-400">{errors.initialMessage}</span> : null}
                                </div>
                            </div>
                            {
                                itemCount > 1?
                                <div className="form-group w-100">
                                    <input className="input-checkbox fs-14" onChange={(e)=>{setCardForAll(e.target.checked)}} checked={cardForAll} value={true} type={"checkbox"} id={`cardForAll${index}`}/>
                                    <label htmlFor={`cardForAll${index}`} className="fs-14"><T>All gift card would have same message</T></label>
                                </div>:<></>
                            }
                            </> :
                            <></>
                        }
                    </div>
                    <div className="modal-footer">
                        {wrap ? <button onClick={() => { removeWrap(); }} type="button" className="btn btn-alternative mr-2"><T>Remove</T></button> : <></>}
                        {
                            loading ?
                            <button disabled={true} type="button" className="btn btn-disabled"><T>Wait</T></button> :
                            initialCard || initialWrap ?
                            <button type="submit" className="btn btn-primary"><T>Finish</T></button> :
                            <button onClick={() => { setIsModalOpen(false); resetForm(); }} type="button" className="btn btn-outline-primary"><T>Cancel</T></button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CartProduct;




