import React, { useState, useEffect } from "react";
import '../../assets/css/--component-account-occasion.css'
import OccasionCard from "./occasion-card";
import OccasionInvitedCard from "./occasion-invited-card";
import countries from "../../data/countries";
import { getCities, getOccasions, getRegions, createOccasion } from "../../helper/backend-methods";
import { analyticsPageEvent } from "../../components/Analytics";
import T, { _T } from "../../locale/translations-main";


function Occasion(props ) {
    const [showTab, setTabShow] = useState(1);
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [reload , setReload] = useState(0);
    const [regions , setRegions] = useState([]);
    const [cities , setCities] = useState([]);
    const [occasionTypes , setOccasionTypes] = useState([]);
    const [myOccasion, setMyOccasion] = useState([]);
    const [invitedOccasion, setInvitedOccasion] = useState([]);
    const [newOccasion, setNewOccasion] = useState(false)
    const [occasionImage , setOccasionImage] = useState('');
    const [occasionImagePost , setOccasionImagePost] = useState(null);
    const [occasionPreview , setOccasionPreview] = useState(null);
    const [occasionTitle , setOccasionTitle] = useState("");
    const [occasionType , setOccasionType] = useState(0);
    const [fullname , setFullname] = useState("");
    const [eventDateTime , setEventDateTime] = useState("");
    const [eventMinDateTime , setEventMinDateTime] = useState("");
    const [eventFlat , setEventFlat] = useState("");
    const [eventStreet , setEventStreet] = useState("");
    const [eventState , setEventState] = useState(0);
    const [eventCity , setEventCity] = useState(0);
    const [loading,setLoading] = useState(true);
    const [apiLoading,setApiLoading] = useState(true);
    const [ language, setLanguage ] = useState("en")
    const [errors, setErrors] = useState({ occasionImage: "", occasionType: "", occasionTitle: "", fullname: "", eventDateTime: "", eventFlat: "", eventStreet: "", eventState: "", eventCity: "" })
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Occasions", link: "" },
    ]
    useEffect(() => {
        setMyOccasion([])
        setInvitedOccasion([])
        getOccasions().then(({data})=>{
            if(data.Status){
                setMyOccasion(data.Data.MyOccasions)
                setInvitedOccasion(data.Data.Invitations)
                setOccasionTypes(data.Data.OccasionTypes)
                if(data.Data.OccasionTypes.length){
                    setOccasionType(data.Data.OccasionTypes[0].Id)
                }
            }
            setLoading(false)
        }).catch((e)=>{
            console.log(e);
        })
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }

    }, [reload]);

    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("Account Occasions");
        }
    },[props.consent.page])
    
    useEffect(() => {
        configurePageTitle("Occasions")
        configureBreadcrumb(breadcrumbItems)
        getRegions().then(({data})=>{
            setEventCity(0)
            setRegions(data.Data)
            // setEventState(data.Data[0].RegionId)
            getCities({regionId: data.Data[0].RegionId}).then(({data})=>{
                // setEventCity(data.Data[0].CityId)
                setCities(data.Data)
                setApiLoading(false)
            }).catch((e)=>{
                setApiLoading(false)
                console.log(e);
            })
        }).catch((e)=>{
            setApiLoading(false)
            console.log(e);
        })

        var now = new Date();
        now.setHours(0,0,0,0)
        now.setDate(now.getDate() + 4)
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        // var hour = now.getHours();
        // var minute = now.getMinutes();
        var localDatetime = year + "-" + (month < 10 ? "0" + month.toString() : month) + "-" +(day < 10 ? "0" + day.toString() : day);
        setEventDateTime(localDatetime)
        setEventMinDateTime(localDatetime)
    }, []);

    const fetchCities = (value) =>{
        setApiLoading(true)
        setEventCity(0)
        setEventState(value)
        getCities({regionId: value}).then(({data})=>{
            // setEventCity(data.Data.CityId)
            setCities(data.Data)
            setApiLoading(false)
        }).catch((e)=>{
            setApiLoading(false)
            console.log(e);
        })
    }

    const removeOccasion = (Id) => {
        setReload(reload+1)
    }

    const validateSubmit = (e) => {
        e.preventDefault();
        var allowedExtension = ['jpeg', 'jpg', 'png'];
        const date = new Date(eventDateTime);
        const now = new Date();
        const tomorrow = new Date(now)
        tomorrow.setDate(tomorrow.getDate() + 1)
        tomorrow.setHours(0,0,0,0)
        const errors = { occasionImage: "", occasionType: "", occasionTitle: "", fullname: "", eventDateTime: "", eventFlat: "", eventStreet: "", eventState: "", eventCity: "" };
        let flag = false;
        // if(!allowedExtension.includes(occasionImage.split('.').pop().toLowerCase())){
        //     errors.occasionImage = translations.Occasions[language].imgFileType;
        //     flag = true;
        // }
        // if (!occasionImage || occasionImage.replaceAll(/\s/g,'').length < 1) {
        //     errors.occasionImage = translations.Occasions[language].validImg;
        //     flag = true;
        // }
        if (!occasionType || parseInt(occasionType) === 0) {
            errors.occasionType = <T>Please select an occasion category.</T>;
            flag = true;
        }
        if (!occasionTitle || occasionTitle.replaceAll(/\s/g,'').length < 1) {
            errors.occasionTitle = <T>Please provide an occasion title.</T>;
            flag = true;
        }
        if (!fullname || fullname.replaceAll(/\s/g,'').length < 2) {
            errors.fullname =  <T>Please provide full name of host.</T>;
            flag = true;
        } 
        // else if (!/^[A-Za-z\s]*$/.test(fullname)) {
        //     errors.fullname =  translations.Occasions[language].numErrorMsg;
        //     flag = true;
        // }
        if (date < tomorrow) {
            errors.eventDateTime = <T>Please select a date/time greater than today.</T>;
            flag = true;
        }
        if (isNaN(date.getTime())) {
            errors.eventDateTime = <T>Please select a valid date.</T>;
            flag = true;
        }
        if (!eventFlat || eventFlat.replaceAll(/\s/g,'').length < 1) {
            errors.eventFlat = <T>Please provide event house/flat no.</T>;
            flag = true;
        }
        if (!eventStreet || eventStreet.replaceAll(/\s/g,'').length < 1) {
            errors.eventStreet = <T>Please provide event street address.</T>;
            flag = true;
        }
        if (!eventState || parseInt(eventState) === 0) {
            errors.eventState = <T>Please select event State/Province.</T>;
            flag = true;
        }
        if (!eventCity || parseInt(eventCity) === 0) {
            errors.eventCity = <T>Please select event City/Town.</T>;
            flag = true;
        }
        setErrors(errors);
        if(!flag){
            setApiLoading(true)
            const formdata = new FormData();
            formdata.append("OccasionPicture", occasionImagePost)
            formdata.append("fullName", fullname)
            formdata.append("title", occasionTitle)
            formdata.append("eventTypeId", occasionType)
            formdata.append("houseNo", eventFlat)
            formdata.append("streetAddress", eventStreet)
            formdata.append("cityId", eventCity)
            formdata.append("regionId", eventState)
            formdata.append("eventDate", eventDateTime)

            createOccasion(formdata).then(({data})=>{
                if(data.Status){
                    setOccasionImagePost(null);
                    setOccasionImage("");
                    setOccasionPreview("");
                    setOccasionTitle("");
                    setOccasionType(0);
                    setFullname("");
                    setEventDateTime("");
                    setEventFlat("");
                    setEventStreet("");
                    setEventState(0);
                    setEventCity(0);
                    setReload(reload + 1)
                }
                setNewOccasion(false)
                setApiLoading(false)
            }).catch((e)=>{
                setNewOccasion(false)
                setApiLoading(false)
                console.log(e)
            })
        }
    }

    const updateImage = (e) => {
        var allowedExtension = ['jpeg', 'jpg', 'png'];
        const err = { occasionImage: "" }
        if (e.target.files && e.target.files[0]) {
            if(!allowedExtension.includes(e.target.value.split('.').pop().toLowerCase())){
                err.occasionImage = <T>Allowed image types are jpeg, jpg, png only.</T>;
                setErrors({...errors,...err })
                setTimeout(() => {
                    setErrors({ occasionImage: "", ...errors })
                }, 5000);
            }
            else{
                setErrors({...errors,...err })
                var file = e.target.files[0];
                setOccasionImagePost(file)
                setOccasionImage(e.target.value)
                var reader  = new FileReader();
                if (file) {
                    reader.readAsDataURL(file);
                } else {
                    setOccasionPreview('')
                }
                reader.onloadend = function () {
                    setOccasionPreview(reader.result)
                }
            }
        }
    }
   
    return (
        <>
            <div className='profile-body-header mb-4'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'><T>Occasions List</T></h1>
                <p className='fs-16 text-gray-400 fw-400 mt-0 mb-0'><T>You can view active occasions here.</T></p>
            </div>
            <div className="tabs w-100 occasion-tabs">
                <div className="d-flex">
                    <button className={showTab === 1 ? " btn-occasion  active" : "btn-occasion "} onClick={() => { setTabShow(1) }}><i className={showTab === 1 ? " ri-shopping-bag-3-line active mr-2" : "ri-shopping-bag-3-line mr-2"}></i><T>My Occasions</T></button>
                    <button className={showTab === 2 ? " btn-occasion  active" : "btn-occasion "} onClick={() => { setTabShow(2) }}> <i className={showTab === 2 ? "ri-gift-line active mr-2" : "ri-gift-line mr-2"}></i><T>Invited Occasions</T></button>
                </div>
            </div>
            {
                loading ?
                <p className="btn btn-alternative w-100 justify-center mt-0"><T>Fetching your occasions and invitations.</T></p> : 
                    <>
                        <div className={showTab === 1 ? "d-flex justify-between flex-wrap" : "d-none justify-between flex-wrap"}>
                            {
                                myOccasion.length ?
                                myOccasion.map((item, index) => {
                                        return <OccasionCard key={index} item={item} removeMethod={removeOccasion}  />
                                    }) : <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>You don't have any active occasions.</T></p>
                            }
                            {
                                newOccasion ?
                                <form onSubmit={validateSubmit} id="createSingleOccasion">
                                    <div className="occasion-card w-100">
                                        <div className='profile-body-header mb-4'>
                                            <h1 className='fs-22 text-gray-500 fw-500 mt-0 mb-0'><T>Create Occasions List</T></h1>
                                            <p className='fs-14 text-gray-400 fw-400 mt-0 mb-0'><T>Fill out the form below to create your occasion here.</T></p>
                                        </div>
                                        <div className="d-flex justify-between flex-wrap">
                                            <div className="occasion-list-content mb-3 w-100 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500 d-block"><T>Occasion Image</T></label>
                                                <label className="occasionImageSelector">
                                                    {
                                                        occasionPreview ?
                                                        <img src={occasionPreview} alt=""/> :
                                                        <i className="ri-add-line"></i> 
                                                    }
                                                    <input className="d-none" name="occasionImage" id="occasionImage" type={"file"} value={occasionImage} onChange={(e)=>{updateImage(e)}}/>
                                                </label>
                                                {errors.occasionImage ? <span className="text-primary fs-12 fw-400 d-block">{errors.occasionImage}</span> : null}
                                            </div>
                                            <div className="occasion-list-content mb-3 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500" htmlFor="occasionType"><T>Occasion Category</T> <span className="text-primary">*</span></label>
                                                <select name="occasionType" id="occasionType" onChange={(e)=>{setOccasionType(e.target.value)}} value={occasionType} className="w-100">
                                                    {
                                                        occasionTypes.map((item,index)=>{
                                                            return <option key={index} value={item.Id}>{item.Name}</option>
                                                        })
                                                    }
                                                </select>
                                                {errors.occasionType ? <span className="text-primary fs-12 fw-400">{errors.occasionType}</span> : null}
                                            </div>
                                            <div className="occasion-list-content mb-3 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500" htmlFor="occasionTitle"><T>Occasion Title</T> <span className="text-primary">*</span></label>
                                                <input type="text" name="occasionTitle" id="occasionTitle" onChange={(e)=>{setOccasionTitle(e.target.value)}} value={occasionTitle} placeholder={_T("Enter occasion title")} className="w-100" />
                                                {errors.occasionTitle ? <span className="text-primary fs-12 fw-400">{errors.occasionTitle}</span> : null}
                                            </div>
                                            <div className="occasion-list-content mb-3 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500" htmlFor="fullname"><T>Your first & last name (Parent/Guardian)</T><span className="text-primary">*</span></label>
                                                <input type="text"  name="fullname" id="fullname"  onChange={(e)=>{setFullname(e.target.value)}} value={fullname} placeholder={_T("Enter full name")} className="w-100" />
                                                {errors.fullname ? <span className="text-primary fs-12 fw-400">{errors.fullname}</span> : null}
                                            </div>
                                            <div className="occasion-list-content mb-3 w-100">
                                                <label className="fs-16 fw-500 text-gray-500"><T>When and where is your event?</T></label>
                                            </div>
                                            <div className="occasion-list-content mb-3 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500" htmlFor="eventDateTime"><T>Event Date</T> <span className="text-primary">*</span></label>
                                                <input type="date" id="eventDateTime" name="eventDateTime"  onChange={(e)=>{setEventDateTime(e.target.value)}} value={eventDateTime} min={eventMinDateTime} placeholder={_T("Enter location of event")} className="w-100"/>
                                                {errors.eventDateTime ? <span className="text-primary fs-12 fw-400">{errors.eventDateTime}</span> : null}
                                            </div>
                                            <div className="occasion-list-content mb-3 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500" htmlFor="eventFlat"><T>House No/Flat No</T><span className="text-primary">*</span></label>
                                                <input type="text" name="eventFlat" id="eventFlat" onChange={(e)=>{setEventFlat(e.target.value)}} value={eventFlat} placeholder={_T("Enter House No/Flat No")}  className="w-100" />
                                                {errors.eventFlat ? <span className="text-primary fs-12 fw-400">{errors.eventFlat}</span> : null}
                                            </div>
                                            <div className="occasion-list-content mb-3 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500" htmlFor="eventStreet"><T>Street Address</T><span className="text-primary">*</span></label>
                                                <input type="text" name="eventStreet" id="eventStreet" onChange={(e)=>{setEventStreet(e.target.value)}} value={eventStreet} placeholder={_T("Enter Street Address")}  className="w-100" />
                                                {errors.eventStreet ? <span className="text-primary fs-12 fw-400">{errors.eventStreet}</span> : null}
                                            </div>
                                            <div className="occasion-list-content mb-3 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500" htmlFor="eventState"><T>Area</T><span className="text-primary">*</span></label>
                                                <select name="eventState" id="eventState" onChange={(e)=>{fetchCities(e.target.value)}} value={eventState} className="w-100">
                                                    <option value={0}><T>Select Area</T></option>
                                                    {
                                                        regions.map((item,index)=>{
                                                            return <option key={index} value={item.RegionId}>{item.RegionName}</option>
                                                        })
                                                    }
                                                </select>
                                                {errors.eventState ? <span className="text-primary fs-12 fw-400">{errors.eventState}</span> : null}
                                            </div>
                                            <div className="occasion-list-content mb-3 occasion-form-field">
                                                <label className="fs-14 fw-400 text-gray-500" htmlFor="eventCity"><T>City/Town</T><span className="text-primary">*</span></label>
                                                <select name="eventCity" id="eventCity" onChange={(e)=>{setEventCity(e.target.value)}} value={eventCity} className="w-100">
                                                    <option value={0}><T>Select City/Town</T></option>
                                                    {
                                                        cities.map((item,index)=>{
                                                            return <option key={index} value={item.CityId}>{item.CityName}</option>
                                                        })
                                                    }
                                                </select>
                                                {errors.eventCity ? <span className="text-primary fs-12 fw-400">{errors.eventCity}</span> : null}
                                            </div>
                                            <div className="occasion-list-content occasion-form-field"></div>
                                        </div>
                                        <div className="save-cancel text-right">
                                            <button type="button" className="btn btn-alternative mr-3" onClick={() => { setNewOccasion(false) }}><T>Cancel</T></button>
                                            <button className="btn btn-primary" disabled={apiLoading} type="submit">{ apiLoading ? <T>Please Wait...</T> : <T>Create Occasion</T> }</button>
                                        </div>
                                    </div>
                                </form> :
                                <div className="add-new-list w-100">
                                    <button type="button" onClick={() => { setNewOccasion(true) }} className="btn btn-outline-primary"><i className="ri-gift-line mr-2"></i><T>Create Occasion</T></button>
                                </div>
                            }
                        </div>
                        <div className={showTab === 2 ? "d-flex justify-between flex-wrap" : "d-none justify-between flex-wrap"}>
                            {
                                invitedOccasion.length ?
                                invitedOccasion.map((item, index) => {
                                    return <OccasionInvitedCard key={index} item={item} />
                                }) : <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line mr-2 fs-22"></i><T>No occasions founds to which you were invited.</T></p>
                            }
                        </div>
                    </>
            }
        </>
    )
}

export default Occasion;