import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import { analyticsTrackEvent } from "../../components/Analytics";

function OfferBanners({consent,banners,bannerLoading}) {
    const [loading,setLoading] = useState(true);
    const [bannerData, setBannerData] = useState([])

    useEffect(()=>{
        if(bannerLoading === false){
            setBannerData(banners.offers);
            setLoading(false);
        }
    },[banners,bannerLoading]);

    const isExternalLink = (url) => {
        const tmp = document.createElement('a');
        tmp.href = url;
        return tmp.host !== window.location.host;
    };

    return (
        !loading && banners.length < 1 ? 
        <></> :
        <section id="promotions">
            {
                loading ?
                <div className="offer-banner d-flex justify-between flex-wrap mb-3">
                    <Link to="/" className="offer-banner-left">
                        <Skeleton height={"400px"} />
                    </Link>
                    <Link to="/" className="offer-banner-right"  >
                        <Skeleton height={"400px"} />
                    </Link>
                </div> :
                <div className="offer-banner d-flex justify-between flex-wrap mb-3">
                    {
                        bannerData.map((b,i)=>{
                            return(
                                isExternalLink(b.HyperLink) ?
                                <div key={i} className="single-offer-banner" onClick={()=>{analyticsTrackEvent("CTA Clicked", { cta_name: "Recent Products Banner", screen_name: "Home Page" }, consent.tracking ); window.open(b.HyperLink, '_blank').focus() }}>
                                    <img src={b.Image} alt="" />
                                </div> :
                                <Link key={i} to={b.HyperLink} className="single-offer-banner" onClick={()=>{analyticsTrackEvent("CTA Clicked", { cta_name: "Recent Products Banner", screen_name: "Home Page" }, consent.tracking );}}>
                                    <img src={b.Image} alt="" />
                                </Link>
                            )
                        })
                    }
                </div>
            }
        </section>
    )
}

export default OfferBanners;