import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import warningIcon from "../../assets/images/failed-icon.png"
import { deleteOccasion } from "../../helper/backend-methods";
import T from "../../locale/translations-main";

function OccasionCard({item, removeMethod }) {

    const [loading, setLoading] = useState(false);
    const [deletePopup , setDeletePopup] = useState(false);
    const [occasioDel , setOccasionDel] = useState(false);


    const deleteOccasionCard = (Id) => {
        setDeletePopup(false)
        setLoading(true)
        deleteOccasion("?occasionId="+Id).then(({data})=>{
            if(data.Status){
                removeMethod(Id)
            }
            else{
                if(data.StatusCode === 0){
                    setOccasionDel(true)
                }
                else{

                }
            }
            setLoading(false)
            setDeletePopup(false)
        }).catch((e)=>{
            console.log(e)
            setLoading(false)            
            setDeletePopup(false)
        })
    }


    return (
        <div className="occasion-card single-occasion-card">
            <div className="occasion-tag">
                <span>{item.Event}</span>
            </div>
            <div className="occasion-close-btn">
                <button className="remove-card" title="Remove from Occasion List" type="button" disabled={loading} onClick={() => { setDeletePopup(true) }} > {loading ? <span className="spinner d-inline-flex align-items-center justify-center"><i className="ri-loader-line"></i></span> : <span> <i className="ri-delete-bin-line"></i> </span>}  </button>
            </div>
            <div className={deletePopup ? "product-card-popup active" : "product-card-popup"}>
                <div className="popup-wrapper">
                    <div className="popup-image-container">
                        <img src={warningIcon} alt="" />
                    </div>
                    <h2 className="text-gray-500 fw-500 fs-20 m-0"><T>DELETE OCCASION</T></h2>
                    <span className="popup-subheading text-gray-400 fw-400 fs-14 mb-4"><T>Are you sure you want to delete the occasion?</T></span>
                    <div className="d-flex align-items-center justify-center">
                    <button className="btn btn-outline-secondary ml-1 mr-1 mb-2" onClick={() => { setDeletePopup(false) }}><T>Cancel</T></button>
                    <button type="button" className={`btn btn-primary ml-1 mr-1 mb-2`} onClick={() => { deleteOccasionCard(item.Id) }}>{ loading ? <T>Please Wait...</T> :  <T>Delete</T>}</button>
                    {/* <button type="button" disabled={apiLoading} className={`btn btn-${ apiLoading ? "disabled" : "primary" } ml-1 mr-1 mb-2`} onClick={() => { removeMethod(item.Id) }}>{ apiLoading ? "Please wait" : translations.occasionCard[language].deleteBtn}</button> */}
                    </div>
                </div>
            </div>
            <div className={occasioDel ? "product-card-popup active" : "product-card-popup"}>
                <div className="popup-wrapper">
                    <div className="popup-image-container">
                        <img src={warningIcon} alt="" />
                    </div>
                    <h2 className="text-gray-500 fw-500 fs-20 m-0"><T>DELETE OCCASION</T></h2>
                    <span className="popup-subheading text-gray-500 fs-14 mb-4"><T>Occasion cannot be deleted once invitation is accepted.</T></span>
                    <button className="btn btn-outline-secondary ml-1 mr-1 mb-2" onClick={() => {setOccasionDel (false) }}><T>Close</T></button>   
                </div>
            </div>
            <div className="days-remaining">
                <p className="text-gray-200 fw-500 fs-12 m-0 LTR">{item.EventDate}</p>
            </div>
            <div className="occasion-body d-flex align-items-center gap-1">
                <div className="left">
                    <div className="occasion-img">
                        <img src={item.Picture} alt="" />
                    </div>
                </div>
                <div className="right">
                    <div className="occasion-user-name">
                        <p className="text-gray-500 fw-500 fs-16 m-0">{item.Title}</p>
                    </div>
                    <div className="dates d-flex gap-1 mb-2">
                        <div className="gift-remaining">
                            <p className="text-gray-400 fw-400 fs-14 m-0"> <span className="text-primary">{item.GiftsRemaining}</span> <T>Gift Remaining</T></p>
                        </div>
                        <div className="gift-purchased">
                            <p className="text-gray-400 fw-400 fs-14 m-0"> <span className="text-primary">{item.GiftsPurchased}</span> <T>Gift Purchased</T></p>
                        </div>
                    </div>
                    <div className="view-gifts">
                        <Link to={"/account/occasions/" + item.Id} className="link"><T>View Occasion</T></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OccasionCard;