import { Link } from "react-router-dom"
import React, { useState, useEffect } from "react";
import "../../assets/css/--component-order-card.css"
import T from "../../locale/translations-main";

function OrderCard({ item }) {
    const props = item;
    const [ language, setLanguage ] = useState("en")
    // const statuses = {
    //     "Delivered": {
    //         "en": "Delivered",
    //         "ar": "تم التوصيل"
    //     },
    //     "Out For Delivery": {
    //         "en": "Out For Delivery",
    //         "ar": "خارج للتوصيل"
    //     },
    //     "OutForDelivery": {
    //         "en": "Out For Delivery",
    //         "ar": "خارج للتوصيل"
    //     },
    //     "Processing": {
    //         "en": "Processing",
    //         "ar": "يعالج"
    //     },
    //     "Order placed": {
    //         "en": "Order placed",
    //         "ar": "تم الطلب"
    //     },
    //     "Pending": {
    //         "en": "Pending",
    //         "ar": "قيد الانتظار"
    //     },
    //     "Cancelled": {
    //         "en": "Cancelled",
    //         "ar": "ألغيت"
    //     },
    //     "PartiallyDelivered_Processing": {
    //         "en": "Partially Delivered Processing",
    //         "ar": "تسليم جزئي للمعالجة",
    //     },
    //     "PartiallyDelivered_Completed": {
    //         "en": "Partially Delivered",
    //         "ar": "سلمت جزئيا",
    //     }
    // }

    const paymentStatus = {
        "pending": {
          "en": "Pending",
          "ar": "قيد الانتظار"
        },
        "authorized": {
          "en": "Authorized",
          "ar": "مُصرَّح به"
        },
        "paid": {
          "en": "Paid",
          "ar": "تم الدفع"
        },
        "partiallyrefunded": {
          "en": "Partially Refunded",
          "ar": "مسترد جزئيًا"
        },
        "refunded": {
          "en": "Refunded",
          "ar": "مسترد"
        },
        "voided": {
          "en": "Voided",
          "ar": "ملغى"
        }
      }
    const statuses = {
        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
        "Out For Delivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "Processing": {
            "en": "Processing",
            "ar": "يعالج"
        },
        "Order placed": {
            "en": "Order placed",
            "ar": "تم الطلب"
        },
        "Pending": {
            "en": "Pending",
            "ar": "قيد الانتظار"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "ألغيت"
        },
        "PartiallyDelivered_Processing": {
            "en": "Partially Delivered Processing",
            "ar": "تسليم جزئي للمعالجة",
        },
        "PartiallyDelivered_Completed": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا",
        },
        "Received": {
            "en": "Received",
            "ar": "تلقى"
        },
        "ProcessedForPicking": {
            "en": "Processed For Picking",
            "ar": "المصنعة للقطف"
        },
        "ProcessedForShipping": {
            "en": "Processed For Shipping",
            "ar": "معالجتها للشحن"
        },
        "PartiallyProcessedForShipping": {
            "en": "Partially Processed For Shipping",
            "ar": "تمت المعالجة جزئياً للشحن"
        },
        "HandedOverToCourier": {
            "en": "Handed Over To Courier",
            "ar": "تسليمه لمندوب الشحن"
        },
        "InTransit": {
            "en": "In Transit",
            "ar": "في مرحلة انتقالية"
        },
        "Attempted": {
            "en": "Attempted",
            "ar": "حاول"
        },
        "PartiallyAttempted": {
            "en": "Partially Attempted",
            "ar": "محاولة جزئية"
        },
        "ReturnToOrigin": {
            "en": "Return To Origin",
            "ar": "العودة إلى الأصل"
        },
        "PartialDeliveryInProgress": {
            "en": "Partial Delivery In Progress",
            "ar": "التسليم الجزئي قيد التنفيذ"
        },
        "PartiallyDelivered": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا"
        },
        "CancelledByCustomer": {
            "en": "Cancelled By Customer",
            "ar": "ألغى العميل"
        },

        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
        "Out For Delivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "PartiallyOutForDelivery": {
            "en": "Partially Out For Delivery",
            "ar": "جزئيًا في طريقه للتسليم" 
        },
        "Processing": {
            "en": "Processing",
            "ar": "يعالج"
        },
        "Order placed": {
            "en": "Order placed",
            "ar": "تم الطلب"
        },
        "Pending": {
            "en": "Pending",
            "ar": "قيد الانتظار"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "ألغيت"
        },
        "PartiallyDelivered_Processing": {
            "en": "Partially Delivered Processing",
            "ar": "تسليم جزئي للمعالجة",
        },
        "PartiallyDelivered_Completed": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا",
        },
        "Received": {
            "en": "Received",
            "ar": "تلقى"
        },
        "ProcessedForPicking": {
            "en": "Processed For Picking",
            "ar": "المصنعة للقطف"
        },
        "PartiallyProcessedForPicking": {
            "en": "Partially Processed For Picking",
            "ar": "تمت معالجته جزئيًا للاختيار"
        },
        "ProcessedForShipping": {
            "en": "Processed For Shipping",
            "ar": "معالجتها للشحن"
        },
        "PartiallyProcessedForShipping": {
            "en": "Partially Processed For Shipping",
            "ar": "تمت المعالجة جزئياً للشحن"
        },
        "HandedOverToCourier": {
            "en": "Handed Over To Courier",
            "ar": "تسليمه لمندوب الشحن"
        },
        "PartiallyHandedOverToCourier": {
            "en": "Partially Handed Over To Courier",
            "ar": "تم تسليمها جزئيًا إلى شركة الشحن"
        },
        "InTransit": {
            "en": "In Transit",
            "ar": "في مرحلة انتقالية"
        },
        "PartiallyInTransit": {
            "en": "Partially In Transit",
            "ar": "جزئيًا في النقل"
        },
        "Attempted": {
            "en": "Attempted",
            "ar": "حاول"
        },
        "PartiallyAttempted": {
            "en": "Partially Attempted",
            "ar": "محاولة جزئية"
        },
        "ReturnToOrigin": {
            "en": "Return To Origin",
            "ar": "العودة إلى الأصل"
        },
        "PartiallyReturnToOrigin": {
            "en": "Partially Return To Origin",
            "ar": "العودة جزئيًا إلى الأصل"
        },
        "PartialDeliveryInProgress": {
            "en": "Partial Delivery In Progress",
            "ar": "التسليم الجزئي قيد التنفيذ"
        },
        "PartiallyDelivered": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا"
        },
        "CancelledByCustomer": {
            "en": "Cancelled By Customer",
            "ar": "ألغى العميل"
        }
    }
    useEffect(() => {
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }
    }, []);


    return (
        <div className="order-detail-card">
            <div className="order-detail-header d-flex justify-between pt-4 pb-4 border-line-light">
                <div className="order-no d-flex">
                    <span className="fs-14 text-gray-400 fw-400"><T>Order</T>#</span>
                    <span className="product-action text-secondary fw-500 fs-14 ml-2"> {props.OrderNo}</span>
                </div>
                <div className="orderplaced-date d-flex">
                    <span className="fs-14 text-gray-400 fw-400"><T>Placed On</T> :</span>
                    <span className="product-action text-gray-500 fw-600 fs-14 ml-2 LTR"> {props.OrderDate}</span>
                </div>
                <div className="order-status d-flex">
                    <span className="fs-14 text-gray-400 fw-400"><T>Status</T> :</span>
                    <span className="product-action text-gray-500 fw-600 fs-14 ml-2"> { statuses[props.Status] ? statuses[props.Status][language] : props.Status }</span>
                </div>
                <div className="delivery-date d-flex">
                    <span className="fs-14 text-gray-400 fw-400">{ props.Status === "Delivered" ? <T>Delivered</T>: props.Status === "Cancelled" || props.Status === "ReturnToOrigin" || props.Status === "CancelledByCustomer" ? <T>Cancelled</T> : <T>Estimated</T> }</span>
                    <span className="product-action text-gray-500 fw-600 fs-14 ml-2 LTR"> { props.Status === "Delivered" ? props.CompletionDate : props.Status === "Cancelled" || props.Status === "ReturnToOrigin" || props.Status === "CancelledByCustomer" ? props.CancellationDate : props.ExpectedDelivery}</span>
                </div>
            </div>
            <div className="order-detail-products">
                <div className="order-detail-footer d-flex justify-between align-items-center pb-3 pt-4 w-100">
                    <div className="total-amount d-flex ">
                        <span className="fs-14 text-gray-400 fw-400"><T>Order Total</T> :</span>
                        <span className="text-gray-500 fw-600 fs-14 ml-2">SAR <span className="text-secondary">{props.TotalAmount}</span></span>
                    </div>
                    <div className="total-amount d-flex ">
                        <span className="fs-14 text-gray-400 fw-400"><T>Payment Status</T> :</span>
                        <span className="text-gray-500 fw-600 fs-14 ml-2">{paymentStatus[props.PaymentStatus.toLowerCase()] ? paymentStatus[props.PaymentStatus.toLowerCase()][language] : props.PaymentStatus}</span>
                    </div>
                    <div className="total-amount d-flex ">
                        <span className="fs-14 text-gray-400 fw-400"><T>Order Items</T> :</span>
                        <span className="text-gray-500 fw-600 fs-14 ml-2">{props.Items}</span>
                    </div>
                    <div className="view-details-btn">
                        <Link to={"/account/orders/"+props.OrderId} className="link-primary fw-500"><T>View Details</T></Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default OrderCard;