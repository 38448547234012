import { Link, useNavigate } from "react-router-dom";
import React, {useRef, useState} from 'react';
import "../../assets/css/--component-login.css"
import logo from "../../assets/images/logo.png";
import logoFromBG from "../../assets/images/login-form-bg.jpg";
import { assignUserPassword, forgotPassword, generateOTP, verifyOTP } from "../../helper/backend-methods";
import { analyticsIdentityEvent, analyticsPageEvent, tiktokIdentity } from "../../components/Analytics";
import translations from '../../data/language.json'
import { useEffect } from "react";
import { generateKey } from "../../helper/api-methods";
import appboy from 'appboy-web-sdk';
import T, { _T } from "../../locale/translations-main";

function ForgetPassword(props){
    const history = useNavigate();
    const [loading, setLoading]= useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [language, setLanguage] = useState("en");
    const [content, setContent] = useState(translations.forgotPassword["en"])
    const [values, setValues] = useState({ commonField: "", otpField: new Array(4).fill(""), passwordField: "", confirmPasswordField: "", showPasswordField: false, showConfirmPasswordField: false, enableOTP: false, enablePassword: false, enableConfirmPassword: false, locked: false })
    const [countDown, setCountDown] = useState(0);
    const timer = useRef(null);
    const otpBoxReference = useRef([]);
    const [errors, setErrors] = useState({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" })

    useEffect(()=>{
        if(props.consent.page){
            analyticsPageEvent("Forgot Password");
        }
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            if(lang === "ar"){
                document.getElementsByTagName("body")[0].classList.add("RTL")
            }
            setContent(lang === "ar" ? translations.forgotPassword["ar"] : translations.forgotPassword["en"])
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },[])


    const handleOtpChange = (value, index) => {
        let newArr = [...values.otpField];
        newArr[index] = value;
        setValues((prev)=>({...prev, otpField: newArr}))
        if(value && index < 4-1){
          otpBoxReference.current[index + 1].focus()
        }
    }
    
    const handleBackspaceAndEnter = (e, index) => {
        if(e.key === "Backspace" && !e.target.value && index > 0){
          otpBoxReference.current[index - 1].focus()
          otpBoxReference.current[index - 1].select()
        }
        if(e.key === "Enter" && e.target.value && index < 4-1){
          otpBoxReference.current[index + 1].focus()
          otpBoxReference.current[index + 1].select()
        }
    }

    const startTimer = (t) => {
        let time = t
        timer.current = setInterval(()=>{
            time = time - 1;
            setCountDown(time);
            if(time === 0){
                clearTimer();
            }
        }, 1000)
    }

    const clearTimer = () => {
        clearInterval(timer.current);
    }
    
    const validateSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let flag = false;
        let type = "email";
        const err = { commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" };
        const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        if (!values || !values.commonField || values.commonField < 6){
            err.commonField = <T>This field is required</T>;
            flag = true;
        }
        else{
            if (!isNaN(values.commonField)) {
                if (!values.commonField || values.commonField.replaceAll(/\s/g,'').length < 9 || values.commonField.replaceAll(/\s/g,'').length > 12 || !values.commonField.startsWith('966') ) {
                // if (!values.commonField || values.commonField.replaceAll(/\s/g,'').length < 9 || values.commonField.replaceAll(/\s/g,'').length > 12  ) {
                    err.commonField = <T>Please provide a valid phone number</T>;
                    flag = true;
                }
                type = "phone";
            }
            else if (!emailRegex.test(values.commonField)) {
                err.commonField = <T>Please provide a valid email address</T>;
                flag = true;
            }
        }
        setErrors(err);
        if(!flag){
            forgotPassword({email: values.commonField}).then(({data})=>{
                if(data.Status){
                    if(type === "email"){
                        setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: content.apiSuccess01+" "+values.commonField+". "+content.apiSuccess02 })
                        setIsSuccess(true)
                    }
                    else{
                        if(data.StatusCode === 5001){
                            setValues((prev)=>({...prev,enableOTP: true}));
                            setCountDown(data.Data.RemainingTimeInSeconds);
                            setTimeout(() => {
                                startTimer(data.Data.RemainingTimeInSeconds);
                            }, 1000);
                        }
                    }
                }
                else{
                    if(data.StatusCode === 2021){
                        setValues((prev)=>({...prev,locked: true}));
                        setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Your account has been locked because too many attempts were made. Please contact support@clicktoys.com for assistance.</T> })
                    }
                    else{
                        setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: type === "email" ? content.apiNoEmailError : content.apiNoPhoneError })
                    }
                    setIsSuccess(false)
                }
                setLoading(false)
            }).catch((e)=>{
                setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: type === "email" ? content.apiNoEmailError : content.apiNoPhoneError })
                setLoading(false)
                setIsSuccess(false)
            })
        }
        else{
            setLoading(false);
        }
    }

    const requestOTP = () => {
        setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" })
        generateOTP({phoneNumber: values.commonField, recovery: true}).then(({data})=>{
            if (data.Status) {
                if(data.StatusCode === 5001){
                    setValues((prev)=>({...prev,enableOTP: true}));
                    setCountDown(data.Data.RemainingTimeInSeconds);
                    setTimeout(() => {
                        startTimer(data.Data.RemainingTimeInSeconds);
                    }, 1000);
                }
                if(data.StatusCode === 5000){
                    setValues((prev)=>({...prev, enablePassword: true, enableConfirmPassword: false}));
                }
                if(data.StatusCode === 5002){
                    setValues((prev)=>({...prev,enablePassword: true, enableConfirmPassword: true}));
                }
            }
            else{
                if(data.StatusCode === 2021){
                    setValues((prev)=>({...prev,locked: true}));
                    setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "Your account has been locked because too many attempts were made. Please contact support@clicktoys.com for assistance." })
                }
                else{
                    setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "Something went wrong. Please try again!" })
                }
            }
            setLoading(false)
        }).catch((error)=>{
            setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "Something went wrong. Please try again!" })
            setLoading(false)
        })
    }

    const tryOTP = (e) => {
        e.preventDefault();
        setLoading(true);
        let flag = false;
        const err = { commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" };
        if(!values || !values.otpField || !values.otpField.length || !values.otpField[0] || !values.otpField[1] || !values.otpField[2] || !values.otpField[3]){
            err.otpField = <T>Please enter your four digit OTP</T>;
            flag = true;
        }
        setErrors(err);
        if(!flag){
            verifyOTP({otp: values.otpField.join(""), phoneNumber: values.commonField, recovery: true}).then(({data})=>{
                if(data.Status){
                    setValues((prev)=>({...prev, enablePassword: true, enableConfirmPassword: true }))
                    setLoading(false);
                }
                else{
                    if(data.StatusCode === 2022){
                        setErrors({ commonField: "", otpField: <T>Your four digit OTP has expired</T>, passwordField: "", confirmPasswordField: "", serverError: "" })
                    }
                    else if(data.StatusCode === 2021){
                        setValues((prev)=>({...prev, locked: true }))
                        setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "Your account has been locked because too many attempts were made. Please contact support@clicktoys.com for assistance." })
                    }
                    else{
                        setErrors({ commonField: "", otpField: <T>Your four digit OTP is invalid</T>, passwordField: "", confirmPasswordField: "", serverError: "" })
                    }
                    setLoading(false);
                }
            }).catch(()=>{
                setErrors({ commonField: "", otpField: <T>Your four digit OTP is invalid</T>, passwordField: "", confirmPasswordField: "", serverError: "" })
                setLoading(false);
            })
        }
        else{
            setLoading(false);
        }
    }

    const tryPassword = (e) => {
        e.preventDefault();
        setLoading(true);
        let flag = false;
        let err = { commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" };
        if (!values.passwordField || values.passwordField.replaceAll(/\s/g,'').length < 6) {
            err.passwordField = <T>Password must have at minimum 6 charaters</T>;
            flag = true;
        }
        if (!values.confirmPasswordField || values.confirmPasswordField.replaceAll(/\s/g,'').length < 6) {
            err.confirmPasswordField = <T>Password must have at minimum 6 charaters</T>;
            flag = true;
        }
        if (values.confirmPasswordField !== values.passwordField) {
            err.confirmPasswordField = <T>Passwords do not match</T>;
            flag = true;
        }
        setErrors(err);
        if(!flag){
            assignUserPassword({ deviceId: generateKey(true), password: values.passwordField, phoneNumber: values.commonField}).then(({data})=>{
                if(data.Status){
                    analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                    localStorage.setItem("authToken", data.Data.Token.Access_Token)
                    localStorage.setItem("userinfo", JSON.stringify(data.Data.UserInfo))
                    tiktokIdentity({"email": data.Data.UserInfo.Email, "phone_number": values.commonField, "external_id": data.Data.UserInfo.CustomerId})
                    appboy.changeUser(data.Data.UserInfo.CustomerGuid);
                    if(data.Data.UserInfo.FullName){
                        appboy.getUser().setFirstName(data.Data.UserInfo.FullName);
                    }
                    if(data.Data.UserInfo.Email){
                        appboy.getUser().setEmail(data.Data.UserInfo.Email);
                    }
                    if(data.Data.UserInfo.Phone){
                        appboy.getUser().setPhoneNumber(data.Data.UserInfo.Phone);
                    }
                    history('/');
                }
                else{
                    if(data.StatusCode === 2027){
                        setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: data.Message })
                    }
                    else{
                        setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "Something went wrong. Please try again!" })
                    }
                    setLoading(false)
                }
            }).catch(()=>{
                setErrors({ commonField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "Something went wrong. Please try again!" })
                setLoading(false)
            })
        }
        else{
            setLoading(false)
        }
    }

    return (
        <section id="login">
            <div className="wrapper" style={{"width" : "100%"}}>
                <div className="login-form-wrapper">
                    {
                        !values.locked?
                        !values.enableOTP ?
                        <form onSubmit={validateSubmit}>
                            <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                            <img src={logo} alt="ClickToys" className="form-logo"/>
                            <h1 className="form-title"><T>Forgot Password?</T></h1>
                            <p className="form-description"><T>Please provide your account email address to reset your password</T></p>
                            <div className="form-group">
                                <label htmlFor="commonField"><T>Email / Phone</T><span className="text-primary">*</span></label>
                                <input id="commonField" readOnly={isSuccess} type="text" name="commonField" onChange={(e)=>{setValues((prev)=>({...prev,commonField: e.target.value.replaceAll(/\s/g,'').slice(0,50)}))}} value={values.commonField} placeholder={_T("Enter Email / Phone")} className="text-left" />
                                {errors.commonField ? <span className="text-primary fs-12 fw-400">{errors.commonField}</span> : null}
                            </div>
                            { errors.serverError ? <p className="text-primary fs-14 fw-400"><T>{errors.serverError}</T></p> : null }
                            { !isSuccess ? <div className="form-group"> <button type="submit" disabled={loading} className="btn btn-primary w-100">{ loading ? <T>Please Wait...</T> : <T>Reset Password</T>}</button> </div> : null }
                        </form> :
                        !values.enablePassword && !values.enableConfirmPassword ?
                        <form onSubmit={tryOTP}>
                            <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                            <img src={logo} alt="ClickToys" className="form-logo" />
                            <h1 className="form-title"><T>Welcome</T></h1>
                            <p className="form-description"><T>Witness a world filled with happiness and fun</T></p>
                            <p className="fs-14 fw-500 text-dark mb-0 text-center"><T>We have sent a four digit OTP to your provided mobile number ********</T>{values.commonField.slice(-4)}.</p>
                            <p className="fs-14 fw-500 text-dark mt-1 text-center"><T>Please enter it below to continue</T></p>
                            <div className="form-group">
                                <div className="otp-fields">
                                    {
                                        values.otpField.map((digit, index)=>(
                                            <input key={index} value={digit} maxLength={1} onChange={(e)=> handleOtpChange(e.target.value, index)} onKeyUp={(e)=> handleBackspaceAndEnter(e, index)} ref={(reference) => (otpBoxReference.current[index] = reference)} className="text-center"/>
                                        ))
                                    }
                                </div>
                                { errors.otpField ? <p className="text-primary fs-12 fw-400 text-center m-0">{errors.otpField}</p> : null }
                            </div>

                            {
                                countDown ?
                                <p className="text-gray-400 fs-14 fw-500 mt-0 mb-2 text-center"><T>Request OTP in</T> <span>{countDown}</span> <T>seconds</T></p> :
                                <p className="text-gray-400 fs-14 fw-500 mt-0 mb-2 text-center"><T>Didn't Recieve an OTP?</T> <span className="text-primary fw-500 cursor-pointer" onClick={()=>{ !loading ? requestOTP() : console.log(); }}><T>Request Now</T></span></p>
                            }

                            { errors.serverError ? <p className="text-center text-primary fs-14 fw-400"><T>{errors.serverError}</T></p> : null }
                            <div className="form-group">
                                <button type="submit" disabled={loading} className="btn btn-primary w-100">{loading ? <T>Please Wait...</T> : <T>Continue</T>}</button>
                            </div>
                        </form> :
                        <form onSubmit={tryPassword}>
                            <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                            <img src={logo} alt="ClickToys" className="form-logo" />
                            <h1 className="form-title"><T>Welcome</T></h1>
                            <p className="form-description"><T>Witness a world filled with happiness and fun</T></p>
                            <div className="form-group has-icon">
                                <label htmlFor="passwordField"><T>New Password</T><span className="text-primary">*</span></label>
                                <input id="passwordField" type={values.showPasswordField ? "text" : "password"} name="passwordField" onChange={(e)=>{setValues((prev)=>({...prev,passwordField: e.target.value.replaceAll(/\s/g,'').slice(0,30)}))}}  value={values.passwordField} placeholder={_T("Enter Password")} />
                                <button onClick={()=>{ setValues((prev)=>({...prev,showPasswordField: !prev.showPasswordField})) }} type="button" >{values.showPasswordField ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                                {errors.passwordField ? <span className="text-primary fs-12 fw-400">{errors.passwordField}</span> : null}
                            </div>
                            <div className="form-group has-icon">
                                <label htmlFor="confirmPasswordField"><T>Confirm New Password</T><span className="text-primary">*</span></label>
                                <input id="confirmPasswordField" type={values.showConfirmPasswordField ? "text" : "password"} name="confirmPasswordField" onChange={(e)=>{setValues((prev)=>({...prev,confirmPasswordField: e.target.value.replaceAll(/\s/g,'').slice(0,30)}))}}  value={values.confirmPasswordField} placeholder={_T("Enter Password")} />
                                <button onClick={()=>{ setValues((prev)=>({...prev,showConfirmPasswordField: !prev.showConfirmPasswordField})) }} type="button" >{values.showPasswordField ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                                {errors.confirmPasswordField ? <span className="text-primary fs-12 fw-400">{errors.confirmPasswordField}</span> : null}
                            </div>
                            { errors.serverError ? <p className="text-center text-primary fs-14 fw-400"><T>{errors.serverError}</T></p> : null }
                            <div className="form-group">
                                <button type="submit" disabled={loading} className="btn btn-primary w-100">{loading ? <T>Please Wait...</T> : <T>Continue</T>}</button>
                            </div>
                        </form> :
                        <form className="d-flex flex-column align-items-center justify-center">
                            <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                            <img src={logo} alt="ClickToys" className="form-logo" />
                            <h1 className="form-title"><T>Account Locked</T></h1>
                            <p className="form-description"><T>Too Many Attempts Were Made</T></p>
                            { errors.serverError ? <p className="text-center text-primary fs-14 fw-400"><T>{errors.serverError}</T></p> : null }
                        </form> 
                    }
                    <div className="login-background">
                        <img src={logoFromBG} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default ForgetPassword;