import React, { useState, useEffect, useContext, useRef } from "react";
import Carousel from "react-multi-carousel";
import Skeleton from 'react-loading-skeleton'
import translations from '../../data/language.json'
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context";
import T from "../../locale/translations-main";

function ShopByCategories() {
    const { categoryList } = useContext(GlobalContext);
    const xAxisStart = useRef(0);
    const navigate = useNavigate();
    const responsive = {
        size01: {
            breakpoint: { max: 10000, min: 3001 },
            items: 8
        },
        size02: {
            breakpoint: { max: 3000, min: 1481 },
            items: 8
        },
        size03: {
            breakpoint: { max: 1480, min: 1201 },
            items: 6
        },
        size04: {
            breakpoint: { max: 1200, min: 901 },
            items: 4
        },
        size05: {
            breakpoint: { max: 900, min: 601 },
            items: 4
        },
        size06: {
            breakpoint: { max: 600, min: 0 },
            items: 3
        },
        // size07: {
        //     breakpoint: { max: 500, min: 0 },
        //     items: 2
        // }
    };

    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)
    const [ language, setLanguage ] = useState("en")

    useEffect(() => {
        if(categoryList && categoryList.length){
            setCategories(categoryList)
            setLoading(false);
        }
 
    }, [categoryList])


    const itemClicked = (e, link) => {
        if (Math.abs(xAxisStart.current - e.clientX) < 20) {
            navigate(link)
        }
        xAxisStart.current = 0
    }

    const SliderBtn = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide, totalItems, slidesToShow } } = rest;
        return(
            <div className="carousel-navigation">
            <button className="carousel-navigation-left carousel-navigation-button position-absolute" disabled={ language === "ar" ? currentSlide === totalItems-slidesToShow : currentSlide === 0 } onClick={()=>{ language === "ar" ? next() : previous()}}><i className="ri-arrow-left-s-line"></i></button>
            <button className="carousel-navigation-right carousel-navigation-button position-absolute" disabled={ language === "ar" ? currentSlide === 0 : currentSlide === totalItems-slidesToShow } onClick={()=>{ language === "ar" ? previous() : next()}}><i className="ri-arrow-right-s-line"></i></button>
        </div>
        )
    }

    return (
        <section id="category_section">

            <div className="category-title d-flex justify-between align-items-center mt-5 mb-3">
                <div className="category-heading">
                    <h2 className="fw-500 text-gray-500 fs-22 m-0"><T>Shop By Category</T></h2>
                    <p className="text-gray-300 m-0"><T>Find products based on a category</T></p>
                </div>
                <div className="category-button">
                    <Link className="link" to="/categories"><T>View All</T></Link>
                </div>
            </div>
           <div className="category-carousel-container position-relative">
            {
                loading ?
                <Carousel rtl={language === "ar"} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<SliderBtn/>} responsive={responsive} >
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                    <div className="category-card">
                        <div className="category-card-container pt-3 pb-3 pl-3 pr-3">
                            <Skeleton height="190px" />
                        </div>
                    </div>
                </Carousel> :
                <Carousel rtl={language === "ar"} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<SliderBtn/>} responsive={responsive} >
                    {
                        categoryList.map(cat => {
                            return (
                                <div onMouseDown={(e) => { xAxisStart.current = e.clientX }} onMouseUp={(e) => itemClicked(e, `/products?category=${cat.Id}`)} className="category-card enabled" key={cat.Id} draggable="false">
                                    <div className="category-card-container" draggable="false">
                                        <div className="category-card-heading" draggable="false">
                                            <h3 className="text-light fw-600 fs-16" draggable="false">{cat.Name}</h3>
                                        </div>
                                        <div className="category-card-images" draggable="false">
                                            <img src={cat.PictureURL} alt="" draggable="false"/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Carousel>
            }
           </div>
        </section>
    )
}


export default ShopByCategories;