// import { AnalyticsBrowser } from '@segment/analytics-next'
import appboy from 'appboy-web-sdk';
// export const analytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_ANALYTICS_WRITE_KEY })

export const analyticsPageEvent = (title) => {
    let locale = localStorage.getItem("LANG")
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar-SA" : "en-US"
    }
    if (window.ttq) {
        window.ttq.page();
        window.ttq.track('PageView', { screen_name: title, locale: locale ? locale : "en-US"});
    }
    if (window.snaptr) {
        window.snaptr('track', 'PAGE_VIEW', { screen_name: title, locale: locale ? locale : "en-US"});
    }
    if (window.gtag) {
        window.gtag('config', 'AW-16609828685', { screen_name: title, locale: locale ? locale : "en-US", page_path: window.location.href });
    }
    if (window.twq) {
        window.twq('track', 'PageView', {screen_name: title, locale: locale ? locale : "en-US", page_path: window.location.href });
    }
    appboy.logCustomEvent("Screen Viewed",{ screen_name: title, locale: locale ? locale : "en-US"});
}
export const analyticsTrackEvent = (title,object,permission=false) => {
    const consent = localStorage.getItem("consent");
    let locale = localStorage.getItem("LANG")
    try {
        permission = JSON.parse(consent).tracking;
    } catch (error) {}
    finally {
        if (locale && locale.length) {
            locale = locale === "ar" ? "ar-SA" : "en-US"
        }
        if(permission){
            // analytics.track(title, {...object,locale: locale ? locale : "en-US"});
            appboy.logCustomEvent(title, {...object,locale: locale ? locale : "en-US"});
        }
        if (window.snaptr) {
            window.snaptr('track', title, {...object,locale: locale ? locale : "en-US"});
        }
        if (window.ttq) {
            window.ttq.track(title, {...object,locale: locale ? locale : "en-US"});
        }
        if (window.gtag) {
            window.gtag('event', title, {...object,locale: locale ? locale : "en-US"});
        }
        if (window.twq) {
            window.twq('track', title, {...object,locale: locale ? locale : "en-US"});
        }
    }
}
export const analyticsIdentityEvent = (id,object,permission=false) => {
    const consent = localStorage.getItem("consent");
    let locale = localStorage.getItem("LANG")
    try {
        permission = JSON.parse(consent).tracking;
    } 
    catch (error) {} 
    finally {
        if (locale && locale.length) {
            locale = locale === "ar" ? "ar-SA" : "en-US"
        }
        if(permission){
            // analytics.identify(id, object);
        }
        if (window.ttq) {
            window.ttq.identify({external_id: id,...object});
        }
        if (window.snaptr) {
            window.snaptr('init', 'eed15bfd-00f0-4839-bab1-4173eb3e7250', {user_id: id, ...object});
        }
        if (window.gtag) {
            window.gtag('config', 'AW-16609828685', { 'user_id': id });
        }
        if (window.twq) {
            window.twq('identify', {user_id: id, ...object});
        }
    }
}

export const tiktokIdentity = (data) => {
    if (window.ttq) {
        window.ttq.identify(data);
    }
}
export const tiktokConversion = (eventName, data) => {
    if (window.ttq) {
        window.ttq.track(eventName, data);
    }
}
export const twitterConversion = (data) => {
    if (window.twq) {
        window.twq('PlaceAnOrder', 'tw-omkkj-omnb6', data)
    }
}