import React, { createContext, useState } from 'react';
const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(-1);
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const updateCategoryList = (value) => {
    setCategoryList(value)
  };
  const updateCartCount = (value) => {
    setCartCount(value)
  };
  const updateBrandList = (value) => {
    setBrandList(value)
  };

  return (
    <GlobalContext.Provider value={{ categoryList, cartCount, brandList, updateCategoryList, updateCartCount, updateBrandList }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };