import { Link } from "react-router-dom";
import ErrorImage from "../assets/images/404.png"
import ErrorImageAR from "../assets/images/404-ar.png"
import "../assets/css/--component-404.css"
import { useEffect, useState } from "react";
import { analyticsPageEvent } from "./Analytics";
import T from "../locale/translations-main";

function Error404(props){
    const [ locale, setLocale ] = useState("en")
    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("Error 404");
        }

    }, []);
    

    return (
        <section className="error-page">
            <div className="wrapper d-flex align-items-center">
                <div className="error-content">
                    <h1 className="fs-36 fw-800 text-gray-500 mt-0 mb-1"><T>PAGE NOT FOUND</T></h1>
                    <p className="fs-18 fw-400 text-gray-400 mt-0 mb-3"><T>We are very sorry for inconvenience. It seems you are trying to access a page that either has been deleted or does not exist.</T></p>
                    <Link className="btn btn-primary" to={"/"}><T>Go to home page</T></Link>
                </div>
                <div className="error-image">
                    <img src={locale === "ar" ? ErrorImageAR : ErrorImage} alt=""/>
                </div>
            </div>
        </section>
    )
}
export default Error404;