import { useEffect, useState } from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import '../../assets/css/--component-contact-us.css'
import { analyticsPageEvent } from "../../components/Analytics";
import T, { _T } from "../../locale/translations-main";

function Contact(props) {
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Contact Us", link: "" },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if(props.consent.page){
            analyticsPageEvent("Contact");
        }
    }, [])
    
  const [ language, setLanguage ] = useState("en")
  useEffect(()=>{
    const lang = localStorage.getItem("LANG")
    if(lang && lang.length){
      setLanguage(lang)
    }
  },[])
    return (
        <>
            <BreadCrumbs pageTile="Contact Us" breadcrumbItems={breadcrumbItems} />
            <section id="Contact" className="pt-4 pb-5">
                <div className="wrapper">
                    <div className="contact-container d-flex justify-between flex-wrap">
                        <div className="contact-box">
                            <div className="contact-heading">
                                <h2 className="fs-24 text-gray-500 fw-500 border-line d-inline-flex  mt-0"><T>Contact Us</T></h2>
                            </div>
                            <div className="form-input-feild mb-3">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="fullname"><T>Full Name</T><span className="text-primary">*</span></label>
                                <input type="text" id="fullname" name="fullname" placeholder= {_T("Enter your full name")}/>
                            </div>
                            <div className="form-input-feild mb-3">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="email"><T>Email Address</T>  <span className="text-primary">*</span></label>
                                <input type="text" id="email" name="email" placeholder={_T("Enter your email address")} />
                            </div>
                            <div className="form-input-feild mb-3">
                                <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="message"><T>Message</T> <span className="text-primary">*</span></label>
                                <textarea id="message" name="message" rows="8" placeholder={_T("Write your message here..")}></textarea>
                            </div>
                            <div className="submit-btn">
                                <button className="btn btn-primary w-40"><T>Submit</T></button>
                            </div>
                        </div>
                        <div className="google-map">
                            <div className="google-map-container">
                                <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.5121752102223!2d46.67723831537512!3d24.743622556023254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f02a888f260f7%3A0x7d9ef8464fbe3cfc!2sDanube!5e0!3m2!1sen!2s!4v1670916028835!5m2!1sen!2s"
                                    height="530"                                 
                                    loading="lazy">
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <div className="contact-info d-flex justify-between flex-wrap pt-5 pb-5">
                        <div className="phone-container d-flex align-items-center">
                            <div className="phone-img mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40.69" height="40.508" viewBox="0 0 20.69 20.508"><g transform="translate(0.754 0.752)"><g transform="translate(-0.5 -0.5)"><path d="M.49,2.374C.806,1.849,2.55-.056,3.793,0A1.642,1.642,0,0,1,4.76.518h0a16.884,16.884,0,0,1,2.467,3.34C7.472,5.026,6.078,5.7,6.5,6.878a9.873,9.873,0,0,0,5.619,5.616c1.177.427,1.851-.965,3.019-.723a16.864,16.864,0,0,1,3.34,2.468h0a1.639,1.639,0,0,1,.517.967c.046,1.309-1.977,3.077-2.371,3.3-.93.666-2.144.654-3.624-.034C8.874,16.757,2.274,10.282.524,6-.146,4.525-.191,3.3.49,2.374Z" transform="translate(0.5 0.5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M0,0A7.971,7.971,0,0,1,7.041,7.032" transform="translate(12.565 0.75)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M0,0A4.426,4.426,0,0,1,3.5,3.5" transform="translate(12.565 4.293)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></g></g></svg>
                            </div>
                            <div className="phone-details">
                                <p className="fw-500 text-gray-500 fs-16 m-0 "><T>Hotline 24/7</T></p>
                                <p className="fw-400 text-gray-400 fs-14 m-0 "><T>800 244 0339 (Toll Free)</T></p>
                            </div>
                        </div>
                        <div className="email-container d-flex align-items-center">
                            <div className="email-img mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36.472" height="34.77" viewBox="0 0 16.472 14.77"><g transform="translate(0.5 0.5)"><g><path d="M9.1,0,5.67,2.763a1.788,1.788,0,0,1-2.209,0L0,0" transform="translate(3.181 4.474)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M3.932,0h7.585A3.988,3.988,0,0,1,14.4,1.279a4.036,4.036,0,0,1,1.067,2.98V9.511A4.036,4.036,0,0,1,14.4,12.49a3.988,3.988,0,0,1-2.88,1.279H3.932A4,4,0,0,1,0,9.511V4.259A4,4,0,0,1,3.932,0Z" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></g></svg>
                            </div>
                            <div className="email-details">
                                <p className="fw-500 text-gray-500 fs-16 m-0 "><T>Email Address:</T></p>
                                <p className="fw-400 text-gray-400 fs-14 m-0 "><T>support@clicktoys.com</T></p>
                            </div>
                        </div>
                        <div className="location-container d-flex align-items-center">
                            <div className="location-img mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36.857" height="39.836" viewBox="0 0 16.857 19.836"><g transform="translate(0.011 0.011)"><path d="M0,7.652A7.678,7.678,0,1,1,15.357,7.7v.087a11.338,11.338,0,0,1-3.478,7.3,20.183,20.183,0,0,1-3.591,2.957.93.93,0,0,1-1.217,0,19.817,19.817,0,0,1-5.052-4.73A9.826,9.826,0,0,1,0,7.678Z" transform="translate(0.739 0.739)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><circle cx="2.461" cy="2.461" r="2.461" transform="translate(5.957 6.078)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></svg>
                            </div>
                            <div className="location-details">
                            <p className="fw-500 text-gray-500 fs-16 m-0 "><T>Location</T></p>
                                <p className="fw-400 text-gray-400 fs-14 m-0 "><T>King Fahd, Riyadh 12272, Saudi Arabia</T></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact