const translations = {
    "successfully added to cart": {
      "en": "successfully added to cart",
      "ar": "تمت الإضافة بنجاح إلى سلة التسوق"
    },
    "Go to Cart": {
      "en": "Go to Cart",
      "ar": "اذهب إلى سلة التسوق"
    },
    "successfully added to your wishlist": {
      "en": "successfully added to your wishlist",
      "ar": "أضيف بنجاح إلى قائمة الأمنيات الخاصة بك"
    },
    "Go to Wishlist": {
      "en": "Go to Wishlist",
      "ar": "اذهب إلى قائمة الأمنيات"
    },
    "Continue Shopping": {
      "en": "Continue Shopping",
      "ar": "متابعة التسوق"
    },
    "Off": {
      "en": "Off",
      "ar": "خصم"
    }
  }
  export default translations