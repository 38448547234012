import React from 'react';
import WishlistCard from "./wishlist-card";
import { useEffect,useState } from 'react';
import { getWishList, addWishlist } from '../../helper/backend-methods';
import { analyticsPageEvent } from '../../components/Analytics';
import T from '../../locale/translations-main';

function Wishlist(props){
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [wishlistData,setWishlistData] = useState([])
    const [loading, SetLoading] = useState(true)
    const [ language, setLanguage ] = useState("en")
    const [ update, setUpdate ] = useState(0)
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Wishlist", link: "" },
    ]
    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("Account Wishlist");
        }
    },[props.consent.page])

    useEffect(() => {
        configurePageTitle("Wishlist")
        configureBreadcrumb(breadcrumbItems)
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }
    },[])

    useEffect(() => {
        SetLoading(true);
        getWishList().then(({data})=>{
            setWishlistData(data.Data.WishlistItems)
            SetLoading(false)
        }).catch((e)=>{
            SetLoading(false)
        })
    }, [update]);

    const removeItem = (product,Id) => {
        addWishlist({ProductId : product.Id, Add : false}).then(({data}) =>{
            if(data.Status){  
                const newList = wishlistData.filter((i)=> i.Id !== Id)
                setWishlistData([...newList])
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    return (
        <>
            <div className='profile-body-header mb-4'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'><T>Your Wishlist</T></h1>
                <p className='fs-16 text-gray-400 fw-400 mt-0 mb-0'><T>Below are the items you have added to you wishlist</T></p>
            </div>
            <div className="product-wishlist d-flex flex-wrap align-items-center justify-between">
                {
                    loading ?
                    <div className='btn btn-alternative w-100'><T>Fetching your wishlist data. Please wait...</T></div> :
                    wishlistData.length ?
                    wishlistData.reverse().map((item,i)=>{
                        return <WishlistCard key={i} item={item.Product} WishlistId={item.Id} removeWishlist={removeItem} setUpdate={setUpdate} permissons={props.consent}/>
                    }) : <div className='btn btn-alternative w-100'><i className="ri-error-warning-line mr-2 fs-22"></i><T>You currently have no products in your wishlist.</T></div>
                }
            </div>
        </>
    )
}

export default Wishlist;