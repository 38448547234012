import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";
import "../../assets/css/--component-cart.css"
import CartProduct from "./cart-product";
import warningIcon from "../../assets/images/failed-icon.png"
import { getGiftWrap, getProductCart, applyCoupon, removeCouponCode } from "../../helper/backend-methods";
import { analyticsPageEvent, analyticsTrackEvent } from "../../components/Analytics";
import { GlobalContext } from "../../context";
import T, { _T } from "../../locale/translations-main";

function Cart(props){
    const {cartCount, updateCartCount } = useContext(GlobalContext);
    const [isOccasion,setIsOccasion] = useState(0)
    const [cartProducts,setCartProducts] = useState([])
    const [giftWrap,setGiftWrap] = useState([])
    const [subTotal,setSubTotal] = useState(0)
    const [voucher,setVoucher] = useState(null)
    const [discount,setDiscount] = useState(0)
    const [shipping,setShipping] = useState(0)
    const [tax,setTax] = useState(0)
    const [total,setTotal] = useState(0)
    const [loading,setLoading] = useState(true)
    const [loadingCoupon,setLoadingCoupon] = useState(false)
    const [update,setUpdate] = useState(0)
    const [updating,setUpdating] = useState(false)
    const [couponCode,setCouponCode] = useState("")
    const [errors, setErrors] = useState({ couponCode: "" })
    const [errorPopup, setErrorPopup] = useState(false)
    const [isAuthenticated,setIsAuthenticated] = useState(false)
    const [ language, setLanguage ] = useState("en")
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Cart", link: "" },
    ];

    useEffect(()=>{
       
        if(props.consent.page){
            analyticsPageEvent("Cart");
        }
    },[])

    useEffect(() => {
        const accessToken = localStorage.getItem("authToken")
        setIsAuthenticated(accessToken && accessToken.length ? true : false)
        getProductCart().then(({data})=>{
            if(data.Status){
                analyticsTrackEvent("Cart Viewed", { discount: data.Data.TotalDiscountAmount, item_count: data.Data.CartItems.length, products: data.Data.CartItems, sub_total: data.Data.SubTotal, total: data.Data.TotalAmount })
                setVoucher(data.Data.CouponCodes.filter(c=>c.CouponCode))
                setCartProducts(data.Data.CartItems)
                setDiscount(data.Data.TotalDiscountAmount)
                setSubTotal(data.Data.SubTotal)
                setTax(data.Data.OrderTaxTotal)
                setTotal(data.Data.TotalAmount)
                setShipping(data.Data.ShippingTotal)
                updateCartCount(data.Data.CartItemCount);
                getGiftWrap().then(({data})=>{
                    if(data.Status){
                        setGiftWrap(data.Data)
                    }
                    setLoading(false)
                    setUpdating(false)
                }).catch((e)=>{
                    console.log(e);
                    setLoading(false)
                    setUpdating(false)
                })
                localStorage.setItem("occasion",data.Data.OccasionId)
                setIsOccasion(parseInt(data.Data.OccasionId))
                updateCartCount(data.Data.CartItemCount);
                localStorage.setItem("cartItemCount",1)
            }
            else{
                setCartProducts([])
                setLoading(false)
                setUpdating(false)
                localStorage.setItem("occasion",0)
                updateCartCount(0);
                localStorage.removeItem("cartItemCount")
                setIsOccasion(0)
            }
        }).catch((e)=>{
            setUpdating(false)
            setLoading(false)
            setIsOccasion(0)
            console.log(e);
        })
    },[update])

    const updateCart = (products) => {
        if(products.wrapForAll || products.GiftCardDetails?.cardForAll){
            setCartProducts([])
            setUpdating(true)
        }
        setUpdate(update+1)
    }
    const removeCart = () => {
        setCartProducts([])
        setUpdating(true)
        setUpdate(update+1)
    }
    const removeCoupon = (c) => {
        setUpdating(true)
        removeCouponCode("?discountCode="+c).then(({data})=>{
            if(data.Status){
                analyticsTrackEvent("User Removed Promo Code", { promo_code: c })
                setCouponCode("")
                updateCart(0)
            }
            else{
                setUpdating(false)
            }
        }).catch((e)=>{
            console.log(e)
            setCouponCode("")
            setUpdating(false)
        })
    }

    const validateSubmit = (e) => {
        e.preventDefault();
        const errors = { couponCode: "" };
        let flag = false;
        if (!couponCode || couponCode.replaceAll(/\s/g, '').length < 2) {
            errors.couponCode = <T>Please provide a valid Coupon.</T>;
            flag = true
        }
        setErrors(errors);
        if(!flag){
            setUpdating(true)
            setLoadingCoupon(true)
            applyCoupon("?discountCode="+couponCode).then(({data})=>{
                if(data.Status){
                    analyticsTrackEvent("User Redeem Promo Code", { promo_code: couponCode })
                    updateCart(0)
                }
                else{
                    errors.couponCode = couponErrorCodes[data.StatusCode] ? couponErrorCodes[data.StatusCode][language] : <T>Please provide a valid Coupon.</T>
                    // errors.couponCode = translations.cart[language].couponError;
                    setUpdate(update+1)
                    setErrors(errors);
                }
                setCouponCode("")
                setLoadingCoupon(false)
                setUpdating(false)
            }).catch((e)=>{
                setCouponCode("")
                setLoadingCoupon(false)
                errors.couponCode = <T>Please provide a valid Coupon.</T>;
                setErrors(errors);
                setUpdating(false)
            })
        }
    }

    const couponErrorCodes = {
        1068: { "en" : "Coupon Code Not Found" , "ar" : "رمز القسيمة غير موجود."},
        1065: { "en" : "Coupon Code Already Applied" , "ar" : "رمز القسيمة مطبق بالفعل." },
        1044: { "en" : "Coupon not valid for current product(s)" , "ar" : "القسيمة غير صالحة للمنتج (المنتجات) الحالية."},
        1018: { "en" : "Oops, this discount is all used up! It has reached its maximum usage limit. Please check out our other deals and promotions!" , "ar" : "عفوًا ، تم استخدام هذا الخصم بالكامل! لقد وصلت إلى الحد الأقصى للاستخدام. يرجى التحقق من صفقاتنا وعروضنا الترويجية الأخرى!"}
    }

    return(
        
        <>
            <BreadCrumbs pageTile="Cart" breadcrumbItems={breadcrumbItems} />
            <section id="cart">
                <div className="wrapper d-flex flex-wrap justify-between">
                    <div className="section-header w-100">
                        <h1 className="fs-24 text-gray-500 fw-500 mt-0 mb-0"><T>Your Cart</T></h1>
                        <p className="fs-16 text-gray-400 fw-400 mt-0 mb-0"><T>Following are all the products you've added to your cart.</T></p>
                    </div>
                    {
                        loading ?
                        <p className="btn btn-alternative w-100"><T>Loading cart items. Please wait...</T></p> :
                        cartProducts.length ?
                        <>
                            <div className="cart-products">
                                <div className="cart-group">
                                    <div className="group-products">
                                        {
                                            cartProducts.map((item,index)=>(
                                                <CartProduct  itemCount={cartProducts.length} isOccasion={isOccasion} key={index} index={index} item={item} wraps={giftWrap} methodUpdate={updateCart} methodDelete={removeCart} cartError={setErrorPopup}/>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="cart-summary">
                                <div className="cart-summary-wrapper">
                                    <h2 className="summary-title"><T>Cart Summary</T></h2>
                                    <div className="cart-amounts">
                                        <div>
                                            <span className="text-gray-500 fw-500 fs-14"><T>Sub Total:</T><span className="d-block fs-12 text-gray-300"><T>Inclusive of VAT</T></span></span>
                                            
                                            <span className="text-gray-400 fw-500 fs-14 LTR">SAR {subTotal.toFixed(2)}</span>
                                            
                                        </div>
                                        {
                                            tax ?
                                            <div>
                                                <span className="text-gray-500 fw-500 fs-14"><T>Tax (VAT):</T></span>
                                                <span className="text-gray-400 fw-500 fs-14 m-0 LTR">SAR { tax }</span>
                                            </div> : <></>
                                        }
                                        <div>
                                            <span className="text-gray-500 fw-500 fs-14"><T>Shipping:</T></span>
                                            <span className="text-gray-400 fw-500 fs-14 LTR">{ shipping > 0 ? "SAR + "+shipping.toFixed(2) : <T>Free</T> }</span>
                                        </div>

                                       {
                                            discount > 0 ?
                                            <div>
                                                <span className="text-gray-500 fw-500 fs-14"><T>Discount</T></span>
                                                <span className="text-primary fw-500 fs-14 LTR">SAR - {discount.toFixed(2)}</span>
                                            </div> : <></>
                                        }
                                    </div>
                                    <div className="cart-total">
                                            <span className="text-gray-500 fw-500 fs-16"><T>Total</T></span>
                                            <span className="text-secondary fw-500 fs-16 LTR">SAR { ( total ).toFixed(2) }</span>
                                    </div>
                                    {
                                        isAuthenticated ? 
                                            voucher.length ?
                                            <div className="cart-voucher mb-0 pb-0">
                                                <label><T>Coupons</T></label>
                                                <div className="applied-coupons mb-0">
                                                    {
                                                        voucher.map((coupon,key)=>{
                                                            return coupon.RequireCouponCode ? <span key={key}> <i className="ri-close-line mr-2" onClick={()=>{removeCoupon(coupon.CouponCode)}}></i> <T>Code Applied:</T> "{coupon.CouponCode}"</span> : <i key={key} className="d-none"></i>
                                                        })
                                                    }
                                                </div>
                                            </div> : <></> 
                                        : <></>
                                    }
                                    {
                                        isAuthenticated ? 
                                            <div className="cart-voucher">
                                                <label><T>Apply Coupon</T></label>
                                                <form className="modal-content" onSubmit={validateSubmit}>
                                                    <input type={"text"} onChange={(e)=>{setCouponCode(e.target.value)}} value={couponCode} placeholder={_T("Enter Code Here")} name="couponCode" id="couponCode"/>
                                                    {
                                                        loadingCoupon ?
                                                        <button className="btn btn-primary" type="button"><T>Wait</T></button> :
                                                        <button className="btn btn-primary" type="submit"><T>Apply</T></button>
                                                    }
                                                </form>
                                                {errors.couponCode ? <span className="text-primary fs-12 fw-400">{errors.couponCode}</span> : null}
                                            </div> :
                                        <></>
                                    }
                                    
                                    <div className="cart-actions">
                                        {
                                            isAuthenticated ?
                                            <Link to="/checkout" className="btn btn-primary w-100 mb-3"><T>Proceed to Checkout</T></Link> :
                                            <Link to="/login?redirect=/cart" className="btn btn-primary w-100 mb-3"><T>Sign-in to Continue</T></Link>
                                        }
                                        <Link to="/products" className="btn btn-alternative w-100"><T>Return to Shopping</T></Link>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <p className="btn btn-alternative w-100"><i className="ri-information-line mr-2 fs-22"></i><T>You currently have no products in your cart. Go to products and add items to your cart.</T></p>
                    }
                    
                </div>
                {
                    loading || updating ?
                    <section id="checkout" style={{position: "fixed", width: "100%", height: "100%", top: "0px", left: "0px", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "999", background: "#fff", opacity: "0.8"}}>
                        <div className="wrapper">
                            <div className="payment-loading text-center">
                                <div className="payment-loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        </div>
                    </section> : <></>
                }

                {
                    errorPopup ?
                    <div className="product-card p-0 m-0">
                        <div className={ errorPopup ? "product-card-popup active" : "product-card-popup"}>
                            <div className="popup-wrapper">
                                <div className="popup-image-container mb-1">
                                    <img src={warningIcon} alt="warning" style={{ height: "140px", width: "140px"}} />
                                </div>
                                <span className="popup-subheading text-gray-500 fw-500 fs-16 mt-4 mb-0"><T>Error!</T></span>
                                <p className="text-gray-400 fw-400 fs-14 mt-0 mb-4"><T>Certain items in your shopping cart have surpassed the available stock level, and their quantity has been adjusted to match the current stock availability</T></p>
                                <div className="d-flex align-items-center justify-center">
                                    <button className="btn btn-outline-secondary ml-1 mr-1 mb-2" onClick={()=>{setCartProducts([]); setUpdating(true); setUpdate(update+1); setErrorPopup(false)}}><T>Okay</T></button>
                                </div>
                            </div>
                        </div>
                    </div> : <></>
                }
            </section>
        </>
    )
}

export default Cart