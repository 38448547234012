import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Error404 from "../../components/Error404";
import { getOccasionDetails, setOccasionInvite } from "../../helper/backend-methods";
function Invite(){
    const [search, setSearch] = useSearchParams();
    const [occasionId, setOccasionId] = useState(0);
    const [occasion, setOccasion] = useState(null);
    const [remaining, setRemaining] = useState([]);
    const [purchased, setPurchased] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const history = useNavigate();

    useEffect(()=>{
        const accessToken = localStorage.getItem("authToken")
        const isAuthenticated = accessToken && accessToken.length ? true : false;
        if(isAuthenticated){
            if(search){
                if(search.get('deep_link_sub2')){
                    setOccasionId(search.get('deep_link_sub2'))
                    getOccasionDetails({occasionId: search.get('deep_link_sub2'), check: false}).then(({data})=>{
                        if(data.Status){
                            setOccasion(data.Data.Occasion)
                            setRemaining(data.Data.GiftsRemaining)
                            setPurchased(data.Data.GiftsPurchased)
                        }
                        else{
                            setError(true)
                        }
                        setLoading(false)
                    }).catch((e)=>{
                        console.log(e)
                        setError(true)
                        setLoading(false)
                    })
                }
                else{
                    setError(true)
                    setLoading(false)
                }
            }
            else{
                setError(true)
                setLoading(false)
            }
        }
        else{
            history('/login/?redirect='+encodeURI('/'+window.location.search));
        }
    },[search])

    const handleAccept = () => {
        setOccasionInvite({occasionId: occasionId, accepted: true }).then(({data})=>{
            if(data.Status){
                history('/account/occasions/invite/'+occasionId);
            }
            else{
                history('/account/occasions/');
            }
        }).catch((e)=>{
            history('/account/occasions/');
            console.log(e)
        })
    }
    const handleReject = () => {
        history('/account/occasions/');
    }

    return (
        loading ?
        <section id="checkout">
            <div className="wrapper">
                <div className="payment-loading text-center">
                    <div className="payment-loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        </section> : error ?
        <Error404/> : 
        <section id="occasionInvitation">
            <div className="invitation-wrapper">
                <div className="invitation-avatar">
                    <img src={occasion.Picture}/>
                </div>
                <div className="invitation-title">
                    <h3 className="fs-16 fw-500 text-secondary mb-0">{occasion.Title}</h3>
                    <h3 className="fs-16 fw-500 text-gray-500 mt-0">{occasion.EventDate}</h3>
                </div>
                <div className="invitation-title">
                    <h3 className="fs-16 fw-500 text-gray-500">Invited by <span className="fs-16 fw-500 text-primary">{occasion.FullName}</span></h3>
                </div>
                <div className="d-flex align-items-center justify-center w-100 flex-wrap">
                    <button className="btn btn-outline-secondary ml-1 mr-1" onClick={()=>{ handleReject() }}>Reject</button>
                    <button className="btn btn-primary ml-1 mr-1" onClick={()=>{ handleAccept() }}>Accept</button>
                </div>
            </div>
        </section>
    )
}
export default Invite;