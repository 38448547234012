import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { addCart, getProduct, setOccasionProduct, addWishlist } from "../../helper/backend-methods";
import ScrollContainer from 'react-indiana-drag-scroll'
import BreadCrumbs from "../../components/BreadCrumbs";
import ReactStars from "react-rating-stars-component";
import ReactImageGallery from "react-image-gallery";
import ProductCard from "./product-card";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../assets/css/--component-product-single.css"
import "react-multi-carousel/lib/styles.css";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import noImage from "../../assets/images/no-image.jpg"
import popupImage from "../../assets/images/popup-icon.png"
import popupWishlistImage from "../../assets/images/popup-wishlist-icon.png"
import warningIcon from "../../assets/images/failed-icon.png"
import userImage from "../../assets/images/no-avatar.jpg"
import { analyticsPageEvent, analyticsTrackEvent } from "../../components/Analytics";
import { GlobalContext } from "../../context/index";
import T from "../../locale/translations-main";
import {app} from '../../helper/backend-methods'
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

function Product(props){
    let params = useParams();
    const { cartCount, updateCartCount } = useContext(GlobalContext);
    const [config, setConfig] = useState({ Maintenance: false, EnableFacebookLogin: false, EnableGoogleLogin: false, EnableTamara: false})
    const [configLoading, setConfigLoading] = useState(false);
    const productID = params.id
    const [authorized, setAuthorized] = useState(false);
    const [images, setImages] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [crossSellingProducts, setCrossSellingProducts] = useState([]);
    const [reviewList, setReviewList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(1);
    const [popup, setPopup] = useState(false)
    const [popupWishlist, setPopupWishlist] = useState(false)
    const [popupOccasionList, setPopupOccasionList] = useState(false)
    const [popupConflict, setPopupConflict] = useState(false)
    const [IsWishlist, setIsWishlist] = useState(false)
    const [occasionId, setOccasionId] = useState(0)
    const [occasionList, setOccasionList] = useState(0)
    const [cartHasItems, setCartHasItems] = useState(localStorage.getItem("cartItemCount"))
    const [inviteList, setInviteList] = useState(0)
    const [cartError, setCartError] = useState("")
    const [occasionListError, setOccasionListError] = useState("")
    const [language, setLanguage] = useState("en")
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "Home", link: "/" },
        { title: "Products", link: "/products" },
        { title: "Product", link: "" },
    ]);

    function magnify(zoom) {
        let img;
        let glass = []
        img = document.getElementsByClassName("image-gallery-image");
        for(let ii = 0 ; ii < img.length ; ii++){
            glass.push(document.createElement("DIV"))
            glass[ii].setAttribute("class", "img-magnifier-glass");
            if (img[ii].complete) {
                configureMagnifier(img[ii],glass[ii],zoom)
            }
            else{
                img[ii].addEventListener('load', function() {
                    configureMagnifier(img[ii],glass[ii],zoom)
                })
            }
        }
    }

    function configureMagnifier(img,glass,zoom){
        let w, h, bw;
        img.parentElement.insertBefore(glass, img);
        glass.style.backgroundImage = "url('" + img.src + "')";
        glass.style.backgroundRepeat = "no-repeat";
        glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
        bw = 3;
        w = glass.offsetWidth / 2;
        h = glass.offsetHeight / 2;
        glass.addEventListener("mousemove", function(e) {
            moveMagnifier(e,img,w,zoom,h,glass,bw)
        });
        img.addEventListener("mousemove", function(e) {
            moveMagnifier(e,img,w,zoom,h,glass,bw)
        });
        glass.addEventListener("touchmove", function(e) {
            moveMagnifier(e,img,w,zoom,h,glass,bw)
        });
        img.addEventListener("touchmove", function(e) {
            moveMagnifier(e,img,w,zoom,h,glass,bw)
        });
    }
    function moveMagnifier(e,img,w,zoom,h,glass,bw) {
        var pos, x, y;
        e.preventDefault();
        pos = getCursorPos(e,img);
        x = pos.x;
        y = pos.y;
        if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
        if (x < w / zoom) {x = w / zoom;}
        if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
        if (y < h / zoom) {y = h / zoom;}
        glass.style.left = (x - w) + "px";
        glass.style.top = (y - h) + "px";
        glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
    }
    function getCursorPos(e,img) {
        var a, x = 0, y = 0;
        e = e || window.event;
        a = img.getBoundingClientRect();
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return {x : x, y : y};
    }
    
    useEffect(()=>{
        getConfig().then((response)=>{
            setConfig(JSON.parse(response._value))
            setConfigLoading(false)
        }).catch((e)=>{
            setConfigLoading(false)
        })
    },[])

    const getConfig = async () =>{
        const firebaseApp = app;
        const remoteConfig = getRemoteConfig(firebaseApp);
        remoteConfig.settings.minimumFetchIntervalMillis = 5000;
        await fetchAndActivate(remoteConfig)
        return getValue(remoteConfig, process.env.REACT_APP_FIREBASE_REMOTE_CONFIG);
    }

    useEffect(() => {
        let lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            if (lang === "ar") {
                lang = "ar"
            }
            else{
                lang = "en"
            }
        }
        else{
            lang = "en"
        }
        setLanguage(lang)
        if(props.consent.page){
            analyticsPageEvent("Product Details");
        }
        setLoading(true)
        setQuantity(1)
        setOccasionList(0)
        setInviteList(0)
        const accessToken = localStorage.getItem("authToken")
        const occasion = localStorage.getItem("occasion")
        setOccasionId(occasion ? parseInt(occasion) : 0)
        const isAuthenticated = accessToken && accessToken.length ? true : false;
        setAuthorized(isAuthenticated)
        setTab(1)
        getProduct({productId: productID}).then(({data})=>{
            if(data.Status){
                let newImages = []
                if(data.Data.ProductDetails.ProductPictures.length){
                    newImages = data.Data.ProductDetails.ProductPictures.map((img)=>{
                        return {
                            original: img.Url,
                            thumbnail: img.Url,
                        }
                    })
                }
                else{
                    newImages = [ { original: noImage, thumbnail: noImage } ]
                }
                setImages([...newImages])
                setProduct(data.Data.ProductDetails)
                const pp = data.Data.ProductDetails
                analyticsTrackEvent("Product Viewed", { screen_name: "Product Details", product_id: pp.ProductId, brand: pp.ManufacturerName, category: pp.CategoryName, name: pp.ProductName, price: pp.Price, sku: pp.ProductSku  },props.consent.tracking)
                setIsWishlist(data.Data.ProductDetails.IsWishlist)
                setRelatedProducts(data.Data.RelatedProducts && data.Data.RelatedProducts.length ? data.Data.RelatedProducts.filter(p=>p) : [])
                setCrossSellingProducts(data.Data.CrossSellingProducts && data.Data.CrossSellingProducts.length ? data.Data.CrossSellingProducts.filter(p=>p) : [])
                setReviewList(data.Data.ProductDetails.ReviewList ? data.Data.ProductDetails.ReviewList : [])
                let productBreadcrumbItems = breadcrumbItems
                productBreadcrumbItems[productBreadcrumbItems.length-1].title = data.Data.ProductDetails.ProductName;
                setBreadcrumbItems([...productBreadcrumbItems])
                setLoading(false)
                window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                setTimeout(() => {
                    magnify(3);
                }, 1500);
            }
        }).catch((e)=>{
            console.log(e)
        })     
    }, [productID]);

    useEffect(()=>{
        if(!loading && config.EnableTamara){
            if(window.tamaraWidgetConfig){
                window.tamaraWidgetConfig.lang = language;
                window.TamaraWidgetV2.refresh();
            }
        }
    },[loading,config.EnableTamara])

    const addToCart = (product) => {
        setCartError("")
        // if cart is currently empty
        if(!cartHasItems){
            // if item being added for normal cart
            if(inviteList === 0){
                addCart({Quantity:quantity, ProductId : [product.ProductId]}).then(({data}) =>{
                    if(data.Status){
                        analyticsTrackEvent("Added to Cart", { screen_name: 'Product Details', product_id: product.ProductId, product_name: product.ProductName, brand: product.ManufacturerName, category: product.CategoryName, sku: product.ProductSku, price: product.Price, quantity: quantity }, props.consent.tracking)
                        updateCartCount(data.Data.CartItemCount);
                        localStorage.setItem("cartItemCount",1)
                        setCartHasItems(1)
                        setPopup(true)
                        setInviteList(0)
                    }
                    else{
                        setCartError(data.Message)
                    }
                }).catch((e)=>{
                    console.log(e);
                })
            }
            // if item being added for occasion cart
            else{
                addCart({Quantity:1, occasionId:inviteList, ProductId : [product.ProductId]}).then(({data}) =>{
                    if(data.Status){
                        analyticsTrackEvent("Added to Cart", { screen_name: 'Product Details', product_id: product.ProductId, product_name: product.ProductName, brand: product.ManufacturerName, category: product.CategoryName, sku: product.ProductSku, price: product.Price, quantity: 1 }, props.consent.tracking)
                        updateCartCount(data.Data.CartItemCount);
                        localStorage.setItem("cartItemCount",1)
                        setCartHasItems(1)
                        setOccasionId(inviteList)
                        setPopup(true)
                        setInviteList(0)
                    }
                    else{
                        setCartError(data.Message)
                    }
                }).catch((e)=>{
                    console.log(e);
                })
            }
        }
        // if item being added for normal cart
        else if(inviteList === 0){
            // if items already in cart don't belong to any occasion
            if(occasionId === 0){
                addCart({Quantity:quantity, ProductId : [product.ProductId]}).then(({data}) =>{
                    if(data.Status){
                        analyticsTrackEvent("Added to Cart", { screen_name: 'Product Details', product_id: product.ProductId, product_name: product.ProductName, brand: product.ManufacturerName, category: product.CategoryName, sku: product.ProductSku, price: product.Price, quantity: quantity }, props.consent.tracking)
                        updateCartCount(data.Data.CartItemCount);
                        localStorage.setItem("cartItemCount",1)
                        setCartHasItems(1)
                        setInviteList(0)
                        setPopup(true)
                    }
                    else{
                        setCartError(data.Message)
                    }
                }).catch((e)=>{
                    console.log(e);
                })
            }
            // if items already in cart belong to some occasion
            else{
                setPopupConflict(true);
            }
        }
        // if item being added for occasion cart
        else{
            // if items already in cart don't belong to any occasion
            if(occasionId === 0){
                setPopupConflict(true);
            }
            // if items already in cart belong to some other occasion
            else if(occasionId === inviteList){
                addCart({Quantity:1, occasionId:inviteList, ProductId : [product.ProductId]}).then(({data}) =>{
                    if(data.Status){
                        analyticsTrackEvent("Added to Cart", { screen_name: 'Product Details', product_id: product.ProductId, product_name: product.ProductName, brand: product.ManufacturerName, category: product.CategoryName, sku: product.ProductSku, price: product.Price, quantity: 1 }, props.consent.tracking)
                        updateCartCount(data.Data.CartItemCount);
                        localStorage.setItem("cartItemCount",1)
                        setCartHasItems(1)
                        setPopup(true)
                        setInviteList(0)
                    }
                    else{
                        setCartError(data.Message)
                    }
                }).catch((e)=>{
                    console.log(e);
                })
            }
            // if items already in cart belong to some other occasion
            else{
                setPopupConflict(true);
            }
        }
    }

    const addToCartAfterConfirmation = () => {
        setPopupConflict(false);
        if(inviteList === 0){
            addCart({Quantity:quantity, ProductId : [product.ProductId]}).then(({data}) =>{
                if(data.Status){
                    analyticsTrackEvent("Added to Cart", { screen_name: 'Product Details', product_id: product.ProductId, product_name: product.ProductName, brand: product.ManufacturerName, category: product.CategoryName, sku: product.ProductSku, price: product.Price, quantity: quantity }, props.consent.tracking)
                    updateCartCount(data.Data.CartItemCount);
                    localStorage.setItem("occasion",0)
                    localStorage.setItem("cartItemCount",1)
                    setOccasionId(0)
                    setCartHasItems(1)
                    setPopup(true);
                    setInviteList(0)
                }
                else{
                    setCartError(data.Message)
                }
            }).catch((e)=>{
                console.log(e);
            })
        }
        else{
            addCart({Quantity:1, occasionId:inviteList, ProductId : [product.ProductId]}).then(({data}) =>{
                if(data.Status){
                    analyticsTrackEvent("Added to Cart", { screen_name: 'Product Details', product_id: product.ProductId, product_name: product.ProductName, brand: product.ManufacturerName, category: product.CategoryName, sku: product.ProductSku, price: product.Price, quantity: 1 }, props.consent.tracking)
                    updateCartCount(data.Data.CartItemCount);
                    localStorage.setItem("occasion",inviteList)
                    localStorage.setItem("cartItemCount",1)
                    setCartHasItems(1)
                    setOccasionId(inviteList)
                    setPopup(true)
                    setInviteList(0)
                }
                else{
                    setCartError(data.Message)
                }
            }).catch((e)=>{
                console.log(e);
            })
        }
    }

    const addUpdateWishlist = () => {
        addWishlist({ ProductId: product.ProductId, Add: !IsWishlist }).then(({data}) => {
            if(data.Status){
                setIsWishlist(!IsWishlist)
                if (!IsWishlist) {
                    setPopupWishlist(true)
                    analyticsTrackEvent("Wish List Item Added", { screen_name: "Product Details", product_id: product.ProductId, product_name: product.ProductName, brand: product.ManufacturerName, category: product.CategoryName },props.consent.tracking)
                }
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    const addOccasionProduct = () => {
        setOccasionListError("")
        setOccasionProduct({ occasionId:occasionList, productId: productID }).then(({data})=>{
            if(data.Status){
                setPopupOccasionList(true)
            }
            else{
                setOccasionListError(<T>You already have this product added to your occasion.</T>)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }    
    return(
        <>
            <BreadCrumbs pageTile="Product" breadcrumbItems={breadcrumbItems} />
            { loading ? 
            <>
                <section id="productSingle" className="pt-5 pb-5">
                    <div className="wrapper d-flex justify-between flex-wrap">
                        <div className="single-product-gallery">
                            <Skeleton height={window.innerWidth < 1025 ? "250px" : "500px"} />
                        </div>
                        <div className="single-product-content">
                            <Skeleton height="50px" style={{maxWidth: "500px", width: "100%"}} />
                            <Skeleton height="20px" style={{maxWidth: "150px", width: "100%", marginBottom: "20px"}} />
                            <Skeleton height="30px" style={{maxWidth: "100px", width: "100%", marginBottom: "30px"}} />
                            <Skeleton count={5} />
                            <Skeleton height="50px" style={{maxWidth: "250px", width: "100%", marginTop: "50px", marginBottom: "30px"}} />
                            <Skeleton count={3} style={{maxWidth: "150px", width: "100%"}} />
                        </div>
                    </div>
                </section>
            </> :
            <section id="productSingle" className="pt-5 pb-5">
                <div className="wrapper d-flex justify-between flex-wrap">
                    <div className="single-product-gallery">
                        {
                            product.DiscountPercentage ? <span className="tag">{product.DiscountPercentage}% <T>Off</T></span> : <></>
                        }
                        <ReactImageGallery items={images} thumbnailPosition={"bottom"} showPlayButton={false} showBullets={true} onErrorImageURL={noImage}/>
                    </div>
                    <div className="single-product-content">
                        <div className="single-product-header d-inline-flex justify-between">
                            <h1 className="single-product-title fw-600 mt-0 mb-0 mr-2 fs-38 text-gray-500">{product.ProductName}</h1>
                        </div>
                        <div className="d-flex align-items-center justify-between mb-2">
                            <div className="single-product-rating d-flex align-items-center mb-0">
                                <ReactStars size={18} count={5} edit={false} color="#FFB300" activeColor="#FFB300" value={product.Rating} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />}/>
                                <span className="single-product-reviews text-gray-300 fs-14 ml-2 fw-500"><span className="text-gray-500 mr-1">{product.Rating.toFixed(2)}</span>(<span className="text-gray-500">{product.Reviews}</span> <T>Reviews</T>)</span>
                            </div>
                            { 
                            authorized ?
                                <button onClick={()=>{addUpdateWishlist()}} className={ IsWishlist ? "product-wishlist active d-flex align-items-center pl-0 pr-0 m-0 bg-none text-secondary fs-16 " : "product-wishlist d-flex align-items-center pl-0 pr-0 m-0 bg-none text-secondary fs-16"}><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20.538" height="19.536" viewBox="0 0 20.538 19.536"><g transform="translate(0.769 0.786)"><g transform="translate(0 0)"><path d="M9.261,17.854a34.84,34.84,0,0,1-6.022-4.689A11.99,11.99,0,0,1,.373,8.6C-.7,5.25.554,1.421,4.071.288A5.978,5.978,0,0,1,9.5,1.2h0A5.987,5.987,0,0,1,14.921.288C18.438,1.421,19.7,5.25,18.628,8.6a11.99,11.99,0,0,1-2.866,4.57A34.84,34.84,0,0,1,9.74,17.854L9.505,18Z" transform="translate(0 0)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M0,0A2.782,2.782,0,0,1,1.917,2.422" transform="translate(13.239 4.053)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></g></svg>{IsWishlist ?<p><T>Remove from Wishlist</T></p>: <p><T>Add to Wishlist</T></p> }</button> : 
                                <Link to={"/login?redirect=/product/"+productID} className="decoration-none product-wishlist d-flex align-items-center pl-0 pr-0 m-0 bg-none text-secondary fs-16"><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20.538" height="19.536" viewBox="0 0 20.538 19.536"><g transform="translate(0.769 0.786)"><g transform="translate(0 0)"><path d="M9.261,17.854a34.84,34.84,0,0,1-6.022-4.689A11.99,11.99,0,0,1,.373,8.6C-.7,5.25.554,1.421,4.071.288A5.978,5.978,0,0,1,9.5,1.2h0A5.987,5.987,0,0,1,14.921.288C18.438,1.421,19.7,5.25,18.628,8.6a11.99,11.99,0,0,1-2.866,4.57A34.84,34.84,0,0,1,9.74,17.854L9.505,18Z" transform="translate(0 0)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M0,0A2.782,2.782,0,0,1,1.917,2.422" transform="translate(13.239 4.053)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></g></svg><p><T>Add to Wishlist</T></p></Link> 
                            }
                        </div>
                        <div className="d-flex align-items-center">
                            <h2 className="single-product-price text-secondary fw-600 fs-28 m-0"><span className="text-gray-500">SAR</span> {product.UnitPriceAmount ? (product.Price - product.UnitPriceAmount).toFixed(2) : product.Price.toFixed(2)}</h2>
                            {product.UnitPriceAmount ? <h2 className="single-product-old-price text-primary fw-500 fs-28 mt-0 mb-0 ml-2"><s>SAR {product.Price.toFixed(2)}</s></h2> : <></> }
                        </div>
                        <div className="d-flex align-items-center justify-between mb-4">
                            {
                               product.StockManagement ?  product.StockManagement.InStock && product.StockManagement.StockQuantity !== null && product.StockManagement.MinStockQuantity > product.StockManagement.StockQuantity ?
                               <p className="m-0 text-secondary fs-14 fw-400">(<T>Only</T> {product.StockManagement.StockQuantity} <T>PCs left</T>)</p> :
                               <></> : <></>
                            }
                        </div>
                        <p className="single-product-short-description fs-16 fw-400 text-gray-400 mb-0 mt-0">{product.ShortDescription}</p>
                        <p className="text-primary fs-14 fw-400">{ product.IsReturnable ? <T>This item can be returned</T>: <T>This item cannot be returned</T>}</p>
                        {
                            authorized && product.InvitedOccasions && product.InvitedOccasions.length ? 
                            <>
                                <div className="divider mt-4 mb-4"></div>
                                <div className="d-flex flex-wrap mb-4">
                                    <div className="d-flex justify-between w-100">
                                        <p className="mt-0 mb-3 w-100 fs-16 fw-500 text-gray-500"><T>Buy this for someone else</T></p>
                                        {
                                             inviteList !== 0 ?
                                            <span className="link" onClick={()=>{setInviteList(0)}}>Clear</span> : <></>
                                        }
                                    </div>
                                    <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
                                    <div className="invited-occasions-wrapper d-flex pl-1 pr-1">
                                        {
                                            product.InvitedOccasions.map((item,key)=>{
                                                return <label key={key} className={ inviteList === item.Id ? "invited-occasions-single active" : "invited-occasions-single"}>
                                                    <img src={item.Picture ? item.Picture : userImage} alt="" />
                                                    <p className="fs-12 fw-400 mt-2 mb-0 text-center">{item.Title}</p>
                                                    <input type={"radio"} name="invitedOccasion" className="d-none" value={item.Id} checked={inviteList === item.Id} onChange={(e)=>{ setInviteList(parseInt(e.target.value)) }}/>
                                                </label>
                                            })
                                        }
                                    </div>
                                    </ScrollContainer>
                                </div>
                            </> 
                            : <></>
                        }
                        <div className="divider mt-4 mb-4"></div>
                        <div className="d-flex flex-wrap mb-3">
                            {
                                product.StockManagement ? product.StockManagement.InStock ?
                                <>
                                {
                                    inviteList === 0 ?
                                    <div className="product-quantity-container d-inline-flex align-items-center mr-3">
                                        <button className="product-quantity-btn" onClick={()=>{ quantity <= 1 ? setQuantity(1) : setQuantity(quantity-1) }}>-</button>
                                        <input className="product-quantity-field" value={quantity} placeholder="1" readOnly/>
                                        <button className="product-quantity-btn" onClick={()=>{ product.StockManagement.StockQuantity !== null ? quantity >= product.StockManagement.StockQuantity ? setQuantity(product.StockManagement.StockQuantity) : setQuantity(quantity+1) : quantity >= 100 ? setQuantity(100) : setQuantity(quantity+1) }}>+</button>
                                    </div> : <></>
                                }
                                    <button onClick={()=>{addToCart(product)}} type={"button"} className="btn btn-primary mr-3"><T>Add to Cart</T></button>
                                    <Link to={`/checkout?q=${quantity}&p=${productID}`} id="buyNowBtn" className="btn btn-secondary"><T>Buy Now!</T></Link>
                                </> :
                                <span className="btn btn-outline-primary-disabled"><T>Out of Stock</T></span> : 
                                <>
                                    <div className="product-quantity-container d-inline-flex align-items-center mr-3">
                                        <button className="product-quantity-btn" onClick={()=>{ quantity <= 1 ? setQuantity(1) : setQuantity(quantity-1) }}>-</button>
                                        <input className="product-quantity-field" value={quantity} placeholder="1" readOnly/>
                                        <button className="product-quantity-btn" onClick={()=>{ quantity >= 100 ? setQuantity(100) : setQuantity(quantity+1) }}>+</button>
                                    </div>
                                    <button onClick={()=>{addToCart(product)}} type={"button"} className="btn btn-primary mr-3"><T>Add to Cart</T></button>
                                    <Link to={`/checkout?q=${quantity}&p=${productID}`} id="buyNowBtn" className="btn btn-secondary"><T>Buy Now!</T></Link>
                                </>

                            }
                        </div>
                        { cartError.length ? <p className="mb-4 text-primary fs-14 fw-400 w-100">{cartError}</p> : <></> }
                        {
                            authorized && product.PersonalOccasions && product.PersonalOccasions.length ?
                            <>
                                <div className="divider mt-4 mb-4"></div>
                                <div className="d-flex flex-wrap mb-4">
                                    <p className="mt-0 mb-2 w-100 fs-14 fw-400 text-gray-400"><T>You may choose to add this to your occasion list and let invited guests buy this for you.</T></p>
                                    <select className="single-product-list mr-3" value={occasionList} onChange={e =>setOccasionList(e.target.value)} >
                                        <option disabled value={0}><T>Select Option</T></option>
                                        {
                                            product.PersonalOccasions.map((item,i)=>(<option key={i} value={item.Id}>{item.Title}</option>))
                                        }
                                    </select>
                                    {
                                        occasionList ? 
                                        <button onClick={()=>{ addOccasionProduct() }} className="btn btn-outline-primary"><T>Add to List</T></button>:
                                        <button disabled onClick={()=>{ addOccasionProduct() }} className="btn btn-disabled"><T>Add to List</T></button>
                                    }
                                    { occasionListError.length ? <p className="text-primary fs-12 fw-400 w-100">{occasionListError}</p> : <></> }
                                </div>
                            </> : null
                        }
                        { config.EnableTamara ? <tamara-widget type="tamara-summary" data-lang={language} amount={product.UnitPriceAmount ? (product.Price - product.UnitPriceAmount).toFixed(2) : product.Price.toFixed(2)} inline-type='5' inline-variant='outlined' config='{"badgePosition":"","showExtraContent":"full"}'> </tamara-widget> : null }
                        <h4 className="single-product-SKU text-gray-500 fw-500 fs-14 mt-3 mb-2 dir-switch"><span className="mr-2">SKU:</span> {product.ProductSku}</h4>
                        {
                            product.CategoryName ?
                            <div className="d-flex align-items-center fs-14 flex-wrap mb-2">
                                <span className="mr-2"><T>Category:</T></span> <Link to={"/products?category="+product.CategoryId} className="text-secondary decoration-none mr-2">{product.CategoryName}</Link>
                            </div> : null
                        }
                        {
                            product.ManufacturerName && product.ManufacturerId ?
                            <div className="d-flex align-items-center fs-14 flex-wrap mb-2">
                                <span className="mr-2"><T>Brand:</T></span>
                                 <Link to={"/products?brand="+product.ManufacturerId} className="text-secondary decoration-none mr-2">{product.ManufacturerName}</Link>
                            </div> : null
                        }
                        {
                            product.AgeAttributeValue ?
                            <div className="d-flex align-items-center fs-14 flex-wrap">
                                <span className="mr-2"><T>Age:</T></span> <span className="text-secondary decoration-none mr-2">{product.AgeAttributeValue}</span>
                            </div> : null
                        }
                        <div className={ popup ? "product-card-popup active" : "product-card-popup"}>
                            <div className="popup-wrapper">
                                <h2 className="popup-title">{product.ProductName.length > 40 ? product.ProductName.slice(0,37)+"..." : product.ProductName}</h2>
                                <span className="popup-subheading"><T>Successfully added to cart</T></span>
                                <div className="popup-image-container">
                                    <img src={popupImage} alt="" />
                                </div>
                                <Link className="btn btn-primary" to="/cart"><T>Go to Cart</T></Link>
                                <button className="btn btn-outline-secondary" onClick={()=>{setPopup(false)}}><T>Continue Shopping</T></button>
                            </div>
                        </div>
                        <div className={ popupWishlist ? "product-card-popup active" : "product-card-popup"}>
                            <div className="popup-wrapper">
                                <h2 className="popup-title">{product.ProductName.length > 40 ? product.ProductName.slice(0,37)+"..." : product.ProductName}</h2>
                                <span className="popup-subheading"><T>Successfully added to your wishlist</T></span>
                                <div className="popup-image-container">
                                    <img src={popupWishlistImage} alt="" />
                                </div>
                                <Link className="btn btn-primary" to="/account/wishlist"><T>Go to Wishlist</T></Link>
                                <button className="btn btn-outline-secondary" onClick={()=>{setPopupWishlist(false)}}><T>Continue Shopping</T> </button>
                            </div>
                        </div>
                        <div className={ popupOccasionList ? "product-card-popup active" : "product-card-popup"}>
                            <div className="popup-wrapper">
                                <h2 className="popup-title">{product.ProductName.length > 40 ? product.ProductName.slice(0,37)+"..." : product.ProductName}</h2>
                                <span className="popup-subheading"><T>Successfully added to</T> { occasionList ? product.PersonalOccasions.find((item)=>(item.Id == occasionList)).Title : "List"}</span>
                                <div className="popup-image-container">
                                    <img src={product.ProductPictures.length ? product.ProductPictures[0].Url : noImage} alt={product.ProductName} />
                                </div>
                                <Link className="btn btn-primary" to="/account/occasions"><T>Go to Occasion List</T> </Link>
                                <button className="btn btn-outline-secondary" onClick={()=>{setPopupOccasionList(false)}}><T>Continue Shopping</T> </button>
                            </div>
                        </div>
                        <div className={ popupConflict ? "product-card-popup active" : "product-card-popup"}>
                            <div className="popup-wrapper">
                                <div className="popup-image-container mb-1">
                                    <img src={warningIcon} alt="warning" style={{ height: "140px", width: "140px"}} />
                                </div>
                                {
                                    occasionId === 0 && inviteList != 0 ?
                                    <span className="popup-subheading text-gray-500 fs-14 mt-4 mb-4"><T>You already have items in your cart and you are about to add a product that belongs to an occasion. This will clear all your current cart items.</T></span> : 
                                    occasionId !== 0  && inviteList === 0 ?
                                    <span className="popup-subheading text-gray-500 fs-14 mt-4 mb-4"><T>You already have items associated with an occasion in your cart and you are about to add a product that doesn't belong to any occasion. This will clear all your current cart items.</T> </span> :
                                    <span className="popup-subheading text-gray-500 fs-14 mt-4 mb-4"><T>You already have items associated with an occasion in your cart and you are about to add a product that belongs to some other occasion. This will clear all your current cart items.</T></span>
                                    
                                }
                                <div className="d-flex align-items-center justify-center">
                                    <button className="btn btn-outline-secondary ml-1 mr-1 mb-2" onClick={()=>{setPopupConflict(false)}}><T>Cancel</T></button>
                                    <button className="btn btn-primary ml-1 mr-1 mb-2" onClick={()=>{addToCartAfterConfirmation()}}><T>Continue</T></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="single-product-tabs">
                        <div className="tab-headings d-inline-flex align-items-center">
                            <h4 className={tab===1 ? "single-heading active": "single-heading"} onClick={()=>{ setTab(1) }}><T>Description</T></h4>
                            <h4 className={tab===2 ? "single-heading active": "single-heading"} onClick={()=>{ setTab(2) }}><T>Additional Info</T></h4>
                            <h4 className={tab===3 ? "single-heading active": "single-heading"} onClick={()=>{ setTab(3) }}><T>Reviews</T></h4>
                        </div>
                        <div className={tab === 1 ? "single-content": "single-content d-none"}>
                            {product.FullDescription ? <div dangerouslySetInnerHTML={{__html: product.FullDescription}}></div> : <p className="m-0 fs-16 text-gray-400 fw-400"><T>This product has no description</T></p>}
                        </div>
                        <div className={tab === 2 ? "single-content": "single-content d-none"} >
                            <div className="additional-info d-flex align-items-center mb-2">
                                <p className="fw-500 fs-14 text-gray-500 mt-0 mb-0 mr-2">SKU:</p>
                                <p className="fw-400 fs-16 text-secondary m-0">{product.ProductSku}</p>
                            </div>
                            <div className="additional-info d-flex align-items-center mb-2">
                                <p className="fw-500 fs-14 text-gray-500 mt-0 mb-0 mr-2"><T>Product Brand:</T></p>
                                <p className="fw-400 fs-16 text-secondary m-0">{product.ManufacturerName}</p>
                            </div>
                            {   product.ProductAttributeId ?
                                <div className="additional-info d-flex align-items-center">
                                    <p className="fw-500 fs-14 text-gray-500 mt-0 mb-0 mr-2">{product.ProductAttributeName}:</p>
                                    <p className="fw-400 fs-16 text-secondary m-0">{product.ProductAttributeValue}</p>
                                </div> : <></>
                            }
                        </div>
                        <div className={tab === 3 ? "single-content": "single-content d-none"}>
                            <div className="product-review-box">
                                {/* <div className="product-review-fields">
                                    {
                                        authorized ? product.ReviewAllowed ?
                                        <form className="w-100">
                                            <div className="d-flex align-items-center justify-between mb-3">
                                                <div className="review-user-info d-inline-flex align-items-center">
                                                    <div className="review-user-avatar mr-2">
                                                        <img src="https://api.lorem.space/image/face?w=640&h=480&r=5614" alt=""/>
                                                    </div>
                                                    <div className="review-user-details">
                                                        <h5 className="review-user-name mb-1 mt-0">John Wick</h5>
                                                        <div className="review-user-rating d-inline-flex align-items-center">
                                                            <ReactStars size={18} count={5} edit={true} color="#FFB300" activeColor="#FFB300" value={rating} onChange={(r)=>{setRating(r)}} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />}/> <span className="ml-2 mb-1 fs-16 fw-400 text-gray-600">{rating.toFixed(1)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary">Submit Review</button>

                                            </div>
                                            <div className="review-user-field">
                                                <textarea rows={4} placeholder={`How did you like ${product.title}?`}></textarea>
                                            </div>
                                        </form>
                                        : <></>
                                        :  <p className="mb-5">Please <Link to={"/login"} className="link">Sign-In</Link> to your account to write a review.</p>
                                    }
                                </div> */}
                                {
                                    reviewList.length ?
                                    <div className="product-reviews mb-5">
                                        {
                                            reviewList.map((review,index)=>{
                                                return <div className="single-review" key={index}>
                                                            <div className="review-header d-flex align-items-center justify-between">
                                                                <div className="review-user-info d-inline-flex align-items-center">
                                                                    <div className="review-user-avatar mr-2">
                                                                        {
                                                                            review.ReviewUserPicture ?
                                                                            <img src={review.ReviewUserPicture} alt={review.ReviewUsername}/> :
                                                                            <div className="review-user-avatar-letter">{review.ReviewUsername[0]}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="review-user-details">
                                                                        <h5 className="review-user-name mb-1 mt-0">{review.ReviewUsername}</h5>
                                                                        <div className="review-user-rating d-inline-flex align-items-center">
                                                                            <ReactStars size={18} count={5} edit={false} color="#FFB300" activeColor="#FFB300" value={review.ReviewRating} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />}/> <span className="ml-2 mb-1 fs-16 fw-400 text-gray-600">{review.ReviewRating.toFixed(1)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="review-user-date fs-14 fw-400 text-gray-500">{review.ReviewCreatedOnUtc.replaceAll("-"," ")}</span>
                                                            </div>
                                                            <div className="review-comment">
                                                                {
                                                                    // review.IsApproved ? "" : <span className="text-primary fs-12 fw-500 d-block mb-2 mt-2">{translations.ProductSingle[language].reviewApprovalLabel}</span>
                                                                }
                                                                <p className="mt-0 mb-0">{review.ReviewUserText}</p>
                                                            </div>
                                                        </div>
                                            })
                                        }
                                    </div> : <p className="m-0 fs-16 text-gray-400 fw-400"><T>This product has no reviews</T></p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    relatedProducts.length ?
                    <div className="wrapper mt-5">
                        <div className="product-related product-related-slider">
                                <div className="product-related-header mb-5">
                                    <div className="d-flex justify-between align-items-center">
                                        <h2 className="fw-500 fs-24 mt-0 mb-0 text-gray-500"><T>Related Products</T></h2>
                                        {
                                            product.CategoryName ?
                                            <div>
                                                <Link className="link" to={`/products?category=${product.CategoryId}`}><T>View All</T></Link>
                                            </div> : null
                                        }
                                    </div>
                                    <p className="fw-400 fs-16 mt-0 mb-0 text-gray-400"><T>Products that are similar to</T> {product.ProductName}</p>
                                </div>
                            
                            {/* <Carousel responsive={responsive}>
                                {
                                    relatedProducts.map((item,key)=>(
                                        <ProductCard key={"product"+key} item={item}/>
                                    ))
                                }
                            </Carousel> */}
                            <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
                                <div className="d-flex">
                                    {
                                        relatedProducts.map((item,key)=>(
                                            <ProductCard onClick={()=>{analyticsTrackEvent("Recommended Product Clicked", { product_id: item.ProductId, brand: item.ManufacturerName, category: item.CategoryName })}} key={"product"+key} item={item} pageTitle="Product Details" permissions={props.consent}/>
                                        ))
                                    }
                                </div>
                            </ScrollContainer>
                        </div>
                    </div> : <></>
                }
                {
                    crossSellingProducts.length ?
                    <div className="wrapper mt-5">
                        <div className="product-related product-related-slider">
                            <div className="product-related-header mb-5">
                                <h2 className="fw-500 fs-24 mt-0 mb-0 text-gray-500"><T>Frequently Bought Together</T></h2>
                                <p className="fw-400 fs-16 mt-0 mb-0 text-gray-400"><T>Products that were often purchased together with</T> {product.ProductName}</p>
                            </div>
                            {/* <Carousel responsive={responsive}>
                                {
                                    crossSellingProducts.map((item,key)=>(
                                        <ProductCard key={"product"+key} item={item}/>
                                    ))
                                }
                            </Carousel> */}
                            <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
                                <div className="d-flex">
                                    {
                                        crossSellingProducts.map((item,key)=>(
                                            <ProductCard key={"product"+key} item={item}/>
                                        ))
                                    }
                                </div>
                            </ScrollContainer>
                        </div>
                    </div> : <></>
                }
                
            </section>
            }
        </>
    )
}

export default Product;