import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import popupImage from "../../assets/images/popup-icon.png"
import warningIcon from "../../assets/images/failed-icon.png"
import noImage from "../../assets/images/no-image.jpg"
import { addCart } from "../../helper/backend-methods";
import { GlobalContext } from "../../context";
import T from "../../locale/translations-main";
function InvitedOccasionProduct({item,occasionId,cartOccasion,setCartOccasion}) {

    const { cartCount, updateCartCount } = useContext(GlobalContext);
    const [popup, setPopup] = useState(false);
    const [popupConflict, setPopupConflict] = useState(false);
    const [cartError, setCartError] = useState("")
    const [outOfStock, setOutOfStock] = useState(false)
    const [cartHasItems, setCartHasItems] = useState(localStorage.getItem("cartItemCount"))

    const addToCart = (id) => {
        setCartError("")
        // if cart is currently empty
        if(!cartHasItems){
            addCart({Quantity:1, occasionId:occasionId, ProductId : [id]}).then(({data}) =>{
                if(data.Status){
                    updateCartCount(data.Data.CartItemCount);
                    setPopup(true)
                    localStorage.setItem("occasion",occasionId)
                    localStorage.setItem("cartItemCount",1);
                    setCartHasItems(1);
                }
                else{
                    setCartError(data.Message)
                    setOutOfStock(true)
                }
            }).catch((e)=>{
                setOutOfStock(true)
                console.log(e);
            })
        }
        // if items already in cart don't belong to any occasion
        else if(cartOccasion === 0){
            setPopupConflict(true);
        }
        // if items already in cart belong to some other occasion
        else if(occasionId === cartOccasion){
            addCart({Quantity:1, occasionId:occasionId, ProductId : [id]}).then(({data}) =>{
                if(data.Status){
                    updateCartCount(data.Data.CartItemCount);
                    setPopup(true);
                    localStorage.setItem("occasion",occasionId)
                    localStorage.setItem("cartItemCount",1);
                    setCartHasItems(1);
                }
                else{
                    setCartError(data.Message)
                    setOutOfStock(true)
                }
            }).catch((e)=>{
                setOutOfStock(true)
                console.log(e);
            })
        }
        // if items already in cart belong to some other occasion
        else{
            setPopupConflict(true);
        }
    }

    const addToCartAfterConfirmation = (id) => {
        setPopupConflict(false);
        addCart({Quantity:1, occasionId:occasionId, ProductId : [id]}).then(({data}) =>{
            if(data.Status){
                updateCartCount(data.Data.CartItemCount);
                localStorage.setItem("occasion",occasionId)
                localStorage.setItem("cartItemCount",1);
                setCartHasItems(1);
                setCartOccasion(occasionId)
                setPopup(true)
            }
            else{
                setCartError(data.Message)
                setOutOfStock(true)
            }
        }).catch((e)=>{
            console.log(e);
        })
    }

    return (
        <div className="occasion-card-container pt-4 pb-4">
            <div className="occasion-product-list  d-flex justify-between flex-wrap align-items-center">
                <Link className="product-image" to={"/product/"+item.ProductId}>
                    <img src={item.ProductPicture} alt={item.ProductName} onError={(e) => { e.target.onerror = null; e.target.src = noImage }}/>
                </Link>
                <Link className="product-title" to={"/product/"+item.ProductId}>
                    <p className="m-0" title={item.ProductName}>{item.ProductName.length > 40 ? item.ProductName.slice(0, 37) + "..." : item.ProductName}</p>
                </Link>
                <div className="product-rating">
                    <span className="fs-12 mb-1 text-gray-400 fw-400"><T>Rating</T></span>
                    <div className="single-product-rating d-flex align-items-center mb-0">
                        <ReactStars size={18} count={5} edit={false} color="#FFB300" activeColor="#FFB300" value={item.ProductRating} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />}/>
                    </div>
                </div>
                <div className="product-price-container">
                    <span className="fs-12 mb-1 text-gray-400 fw-400"><T>Price</T></span>
                    <div className="product-price d-flex align-items-center">
                        <p className="single-product-price text-secondary mt-0 mb-0 mr-1"><span className="text-gray-500">SAR</span> {item.UnitPriceDiscount ? (item.ProductPrice - item.UnitPriceDiscount).toFixed(2) : item.ProductPrice.toFixed(2)}</p>
                        { item.UnitPriceDiscount ? <span className="price-drop text-gray-500 fs-14 fw-400 text-left"><s>SAR { (item.ProductPrice).toFixed(2) }</s></span> : <></> }
                    </div>
                </div>
                <div className="btn-occasion">
                    {
                        item.InStock ?
                        <button className="btn btn-primary w-100" onClick={()=>{addToCart(item.ProductId)}}><T>Add to Cart</T></button> :
                        <button type="button" className="product-action btn btn-outline-primary-disabled"><T>Out of Stock</T></button>
                    }
                </div>
                <div className={ popup ? "product-card-popup active" : "product-card-popup"}>
                    <div className="popup-wrapper">
                        <h2 className="popup-title">{item.ProductName.length > 40 ? item.ProductName.slice(0,37)+"..." : item.ProductName}</h2>
                        <span className="popup-subheading"><T>Successfully added to cart</T></span>
                        <div className="popup-image-container">
                            <img src={popupImage} alt="" />
                        </div>
                        <Link className="btn btn-primary" to="/cart"><T>Go to Cart</T></Link>
                        <button className="btn btn-outline-secondary" onClick={()=>{setPopup(false)}}><T>Continue Shopping</T></button>
                    </div>
                </div>
                <div className={ popupConflict ? "product-card-popup active" : "product-card-popup"}>
                    <div className="popup-wrapper">
                        <div className="popup-image-container mb-1">
                            <img src={warningIcon} alt="warning" style={{ height: "140px", width: "140px"}} />
                        </div>
                        {
                            cartOccasion === 0 ?
                            <span className="popup-subheading text-gray-500 fs-14 mt-4 mb-4"><T>You already have items in your cart and you are about to add a product that belongs to an occasion. This will clear all your current cart items.</T></span> :
                            occasionId === 0 ?
                            <span className="popup-subheading text-gray-500 fs-14 mt-4 mb-4"><T>You already have items associated with an occasion in your cart and you are about to add a product that doesn't belong to any occasion. This will clear all your current cart items.</T></span> :
                            <span className="popup-subheading text-gray-500 fs-14 mt-4 mb-4"><T>You already have items associated with an occasion in your cart and you are about to add a product that belongs to some other occasion. This will clear all your current cart items.</T></span>
                        }
                        <div className="d-flex align-items-center justify-center">
                            <button className="btn btn-outline-secondary ml-1 mr-1 mb-2" onClick={()=>{setPopupConflict(false)}}><T>Cancel</T></button>
                            <button className="btn btn-primary ml-1 mr-1 mb-2" onClick={()=>{addToCartAfterConfirmation(item.ProductId)}}><T>Continue</T></button>
                        </div>
                    </div>
                </div>
                <div className={ outOfStock ? "product-card-popup active" : "product-card-popup"}>
                    <div className="popup-wrapper">
                        <div className="popup-image-container mb-1">
                            <img src={warningIcon} alt="warning" style={{ height: "140px", width: "140px"}} />
                        </div>
                        <span className="popup-heading text-gray-500 fw-500 fs-18 mt-4 mb-2"><T>Out of Stock</T></span>
                        <span className="popup-subheading text-gray-500 fs-14 mt-2 mb-4"><T>We apologize for the inconvenience, but the product seems to be out of stock. Please check again in a few days.</T></span>
                        <div className="d-flex align-items-center justify-center">
                            <button className="btn btn-outline-secondary ml-1 mr-1 mb-2" onClick={()=>{setOutOfStock(false)}}><T>Continue</T></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InvitedOccasionProduct;