const translations = {
    "My Address": {
      "en": "My Address",
      "ar": "عناويني"
    },
    "My Delivery Address": {
      "en": "My Delivery Address",
      "ar": "عنوان التوصيل"
    },
    "Address": {
      "en": "Address",
      "ar": "العنوان"
    },
    "Make Default": {
      "en": "Make Default",
      "ar": "ضبط كعنوان افتراضي"
    },
    "Title": {
      "en": "Title",
      "ar": "العنوان"
    },
    "House No/Flat No": {
      "en": "House No/Flat No",
      "ar": "رقم المنزل / رقم الشقة"
    },
    "Street Address": {
      "en": "Street Address",
      "ar": "عنوان الشارع"
    },
    "Region": {
      "en": "Region",
      "ar": "المنطقة"
    },
    "City/Town": {
      "en": "City/Town",
      "ar": "المدينة / البلدة"
    },
    "Zip Code": {
      "en": "Zip Code",
      "ar": "الرمز البريدي"
    },
    "Delete": {
      "en": "Delete",
      "ar": "حذف"
    },
    "Add New Address": {
      "en": "Add New Address",
      "ar": "إضافة عنوان جديد"
    },
    "Update Address": {
      "en": "Update Address",
      "ar": "تحديث العنوان"
    },
    "Fetching address data. Please wait....": {
      "en": "Fetching address data. Please wait....",
      "ar": "تحميل بيانات العنوان. أرجو الإنتظار..."
    },
    "Default": {
      "en": "Default",
      "ar": "افتراضي"
    },
    "Select Region": {
      "en": "Select Region",
      "ar": "حدد المنطقة"
    },
    "Select City/Town": {
      "en": "Select City/Town",
      "ar": "حدد المدينة / البلدة"
    },
    "You currently have no addresses configured in your account.": {
      "en": "You currently have no addresses configured in your account.",
      "ar": "ليس لديك حاليا أي عناوين في حسابك."
    },
    "Address title is required": {
      "en": "Address title is required",
      "ar": "العنوان مطلوب"
    },
    "Please provide a valid Address": {
      "en": "Please provide a valid Address",
      "ar": "يرجى تقديم عنوان صالح"
    },
    "Please provide a valid ZipCode": {
      "en": "Please provide a valid ZipCode",
      "ar": "الرجاء تقديم رمز بريدي صالح"
    },
    "Please select event State/Province": {
      "en": "Please select event State/Province",
      "ar": "الرجاء تحديد ولاية / مقاطعة "
    },
    "Please select event City/Town": {
      "en": "Please select event City/Town",
      "ar": "الرجاء تحديد مدينة / بلدة"
    },
    "Please provide a valid street address": {
      "en": "Please provide a valid street address",
      "ar": "يرجى تقديم عنوان شارع صالح"
    }
  }
  export default translations