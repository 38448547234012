import React, { useState, useEffect } from "react";
import '../../assets/css/--component-account-to-be-reviewed-card.css'
import { postReview } from "../../helper/backend-methods";
import noImage from "../../assets/images/no-image.jpg"
import { Link } from "react-router-dom";
import T, { _T } from "../../locale/translations-main";

function ToBeReviewed({ item, method }) {
    const props = item
    const [editable, setEditable] = useState(false)
    const [rating, setRating] = useState(5);
    const [reviewText, setReviewText] = useState("");
    const [error, setError] = useState({ reviewText: "" });
    const [loading, setLoading] = useState(false);


    const handleInput = (e) => {
        e.preventDefault()
        let flag = false;
        const errors = { reviewText: "" };
        if (!reviewText || reviewText.replaceAll(/\s/g, '').length < 2) {
            errors.reviewText = 'Please type-in your review.';
            flag = true;
        }
        setError(errors)
        if (!loading && !flag) {
            setLoading(true)
            const event = e
            postReview({ ProductId: props.ProductId, ReviewText: event.target[0].value, Rating: rating, OrderId: props.OrderId }).then(({ data }) => {
                setEditable(false)
                method()
                setLoading(false)
                setError({ reviewText: "" });
                setReviewText("");
                setRating(5)
            }).catch((e) => {
                setEditable(false)
                console.log(e);
                setLoading(false)
                setError({ reviewText: "" });
                setReviewText("");
                setRating(5)
            })
        }
    }
    return (

        <div className={editable ? "to-be-reviewed-cards  d-flex justify-between flex-wrap  align-items-center active" : "to-be-reviewed-cards  d-flex justify-between flex-wrap  align-items-center"}>
            <Link to={"/product/" + props.ProductId} className="product-image decoration-none">
                <img src={props.ProductPictureUrl ? props.ProductPictureUrl : noImage} alt="" />
            </Link>
            <div className="product-name">
                <Link to={"/product/" + props.ProductId} className="fw-500 text-gray-500 fs-16 m-0 decoration-none">{props.ProductName.length > 45 ? props.ProductName.slice(0, 42) + "..." : props.ProductName}</Link>
            </div>
            <div className="produt-brand">
                <p className="fw-500 text-gray-400 fs-16 m-0"><T>Brand: </T></p><span className="text-primary">{props.ProductManufacturerName}</span>
            </div>
            <div className="product-purchase-date">
                <p className="fw-500 text-gray-400 fs-16 m-0"><T>Purchased on: </T></p><span className="text-gray-500">{props.ProductPurchasedOn}</span>
            </div>
            <div className="write-review-btn">
                <button onClick={(e) => { setEditable(true) }} id="toggle" className="btn btn-primary" ><T>Write A Review</T></button>
            </div>
            <form className="review-form-modal" onSubmit={handleInput}>
                <div className="review-form-container">
                    <div className="review-form-heading">
                        <h2 className='fs-20 text-gray-500 fw-500 mt-0'><T>Write A Review</T></h2>
                    </div>
                    <div className="review-form-rating mb-2">
                        <label className="fw-500 fs-16 text-gray-500 mb-2"><T>Your Rating</T><span className="text-primary">*</span></label>
                        <div className="star-rating d-flex align-items-center">
                            {
                                [...Array(5)].map((s, i) => {
                                    return (i + 1) <= rating ? <i key={i} className="ri-star-fill fs-18 d-inline-block cursor-pointer" style={{ color: "#FFB300" }} onClick={() => { setRating(i + 1) }} /> : <i key={i} className="ri-star-line fs-18 d-inline-block cursor-pointer" style={{ color: "#FFB300" }} onClick={() => { setRating(i + 1) }} />
                                })
                            }
                            <span className="fs-16 fw-500 text-gray-500 ml-2 mb-1">{rating.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-between">
                        <label htmlFor="account-review-textarea" className="fw-500 fs-16 text-gray-500 m-0"><T>Product Review</T><span className="text-primary">*</span></label>
                        <span className="fs-12 fw-500 text-gray-500">{reviewText.length} / {255} characters</span>
                    </div>
                    <textarea onChange={(e) => { setReviewText((e.target.value).slice(0, 255)) }} value={reviewText} type="text" name="addreviewbox" rows="5" id="account-review-textarea" className="textarea-review mt-2 d-block mb-1" placeholder={_T("Write your review here")} ></textarea>
                    {error.reviewText ? <p className="fs-14 text-primary fw-500 mt-0">{error.reviewText}</p> : <></>}
                    <div className="btn-review-group text-right mt-4">
                        <button type="button" onClick={(e) => { setEditable(false); setError({ reviewText: "" }); setReviewText(""); setRating(5) }} className="btn btn-alternative mr-2 tex"><T>Cancel</T></button>
                        {
                            loading ?
                                <button type="button" className="btn btn-outline-primary"><T>Please Wait</T></button> :
                                <button type="submit" className="btn btn-primary"><T>Post Review</T></button>
                        }
                    </div>
                </div>
            </form>




        </div>
    )
}

export default ToBeReviewed;