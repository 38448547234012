import React, { useState, useEffect } from "react";
import { cardIcons } from "../checkout/payment-cards";
import T from "../../locale/translations-main";

function PaymentMethodCard({ item , methodDefault , methodDelete }) {
 
    return (
        <div className="payment-method-card">
            {
                <>
                    <div onClick={()=>{methodDefault(item)}} className={ item.Selected ? "payment-method-card-ui active" : "payment-method-card-ui"}>
                        <div className="payment-method-card-header">
                            <h2>{item.Scheme}</h2>
                            <img className="card-icon" src={cardIcons[item.Scheme.toLowerCase().replaceAll(" ", "-")] ? cardIcons[item.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="" />
                        </div>
                        <div className="payment-method-card-number">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span>{item.Last4}</span>
                        </div>
                        <div className="payment-method-card-footer">
                            <div className="payment-method-card-holder">
                                <span><T>CARDHOLDER</T></span>
                                <h2>{item.CardHolderName}</h2>
                            </div>
                            <div className="payment-method-card-expiry">
                                <span><T>EXPIRY</T></span>
                                <h2>{item.ExpiryMonth}/{item.ExpiryYear.toString().slice(-2)}</h2>
                            </div>
                        </div>
                    </div>
                    <span className="delete-btn" onClick={() => { methodDelete(item) }}><i className="ri-delete-bin-line fs-18 mr-2"></i><T>Delete Card</T></span>


                    {/* <div className="d-flex align-items-center flex-wrap">
                        <img className="card-icon" src={cardIcons[item.Scheme.toLowerCase().replaceAll(" ", "-")] ? cardIcons[item.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="" />
                        <div className="card-number">
                            <p className="text-gray-500 fw-500 fs-16 m-0">{item.Scheme} **** {item.Last4}</p>
                            <p className="text-gray-400 fw-400 fs-14 m-0">Expires on <span className="d-inline-flex">{item.ExpiryMonth}</span><span className="d-inline-flex">/</span><span className="d-inline-flex">{item.ExpiryYear.toString().slice(-2)}</span></p>
                        </div>
                        <div className="default-method text-center">
                            {
                                item.Selected ?
                                <span className="text-primary fs-16 fw-500">Default Card</span> :
                                <button onClick={()=>{methodDefault(item)}} className="link"><span className="text-secondary fs-16 fw-500">Set as Default</span></button>
                            }
                        </div>
                    </div>
                    <button className="btn btn-primary delete-btn" onClick={() => { methodDelete(true) }}><i className="ri-close-line"></i></button> */}
                </>
            }
        </div>


    )
}

export default PaymentMethodCard;