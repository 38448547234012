export default function nationalityTranslation(n=null){
    const nationality = {
        "Afghan": {
            "en": "Afghan",
            "ar": "أفغانستاني"
        },
        "Albanian": {
            "en": "Albanian",
            "ar": "ألباني"
        },
        "Algerian": {
            "en": "Algerian",
            "ar": "جزائري"
        },
        "American": {
            "en": "American",
            "ar": "أمريكي"
        },
        "Andorran": {
            "en": "Andorran",
            "ar": "أندوري"
        },
        "Angolan": {
            "en": "Angolan",
            "ar": "أنغولي"
        },
        "Antiguans and Barbudans": {
            "en": "Antiguans and Barbudans",
            "ar": "أنتيغويونيون وبربودييون"
        },
        "Argentinean": {
            "en": "Argentinean",
            "ar": "أرجنتيني"
        },
        "Armenian": {
            "en": "Armenian",
            "ar": "أرميني"
        },
        "Australian": {
            "en": "Australian",
            "ar": "أسترالي"
        },
        "Austrian": {
            "en": "Austrian",
            "ar": "نمساوي"
        },
        "Azerbaijani": {
            "en": "Azerbaijani",
            "ar": "أذربيجاني"
        },
        "Bahamian": {
            "en": "Bahamian",
            "ar": "باهامي"
        },
        "Bahraini": {
            "en": "Bahraini",
            "ar": "بحريني"
        },
        "Bangladeshi": {
            "en": "Bangladeshi",
            "ar": "بنغالي"
        },
        "Barbadian": {
            "en": "Barbadian",
            "ar": "بربادوسي"
        },
        "Belarusian": {
            "en": "Belarusian",
            "ar": "بيلاروسي"
        },
        "Belgian": {
            "en": "Belgian",
            "ar": "بلجيكي"
        },
        "Belizean": {
            "en": "Belizean",
            "ar": "بليزي"
        },
        "Beninese": {
            "en": "Beninese",
            "ar": "بنيني"
        },
        "Bhutanese": {
            "en": "Bhutanese",
            "ar": "بوتاني"
        },
        "Bolivian": {
            "en": "Bolivian",
            "ar": "بوليفي"
        },
        "Bosnian": {
            "en": "Bosnian",
            "ar": "البوسنة والهرسكي"
        },
        "Brazilian": {
            "en": "Brazilian",
            "ar": "برازيلي"
        },
        "British": {
            "en": "British",
            "ar": "بريطاني"
        },
        "Bruneian": {
            "en": "Bruneian",
            "ar": "بروناي"
        },
        "Bulgarian": {
            "en": "Bulgarian",
            "ar": "بلغاري"
        },
        "Burkinabe": {
            "en": "Burkinabe",
            "ar": "بوركينابي"
        },
        "Burmese": {
            "en": "Burmese",
            "ar": "بورمي"
        },
        "Burundian": {
            "en": "Burundian",
            "ar": "بوروندي"
        },
        "Cambodian": {
            "en": "Cambodian",
            "ar": "كمبودي"
        },
        "Cameroonian": {
            "en": "Cameroonian",
            "ar": "كاميروني"
        },
        "Canadian": {
            "en": "Canadian",
            "ar": "كندي"
        },
        "Cape Verdean": {
            "en": "Cape Verdean",
            "ar": "الرأس الأخضري"
        },
        "Central African": {
            "en": "Central African",
            "ar": "وسط أفريقي"
        },
        "Chadian": {
            "en": "Chadian",
            "ar": "تشادي"
        },
        "Chilean": {
            "en": "Chilean",
            "ar": "تشيلي"
        },
        "Chinese": {
            "en": "Chinese",
            "ar": "صيني"
        },
        "Colombian": {
            "en": "Colombian",
            "ar": "كولومبي"
        },
        "Comoran": {
            "en": "Comoran",
            "ar": "جزر القمري"
        },
        "Congolese": {
            "en": "Congolese",
            "ar": "الكونغولي"
        },
        "Costa Rican": {
            "en": "Costa Rican",
            "ar": "كوستاريكي"
        },
        "Croatian": {
            "en": "Croatian",
            "ar": "كرواتي"
        },
        "Cuban": {
            "en": "Cuban",
            "ar": "كوبي"
        },
        "Cypriot": {
            "en": "Cypriot",
            "ar": "قبرصي"
        },
        "Czech": {
            "en": "Czech",
            "ar": "تشيكي"
        },
        "Danish": {
            "en": "Danish",
            "ar": "دنماركي"
        },
        "Djiboutian": {
            "en": "Djiboutian",
            "ar": "جيبوتي"
        },
        "Dominican": {
            "en": "Dominican",
            "ar": "دومينيكي"
        },
        "Dutch": {
            "en": "Dutch",
            "ar": "هولندي"
        },
        "East Timorese": {
            "en": "East Timorese",
            "ar": "تيموري"
        },
        "Ecuadorean": {
            "en": "Ecuadorean",
            "ar": "إكوادوري"
        },
        "Egyptian": {
            "en": "Egyptian",
            "ar": "مصري"
        },
        "Emirati": {
            "en": "Emirati",
            "ar": "إماراتي"
        },
        "Equatorial Guinean": {
            "en": "Equatorial Guinean",
            "ar": "غينيا الاستوائي"
        },
        "Eritrean": {
            "en": "Eritrean",
            "ar": "إريتري"
        },
        "Estonian": {
            "en": "Estonian",
            "ar": "إستوني"
        },
        "Ethiopian": {
            "en": "Ethiopian",
            "ar": "إثيوبي"
        },
        "Fijian": {
            "en": "Fijian",
            "ar": "فيجي"
        },
        "Filipino": {
            "en": "Filipino",
            "ar": "فيليبيني"
        },
        "Finnish": {
            "en": "Finnish",
            "ar": "فنلندي"
        },
        "French": {
            "en": "French",
            "ar": "فرنسي"
        },
        "Gabonese": {
            "en": "Gabonese",
            "ar": "جابوني"
        },
        "Gambian": {
            "en": "Gambian",
            "ar": "غامبي"
        },
        "Georgian": {
            "en": "Georgian",
            "ar": "جورجي"
        },
        "German": {
            "en": "German",
            "ar": "ألماني"
        },
        "Ghanaian": {
            "en": "Ghanaian",
            "ar": "غاني"
        },
        "Greek": {
            "en": "Greek",
            "ar": "يوناني"
        },
        "Grenadian": {
            "en": "Grenadian",
            "ar": "غرينادي"
        },
        "Guatemalan": {
            "en": "Guatemalan",
            "ar": "غواتيمالي"
        },
        "Guinean": {
            "en": "Guinean",
            "ar": "غيني"
        },
        "Guinea-Bissauan": {
            "en": "Guinea-Bissauan",
            "ar": "غينيا بيساوي"
        },
        "Guyanese": {
            "en": "Guyanese",
            "ar": "غياني"
        },
        "Haitian": {
            "en": "Haitian",
            "ar": "هايتي"
        },
        "Herzegovinian": {
            "en": "Herzegovinian",
            "ar": "الهرسكي"
        },
        "Honduran": {
            "en": "Honduran",
            "ar": "هندوراسي"
        },
        "Hungarian": {
            "en": "Hungarian",
            "ar": "مجري"
        },
        "Icelandic": {
            "en": "Icelandic",
            "ar": "أيسلندي"
        },
        "Indian": {
            "en": "Indian",
            "ar": "هندي"
        },
        "Indonesian": {
            "en": "Indonesian",
            "ar": "إندونيسي"
        },
        "Iranian": {
            "en": "Iranian",
            "ar": "إيراني"
        },
        "Iraqi": {
            "en": "Iraqi",
            "ar": "عراقي"
        },
        "Irish": {
            "en": "Irish",
            "ar": "إيرلندي"
        },
        "Israeli": {
            "en": "Israeli",
            "ar": "إسرائيلي"
        },
        "Italian": {
            "en": "Italian",
            "ar": "إيطالي"
        },
        "Ivorian": {
            "en": "Ivorian",
            "ar": "إيفواري"
        },
        "Jamaican": {
            "en": "Jamaican",
            "ar": "جامايكي"
        },
        "Japanese": {
            "en": "Japanese",
            "ar": "ياباني"
        },
        "Jordanian": {
            "en": "Jordanian",
            "ar": "أردني"
        },
        "Kazakhstani": {
            "en": "Kazakhstani",
            "ar": "كازاخستاني"
        },
        "Kenyan": {
            "en": "Kenyan",
            "ar": "كيني"
        },
        "Kittian and Nevisian": {
            "en": "Kittian and Nevisian",
            "ar": "كيتي ونيفيسي"
        },
        "Kuwaiti": {
            "en": "Kuwaiti",
            "ar": "كويتي"
        },
        "Kyrgyz": {
            "en": "Kyrgyz",
            "ar": "قيرغيزي"
        },
        "Laotian": {
            "en": "Laotian",
            "ar": "لاوسي"
        },
        "Latvian": {
            "en": "Latvian",
            "ar": "لاتفي"
        },
        "Lebanese": {
            "en": "Lebanese",
            "ar": "لبناني"
        },
        "Liberian": {
            "en": "Liberian",
            "ar": "ليبيري"
        },
        "Libyan": {
            "en": "Libyan",
            "ar": "ليبي"
        },
        "Liechtensteiner": {
            "en": "Liechtensteiner",
            "ar": "ليختنشتاينر"
        },
        "Lithuanian": {
            "en": "Lithuanian",
            "ar": "ليتواني"
        },
        "Luxembourger": {
            "en": "Luxembourger",
            "ar": "لوكسمبورجي"
        },
        "Macedonian": {
            "en": "Macedonian",
            "ar": "مقدوني"
        },
        "Malagasy": {
            "en": "Malagasy",
            "ar": "مدغشقري"
        },
        "Malawian": {
            "en": "Malawian",
            "ar": "مالاوي"
        },
        "Malaysian": {
            "en": "Malaysian",
            "ar": "ماليزي"
        },
        "Maldivian": {
            "en": "Maldivian",
            "ar": "مالديفي"
        },
        "Malian": {
            "en": "Malian",
            "ar": "مالي"
        },
        "Maltese": {
            "en": "Maltese",
            "ar": "مالطي"
        },
        "Marshallese": {
            "en": "Marshallese",
            "ar": "مارشالي"
        },
        "Mauritanian": {
            "en": "Mauritanian",
            "ar": "موريتاني"
        },
        "Mauritian": {
            "en": "Mauritian",
            "ar": "موريشيوسي"
        },
        "Mexican": {
            "en": "Mexican",
            "ar": "مكسيكي"
        },
        "Micronesian": {
            "en": "Micronesian",
            "ar": "ميكرونيزي"
        },
        "Moldovan": {
            "en": "Moldovan",
            "ar": "مولدوفي"
        },
        "Monacan": {
            "en": "Monacan",
            "ar": "موناكوي"
        },
        "Mongolian": {
            "en": "Mongolian",
            "ar": "منغولي"
        },
        "Montenegrin": {
            "en": "Montenegrin",
            "ar": "الجبل الأسودي"
        },
        "Moroccan": {
            "en": "Moroccan",
            "ar": "مغربي"
        },
        "Mozambican": {
            "en": "Mozambican",
            "ar": "موزمبيقي"
        },
        "Namibian": {
            "en": "Namibian",
            "ar": "ناميبي"
        },
        "Nauruan": {
            "en": "Nauruan",
            "ar": "نوروي"
        },
        "Nepalese": {
            "en": "Nepalese",
            "ar": "نيبالي"
        },
        "New Zealander": {
            "en": "New Zealander",
            "ar": "نيوزيلندي"
        },
        "Nicaraguan": {
            "en": "Nicaraguan",
            "ar": "نيكاراغوي"
        },
        "Nigerien": {
            "en": "Nigerien",
            "ar": "نيجيري"
        },
        "Nigerian": {
            "en": "Nigerian",
            "ar": "نيجيريا"
        },
        "North Korean": {
            "en": "North Korean",
            "ar": "كوري شمالي"
        },
        "Norwegian": {
            "en": "Norwegian",
            "ar": "نرويجي"
        },
        "Omani": {
            "en": "Omani",
            "ar": "عماني"
        },
        "Pakistani": {
            "en": "Pakistani",
            "ar": "باكستاني"
        },
        "Palauan": {
            "en": "Palauan",
            "ar": "بالاوي"
        },
        "Panamanian": {
            "en": "Panamanian",
            "ar": "بنمي"
        },
        "Papua New Guinean": {
            "en": "Papua New Guinean",
            "ar": "بابوا غينيا الجديدة"
        },
        "Paraguayan": {
            "en": "Paraguayan",
            "ar": "باراغوياني"
        },
        "Peruvian": {
            "en": "Peruvian",
            "ar": "بيروفي"
        },
        "Philippine": {
            "en": "Philippine",
            "ar": "فلبيني"
        },
        "Polish": {
            "en": "Polish",
            "ar": "بولندي"
        },
        "Portuguese": {
            "en": "Portuguese",
            "ar": "برتغالي"
        },
        "Qatari": {
            "en": "Qatari",
            "ar": "قطري"
        },
        "Romanian": {
            "en": "Romanian",
            "ar": "روماني"
        },
        "Russian": {
            "en": "Russian",
            "ar": "روسي"
        },
        "Rwandan": {
            "en": "Rwandan",
            "ar": "رواندي"
        },
        "Saint Lucian": {
            "en": "Saint Lucian",
            "ar": "سانت لوسيان"
        },
        "Salvadoran": {
            "en": "Salvadoran",
            "ar": "سلفادوري"
        },
        "Samoan": {
            "en": "Samoan",
            "ar": "ساموي"
        },
        "Sanmarinese": {
            "en": "Sanmarinese",
            "ar": "سان مارينيز"
        },
        "Sao Tomean": {
            "en": "Sao Tomean",
            "ar": "ساو تومي"
        },
        "Saudi": {
            "en": "Saudi",
            "ar": "سعودي"
        },
        "Senegalese": {
            "en": "Senegalese",
            "ar": "سنغالي"
        },
        "Serbian": {
            "en": "Serbian",
            "ar": "صربي"
        },
        "Seychellois": {
            "en": "Seychellois",
            "ar": "سيشيلي"
        },
        "Sierra Leonean": {
            "en": "Sierra Leonean",
            "ar": "سيراليوني"
        },
        "Singaporean": {
            "en": "Singaporean",
            "ar": "سنغافوري"
        },
        "Slovak": {
            "en": "Slovak",
            "ar": "سلوفاكي"
        },
        "Slovenian": {
            "en": "Slovenian",
            "ar": "سلوفيني"
        },
        "Solomon Islander": {
            "en": "Solomon Islander",
            "ar": "جزر سليماني"
        },
        "Somali": {
            "en": "Somali",
            "ar": "صومالي"
        },
        "South African": {
            "en": "South African",
            "ar": "جنوب أفريقي"
        },
        "South Korean": {
            "en": "South Korean",
            "ar": "كوري جنوبي"
        },
        "Spanish": {
            "en": "Spanish",
            "ar": "إسباني"
        },
        "Sri Lankan": {
            "en": "Sri Lankan",
            "ar": "سريلانكي"
        },
        "Sudanese": {
            "en": "Sudanese",
            "ar": "سوداني"
        },
        "Surinamese": {
            "en": "Surinamese",
            "ar": "سورينامي"
        },
        "Swazi": {
            "en": "Swazi",
            "ar": "سوازيلندي"
        },
        "Swedish": {
            "en": "Swedish",
            "ar": "سويدي"
        },
        "Swiss": {
            "en": "Swiss",
            "ar": "سويسري"
        },
        "Syrian": {
            "en": "Syrian",
            "ar": "سوري"
        },
        "Taiwanese": {
            "en": "Taiwanese",
            "ar": "تايواني"
        },
        "Tajik": {
            "en": "Tajik",
            "ar": "طاجيكي"
        },
        "Tanzanian": {
            "en": "Tanzanian",
            "ar": "تنزاني"
        },
        "Thai": {
            "en": "Thai",
            "ar": "تايلندي"
        },
        "Togolese": {
            "en": "Togolese",
            "ar": "توجولي"
        },
        "Tongan": {
            "en": "Tongan",
            "ar": "تونغي"
        },
        "Trinidadian or Tobagonian": {
            "en": "Trinidadian or Tobagonian",
            "ar": "ترينيدادي وتوباغوني"
        },
        "Tunisian": {
            "en": "Tunisian",
            "ar": "تونسي"
        },
        "Turkish": {
            "en": "Turkish",
            "ar": "تركي"
        },
        "Tuvaluan": {
            "en": "Tuvaluan",
            "ar": "توفالوي"
        },
        "Ugandan": {
            "en": "Ugandan",
            "ar": "أوغندي"
        },
        "Ukrainian": {
            "en": "Ukrainian",
            "ar": "أوكراني"
        },
        "Uruguayan": {
            "en": "Uruguayan",
            "ar": "أوروغواياني"
        },
        "Uzbek": {
            "en": "Uzbek",
            "ar": "أوزبكي"
        },
        "Ni-Vanuatu": {
            "en": "Ni-Vanuatu",
            "ar": "نيفانواتو"
        },
        "Venezuelan": {
            "en": "Venezuelan",
            "ar": "فنزويلي"
        },
        "Vietnamese": {
            "en": "Vietnamese",
            "ar": "فيتنامي"
        },
        "Yemeni": {
            "en": "Yemeni",
            "ar": "يمني"
        },
        "Zambian": {
            "en": "Zambian",
            "ar": "زامبي"
        },
        "Zimbabwean": {
            "en": "Zimbabwean",
            "ar": "زيمبابوي"
        }
    }
    if(n){
        const result = Object.keys(nationality).filter((i)=>{
            return nationality[i]["ar"] === n || nationality[i]["en"] === n
        })
        return result && result.length && result[0] ? nationality[result[0]] : { en: n , ar: n}
    }
    else{
        return nationality
    }
}