import React,{useState, useEffect} from "react";
import '../../assets/css/--component-account-payment-method.css'
import PaymentMethodCard from "./payment-method-card";
import { addCardDetails, deleteCard, getUserCards, makeCardDefault } from "../../helper/backend-methods";
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import { cardIcons } from "../checkout/payment-cards";
import { analyticsPageEvent } from "../../components/Analytics";
import T from "../../locale/translations-main";

function PaymentMethod(props){
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [loading, setLoading] = useState(true)
    const [cardDetails, setCardDetails] = useState([])
    const [update, setUpdate] = useState(0)
    const [createCard, setCreateCard] = useState(false)
    const [apiLoading, setApiLoading] = useState(false)
    const [language, setLanguage] = useState("en")
    const [apiResponseStatus, setApiResponseStatus] = useState("")
    const [isArabic, setIsArabic] = useState(false)


    const [showCardNumberError, setShowCardNumberError] = useState(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);
    const [showExpiryError, setShowExpiryError] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [showCVCError, setShowCVCError] = useState(false);
    const [isCardCVCValid, setIsCardCVCValid] = useState(false);
    const [selectedCardIcon, setSelectedCardIcon] = useState(cardIcons["undefined"]);

    const [tokenObject,setTokenObject] = useState(null)


    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Payment Methods", link: "" },
    ]

    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("Account Payment Methods");
        }
    },[props.consent.page])

    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setIsArabic(lang === "ar")
            setLanguage(lang)
        }
    },[])

    useEffect(() => {
        configurePageTitle("Payment Methods")
        configureBreadcrumb(breadcrumbItems)
        getUserCards().then(({data})=>{
            if(data.Status){
                setCardDetails(data.Data)
                setLoading(false)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }, [update]);


    useEffect(() => {
        if(tokenObject){
            addCardDetails(tokenObject).then(({data})=>{
                if(data.Status){
                    setCreateCard(false)
                    setUpdate(update+1)
                }
                else{
                    if(data.StatusCode === 1028){
                        setApiResponseStatus("This card has already been added to your account.")
                    }
                    else{
                        setApiResponseStatus("We weren't able to save your card details. Please make sure you have provided all the details.")
                    }
                }
                setApiLoading(false);
            }).catch((e)=>{
                console.log(e)
                setApiLoading(false);
            })
        }
    }, [tokenObject]);
  

    const removeCard = (card)=>{
        deleteCard("?cardId="+card.CardId).then(({data})=>{
            if(data.Status){
                setUpdate(update+1)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }
    const makeDefault = (card) => {
        makeCardDefault("?cardId="+card.CardId).then(({data})=>{
            if(data.Status){
                setUpdate(update+1)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }
    const changeCardIcon = (e) => {
        if (e.scheme) {
            const src = e.scheme.toLowerCase().replaceAll(" ", "-")
            setSelectedCardIcon(cardIcons[src] ? cardIcons[src] : cardIcons["undefined"])
        }
        else {
            setSelectedCardIcon(cardIcons["undefined"])
        }
    }
    const onFrameValidationChanged = (e) => {
        switch (e.element) {
            case "card-number":
                setIsCardNumberValid(e.isValid)
                setShowCardNumberError(!e.isEmpty)
                break;
            case "expiry-date":
                setIsCardExpiryValid(e.isValid)
                setShowExpiryError(!e.isEmpty)
                break;
            case "cvv":
                setIsCardCVCValid(e.isValid)
                setShowCVCError(!e.isEmpty)
                break;
            default:
                break;
        }
    }
    window.addEventListener( "message",(event) => {
        if(event.data.event === "cardTokenized"){
            if(event.data.data.type === "card" && event.data.data.token.length > 5){
                setTokenObject(event.data.data)
            }
            else{
                setApiResponseStatus("We weren't able to save your card details. Please make sure you have provided all the details.")
                setApiLoading(false);
            }
        }
    }, false);

    const formSubmit = (e) => {
        setApiLoading(true);
        Frames.submitCard();
    }

    return(
        <>
            <div className='profile-body-header mb-4'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'><T>Payment Methods</T></h1>
                <p className='fs-16 text-gray-400 fw-400 mt-0 mb-0'><T>Your payment card details</T></p>
            </div>
            {
                loading ?
                <p className="btn btn-alternative w-100 justify-center"><T>Fetching your saved debit/credit cards.</T></p> :
                cardDetails.length ?
                <div className="payment-method-card-wrapper d-flex align-items-center flex-wrap mb-5">
                    {
                        cardDetails.map((item, index) => {
                            return <PaymentMethodCard key={index} item={item} methodDefault={makeDefault} methodDelete={removeCard}/>
                        }) 
                    }
                </div>
                : <p className="btn btn-alternative w-100 justify-center"><i className="ri-information-line fs-22 mr-2"></i><T>You currently have no debit/credit cards saved.</T></p>
            }
            
            {
                createCard ?
                <section id="newPaymentOption" className="pt-5">
                    <div className="address-card-wrapper w-100 pb-7 pt-3" >
                        <div className="w-100">
                            <div className="new-address-details">
                                <h3 className="fs-16 fw-500 text-gray-500 d-inline-block mt-0 mb-0"><T>Add New Card</T></h3>
                                <p className="fs-14 fw-400 text-gray-400 mt-0 mb-2"><T>Please provide the following information</T></p>
                            </div>
                            <div>
                                <Frames
                                    config={{
                                        publicKey: process.env.REACT_APP_CHECKOUT_PUBLIC_KEY,
                                        debugMode: true,
                                        localization: {
                                        cardNumberPlaceholder: !isArabic ? "Card Number" : "رقم البطاقة",
                                        expiryMonthPlaceholder: 'MM',
                                        expiryYearPlaceholder: 'YY',
                                        cvvPlaceholder: 'CVV',
                                        },
                                        style: {
                                            base: {
                                                fontSize: '14px',
                                                width: "100%",
                                                margin: "5px 0px 0px 0px",
                                                border: "1px solid #d6d5d5",
                                                padding: "0px 12px",
                                                outline: "none",
                                                boxShadow: "none",
                                                borderRadius: "5px",
                                                background: "#fff",
                                                color: "#393939",
                                                height: "42px;",
                                                textAlign: !isArabic ? "left":"right"
                                            },
                                            autofill: {
                                                background: "none",
                                            },
                                            hover: {
                                                color: "#393939",
                                            },
                                            focus: {
                                                color: "#393939",
                                                border: "1px solid #ED378F",
                                            },
                                            valid: {
                                                color: "#393939",
                                            },
                                            invalid: {
                                                border: "1px solid #ED378F",
                                                color: "#ED378F",
                                            },
                                            placeholder: {
                                                base: {
                                                color: "#595959",
                                                fontSize: '14px',
                                                fontWeight: "600",
                                                textAlign: !isArabic ? "left":"right"
                                                },
                                                focus: {
                                                color: "#444",
                                                border: "1px solid #d6d5d5",
                                                },
                                            },
                                        },
                                    }}
                                    ready={() => {}}
                                    frameActivated={(e) => {}}
                                    frameFocus={(e) => {}}
                                    frameBlur={(e) => {}}
                                    frameValidationChanged={onFrameValidationChanged}
                                    paymentMethodChanged={(e) => {}}
                                    cardSubmitted={() => {}}
                                    // cardTokenized={(e) => { console.log(e); finishCheckout(e)}}
                                    cardTokenizationFailed={(e) => {}}
                                    cardBinChanged={(e) => {changeCardIcon(e)}}
                                >
                                    <div className="d-flex justify-between flex-wrap">
                                        <div className="checkout-form-content mt-2 w-100 position-relative">
                                            <label className="fw-500 fs-14 m-0 ">{!isArabic ? "Card Number":"رقم البطاقة"} <span className="text-primary">*</span></label>
                                            <CardNumber/>
                                            <div className="card-icon"><img src={selectedCardIcon} alt=""/></div>
                                            { showCardNumberError && !isCardNumberValid ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Card number not valid</T></span> : <></> }
                                        </div>
                                        <div className="checkout-form-content mt-2">
                                            <label className="fw-500 fs-14 m-0 ">{!isArabic ? "Card Expiry":"انتهاء صلاحية البطاقة"} <span className="text-primary">*</span></label>
                                            <ExpiryDate/>
                                            { showExpiryError && !isCardExpiryValid ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Card expiry not valid</T></span> : <></> }
                                        </div>
                                        <div className="checkout-form-content mt-2">
                                            <label className="fw-500 fs-14 m-0 ">{!isArabic ? "Card CVV/CVC":"بطاقة CVV / CVC"} <span className="text-primary">*</span></label>
                                            <Cvv/>
                                            { showCVCError && !isCardCVCValid ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Card CVV/CVC not valid</T></span> : <></> }
                                        </div>
                                    </div>
                                </Frames>
                                {
                                    apiResponseStatus.length ?
                                    <p className="text-primary fs-14 fw-400">{apiResponseStatus}</p> : <></>
                                }
                            </div>
                            <div className="save-cancel" style={{"right":"20px"}}>
                                <button className="btn btn-alternative" onClick={() => { setCreateCard(false); setApiResponseStatus("") }}><T>Cancel</T></button>
                                {
                                    apiResponseStatus.length ? <></> :
                                    <button disabled={apiLoading} className="ml-3 btn btn-primary" onClick={formSubmit}>{apiLoading ? "Please Wait" : <T>Save</T>}</button>
                                }
                            </div>
                        </div>
                    </div>
                </section> :
                <></>
                // <button className="btn btn-outline-primary mt-5" onClick={()=>setCreateCard(true)}><i className="ri-bank-card-line fs-22 mr-2"></i>{translations.ProfileTab[language].paymentMethodBtn}</button>
            }
        </>
    )
}

export default PaymentMethod;