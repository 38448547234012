import React from 'react';
import { Link } from "react-router-dom";
import OrderCard from "./order-card";
import { useEffect, useState } from 'react';
import "../../assets/css/--component-order-card.css"
import ViewOrderDetails from './view-order-details';
import { getOrderlist } from '../../helper/backend-methods';
import { analyticsPageEvent } from '../../components/Analytics';
import T from '../../locale/translations-main'


function Orders(props) {
    const [showTab, setTabShow] = useState(1);
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [orderedProducts, setOrderedProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [language, setLanguage] = useState("en")
    const [visible, setVisible] = useState(10)
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Orders", link: "" },
    ]

    useEffect(() => {
        if(props.consent.page){
            analyticsPageEvent("Account Orders");
        }
    },[props.consent.page])

    useEffect(() => {
        getOrderlist({ orderStatus: "all" }).then(({ data }) => {
            if (data.Status) {
                setOrderedProducts(data.Data.OrderList)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e);
            setLoading(false)
        })
        configurePageTitle("Orders")
        configureBreadcrumb(breadcrumbItems)
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            setLanguage(lang)
        }
    }, []);

    return (
        <>
            <div className='profile-body-header mb-4 justify-between d-flex align-items-center'>
                <div>
                    <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'><T>Your Order</T></h1>
                    <p className='fs-16 text-gray-400 fw-400 mt-0 mb-0'><T>Below is a history of your purchased items.</T></p>
                </div>
                <div className='order-dropdown'>
                    <select value={showTab} onChange={(e) => { setTabShow(parseInt(e.target.value)) }}>
                        <option value={1}><T>All</T></option>
                        <option value={2}><T>Pending</T></option>
                        <option value={3}><T>Processing</T></option>
                        <option value={4}><T>Shipped</T></option>
                        <option value={5}><T>Delivered</T></option>
                        <option value={6}><T>Cancelled</T></option>
                    </select>
                </div>
            </div>
            <div className="order-card-box order-filter-buttons">
                <div className="order-btn-group d-flex justify-between align-items-center">
                    <button className={showTab === 1 ? " btn-order active" : "btn-order"} onClick={() => { setTabShow(1) }}><i className="ri-function-line mr-2" ></i><T>All</T></button>
                    <span className='breaker'></span>
                    <button className={showTab === 2 ? " btn-order active" : "btn-order"} onClick={() => { setTabShow(2) }}><i className="ri-time-line mr-2"></i><T>Received</T></button>
                    <span className='breaker'></span>
                    <button className={showTab === 3 ? " btn-order active" : "btn-order"} onClick={() => { setTabShow(3) }}><i className="ri-calendar-line mr-2"></i><T>Processing</T></button>
                    <span className='breaker'></span>
                    <button className={showTab === 4 ? " btn-order active" : "btn-order"} onClick={() => { setTabShow(4) }}><i className="ri-truck-line mr-2"></i><T>Shipped</T></button>
                    <span className='breaker'></span>
                    <button className={showTab === 5 ? " btn-order active" : "btn-order"} onClick={() => { setTabShow(5) }}><i className="ri-check-line  mr-2"></i><T>Delivered</T></button>
                    <span className='breaker'></span>
                    <button className={showTab === 6 ? " btn-order active" : "btn-order"} onClick={() => { setTabShow(6) }}><i className="ri-close-line mr-2"></i><T>Cancelled</T></button>
                </div>
            </div>
            {
                loading ?
                    <span className="btn btn-alternative w-100 mb-4"><T>Fetching Order data. Please wait....</T></span> :
                    <>
                        <div className={showTab === 1 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                            <div className="orders-card product-wishlist d-flex flex-wrap align-items-center justify-between">
                                {
                                    orderedProducts.length ?
                                        orderedProducts.slice(0, visible).map((item, i) => (
                                            <OrderCard key={i} item={item} />
                                        )) :
                                        <span className="btn btn-alternative w-100"> <i className="ri-error-warning-line fs-22 mr-2"></i><T>You currently have no orders</T></span>
                                }
                            </div>
                            {
                                orderedProducts.length <= visible ?
                                    <></> : <div className="view-more-btn text-right">
                                        <button className="link fs-16" onClick={() => { setVisible(visible + 3) }}>View More</button>
                                    </div>
                            }
                        </div>
                        <div className={showTab === 2 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                            <div className="orders-card product-wishlist d-flex flex-wrap align-items-center justify-between">
                                {
                                    orderedProducts.filter((item) => item.Status === "Received").length ?
                                        orderedProducts.filter((item) => item.Status === "Received").map((item, i) => (
                                            <OrderCard key={i} item={item} />
                                        )) :
                                        <span className="btn btn-alternative w-100"> <i className="ri-error-warning-line fs-22 mr-2"></i><T>You currently have no pending orders</T></span>
                                }
                            </div>
                        </div>
                        <div className={showTab === 3 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                            <div className="orders-card product-wishlist d-flex flex-wrap align-items-center justify-between">
                                {
                                    
                                    orderedProducts.filter((item) =>  ["PartiallyProcessedForPicking","ProcessedForPicking","PartiallyProcessedForShipping","ProcessedForShipping"].includes(item.Status)).length ?
                                        orderedProducts.filter((item) => ["PartiallyProcessedForPicking","ProcessedForPicking","PartiallyProcessedForShipping","ProcessedForShipping"].includes(item.Status)).map((item, i) => (
                                            <OrderCard key={i} item={item} />
                                        )) :
                                        <span className="btn btn-alternative w-100"> <i className="ri-error-warning-line fs-22 mr-2"></i><T>You currently have no orders in process</T></span>
                                }
                            </div>
                        </div>
                        <div className={showTab === 4 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                            <div className="orders-card product-wishlist d-flex flex-wrap align-items-center justify-between">
                                {
                                    orderedProducts.filter((item) => ["PartiallyHandedOverToCourier","HandedOverToCourier","PartiallyInTransit","InTransit","PartiallyOutForDelivery","OutForDelivery"].includes(item.Status)).length ?
                                        orderedProducts.filter((item) => ["PartiallyHandedOverToCourier","HandedOverToCourier","PartiallyInTransit","InTransit","PartiallyOutForDelivery","OutForDelivery"].includes(item.Status)).map((item, i) => (
                                            <OrderCard key={i} item={item} />
                                        )) :
                                        <span className="btn btn-alternative w-100"> <i className="ri-error-warning-line fs-22 mr-2"></i><T>You currently have no orders that are already shipped</T></span>
                                }
                            </div>
                        </div>
                        <div className={showTab === 5 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                            <div className="orders-card product-wishlist d-flex flex-wrap align-items-center justify-between">
                                {
                                    orderedProducts.filter((item) => ["PartiallyAttempted","Attempted","PartiallyReturnToOrigin","ReturnToOrigin","PartiallyDelivered","Delivered"].includes(item.Status)).length ?
                                        orderedProducts.filter((item) => ["PartiallyAttempted","Attempted","PartiallyReturnToOrigin","ReturnToOrigin","PartiallyDelivered","Delivered"].includes(item.Status)).map((item, i) => (
                                            <OrderCard key={i} item={item} />
                                        )) :
                                        <span className="btn btn-alternative w-100"> <i className="ri-error-warning-line fs-22 mr-2"></i><T>You currently have no orders that are complete</T></span>
                                }
                            </div>
                        </div>
                        <div className={showTab === 6 ? "remaining-gift w-100" : "remaining-gift d-none"}>
                            <div className="orders-card product-wishlist d-flex flex-wrap align-items-center justify-between">
                                {
                                    orderedProducts.filter((item) => item.Status === "Cancelled").length ?
                                        orderedProducts.filter((item) => item.Status === "Cancelled").map((item, i) => (
                                            <OrderCard key={i} item={item} />
                                        )) :
                                        <span className="btn btn-alternative w-100"> <i className="ri-error-warning-line fs-22 mr-2"></i><T>You currently have no orders that are cancelled</T></span>
                                }
                            </div>
                        </div>
                    </>
            }

        </>
    )
}

export default Orders;