import React, { useState, useEffect } from "react";
import ProductCard from "../products/product-card";
import "../../assets/css/--home.css";
import { Link } from "react-router-dom";
import { getProductsByTag } from "../../helper/backend-methods";
import Skeleton from 'react-loading-skeleton'
import T from "../../locale/translations-main";

function HotSelling({consent,products,loading}) {
  const [productList, setProductList] = useState([]);
  const [sectionLoading, setSectionLoading] = useState(true);

  useEffect(()=>{
    if(loading === false && products){
      setProductList(products.Products);
    }
    setSectionLoading(loading)
  },[products,loading]);

  return (
    !sectionLoading && !productList.length ? null :
    <section id="featured_product_section" className="mb-5">
      <div className="category-title d-flex justify-between align-items-end mt-5 mb-5">
        <div className="category-heading">
          <h2 className="fw-500 text-gray-500 fs-22 m-0 text-capitalize">{ loading ? <Skeleton height={20} width={100}/> : products?.Name }</h2>
          {/* <p className="text-gray-300 m-0"><T>View some of our</T> {products.Name} <T>products</T></p> */}
        </div>
        <div className="category-button">
          { loading ? <Skeleton height={14} width={60}/> : <Link className="link" to={`/products?tag=${products?.Id}`}><T>View All</T></Link> }
        </div>
      </div>
      {
        sectionLoading ?
          <div className="small-product-card d-flex flex-wrap">
            <div className="product-card p-0" style={{"minHeight": "360px"}}>
              <Skeleton height="100%" />
            </div>
            <div className="product-card p-0" style={{"minHeight": "360px"}}>
              <Skeleton height="100%" />
            </div>
            <div className="product-card p-0" style={{"minHeight": "360px"}}>
              <Skeleton height="100%" />
            </div>
            <div className="product-card p-0" style={{"minHeight": "360px"}}>
              <Skeleton height="100%" />
            </div>
            <div className="product-card p-0" style={{"minHeight": "360px"}}>
              <Skeleton height="100%" />
            </div>
            <div className="product-card p-0" style={{"minHeight": "360px"}}>
              <Skeleton height="100%" />
            </div>
          </div>
          :
          <div className="small-product-card d-flex flex-wrap">
            {productList.slice(0,6).map((item, i) => (
              <ProductCard key={i} item={item} pageTitle="Home" permissions={consent}/>
            ))}
          </div>
      }
    </section>
  );
}
export default HotSelling;
