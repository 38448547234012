const translations = {
    "Occasions List": {
      "en": "Occasions List",
      "ar": "قائمة المناسبات"
    },
    "You can view active occasions here.": {
      "en": "You can view active occasions here.",
      "ar": "يمكنك مشاهدة المناسبات النشطة هنا."
    },
    "My Occasions": {
      "en": "My Occasions",
      "ar": "مناسباتي"
    },
    "Invited Occasions": {
      "en": "Invited Occasions",
      "ar": "المناسبات المدعوة"
    },
    "Fetching your occasions and invitations.": {
      "en": "Fetching your occasions and invitations.",
      "ar": "تحميل بيانات مناسباتك ودعواتك."
    },
    "You don't have any active occasions.": {
      "en": "You don't have any active occasions.",
      "ar": "ليس لديك أي مناسبات نشطة."
    },
    "Create Occasions List": {
      "en": "Create Occasions List",
      "ar": "إنشاء قائمة بالمناسبات"
    },
    "Fill out the form below to create your occasion here.": {
      "en": "Fill out the form below to create your occasion here.",
      "ar": "املأ النموذج أدناه لإنشاء مناسبتك هنا."
    },
    "Occasion Image": {
      "en": "Occasion Image",
      "ar": "صورة المناسبة"
    },
    "Occasion Category": {
      "en": "Occasion Category",
      "ar": "فئة المناسبة"
    },
    "Occasion Title": {
      "en": "Occasion Title",
      "ar": "عنوان المناسبة"
    },
    "Enter occasion title": {
      "en": "Enter occasion title",
      "ar": "أدخل عنوان المناسبة"
    },
    "Your first & last name (Parent/Guardian)": {
      "en": "Your first & last name (Parent/Guardian)",
      "ar": "الاسم الأول والأخير"
    },
    "Enter full name": {
      "en": "Enter full name",
      "ar": "أدخل الاسم الكامل"
    },
    "When and where is your event?": {
      "en": "When and where is your event?",
      "ar": "متى وأين هو الحدث الخاص بك؟"
    },
    "Event Date": {
      "en": "Event Date",
      "ar": "تاريخ الحدث"
    },
    "Enter location of event": {
      "en": "Enter location of event",
      "ar": "أدخل موقع الحدث"
    },
    "House No/Flat No": {
      "en": "House No/Flat No",
      "ar": "رقم المنزل / رقم الشقة"
    },
    "Enter House No/Flat No": {
      "en": "Enter House No/Flat No",
      "ar": "أدخل رقم المنزل / رقم الشقة"
    },
    "Street Address": {
      "en": "Street Address",
      "ar": "عنوان الشارع"
    },
    "Enter Street Address": {
      "en": "Enter Street Address",
      "ar": "أدخل عنوان الشارع"
    },
    "Area": {
      "en": "Area",
      "ar": "المنطقة"
    },
    "Select Area": {
      "en": "Select Area",
      "ar": "حدد المنطقة"
    },
    "City/Town": {
      "en": "City/Town",
      "ar": "المدينة / البلدة"
    },
    "Select City/Town": {
      "en": "Select City/Town",
      "ar": "حدد المدينة / البلدة"
    },
    "Create Occasion": {
      "en": "Create Occasion",
      "ar": "انشاء مناسبة"
    },
    "Update Occasion": {
      "en": "Update Occasion",
      "ar": "تحديث المناسبة"
    },
    "Cancel": {
      "en": "Cancel",
      "ar": "إلغاء"
    },
    "No occasions founds to which you were invited.": {
      "en": "No occasions founds to which you were invited.",
      "ar": "لم يتم العثور على مناسبات تمت دعوتك إليها."
    },
    "Please Wait...": {
      "en": "Please Wait...",
      "ar": "انتظر من فضلك..."
    },
    "Allowed image types are jpeg, jpg, png only.": {
      "en": "Allowed image types are jpeg, jpg, png only.",
      "ar": "أنواع الصور المسموح بها هي jpeg jpg و png فقط."
    },
    "Please provide a valid image.": {
      "en": "Please provide a valid image.",
      "ar": "الرجاء تقديم صورة صالحة."
    },
    "Please select an occasion category.": {
      "en": "Please select an occasion category.",
      "ar": "الرجاء تحديد فئة المناسبة."
    },
    "Please provide an occasion title.": {
      "en": "Please provide an occasion title.",
      "ar": "يرجى تقديم عنوان المناسبة."
    },
    "Please provide full name of host.": {
      "en": "Please provide full name of host.",
      "ar": "يرجى تقديم الاسم الكامل للمضيف."
    },
    "Full name must contain alphabets only": {
      "en": "Full name must contain alphabets only",
      "ar": "يجب أن يحتوي الاسم الكامل على أحرف أبجدية فقط"
    },
    "Please select a date/time greater than today.": {
      "en": "Please select a date/time greater than today.",
      "ar": "الرجاء تحديد تاريخ / وقت بعد اليوم"
    },
    "Please select a valid date.": {
      "en": "Please select a valid date.",
      "ar": "الرجاء تحديد تاريخ صالح."
    },
    "Please provide event house/flat no.": {
      "en": "Please provide event house/flat no.",
      "ar": "الرجاء تقديم رقم المنزل / الشقة "
    },
    "Please provide event street address.": {
      "en": "Please provide event street address.",
      "ar": "الرجاء تقديم عنوان الشارع "
    },
    "Please select event State/Province.": {
      "en": "Please select event State/Province.",
      "ar": "الرجاء تحديد الولاية / المقاطعة "
    },
    "Please select event City/Town.": {
      "en": "Please select event City/Town.",
      "ar": "الرجاء تحديد المدينة / البلدة."
    },
    "Gift Remaining": {
      "en": "Gift Remaining",
      "ar": "الهدايا المتبقية"
    },
    "Gift Purchased": {
      "en": "Gift Purchased",
      "ar": "الهدايا المشتراة"
    },
    "View Occasion": {
      "en": "View Occasion",
      "ar": "مشاهدة المناسبة"
    },
    "Are you sure you want to delete the occasion?": {
      "en": "Are you sure you want to delete the occasion?",
      "ar": "هل أنت متأكد أنك تريد حذف المناسبة؟"
    },
    "Delete": {
      "en": "Delete",
      "ar": "حذف"
    },
    "DELETE OCCASION": {
      "en": "DELETE OCCASION",
      "ar": "حذف المناسبة"
    },
    "Occasion cannot be deleted once invitation is accepted.": {
      "en": "Occasion cannot be deleted once invitation is accepted.",
      "ar": "لا يمكن حذف المناسبة بمجرد قبول الدعوة."
    },
    "Close": {
      "en": "Close",
      "ar": "اغلاق"
    },
    "Fetching your occasion details. Please wait...": {
      "en": "Fetching your occasion details. Please wait...",
      "ar": "إحضار تفاصيل مناسبتك. أرجو الإنتظار..."
    },
    "Share": {
      "en": "Share",
      "ar": "يشارك"
    },
    "Guardian: ": {
      "en": "Guardian: ",
      "ar": "وصي: "
    },
    "Occasion: ": {
      "en": "Occasion: ",
      "ar": "مناسبات: "
    },
    "Address: ": {
      "en": "Address: ",
      "ar": "تبوك: "
    },
    "Gift Remaining": {
      "en": "Gift Remaining",
      "ar": "الهدية المتبقية "
    },
    "Below is the list of remaining products": {
      "en": "Below is the list of remaining products",
      "ar": "أدناه قائمة المنتجات المتبقية"
    },
    "Rating ": {
      "en": "Rating ",
      "ar": "تقييم "
    },
    "Price ": {
      "en": "Price ",
      "ar": "سعر "
    },
    "Remove ": {
      "en": "Remove ",
      "ar": "إزالة "
    },
    "All remaining gifts were purchased.": {
      "en": "All remaining gifts were purchased.",
      "ar": "تم شراء جميع الهدايا المتبقية."
    },
    "You haven't added any gifts for your occasion.": {
      "en": "You haven't added any gifts for your occasion.",
      "ar": "لم تقم بإضافة أي هدايا لمناسبتك."
    },
    "Purchased By": {
      "en": "Purchased By",
      "ar": "تم الشراء بواسطة"
    },
    "There are currently no gifts purchased for your occasion.": {
      "en": "There are currently no gifts purchased for your occasion.",
      "ar": "لا يوجد حاليا أي هدايا تم شراؤها لمناسبتك."
    },
    "Share Occasion": {
      "en": "Share Occasion",
      "ar": "مناسبة المشاركة"
    },
    "Please share the link with people you would like to invite to ": {
      "en": "Please share the link with people you would like to invite to ",
      "ar": "يرجى مشاركة الرابط مع الأشخاص الذين ترغب في دعوتهم "
    },
    "Occasion has expired or does not exist.": {
      "en": "Occasion has expired or does not exist.",
      "ar": "المناسبة انتهت أو غير موجودة."
    },
    "Update Occasion": {
      "en": "Update Occasion",
      "ar": "مناسبة التحديث"
    },
    "You may chose to update the following information,": {
      "en": "You may chose to update the following information,",
      "ar": "يمكنك اختيار تحديث المعلومات التالية ،"
    },
    "You can not delete any product from the list as occasion invation is accepted by invitee": {
      "en": "You can not delete any product from the list as occasion invation is accepted by invitee",
      "ar": "لا يمكنك حذف أي منتج من القائمة حيث يتم قبول دعوة المناسبة من قبل المدعو"
    },
    "Copied to clipboard!": {
      "en": "Copied to clipboard!",
      "ar": "نسخ إلى الحافظة!"
    },
    "Invited by": {
      "en": "Invited by",
      "ar": "بدعوة من"
    },
    "Accept": {
      "en": "Accept",
      "ar": "قبول"
    },
    "Reject": {
      "en": "Reject",
      "ar": "رفض"
    },
    "Fetching invited occasion details. Please wait...": {
      "en": "Fetching invited occasion details. Please wait...",
      "ar": "تحميل بيانات تفاصيل المناسبة المدعوة. انتظر من فضلك..."
    },
    "Address ": {
      "en": "Address ",
      "ar": "العنوان:"
    },
    "Add to Cart": {
      "en": "Add to Cart",
      "ar": "أضف إلى السلة"
    },
    "You already have items in your cart and you are about to add a product that belongs to an occasion. This will clear all your current cart items.": {
      "en": "You already have items in your cart and you are about to add a product that belongs to an occasion. This will clear all your current cart items.",
      "ar": "لديك منتجات في عربة التسوق الخاصة بك وأنت على وشك إضافة منتج ينتمي إلى مناسبة ما. سيؤدي هذا إلى مسح جميع منتجات عربة التسوق الحالية."
    },
    "You already have items associated with an occasion in your cart and you are about to add a product that doesn't belong to any occasion. This will clear all your current cart items.": {
      "en": "You already have items associated with an occasion in your cart and you are about to add a product that doesn't belong to any occasion. This will clear all your current cart items.",
      "ar": "لديك منتجات مرتبطة بمناسبة في عربة التسوق الخاصة بك وأنت على وشك إضافة منتج لا ينتمي إلى أي مناسبة. سيؤدي هذا إلى مسح جميع منتجات عربة التسوق الحالية."
    },
    "You already have items associated with an occasion in your cart and you are about to add a product that belongs to some other occasion. This will clear all your current cart items.": {
      "en": "You already have items associated with an occasion in your cart and you are about to add a product that belongs to some other occasion. This will clear all your current cart items.",
      "ar": "لديك منتجات مرتبطة بمناسبة ما في عربة التسوق الخاصة بك وأنت على وشك إضافة منتج ينتمي إلى مناسبة أخرى. سيؤدي هذا إلى مسح جميع منتجات عربة التسوق الحالية."
    },
    "Continue": {
      "en": "Continue",
      "ar": "المتابعة"
    },
    "All remaining gifts were purchased for the occasion.": {
      "en": "All remaining gifts were purchased for the occasion.",
      "ar": "تم شراء جميع الهدايا المتبقية لهذه المناسبة."
    },
    "No gifts were added for the occasion..": {
      "en": "No gifts were added for the occasion..",
      "ar": "لم يتم اضافة هدايا لهذه المناسبة .."
    },
    "Purchased": {
      "en": "Purchased",
      "ar": "تم شراؤها"
    },
    "There are currently no gifts purchased for this occasion..": {
      "en": "There are currently no gifts purchased for this occasion..",
      "ar": "لا يوجد حاليا أي هدايا تم شراؤها لهذه المناسبة .."
    },
    "Go to Cart": {
      "en": "Go to Cart",
      "ar": "اذهب إلى سلة التسوق"
    },
    "Continue Shopping": {
      "en": "Continue Shopping",
      "ar": "متابعة التسوق"
    },
    "Out of Stock": {
      "en": "Out of Stock",
      "ar": "نفدت الكمية"
    },
    "We apologize for the inconvenience, but the product seems to be out of stock. Please check again in a few days.": {
      "en": "We apologize for the inconvenience, but the product seems to be out of stock. Please check again in a few days.",
      "ar": "نعتذر عن الإزعاج ولكن يبدو أن المنتج نفد من المخزون. يرجى التحقق مرة أخرى في غضون أيام قليلة."
    },
    "Successfully added to cart": {
      "en": "Successfully added to cart",
      "ar": "تمت الإضافة إلى عربة التسوق بنجاح"
    },
    "N/A": {
      "en": "N/A",
      "ar": "غير متاح"
    }
  }
export default translations
