const translations = {
    "ClickToys": {
      "en": "ClickToys",
      "ar": "كليك تويز"
    },
    "A world filled with": {
      "en": "A world filled with",
      "ar": "عالم مليء"
    },
    "Happiness": {
      "en": "Happiness",
      "ar": "سعادة"
    },
    "and": {
      "en": "and",
      "ar": "و"
    },
    "Fun": {
      "en": "Fun",
      "ar": "مرح"
    },
    "No filters selected": {
      "en": "No filters selected",
      "ar": "لم يتم تحديد فلتر"
    },
    "Filters": {
      "en": "Filters",
      "ar": "الفلاتر"
    },
    "For": {
      "en": "For",
      "ar": "ل"
    },
    "Gender": {
      "en": "Gender",
      "ar": "الجنس"
    },
    "Clear All": {
      "en": "Clear All",
      "ar": "مسح الكل"
    },
    "Brands": {
      "en": "Brands",
      "ar": "الماركات"
    },
    "Categories": {
      "en": "Categories",
      "ar": "الفئات"
    },
    "Show Less": {
      "en": "Show Less",
      "ar": "عرض أقل"
    },
    "Show More": {
      "en": "Show More",
      "ar": "عرض المزيد"
    },
    "Price": {
      "en": "Price",
      "ar": "السعر"
    },
    "Max": {
      "en": "Max",
      "ar": "أعلى"
    },
    "Min": {
      "en": "Min",
      "ar": "أقل"
    },
    "Apply": {
      "en": "Apply",
      "ar": "تطبيق"
    },
    "Age": {
      "en": "Age",
      "ar": "العمر"
    },
    "By Rating": {
      "en": "By Rating",
      "ar": "حسب التصنيف"
    },
    "& Up": {
      "en": "& Up",
      "ar": "& أعلى"
    },
    "Sort By:": {
      "en": "Sort By:",
      "ar": "ترتيب حسب:"
    },
    "Most Recent": {
      "en": "Most Recent",
      "ar": "الأحدث"
    },
    "Most Popular": {
      "en": "Most Popular",
      "ar": "الأكثر شهرة"
    },
    "Lowest Price": {
      "en": "Lowest Price",
      "ar": "أقل سعر"
    },
    "Highest Price": {
      "en": "Highest Price",
      "ar": "اغلى سعر"
    },
    "Show More Products": {
      "en": "Show More Products",
      "ar": "عرض المزيد من المنتجات"
    },
    "No Products Found. Please try a different filter.": {
      "en": "No Products Found. Please try a different filter.",
      "ar": "لا توجد منتجات. الرجاء اختيار فلتر اخر."
    },
    "Loading...": {
      "en": "Loading...",
      "ar": "تحميل..."
    }
  }
  export default translations