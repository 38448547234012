const translations = {
    "Your Wishlist": {
      "en": "Your Wishlist",
      "ar": "قائمة الأمنيات"
    },
    "Below are the items you have added to you wishlist": {
      "en": "Below are the items you have added to you wishlist",
      "ar": "المنتجات التي قمت بإضافتها إلى قائمة الأمنيات الخاصة بك"
    },
    "Brand": {
      "en": "Brand",
      "ar": "الماركات"
    },
    "Price": {
      "en": "Price",
      "ar": "السعر"
    },
    "Rating": {
      "en": "Rating",
      "ar": "التقييم "
    },
    "reviews": {
      "en": "reviews",
      "ar": "التقييمات"
    },
    "Add to Cart": {
      "en": "Add to Cart",
      "ar": "أضف إلى السلة"
    },
    "Fetching your wishlist data. Please wait...": {
      "en": "Fetching your wishlist data. Please wait...",
      "ar": "تحميل بيانات قائمة الأمنيات. أرجو الإنتظار..."
    },
    "You currently have no products in your wishlist.": {
      "en": "You currently have no products in your wishlist.",
      "ar": "ليس لديك حاليا أي منتجات في قائمة الأمنيات الخاصة بك."
    },
    "Please Wait...": {
      "en": "Please Wait...",
      "ar": "انتظر من فضلك..."
    },
    "successfully added to cart": {
      "en": "successfully added to cart",
      "ar": "تمت الإضافة بنجاح إلى عربة التسوق"
    },
    "Go to Cart": {
      "en": "Go to Cart",
      "ar": "اذهب إلى سلة التسوق"
    },
    "Continue": {
      "en": "Continue",
      "ar": "اكمل"
    },
    "Saved": {
      "en": "Saved",
      "ar": "أنقذ"
    }
  }
  export default translations