const translations = {
    "Please wait while the shipment is loading...": {
      "en": "Please wait while the shipment is loading...",
      "ar": "يرجى الانتظار حتى يتم تحميل الشحنة..."
    },
    "Shipping Address": {
      "en": "Shipping Address",
      "ar": "عنوان الشحن"
    },
    "Tracking Number": {
      "en": "Tracking Number",
      "ar": "رقم التتبع"
    },
    "Courier Name": {
      "en": "Courier Name",
      "ar": "اسم البريد"
    },
    "Order Shipment": {
      "en": "Order Shipment",
      "ar": "أمر الشحن"
    },
    "Track Shipment": {
      "en": "Track Shipment",
      "ar": "مسار الشحنة"
    }
  }
  export default translations