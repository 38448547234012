import { del, get, post, put, postForm, putForm, postJSON, apiWrapper } from "./api-methods";
import * as url from "./api-urls"
import { initializeApp } from "firebase/app";

// Authentication
// export const postLogin = (params) => post(url.LOGIN,params)
export const postLogin = (params) => apiWrapper(post,url.LOGIN,params)
export const socialLogin = (params) => apiWrapper(post,url.SOCIAL_LOGIN,params)
export const generateOTP = (params) => apiWrapper(post,url.GENERATE_OTP,params)
export const verifyOTP = (params) => apiWrapper(post,url.VERIFY_OTP,params)
export const assignUserPassword = (data) => apiWrapper(post,url.ASSIGN_PASSWORD,data)
export const postlogout = () => apiWrapper(post,url.LOGOUT)
// export const postRegister = (params) => post(url.REGISTER,params)
export const postRegister = (params) => apiWrapper(post,url.REGISTER,params)
export const forgotPassword = (params) => apiWrapper(get,url.FORGET_PASSWORD,params)
export const resetPassword = (data) => apiWrapper(post,url.RESET_PASSWORD,data)
// export const postUpdateProfile = (params) => post(url.UPDATE_PROFILE,params)
export const postUpdateProfile = (params) => apiWrapper(post,url.UPDATE_PROFILE,params)

// products
// export const getProduct = (params) => get(url.PRODUCT, params)
export const getProduct = (params) => apiWrapper(get,url.PRODUCT,params)
// export const getProducts = (params) => get(url.PRODUCTS, params)
export const getProducts = (params) => apiWrapper(post,url.PRODUCTS,params)
export const getFilters = (params) => apiWrapper(get,url.PRODUCTFILTERS)
// export const getCategories = () => get(url.CATEGORIES)
export const getCategories = () => apiWrapper(get,url.CATEGORIES)
//  export const getProductsByTag = (params) => get(url.PRODUCTS_BY_TAG, params)
export const getProductsByTag = (params) => apiWrapper(get,url.PRODUCTS_BY_TAG,params)
//  export const getRecentProducts = (params) => get(url.RECENT_PRODUCTS, params)
export const getRecentProducts = (params) => apiWrapper(get,url.RECENT_PRODUCTS,params)
// export const addWishlist = (params) => post(url.ADD_WISHLIST,params)
export const addWishlist = (params) => apiWrapper(post,url.ADD_WISHLIST,params)
// export const getWishlist = () => get(url.DELETE_ADDRESS)
export const getWishList  = () => apiWrapper(get,url.GET_WISHLIST)
export const addCart  = (params) => apiWrapper(post,url.ADD_CART,params)
export const getProductCart  = (params) => apiWrapper(get,url.GET_PRODUCT_CART,params)
export const getRecentProduct  = (params) => apiWrapper(get,url.GET_RECENT_PRODUCT,params)
export const getGiftWrap  = () => apiWrapper(get,url.GET_GIFT_WRAP)
export const updateCart  = (params) => apiWrapper(put,url.UPDATE_CART,params)
export const deleteCart  = (params) => apiWrapper(del,url.DELETE_CART+params)
export const applyCoupon  = (params) => apiWrapper(post,url.APPLY_COUPON+params)
export const getCheckoutDetails  = (params) => apiWrapper(get,url.GET_CHECKOUT_DETAILS,params)
export const cartCheckout = (params) => apiWrapper(post,url.CHECKOUT,params)
export const removeCouponCode = (params) => apiWrapper(del,url.REMOVE_COUPON+params)
export const postReview = (params) => apiWrapper(post,url.ADD_REVIEW,params)
export const getParentCategory = () => apiWrapper(get,url.GET_PARENT_CATEGORY)
export const getManufacturers = () => apiWrapper(get,url.GET_MANUFACTURERS)
export const getBanners = () => apiWrapper(get,url.GET_BANNERS)
export const getTaggedProducts = (params) => apiWrapper(get,url.GET_TAGGED_PRODUCTS,params)



// User Data 
// export const getUserData = () => get(url.USER_DATA)
export const getUserData = () => apiWrapper(get,url.USER_DATA)
// export const getUserAddress = () => get(url.USER_ADDRESS)
export const getUserAddress = () => apiWrapper(get,url.USER_ADDRESS)
// export const updateAddress = (params,query) => put(url.UPDATE_ADDRESS+query, params)
export const updateAddress = (params) => apiWrapper(put,url.UPDATE_ADDRESS,params)
// export const postAddress = (params) => post(url.POST_ADDRESS, params)
export const postAddress = (params) => apiWrapper(post,url.POST_ADDRESS,params)
// export const removeAddress = (query) => del(url.DELETE_ADDRESS+query)
export const removeAddress = (query) => apiWrapper(del,url.DELETE_ADDRESS+query)
// export const getWishlist = () => get(url.DELETE_ADDRESS)
export const getOrderlist = (params) => apiWrapper(get,url.GET_ORDER_LIST,params)
export const getTrackinglist = (params) => apiWrapper(get,url.GET_ORDER_TRACKING,params)
export const sendOrderInvoice = (params) => apiWrapper(post,url.SEND_ORDER_INVOCIE+params)
export const getUserCards = () => apiWrapper(get,url.GET_CARDS)
export const deleteCard = (params) => apiWrapper(del,url.DELETE_CARD+params)
export const makeCardDefault = (params) => apiWrapper(put,url.MAKE_CARD_DEFAULT+params)
export const addCardDetails = (data) => apiWrapper(post,url.ADD_CARD_DETAILS,data)

export const getShipmentList = (params) => apiWrapper(get, url.GET_SHIPMENT_LIST, params)
export const getPendingItems = (params) => apiWrapper(get, url.GET_PENDING_ITEMS, params)
export const getTrackShipment = (params) => apiWrapper(get, url.GET_TRACK_SHIPMENT, params)
export const getReturnList = () => apiWrapper(get, url.GET_RETURN_LIST)
export const getReturnOrderDetail = (params) => apiWrapper(get, url.GET_RETURN_ORDER_DETAIL, params)
export const trackReturnShipment = (params) => apiWrapper(get, url.TRACK_RETURN_SHIPMENT, params)
export const cancelReturnRequest = (params) => apiWrapper(get, url.CANCEL_RETURN_REQUEST, params)
export const getReturnableShipmentItems = (params) => apiWrapper(get, url.GET_RETURNABLE_SHIPMENT_ITEMS, params)
export const postReturnOrderRequest = (params) => apiWrapper(postForm, url.POST_RETURN_ORDER_REQUEST, params)

// User Profile 
export const getUserReview = () => apiWrapper(get,url.GET_USER_REVIEW)
export const setProfilePicture = (data) => apiWrapper(putForm,url.UPDATE_PROFILE_PICTURE,data)
export const setDefaultLanguage = (data) => apiWrapper(post,url.DEFAULT_LANGUAGE+data)




export const getRegions = () => apiWrapper(get,url.GETREGIONS)
export const getCities = (params) => apiWrapper(get,url.GETCITIES,params)
export const getOccasions = () => apiWrapper(get,url.GETOCCASIONS)
export const createOccasion = (data) => apiWrapper(postForm,url.OCCASION,data)
export const deleteOccasion = (data) => apiWrapper(del,url.OCCASION+data)
export const updateOccasion = (data) => apiWrapper(putForm,url.OCCASION,data)
export const getOccasionDetails = (params) => apiWrapper(get,url.GETOCCASIONDETAILS,params)
export const setOccasionProduct = (data) => apiWrapper(post,url.OCCASIONPRODUCT,data)
export const deleteOccasionProduct = (data) => apiWrapper(del,url.OCCASIONPRODUCT,data)
export const setOccasionInvite = (data) => apiWrapper(post,url.OCCASIONINVITE,data)
export const cancelOrder = (data) => apiWrapper(put,url.CANCELORDER,data)
export const getOrderReasons = (params) => apiWrapper(post,url.GETORDERREASONS+params)
export const getReasonTypes = (params) => apiWrapper(get,url.GETORDERREASONTYPES,params)
export const postOrderReturn = (params) => apiWrapper(post,url.POSTRETURNORDER,params)
export const getReturnPolicy = () => apiWrapper(get,url.GETRETURNPOLICY)
export const getPrivacyPolicy = () => apiWrapper(get,url.GETPRIVACYPOLICY)
export const getTermsOfService = () => apiWrapper(get,url.GETTERMSOFUSE)
export const getHowToRi = () => apiWrapper(get,url.GET_HOW_TO_RI_POLICY)
export const removeReturnItems = (params) => apiWrapper(del,url.REMOVE_RETURN_ITEMS+params)
export const saveReturnImages = (data) => apiWrapper(postJSON,url.SAVE_RETURN_IMAGE,data)

//Firebase config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_PROJECT_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PROJECT_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_PROJECT_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_PROJECT_MESSAGE_ID,
  appId: process.env.REACT_APP_FIREBASE_PROJECT_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_PROJECT_MEASUREMENT_ID
};

  
  // Initialize Firebase
 export const app = initializeApp(firebaseConfig);