import Search from "./Search";
import SearchAlgolia from "./SearchAlgolia";
import User from "./User";
import Topbar from "./Topbar";
import NavItems from "./NavItems";
import Cart from "./Cart";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import "../assets/css/--component-navbar.css";
import T from "../locale/translations-main";

function NavBar() {

  return (
    <>
    <Topbar/>
    <header>
      <div className="wrapper d-flex flex-row align-items-center justify-between">
        <div className="header-logo">
          <Link to={"/"} className="d-inline-block">
            <img src={logo} alt="ClickToys" />
          </Link>
        </div>
        <SearchAlgolia />
        <div className="header-right text-right d-flex align-items-center justify-end">
          <div className="header-info d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20.69" height="20.508" viewBox="0 0 20.69 20.508"><g transform="translate(0.754 0.752)"><g transform="translate(-0.5 -0.5)"><path d="M.49,2.374C.806,1.849,2.55-.056,3.793,0A1.642,1.642,0,0,1,4.76.518h0a16.884,16.884,0,0,1,2.467,3.34C7.472,5.026,6.078,5.7,6.5,6.878a9.873,9.873,0,0,0,5.619,5.616c1.177.427,1.851-.965,3.019-.723a16.864,16.864,0,0,1,3.34,2.468h0a1.639,1.639,0,0,1,.517.967c.046,1.309-1.977,3.077-2.371,3.3-.93.666-2.144.654-3.624-.034C8.874,16.757,2.274,10.282.524,6-.146,4.525-.191,3.3.49,2.374Z" transform="translate(0.5 0.5)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M0,0A7.971,7.971,0,0,1,7.041,7.032" transform="translate(12.565 0.75)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M0,0A4.426,4.426,0,0,1,3.5,3.5" transform="translate(12.565 4.293)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></g></svg>
            <div className="text-left">
              <span className="subtitle d-block text-gray-500"><T>24/7 Delivery</T></span>
              <a href="tel:8002440339" className="content decoration-none d-inline-block text-gray-500">8002440339</a>
              {/* <p className="fw-500 fs-16 m-0 d-inline-block text-gray-500">8002442225</p> */}
            </div>
          </div>
          <Cart/>
          <User />
        </div>
      </div>
    </header>
    <NavItems/>
    </>
  );
}

export default NavBar;
