import React, { useEffect } from 'react';
import Sidebar from "../Sidebar";
import Breadcrumbs from "../BreadCrumbs";
import { useState } from "react";
import Navbar from '../Navbar'
import Footer from '../Footer'
import "../../assets/css/--component-account.css";
import Maintenance from '../Maintenance';
import { fetchUserToken } from '../../helper/api-methods';
import appboy from 'appboy-web-sdk';
import Loader from '../Loader';
function Layout(props) {
    const [pageTitle, setPageTitle] = useState("Account")
    const [loading, setLoading] = useState(true);
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
    ])


    useEffect(() => {
        const guestToken = localStorage.getItem("guestToken");
        if (guestToken && guestToken.length) {
            setLoading(false);
        }
        else {
            fetchUserToken("web/guestUserLogin").then((response) => {
                localStorage.setItem("guestToken",response.data.Data.Token.Access_Token);
                localStorage.setItem("guestCustomerGuid",response.data.Data.UserInfo.CustomerGuid);
                appboy.changeUser(response.data.Data.UserInfo.CustomerGuid);
                setTimeout(() => { setLoading(false); }, 500);
            })
        }
    }, [])

    const configurePageTitle = (value) => {
        setPageTitle(value)
    }
    const configureBreadcrumb = (value) => {
        setBreadcrumbItems(value)
    }
    return (
        props.maintenance ? <Maintenance/> :
            loading ? <Loader/> :
            <>
                <Navbar />
                <Breadcrumbs pageTile={pageTitle} breadcrumbItems={breadcrumbItems} />
                <section id="account">
                    <div className="wrapper d-flex justify-between">
                        <Sidebar />
                        <div className='account-body'>
                            <>{React.cloneElement(props.children, { methods: [configurePageTitle, configureBreadcrumb] })}</>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
    )
}

export default Layout; 