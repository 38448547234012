const translations = {
    "In Stock": {
      "en": "In Stock",
      "ar": "متوفر"
    },
    "Add to Cart": {
      "en": "Add to Cart",
      "ar": "أضف إلى السلة"
    },
    "Please Wait...": {
      "en": "Please Wait...",
      "ar": "انتظر من فضلك..."
    }
  }
  export default translations