const translations = {
    "PAGE NOT FOUND": {
      "en": "PAGE NOT FOUND",
      "ar": "الصفحة غير موجودة"
    },
    "We are very sorry for inconvenience. It seems you are trying to access a page that either has been deleted or does not exist.": {
      "en": "We are very sorry for inconvenience. It seems you are trying to access a page that either has been deleted or does not exist.",
      "ar": "نحن نأسف جدا للإزعاج. يبدو أنك تحاول الوصول إلى صفحة محذوفة أو غير موجودة."
    },
    "Go to home page": {
      "en": "Go to home page",
      "ar": "اذهب إلى الصفحة الرئيسية"
    }
  }
  export default translations