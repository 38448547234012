const translations = {
        "Shopping Cart": {
          "en": "Shopping Cart",
          "ar": "سلة التسوق"
        },
        "Your Cart": {
          "en": "Your Cart",
          "ar": "السلة"
        },
        "Stock Available": {
          "en": "Stock Available",
          "ar": "المخزون المتوفر"
        },
        "Following are all the products you've added to your cart.": {
          "en": "Following are all the products you've added to your cart.",
          "ar": "جميع المنتجات التي أضفتها إلى سلة التسوق الخاصة بك."
        },
        "Cart Summary": {
          "en": "Cart Summary",
          "ar": "ملخص سلة التسوق"
        },
        "Sub Total:": {
          "en": "Sub Total:",
          "ar": "المجموع الفرعي: "
        },
        "Shipping:": {
          "en": "Shipping:",
          "ar": "الشحن: "
        },
        "Saved": {
          "en": "Saved",
          "ar": "تم الحفظ"
        },
        "None": {
          "en": "None",
          "ar": "لا أحد"
        },
        "Free": {
          "en": "Free",
          "ar": "مجانًا"
        },
        "Discount": {
          "en": "Discount",
          "ar": "الخصم"
        },
        "Coupon": {
          "en": "Coupon",
          "ar": "قسيمة الخصم"
        },
        "Apply Coupon": {
          "en": "Apply Coupon",
          "ar": "تطبيق القسيمة"
        },
        "Apply": {
          "en": "Apply",
          "ar": "تطبيق"
        },
        "Enter Code Here": {
          "en": "Enter Code Here",
          "ar": "أدخل الرمز هنا"
        },
        "Proceed to Checkout": {
          "en": "Proceed to Checkout",
          "ar": "الانتقال للدفع"
        },
        "Return to Shopping": {
          "en": "Return to Shopping",
          "ar": "مواصلة التسوق"
        },
        "Please provide a valid Coupon.": {
          "en": "Please provide a valid Coupon.",
          "ar": "يرجى تقديم قسيمة صالحة."
        },
        "Price": {
          "en": "Price",
          "ar": "سعر"
        },
        "Quantity": {
          "en": "Quantity",
          "ar": "كمية"
        },
        "Total": {
          "en": "Total",
          "ar": "المجموع"
        },
        "Gift Wrapping & Gift Card": {
          "en": "Gift Wrapping & Gift Card",
          "ar": "تغليف الهدايا وبطاقة الهدايا"
        },
        "Select Sheet": {
          "en": "Select Sheet",
          "ar": "اختيار التغليف"
        },
        "Gift Card?": {
          "en": "Gift Card?",
          "ar": "بطاقة هدايا ؟"
        },
        "Attach Gift Card?": {
          "en": "Attach Gift Card?",
          "ar": "إرفاق بطاقة هدايا ؟"
        },
        "From": {
          "en": "From",
          "ar": "من"
        },
        "To": {
          "en": "To",
          "ar": "إلى"
        },
        "Message": {
          "en": "Message",
          "ar": "رسالة"
        },
        "Sender name is required.": {
          "en": "Sender name is required.",
          "ar": "اسم المرسل مطلوب."
        },
        "Receiver name is required.": {
          "en": "Receiver name is required.",
          "ar": "اسم المتلقي مطلوب."
        },
        "Please type a message for the receiver.": {
          "en": "Please type a message for the receiver.",
          "ar": "الرجاء كتابة رسالة للمتلقي."
        },
        "Sender Name": {
          "en": "Sender Name",
          "ar": "اسم المرسل"
        },
        "Receiver name": {
          "en": "Receiver name",
          "ar": "اسم المتلقي"
        },
        "Your message here": {
          "en": "Your message here",
          "ar": "الرجاء كتابة رسالة للمتلقي."
        },
        "Gift Wrap": {
          "en": "Gift Wrap",
          "ar": "تغليف الهدايا"
        },
        "Rewrap": {
          "en": "Rewrap",
          "ar": "إعادة التغليف"
        },
        "Finish": {
          "en": "Finish",
          "ar": "الانتهاء"
        },
        "Remove": {
          "en": "Remove",
          "ar": "حذف"
        },
        "Loading cart items. Please wait...": {
          "en": "Loading cart items. Please wait...",
          "ar": "تحميل منتجات السلة. أرجو الإنتظار..."
        },
        "You currently have no products in your cart. Go to products and add items to your cart.": {
          "en": "You currently have no products in your cart. Go to products and add items to your cart.",
          "ar": "ليس لديك حاليا أي منتجات في سلة التسوق الخاصة بك. انتقل إلى المنتجات وأضف منتج إلى سلة التسوق الخاصة بك."
        },
        "Sign-in to Continue": {
          "en": "Sign-in to Continue",
          "ar": "تسجيل الدخول للمتابعة"
        },
        "Code Applied:": {
          "en": "Code Applied:",
          "ar": "تم تطبيق الكود:"
        },
        "Coupons": {
          "en": "Coupons",
          "ar": "قسيمة الخصم"
        },
        "Tax (VAT):": {
          "en": "Tax (VAT):",
          "ar": "ضريبة القيمة المضافة:"
        },
        "Inclusive of VAT": {
          "en": "Inclusive of VAT",
          "ar": "شامل ضريبة القيمة المضافة"
        },
        "Wait": {
          "en": "Wait",
          "ar": "انتظر"
        },
        "Cancel": {
          "en": "Cancel",
          "ar": "إلغاء"
        },
        "Out of Stock": {
          "en": "Out of Stock",
          "ar": "نفدت الكمية"
        },
        "Already Purchased": {
          "en": "Already Purchased",
          "ar": "مباع"
        },
        "All gift sheet would be same": {
          "en": "All gift sheet would be same",
          "ar": "كل أوراق تغليف الهدايا ستكون متشابهة"
        },
        "All gift card would have same message": {
          "en": "All gift card would have same message",
          "ar": "جميع بطاقات الهدايا سيكون لها نفس الرسالة"
        },
        "Error!": {
          "en": "Error!",
          "ar": "خطأ!"
        },
        "Okay": {
          "en": "Okay",
          "ar": "موافق"
        },
        "Expected Delivery": {
          "en": "Expected Delivery",
          "ar": "وقت التوصيل المتوقع"
        },
        "Certain items in your shopping cart have surpassed the available stock level, and their quantity has been adjusted to match the current stock availability": {
          "en": "Certain items in your shopping cart have surpassed the available stock level, and their quantity has been adjusted to match the current stock availability",
          "ar": "تجاوزت بعض العناصر عرب في المخزون."
        }
}
export default translations