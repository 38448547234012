import React, { useState, useEffect } from "react";
import gift from '../../assets/images/presentgift.png'
import { Link } from "react-router-dom";
import T from "../../locale/translations-main";

function PresentGift() {

    return (

        <>
            <section id="cta_section">
                <div className="present-gift d-flex align-items-center justify-between">
                    <div className="present-gift-content">
                        <h2 className="mt-0 mb-2"><T>Present Someone a gift?</T></h2>
                        <p className="mt-0 mb-4"><T>On special Occasions let your hearty wishes be conveyed to your close and loved ones with this little token of love.You can create profile for your loved one's and present them gift's on their special Occasion with your choose of gift wrap.</T></p>
                        <Link className="btn btn-primary" to="/account/occasions"><T>Create Occasion</T></Link>
                    </div>
                    <div className="present-gift-image">
                        <img src={gift} alt="" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default PresentGift;