import React, { useState,useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { Link, useNavigate } from "react-router-dom";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useInstantSearch } from 'react-instantsearch-hooks-web';
import T, { _T } from "../locale/translations-main";
import { tiktokConversion } from "./Analytics";

function Search(){
    const [isAlgoliaInitialized , setIsAlgoliaInitialized] = useState(false)
    const [searchClient , setSearchClient] = useState(null)
    // const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ENV, process.env.REACT_APP_ALGOLIA_ENV_KEY);
    const [ isFocused, setIsFocused ] = useState(false);
    const [ showSearchBox, setShowSearchBox ] = useState(false);
    const [ language, setLanguage ] = useState("en")
    const history = useNavigate();


    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }
    },[])


    useEffect(()=>{
        if(!isAlgoliaInitialized){
            initializeAlgolia();
        } 
    },[])


    const initializeAlgolia = () =>{
        const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_ENV, process.env.REACT_APP_ALGOLIA_ENV_KEY);
        setSearchClient(algoliaClient)
        setIsAlgoliaInitialized(true)
    }

    const onSearchSumbit= (e) =>{
        const value = e.target[0].value
        if (value.replaceAll(/\s/g, '').length) {
            tiktokConversion('Search', { "query": value })
            history('/products?keyword='+value);
        }
        else{
            history('/products');
        }
    }

    const transformItems = (items, { results }) => {
        return items.map((item, index) => ({
          ...item,
          searchQuery: results.query,
        }));
    };
    
    return (
        <>
        <div className={ showSearchBox ? "header-search toggle-search" : "header-search" }>
            {
                isAlgoliaInitialized ?
                <InstantSearch searchClient={searchClient} indexName={process.env.REACT_APP_ALGOLIA_SEARCH_INDEX} >
                    <Configure hitsPerPage={5} filters={`disableBuyButton:false AND disableWishlistButton: false`}/>
                    <button onClick={()=>{setShowSearchBox(!showSearchBox)}} className="search-toggle" type="button"><i className="ri-search-2-line"></i></button>
                    <button onClick={()=>{setShowSearchBox(!showSearchBox)}} className="search-close" type="button"><i className="ri-close-line"></i></button>
                    <SearchBox onSubmit={onSearchSumbit} searchAsYouType={true} placeholder={_T("Search our store for toys")} onBlur={()=>{ setTimeout(() => { setIsFocused(false) }, 500); }} onFocus={()=>{ setIsFocused(true) }}/>
                    {
                        isFocused ? 
                        <EmptyQueryBoundary fallback={<NoResults language={language}/>}>
                            <Hits hitComponent={SearchResult} transformItems={transformItems}/>
                        </EmptyQueryBoundary>
                        : <></>
                    }
                </InstantSearch> : null
            }
        </div>
        </>
    )
}
function SearchResult({hit}){
    const [ language, setLanguage ] = useState("en");
    const history = useNavigate();

    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang === "ar" ? "ar" : "en");
        }
        else{
            localStorage.setItem("LANG","en")
        }
    },[])
    
    return (
        <>
            <div onClick={()=>{ history(`/product/${hit.id}`) }} className="search-product-result">
                <div className="search-product-image">
                    <img src={hit.picture_url} alt=""/>
                </div>
                <div className="search-product-info">
                    <h4>{ language === "ar" ?  hit.category_ar ? "فئة: "+hit.category_ar : "التصنيف: غير متوفر" :  hit.category_en ? "Catergory: "+hit.category_en : "Catergory: N/A"}</h4>
                    <h2>{ language === "ar" ? hit.productname_ar : hit.productname_en }</h2>
                    <div className="search-product-rating">
                        <ReactStars size={14} count={5} edit={false} color="#FFB300" activeColor="#FFB300" value={hit.review} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />}/>
                        <span className="ml-2 mb-1 fs-16 fw-400 text-gray-600">{hit.review.toFixed(1)}</span>
                    </div>
                </div>
                {/* <div className="search-product-price">
                    <span>SAR<span className="text-secondary"> {hit.current_price.toFixed(2)}</span></span>
                </div> */}
            </div>
            { hit.searchQuery.length ? <Link to={"/products?keyword="+hit.searchQuery} className="showMoreLink" ><T>Show More Products</T></Link> : <></> }
        </>
    )
}
function EmptyQueryBoundary({ children, fallback }) {
    const { indexUiState, results } = useInstantSearch();
    if (!results.__isArtificial && results.nbHits === 0) {
      return fallback;
    }
    if (!indexUiState.query) {
        return null;
    }
    return <div className={results.nbHits > 5 ? "show" : "hide"}>{children}</div>;
}
function NoResults({language}) {
    return (<div className="ais-Hits">
        <ol className="ais-Hits-list">
            <li className="ais-Hits-item"><span className="search-product-result"><T>Sorry, we could not find any toys for you.</T></span></li>
        </ol>
    </div>);
  }
export default Search;