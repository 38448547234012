import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect }  from 'react';
import "../../assets/css/--component-login.css"
import logo from "../../assets/images/logo.png";
import logoFromBG from "../../assets/images/login-form-bg.jpg";
import apple from "../../assets/images/social-icons/apple.png";
import google from "../../assets/images/social-icons/google.png";
import facebook from "../../assets/images/social-icons/facebook.png";
import { postRegister, socialLogin } from "../../helper/backend-methods";
import {app} from '../../helper/backend-methods'
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import translations from "../../data/language.json"
import { analyticsIdentityEvent, analyticsPageEvent } from "../../components/Analytics";
import nationalityTranslation from "../../data/nationalities";
import { LoginSocialFacebook } from "reactjs-social-login";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

function Register(props){
    
    const [search, setSearch] = useSearchParams();
    const [redirect, setRedirect] = useState(false);
    const [serverError, setServerError]= useState('');
    const [isVisible, setIsVisible] = useState(false)
    const [userfirstname, setUserfirstname] = useState("")
    const [userlastname, setUserlastname] = useState("")
    const [useremail, setUseremail] = useState("")
    const [userphone, setUserphone] = useState("")
    const [usernationality, setUsernationality] = useState(0)
    const [userpassword, setUserpassword] = useState("")
    const [errors, setErrors] = useState({ userfirstname: "", userlastname: "", useremail: "", userphone: "", usernationality: "", userpassword: "" })
    const [loading, setLoading]= useState(false);
    const [configLoading, setConfigLoading] = useState(false);
    const [config, setConfig] = useState({ Maintenance: false, EnableFacebookLogin: false, EnableGoogleLogin: false})
    const history = useNavigate();
    const [language,setLanguage] = useState("en")
    const changeLang = (e) => {
        const lang = e;
        localStorage.setItem("LANG",lang);
        setLanguage(lang)
        if(lang === "ar"){
            document.getElementsByTagName("body")[0].classList.add("RTL")
        }
        else{
            document.getElementsByTagName("body")[0].classList.remove("RTL")
        }
    }

    const getConfig = async () =>{
        const firebaseApp = app;
        const remoteConfig = getRemoteConfig(firebaseApp);
        remoteConfig.settings.minimumFetchIntervalMillis = 5000;
        await fetchAndActivate(remoteConfig)
        return getValue(remoteConfig, process.env.REACT_APP_FIREBASE_REMOTE_CONFIG);
    }

    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            if(lang === "ar"){
                document.getElementsByTagName("body")[0].classList.add("RTL")
            }
        }
        getConfig().then((response)=>{
            setConfig(JSON.parse(response._value))
            setConfigLoading(false)
        }).catch((e)=>{
            setConfigLoading(false)
        })
    },[])

    useEffect(()=>{
        if(props.consent.page){
            analyticsPageEvent("Registration");
        }
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
            if(lang === "ar"){
                document.getElementsByTagName("body")[0].classList.add("RTL")
            }
        }
        if(search){
            if(search.get('redirect') && search.get('redirect') !== ""){
                setRedirect(search.get('redirect'))
            }
        }
    },[])
    
    const validateSubmit = (e) => {
        e.preventDefault();
        const errors = { userfirstname: "", userlastname: "", useremail: "", userphone: "", usernationality: "", userpassword: "" };
        let flag = false;
        if (!userfirstname || userfirstname.replaceAll(/\s/g,'').length < 2) {
            errors.userfirstname = translations.register[language].firstNameError;
            flag = true;
        } 
        // else if (!/^[A-Za-z\s]*$/.test(userfirstname)) {
        //     errors.userfirstname = translations.register[language].firstNameError;
        //     flag = true;
        // }
        if (!userlastname || userlastname.replaceAll(/\s/g,'').length < 2) {
            errors.userlastname = translations.register[language].lastNameError;
            flag = true;
        } 
        // else if (!/^[A-Za-z\s]*$/.test(userlastname)) {
        //     errors.userlastname = translations.register[language].lastNameError;
        //     flag = true;
        // }
        if (!useremail || useremail.replaceAll(/\s/g,'').length < 3) {
            errors.useremail = translations.register[language].emailError;
            flag = true;
        } 
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(useremail)) {
            errors.useremail = translations.register[language].emailError;
            flag = true;
        }
            if (!userphone || userphone.replaceAll(/\s/g,'').length < 9 || userphone.replaceAll(/\s/g,'').length > 12 || !userphone.startsWith('9665') ) {
                errors.userphone = translations.register[language].phoneError;
                flag = true;
            } 
        // else if (!/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(userphone)) {
        //   errors.userphone = 'Please provide a valid phone number';
        // }
        // if (!usernationality || usernationality === "") {
        //     errors.usernationality = translations.register[language].nationalityError;
        //     flag = true;
        // } 
        if (!userpassword) {
            errors.userpassword = translations.register[language].PasswordError;
            flag = true;
        } 
        else if (userpassword.replaceAll(/\s/g,'').length < 6) {
            errors.userpassword = translations.register[language].PasswordError;
            flag = true;
        }
        setErrors(errors);
        if(!flag){
            setLoading(true)
            setServerError('')
            postRegister({FirstName: userfirstname , LastName: userlastname , Email: useremail , Password: userpassword , Phone: userphone , Nationality: usernationality ? usernationality : 0}).then(({data})=>{
                if(data.Status){
                    analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                    localStorage.setItem("authToken",data.Data.Token.Access_Token)
                    localStorage.setItem("userinfo",JSON.stringify(data.Data.UserInfo))
                    if(redirect){
                        history(redirect);
                    }
                    else{
                        history('/account/profile');
                    }
                }
                else{
                    setServerError(data.Message)
                    setLoading(false)
                }
            }).catch((e)=>{
                setServerError(translations.register[language].accountExistsError)
                setLoading(false)
            })
        }
    }

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    const signInWithGoogle = () =>{
        setServerError("")
        signInWithPopup(auth , provider).then((result)=>{
            socialLogin({socialToken: result.user.accessToken}).then(({data})=>{
                if(data.Status){
                    analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                    localStorage.setItem("authToken",data.Data.Token.Access_Token)
                    localStorage.setItem("userinfo",JSON.stringify(data.Data.UserInfo))
                    if(redirect){
                        history(redirect);
                    }
                    else{
                        history('/');
                    }
                }
                else{
                    setServerError(data.Message)
                }
            }).catch((e)=>{
                setServerError(translations.register[language].googleError)
                console.log(e);
            })
        }).catch((e)=>{
            setServerError(translations.register[language].googleError)
            console.log(e);
        })
    
    }
    // const signInWithFacebook = () =>{
    //     setServerError("")
    //     const provider = new FacebookAuthProvider();
    //     signInWithPopup(auth, provider).then((result)=>{
    //         socialLogin({socialToken: result.user.accessToken}).then(({data})=>{
    //             if(data.Status){
    //                 localStorage.setItem("authToken",data.Data.Token.Access_Token)
    //                 localStorage.setItem("userinfo",JSON.stringify(data.Data.UserInfo))
    //                 if(redirect){
    //                     history(redirect);
    //                 }
    //                 else{
    //                     history('/');
    //                 }
    //             }
    //             else{
    //                 setServerError(data.Message)
    //             }
    //         }).catch((e)=>{
    //             setServerError(translations.register[language].facebookError)
    //             console.log(e);
    //         })
    //     }).catch((e)=>{
    //         setServerError(translations.register[language].facebookError)
    //         console.log(e);
    //     })
    // }
    const signInWithFacebook = (response) => {
        setServerError("")
        const data = { "email": response.data.email, "Name": response.data.name, "Picture": response.data.picture.data.url, "signInProvider": response.provider }
        socialLogin({ data: data, decrypted: true , socialToken: null}).then(({ data }) => {
            if (data.Status) {
                analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                localStorage.setItem("authToken", data.Data.Token.Access_Token)
                localStorage.setItem("userinfo", JSON.stringify(data.Data.UserInfo))
                if (redirect) {
                    history(redirect);
                }
                else {
                    history('/');
                }
            }
            else {
                setServerError(data.Message)
            }
        }).catch((e) => {
            setServerError(translations.login[language].facebookError)
            console.log(e);
        })
    }

    return (
        <section id="login">
            <div className="wrapper" style={{"width" : "100%"}}>
                <div className="login-form-wrapper">
                    <form onSubmit={validateSubmit}>
                        <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                        <img src={logo} alt="ClickToys" className="form-logo"/>
                        <h1 className="form-title">{translations.register[language].registerHeading}</h1>
                        <p className="form-description">{translations.register[language].registerSubHeading}</p>
                        <div className="form-group">
                            <label htmlFor="userfirstname">{translations.register[language].firstNameLabel} <span className="text-primary">*</span></label>
                            <input id="userfirstname" type="text" name="userfirstname" onChange={(e)=>{setUserfirstname(e.target.value.slice(0,25))}} value={userfirstname} placeholder={translations.register[language].firstNameLabel} />
                            {errors.userfirstname ? <span className="text-primary fs-12 fw-400">{errors.userfirstname}</span> : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="userlastname">{translations.register[language].lastNameLabel} <span className="text-primary">*</span></label>
                            <input id="userlastname" type="text" name="userlastname" onChange={(e)=>{setUserlastname(e.target.value.slice(0,25))}} value={userlastname} placeholder={translations.register[language].lastNameLabel} />
                            {errors.userlastname ? <span className="text-primary fs-12 fw-400">{errors.userlastname}</span> : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="useremail">{translations.register[language].emaiLabel} <span className="text-primary">*</span></label>
                            <input id="useremail" type="email" name="useremail" onChange={(e)=>{setUseremail(e.target.value.slice(0,50))}}  value={useremail} placeholder="name@xxx.com" />
                            {errors.useremail ? <span className="text-primary fs-12 fw-400">{errors.useremail}</span> : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="userphone">{translations.register[language].phoneLabel} <span className="text-primary">*</span></label>
                            <input id="userphone" type="tel" name="userphone" onChange={(e)=>{setUserphone(e.target.value.replace(/\D/g, '').slice(0,14))}}  value={userphone} placeholder="9665-XXXX-XXXX" className="text-left" />
                            {errors.userphone ? <span className="text-primary fs-12 fw-400">{errors.userphone}</span> : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="usernationality">{translations.register[language].nationalityLabel}</label>
                            <select  onChange={(e)=>{setUsernationality(e.target.value)}} value={usernationality}  name="usernationality" id="usernationality">
                                <option value={0}>{translations.register[language].selectNationality}</option>
                                {
                                    Object.keys(nationalityTranslation()).map((c,i)=>(
                                        <option key={i} value={c}>{nationalityTranslation()[c][language]}</option>
                                    ))
                                }
                            </select>
                            {errors.usernationality ? <span className="text-primary fs-12 fw-400">{errors.usernationality}</span> : null}
                        </div>
                        <div className="form-group has-icon">
                            <label htmlFor="userpassword">{translations.register[language].passwordLabel} <span className="text-primary">*</span></label>
                            <input id="userpassword" type={isVisible ? "text" : "password"} name="userpassword" onChange={(e)=>{setUserpassword(e.target.value.replaceAll(/\s/g,''))}}  value={userpassword} placeholder={translations.register[language].enterPassword} />
                            <button onClick={()=>{ setIsVisible(!isVisible) }} type="button" >{isVisible ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                            {errors.userpassword ? <span className="text-primary fs-12 fw-400">{errors.userpassword}</span> : null}
                        </div>
                        {
                            serverError.length ? <p className="text-primary text-center fw-400 fs-14">{serverError}</p> : <></>
                        }
                        <div className="form-group">
                            <button type="submit" disabled={loading} className="btn btn-primary w-100">{ loading ? translations.register[language].pleaseWait : translations.register[language].registerBtn}</button>
                        </div>
                        {
                            !configLoading && ( config.EnableFacebookLogin || config.EnableGoogleLogin )?
                            <>
                                <div className="form-group d-flex align-items-center justify-center">
                                    <span className="line-left"></span>
                                    <span className="fs-12 text-gray-300 fw-400">{translations.login[language].continueWith}</span>
                                    <span className="line-right"></span>
                                </div>
                                <div className="social-login-wrapper d-flex align-items-center justify-center">
                                    {
                                        config.EnableGoogleLogin ?
                                        <button type="button" className="social-login cursor-pointer" onClick={signInWithGoogle}><img alt="Google Sign-in" src={google} /></button> : <></>
                                    }
                                    {
                                        config.EnableFacebookLogin ?
                                        <LoginSocialFacebook appId={process.env.REACT_APP_FACEBOOK_APP_ID} onResolve={signInWithFacebook} onReject={(error) => { setServerError(translations.login[language].facebookError) }}>
                                            <button type="button" className="social-login cursor-pointer" ><img alt="facebook Sign-in" src={facebook} /></button>
                                        </LoginSocialFacebook> : <></>
                                    }
                                    {/* <button type="button" className="social-login cursor-pointer" onClick={signInWithFacebook}><img alt="Facebook Sign-in" src={facebook} /></button> */}
                                    {/* <button type="button" className="social-login cursor-pointer"><img alt="Apple Sign-in" src={apple} /></button> */}
                                </div>
                            </> : <></>
                        }
                        <div className="form-group mb-0 fs-14 text-gray-500 text-center">
                        {translations.register[language].alreadyAccount}
                            <Link to={"/login"} className="link fs-14 ml-2">{translations.register[language].loginLink}</Link>
                        </div>
                        <div className="form-group mt-5 text-center">
                            {
                                language === "ar" ? 
                                <span className="link fs-14 ml-2 text-en" onClick={() => changeLang("en")}>Switch to English</span> :
                                <span className="link fs-14 ml-2 text-ar" onClick={() => changeLang("ar")}>التحول إلى اللغة العربية</span>
                            }
                        </div>
                    </form>
                    <div className="login-background">
                        <img src={logoFromBG} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default Register;