const translations = {
    "Personal Profile": {
      "en": "Personal Profile",
      "ar": "الملف الشخصي"
    },
    "You can edit and update your profile information": {
      "en": "You can edit and update your profile information",
      "ar": "يمكنك تحديث معلومات الملف الشخصي الخاص بك"
    },
    "First Name": {
      "en": "First Name",
      "ar": "الاسم الأول"
    },
    "Last Name": {
      "en": "Last Name",
      "ar": "الاسم الأخير"
    },
    "Email Address": {
      "en": "Email Address",
      "ar": "عنوان البريد الالكترونى"
    },
    "Mobile": {
      "en": "Mobile",
      "ar": "رقم الهاتف"
    },
    "Nationality": {
      "en": "Nationality",
      "ar": "الجنسية"
    },
    "Gender": {
      "en": "Gender",
      "ar": "الجنس"
    },
    "Update": {
      "en": "Update",
      "ar": "تحديث"
    },
    "Save": {
      "en": "Save",
      "ar": "حفظ"
    },
    "Cancel": {
      "en": "Cancel",
      "ar": "إلغاء"
    },
    "Male": {
      "en": "Male",
      "ar": "ذكر"
    },
    "Female": {
      "en": "Female",
      "ar": "أنثى"
    },
    "Fetching profile data. Please wait...": {
      "en": "Fetching profile data. Please wait...",
      "ar": "تحميل بيانات الملف الشخصي. أرجو الإنتظار..."
    },
    "Your First Name is required": {
      "en": "Your First Name is required",
      "ar": "الاسم الأول مطلوب"
    },
    "Your Last Name is required": {
      "en": "Your Last Name is required",
      "ar": "الاسم الأخير مطلوب"
    },
    "Please provide your gender": {
      "en": "Please provide your gender",
      "ar": "يرجى كتابة جنسك"
    },
    "Please provide your Nationality": {
      "en": "Please provide your Nationality",
      "ar": "يرجى كتابة جنسيتك"
    },
    "Add Payment Method": {
      "en": "Add Payment Method",
      "ar": "إضافة طريقة دفع"
    },
    "Enable to fetch user profile data. Please try again!": {
      "en": "Enable to fetch user profile data. Please try again!",
      "ar": "تمكين لجلب بيانات ملف تعريف المستخدم. حاول مرة اخرى!"
    },
    "Please Wait": {
      "en": "Please Wait",
      "ar": "انتظر من فضلك"
    },
    "N/A": {
      "en": "N/A",
      "ar": "غير متاح"
    },
    "Select Nationality": {
      "en": "Select Nationality",
      "ar": "اختر الجنسية"
    }
  }
  export default translations