import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import '../../assets/css/--page-account-shipment.css'
import noImage from "../../assets/images/no-image.jpg"
import { getPendingItems } from "../../helper/backend-methods"

const RemoveOrderItems = () => {
    let params = useParams();
    const orderID = params.id
    const [loading, setLoading] = useState(true);
    // const [pendingItemsList, setPendingItemsList] = useState([]);
    const [pendingItemsProcessedList, setPendingItemsProcessedList] = useState([]);
    const [error, setErrors] = useState([]);
    const [iscopied, setIsCopied] = useState(false);
    // const [pendingItemsError, setPendingItemsError] = useState("");

    useEffect(() => {
        setLoading(true)
        getPendingItems({
            'orderId': orderID
        }).then(({ data }) => {
            if (data.Status) {
                let error = []
                const processedData = data.Data.map((pendingItem) => {
                    error.push({ QuantityError: "" })
                    const item = {
                        ...pendingItem,
                        removedQuantity: 0,
                        IsSelected: false
                    }
                    return item
                })
                setErrors(error);
                setPendingItemsProcessedList(processedData)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        });
    }, [orderID]);

    const updateRemovedProductIsSelected = (e, productId) => {
        const updatedRemovedItems = pendingItemsProcessedList.map((pendingItem) => {
            if (pendingItem.ProductId === productId) {
                return {
                    ...pendingItem,
                    IsSelected: e.target.checked
                }
            }
            else {
                return pendingItem
            }
        })
        setPendingItemsProcessedList(updatedRemovedItems)
    }
   

    const updateRemovedProductQuantity = (removedQuantity, productId) => {
        const updatedRemovedItems = pendingItemsProcessedList.map((pendingItem) => {
            if (pendingItem.ProductId === productId) {
                return {
                    ...pendingItem,
                    removedQuantity: removedQuantity
                }
            }
            else {
                return pendingItem
            }
        })
        setPendingItemsProcessedList(updatedRemovedItems)
    }

    const submitRemoveRequest = () => {

    }

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <div id="remove-item-section">
            {loading ? <span className="btn btn-alternative w-100 mb-4">Please wait while your products are loading.</span> :
                <>
                    <div className="warning-alert danger-bg pt-3 pb-3 pl-3 pr-3 mt-2 mb-2">
                        <p className="mt-1 mb-1 fs-16 fw-500 text-danger">
                            <i className="ri-error-warning-line text-danger mr-2 fs-22 fw-500"></i>
                            We'll refund you the amount for the items you’ve removed from the order
                        </p>
                    </div>
                    <div className="modal-card">
                        <div id="product-shipments">
                            {pendingItemsProcessedList.map((pi, j) => {
                                return (
                                    <div key={j} className="modal-card-wrapper">
                                        <div className="d-flex align-items-center justify-start position-relative mb-3 mt-3 ml-4 mr-4">
                                            <div className="product-checkbox">
                                                <input className="input-checkbox" type="checkbox" id={pi.OrderItemId} checked={pi.IsSelected} onChange={(e) => updateRemovedProductIsSelected(e, pi.ProductId)} />
                                                <label htmlFor={pi.OrderItemId}></label>
                                            </div>
                                            <div className="product-description d-flex align-items-center justify-between flex-wrap">
                                                <div className="card-image text-center">
                                                    <Link className="product-image">
                                                        <img src={pi.PictureUrl ? pi.PictureUrl : noImage} alt={pi.Name} draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                                    </Link>
                                                </div>
                                                <div className="card-meta">
                                                    <div className="card-title-brand d-inline-flex flex-column" style={{ maxWidth: "250px" }}>
                                                        <Link className="product-title decoration-none text-gray-500 fs-16 fw-500" title={pi.Name}>{pi.Name}</Link>
                                                    </div>
                                                    <div className="product-quantity w-100">
                                                        <div className="product-quantity-container d-inline-flex align-items-center w-100">
                                                            <button className="product-quantity-btn" onClick={() => { pi.removedQuantity <= 1 ? updateRemovedProductQuantity(1, pi.ProductId) : updateRemovedProductQuantity(pi.removedQuantity - 1, pi.ProductId) }}>-</button>
                                                            <input className="product-quantity-field" value={pi.removedQuantity} placeholder="1" readOnly />
                                                            <button className="product-quantity-btn" onClick={() => { pi.removedQuantity < pi.Quantity ? updateRemovedProductQuantity(pi.removedQuantity + 1, pi.ProductId) : updateRemovedProductQuantity(pi.removedQuantity, pi.ProductId) }}>+</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            error[j].QuantityError.length ?
                                                <ul className="error-list">
                                                    {
                                                        error[j].QuantityError.length ?
                                                            <li>{error[j].QuantityError}</li> : <></>
                                                    }
                                                </ul> : <></>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="submit-return-container">
                        {
                            // returnFormResponse.ResponseMessage != "" && <>
                            //     <p className="mt-1 mb-1 fs-16 fw-500 text-danger text-center">
                            //         {returnFormResponse.ResponseMessage}
                            //     </p>
                            // </>
                        }
                        <button className="btn btn-primary w-100" onClick={submitRemoveRequest}>Remove selected items</button>
                    </div>
                </>
            }

        </div>
    )
}

export default RemoveOrderItems
