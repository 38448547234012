import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { protectedRoutes, publicRoutes, baseRoutes } from "./routes/";
import {PrivateRoute,AuthRoute} from "./routes/route";
import PublicLayout from "./components/Public-Layout/";
import ProtectedLayout from "./components/Protected-Layout/";
import AuthLayout from "./components/Auth-Layout/";
import Error404 from "./components/Error404";
import 'remixicon/fonts/remixicon.css'
import { app } from "./helper/backend-methods";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import Loader from "./components/Loader";
import appboy from 'appboy-web-sdk';
import { getUserGUID } from "./common";
import T from "./locale/translations-main";
import TagManager from "react-gtm-module";

function App() {
  const location = useLocation();
  const [checkConsent, setCheckConsent] = useState(false)
  const [consentClass, setConsentClass] = useState(false)
  const [consentIdentity, setConsentIdentity] = useState(true)
  const [consentTracking, setConsentTracking] = useState(true)
  const [consentPage, setConsentPage] = useState(true)
  const [managePreference, setManagePreference] = useState(false)
  const [consentObject, setConsentObject] = useState({"identity": false, "tracking":false, "page": false})
  const [config, setConfig] = useState({ Maintenance: false, EnablePromoPopup: false, PromoPopupURL: "", PromoMediaDesktopSrc: "", PromoMediaTabletSrc: "", PromoMediaMobileSrc: "" })
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    if(process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY && process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY.length){
      const tagManagerArgs = { dataLayer: { event: 'pageview', page: window.location.href }};
      TagManager.dataLayer(tagManagerArgs);
    }
}, [location]);

  const getConfig = async () =>{
    const firebaseApp = app;
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 5000;
    await fetchAndActivate(remoteConfig)
    return getValue(remoteConfig, process.env.REACT_APP_FIREBASE_REMOTE_CONFIG);
  }

  useEffect(()=>{
    getConfig().then((response)=>{
      setConfig(JSON.parse(response._value))
      setLoading(false)
    }).catch((e)=>{
      setLoading(false)
    })

    // const lang = localStorage.getItem("LANG")
    // if(lang && lang.length){
    //   if(lang === "ar"){
    //     setContent(translations.tracking["ar"])
    //     localStorage.setItem("LANG","ar")
    //     document.getElementsByTagName("body")[0].classList.add("RTL")
    //   }
    //   else{
    //     setContent(translations.tracking["en"])
    //     localStorage.setItem("LANG","en")
    //     document.getElementsByTagName("body")[0].classList.remove("RTL")
    //   }
    // }
    const consent = localStorage.getItem("consent");
    if(!consent || consent.length < 1){
      promptForConsent();
    }
    else{
      try{
        if(JSON.parse(consent) && (JSON.parse(consent).identity || JSON.parse(consent).identity === false) && (JSON.parse(consent).tracking || JSON.parse(consent).tracking === false) && (JSON.parse(consent).page || JSON.parse(consent).page === false)){
          const c = JSON.parse(consent);
          setConsentObject({"identity": c.identity, "tracking": c.tracking, "page": c.page})
        }
        else{
          promptForConsent();
        }
      }
      catch (e){
        promptForConsent();
      }
    }
  },[])

  const promptForConsent = () => {
    localStorage.removeItem("consent")
    setCheckConsent(true)
    setTimeout(() => {
        setConsentClass(true)
    }, 1000);
  }

  const acceptConsent = (identity=true,tracking=true,page=true) =>{
    const permissions = JSON.stringify({"identity": identity, "tracking":tracking, "page": page})
    setConsentObject({"identity": identity, "tracking":tracking, "page": page})
    localStorage.setItem("consent",permissions);
    setConsentClass(false);
    setTimeout(() => {
      setCheckConsent(false)
    }, 1000);
  }

  useEffect(() => {
    appboy.initialize(process.env.REACT_APP_BRAZE_API_KEY, { baseUrl: process.env.REACT_APP_BRAZE_BASE_URL, enableLogging: true, allowUserSuppliedJavascript: false });
    appboy.openSession();
    if(getUserGUID()){
      appboy.changeUser(getUserGUID());
    }
  }, []);
  return (
    <React.Fragment>
    
      { loading ? <Loader/> : <></> }

      <Routes>
        {
          baseRoutes.map((route, idx) => (
            <Route exact key={`open-${idx}`} path={route.path} element={<AuthRoute><AuthLayout maintenance={config.Maintenance}><route.element consent={consentObject}/></AuthLayout></AuthRoute>}/>
          ))
        }
        {
          publicRoutes.map((route, idx) => (
            <Route exact key={`public-${idx}`} path={route.path} element={<PublicLayout hasHeader={route.header} hasFooter={route.footer} maintenance={config.Maintenance}><route.element consent={consentObject}/></PublicLayout>}/>
          ))
        }
        {
          protectedRoutes.map((route, idx) => (
            <Route exact key={`protected-${idx}`} path={route.path} element={<PrivateRoute><ProtectedLayout maintenance={config.Maintenance}><route.element consent={consentObject}/></ProtectedLayout></PrivateRoute>}/>
          ))
        }
        <Route exact path="*" element={<PublicLayout maintenance={config.Maintenance}><Error404 consent={consentObject}/></PublicLayout>}/>
      </Routes>

      {/* for tracking purposes */}
      {
        checkConsent && !config.Maintenance ? 
        <div className={`consent-popup ${consentClass ? "active" : ""}`}>
          {
            !managePreference ?
            <div className="popup-wrapper">
              {/* <button className="popup-close"><i className="ri-close-line"></i></button> */}
              <p className="popup-text"><T>This website tracks your activities to enhance your shopping experience. The website tracks your activities, such as page views, product searches, and purchases, to provide you with a personalized experience and better understand how you use the website. All data collected is anonymous and will be used solely for the purpose of improving the website and it's services. By clicking 'Accept,' you consent to the collection and use of this information.</T></p>
              <div className="popup-footer">
                <button className="btn btn-primary mr-2" onClick={()=>{acceptConsent()}}><T>Accept</T></button>
                <button className="btn btn-secondary" type="button" onClick={()=>setManagePreference(true)}><T>Manage Preferences</T></button>
              </div>
            </div> : 
            <div className="popup-wrapper">
              <div>
                  <h4 className="text-gray-500 fs-18 fw-500 m-0"><T>Manage Preferences</T></h4>
                  <p className="text-gray-400 fs-14 fw-400 mt-0 mb-2"></p>
                  <div className="field-group">
                      <input type="checkbox" id="consentIdentity" className="input-checkbox" checked={consentIdentity} onChange={(e)=>{setConsentIdentity(e.target.checked)}}/>
                      <label htmlFor="consentIdentity" className="text-gray-500 fs-14 fw-400"><T>Allow tracking my identity on the website</T></label>
                  </div>
                  <div className="field-group">
                      <input type="checkbox" id="consentTracking" className="input-checkbox" checked={consentTracking} onChange={(e)=>{setConsentTracking(e.target.checked)}}/>
                      <label htmlFor="consentTracking" className="text-gray-500 fs-14 fw-400"><T>Allow tracking my interactions on the website</T></label>
                  </div>
                  <div className="field-group">
                      <input type="checkbox" id="consentPage" className="input-checkbox" checked={consentPage} onChange={(e)=>{setConsentPage(e.target.checked)}}/>
                      <label htmlFor="consentPage" className="text-gray-500 fs-14 fw-400"><T>Allow tracking my page visits on the website</T></label>
                  </div>
              </div>
              <div className="popup-footer mt-3">
                <button className="btn btn-primary" onClick={()=>{acceptConsent(consentIdentity,consentTracking,consentPage)}}><T>Accept</T></button>
              </div>
            </div>
          }
        </div> : <></>
      }
    </React.Fragment>
  );
}
export default App;