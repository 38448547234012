import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import '../../assets/css/--page-account-shipment.css'
import '../../assets/css/--page-returns.css'
import { getReturnList } from "../../helper/backend-methods"
import T, { _T } from '../../locale/translations-main';

const ViewReturns = (props) => {
    const [loading, setLoading] = useState(true);
    const [returnList, setReturnList] = useState([]);


    const [configurePageTitle, configureBreadcrumb] = props.methods
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Returns", link: "" },
    ]
    useEffect(() => {
       
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        configurePageTitle("Returns")
        configureBreadcrumb(breadcrumbItems)
    }, [])

    useEffect(() => {
        setLoading(true)
        getReturnList().then(({ data }) => {
            // console.log('success', data)
            if (data.Status) {
                setReturnList(data.Data)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        });
    }, []);

    return (
        <section id="product-returns">
            {
                loading ?
                    <p className="btn btn-alternative w-100"><T>Please wait while the return list is loading...</T></p> :
                    <>
                        <div className="returns-section">
                            <h1 className='fs-20 fw-500 text-gray-500 mt-1 mb-1'><T>Return Requests</T></h1>
                            <div className="d-flex justify-between flex-wrap">
                                {
                                    returnList.length ?
                                        <>
                                            {returnList.map((rl, index) => {
                                                return (
                                                    <div key={rl.ReturnOrderId} className="order-details-container order-product-container d-flex justify-between flex-wrap">
                                                        <div className="returns-heading">
                                                            <h2 className='fs-14 fw-500 mt-0 mb-0'>{`${_T("Return Order No")}: ${rl.ReturnOrderNumber}`}</h2>
                                                        </div>
                                                        <div className="order-card">
                                                            <div className="order-card-wrapper">
                                                                <div className="d-flex align-items-center justify-between flex-wrap position-relative mb-3 gap-10">
                                                                <div className="">
                                                                        <p className='fs-14 text-secondary fw-400 mt-1 mb-1'><T>Order Number</T></p>
                                                                        <p className='text-gray-500 fs-14 fw-500 mt-1 mb-1'> {rl.OrderNumber}</p>
                                                                    </div>
                                                                    {/* <div className="">
                                                                        <p className='fs-14 text-secondary fw-400 mt-1 mb-1'>{translations.returns[language].status}</p>
                                                                        <p className='text-gray-500 fs-14 fw-500 mt-1 mb-1'><span className="status-indicator status-processing"></span> {rl.Status}</p>
                                                                    </div> */}
                                                                    <div className="">
                                                                        <p className='fs-14 text-secondary fw-400 mt-1 mb-1 text-center'><T>Request Date</T></p>
                                                                        <p className='text-gray-500 fs-14 fw-500 mt-1 mb-1 text-center LTR'>{rl.RequestDate}</p>
                                                                    </div>
                                                                    <div className="">
                                                                        <p className='fs-14 text-secondary fw-400 mt-1 mb-1 text-center'><T>Items</T></p>
                                                                        <p className='text-gray-500 fs-14 fw-500 mt-1 mb-1 text-center'>{rl.Items}</p>
                                                                    </div>
                                                                    <Link to={`/account/returns/return-details/${rl.ReturnOrderId}`} className='btn btn-primary'><T>Return Details</T></Link>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </> :
                                        <>
                                            <p className="btn btn-alternative w-100"><T>No Return Request Found</T></p>
                                        </>
                                }
                            </div>
                        </div>
                    </>
            }
        </section>
    )
}

export default ViewReturns
