import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import '../../assets/css/--page-account-shipment.css'
import noImage from "../../assets/images/no-image.jpg"
import { getShipmentList, getPendingItems } from "../../helper/backend-methods"
import moment from 'moment';
import T, { _T } from '../../locale/translations-main';

const ViewOrderShipment = (props) => {

    const statuses = {
        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
        "Out For Delivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "Processing": {
            "en": "Processing",
            "ar": "يعالج"
        },
        "Order placed": {
            "en": "Order placed",
            "ar": "تم الطلب"
        },
        "Pending": {
            "en": "Pending",
            "ar": "قيد الانتظار"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "ألغيت"
        },
        "PartiallyDelivered_Processing": {
            "en": "Partially Delivered Processing",
            "ar": "تسليم جزئي للمعالجة",
        },
        "PartiallyDelivered_Completed": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا",
        },
        "Received": {
            "en": "Received",
            "ar": "تلقى"
        },
        "ProcessedForPicking": {
            "en": "Processed For Picking",
            "ar": "المصنعة للقطف"
        },
        "PickingCompleted": {
            "en": "Picking Completed",
            "ar": "اكتمل الاختيار"
        },
        "ProcessedForShipping": {
            "en": "Processed For Shipping",
            "ar": "معالجتها للشحن"
        },
        "PartiallyProcessedForShipping": {
            "en": "Partially Processed For Shipping",
            "ar": "تمت المعالجة جزئياً للشحن"
        },
        "HandedOverToCourier": {
            "en": "Handed Over To Courier",
            "ar": "تسليمه لمندوب الشحن"
        },
        "InTransit": {
            "en": "In Transit",
            "ar": "في مرحلة انتقالية"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "Attempted": {
            "en": "Attempted",
            "ar": "حاول"
        },
        "PartiallyAttempted": {
            "en": "Partially Attempted",
            "ar": "محاولة جزئية"
        },
        "ReturnToOrigin": {
            "en": "Return To Origin",
            "ar": "العودة إلى الأصل"
        },
        "PartialDeliveryInProgress": {
            "en": "Partial Delivery In Progress",
            "ar": "التسليم الجزئي قيد التنفيذ"
        },
        "PartiallyDelivered": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا"
        },
        "CancelledByCustomer": {
            "en": "Cancelled By Customer",
            "ar": "ألغى العميل"
        },
        
        "Delivered": {
            "en": "Delivered",
            "ar": "تم التوصيل"
        },
        "Out For Delivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "OutForDelivery": {
            "en": "Out For Delivery",
            "ar": "خارج للتوصيل"
        },
        "PartiallyOutForDelivery": {
            "en": "Partially Out For Delivery",
            "ar": "جزئيًا في طريقه للتسليم" 
        },
        "Processing": {
            "en": "Processing",
            "ar": "يعالج"
        },
        "Order placed": {
            "en": "Order placed",
            "ar": "تم الطلب"
        },
        "Pending": {
            "en": "Pending",
            "ar": "قيد الانتظار"
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "ألغيت"
        },
        "PartiallyDelivered_Processing": {
            "en": "Partially Delivered Processing",
            "ar": "تسليم جزئي للمعالجة",
        },
        "PartiallyDelivered_Completed": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا",
        },
        "Received": {
            "en": "Received",
            "ar": "تلقى"
        },
        "ProcessedForPicking": {
            "en": "Processed For Picking",
            "ar": "المصنعة للقطف"
        },
        "PartiallyProcessedForPicking": {
            "en": "Partially Processed For Picking",
            "ar": "تمت معالجته جزئيًا للاختيار"
        },
        "ProcessedForShipping": {
            "en": "Processed For Shipping",
            "ar": "معالجتها للشحن"
        },
        "PartiallyProcessedForShipping": {
            "en": "Partially Processed For Shipping",
            "ar": "تمت المعالجة جزئياً للشحن"
        },
        "HandedOverToCourier": {
            "en": "Handed Over To Courier",
            "ar": "تسليمه لمندوب الشحن"
        },
        "PartiallyHandedOverToCourier": {
            "en": "Partially Handed Over To Courier",
            "ar": "تم تسليمها جزئيًا إلى شركة الشحن"
        },
        "InTransit": {
            "en": "In Transit",
            "ar": "في مرحلة انتقالية"
        },
        "PartiallyInTransit": {
            "en": "Partially In Transit",
            "ar": "جزئيًا في النقل"
        },
        "Attempted": {
            "en": "Attempted",
            "ar": "حاول"
        },
        "PartiallyAttempted": {
            "en": "Partially Attempted",
            "ar": "محاولة جزئية"
        },
        "ReturnToOrigin": {
            "en": "Return To Origin",
            "ar": "العودة إلى الأصل"
        },
        "PartiallyReturnToOrigin": {
            "en": "Partially Return To Origin",
            "ar": "العودة جزئيًا إلى الأصل"
        },
        "PartialDeliveryInProgress": {
            "en": "Partial Delivery In Progress",
            "ar": "التسليم الجزئي قيد التنفيذ"
        },
        "PartiallyDelivered": {
            "en": "Partially Delivered",
            "ar": "سلمت جزئيا"
        },
        "CancelledByCustomer": {
            "en": "Cancelled By Customer",
            "ar": "ألغى العميل"
        }
    }

    let params = useParams();
    const orderID = params.id
    const [showTab, setTabShow] = useState(1);
    const [loading, setLoading] = useState(true);
    const [shipmentList, setShipmentList] = useState([]);
    // const [shipmentError, setShipmentError] = useState("");
    const [pendingItemsList, setPendingItemsList] = useState([]);
    const [iscopied, setIsCopied] = useState(false);
    // const [pendingItemsError, setPendingItemsError] = useState("");


    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [language, setLanguage] = useState("en")
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Orders", link: "/account/orders" },
        { title: "Order Details", link: "/account/orders/"+orderID },
        { title: "Order Shipment", link: "" },
    ]
    useEffect(() => {
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            setLanguage(lang)
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        configurePageTitle("Order Details")
        configureBreadcrumb(breadcrumbItems)
    }, [])

    useEffect(() => {
        setLoading(true)
        getShipmentList({
            'orderId': orderID
        }).then(({ data }) => {
            if (data.Data.length) {
                setShipmentList(data.Data)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        });
    }, [orderID]);

    useEffect(() => {
        setLoading(true)
        getPendingItems({
            'orderId': orderID
        }).then(({ data }) => {
            if (data.Data.length) {
                setPendingItemsList(data.Data)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        });
    }, [orderID]);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (
        <section id="product-shipments">
            {/* <div className='profile-body-header mb-4'>
                <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'>{translations.MyReviews[language].reviewHeading}</h1>
                <p className='fs-16 text-gray-400 fw-400 mt-0 mb-0'>{translations.MyReviews[language].reviewSubHeading}</p>
            </div> */}
            {
                loading ?
                    <p className="btn btn-alternative w-100"><T>Please wait while the shipments are loading...</T></p> :
                    <>
                        <div className="shipments-cards-header">
                            <div className="shipments-btn-group d-flex">
                                <button className={showTab === 1 ? " btn-shipments  active" : "btn-shipments "} onClick={() => { setTabShow(1) }}><i className={showTab === 1 ? "ri-box-3-line active mr-2" : "ri-box-3-line mr-2"}></i><T>Shipments</T></button>
                                <button className={showTab === 2 ? " btn-shipments  active" : "btn-shipments "} onClick={() => { setTabShow(2) }}> <i className={showTab === 2 ? "ri-time-line active mr-2" : "ri-time-line mr-2"}></i><T>Pending Items</T></button>
                            </div>
                        </div>
                        <div className={showTab === 1 ? "shipments-section" : "shipments-section d-none"}>
                            <div className="d-flex justify-between flex-wrap">
                                {
                                    shipmentList.length ?
                                        <>
                                            {shipmentList.map((sl, index) => {
                                                return (
                                                    <div key={sl.ShipmentId} className="order-details-container order-product-container d-flex justify-between flex-wrap w-100">
                                                        <div className="shipment-heading">
                                                            <h1 className='fs-14 text-gray-500 fw-500 mt-0 mb-0'><i className="ri-box-3-line fs-18"></i> <T>Shipment</T> {sl.ShipmentId}</h1>
                                                        </div>
                                                        <div className="track-shipment">
                                                            <Link to={`/account/orders/shipments/track-shipment/${orderID}/${sl.ShipmentId}`} className="fs-14 text-primary fw-500 mt-0 mb-0"><i className="ri-radar-line fs-18"></i><T>Track Shipment</T></Link>
                                                        </div>
                                                        <div className="order-card">
                                                            <div className="shipment-info d-flex align-items-center justify-between flex-wrap mt-4 mb-4">
                                                                <div className="courier-logo-container">
                                                                    <img src={sl.CourierImage ? sl.CourierImage : noImage} alt="courier-logo" onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                                                </div>
                                                                <div className="courier-title-container">
                                                                    <span className="courier-label text-secondary fs-14 fw-400"><T>Courier Name</T></span>
                                                                    <p className="courier-info">{sl.CourierName}</p>
                                                                </div>
                                                                <div className="shipment-status-container">
                                                                    <span className="courier-label text-secondary fs-14 fw-400"><T>Status</T></span>
                                                                    <p className="courier-info">{statuses[sl.Status] ? statuses[sl.Status][language] : sl.Status}</p>
                                                                </div>
                                                                <div className="tracking-number-container">
                                                                    <span className="courier-label text-secondary fs-14 fw-400"><T>Tracking Number</T></span>
                                                                    <p className="courier-info">{sl.TrackingNumber} {iscopied ? <i className="ri-checkbox-circle-fill text-primary"></i> : <i style={{ cursor: "pointer" }} onClick={() => handleCopyClick(sl.TrackingNumber)} className="ri-file-copy-line text-primary"></i>}</p>
                                                                </div>
                                                                {
                                                                    !["Cancelled","CancelledByCustomer","ReturnToOrigin"].includes(sl.Status) ?
                                                                    <div className="shipment-date w-100 d-flex btn btn-alternative mt-3">
                                                                        <span className="shipment-date-label text-secondary fs-14 fw-500">{ sl.Status === "Delivered" ? <T>Delivered</T> : <T>Expected Delivery</T>}{" "}:{" "}</span>
                                                                        <p className="shipment-date-info LTR">{" "}{ sl.Status === "Delivered" ? moment(sl.DeliveryDate).format("DD-MMM-YYYY") : moment(sl.ExpectedDelivery).format("DD-MMM-YYYY")}</p>
                                                                    </div> : null
                                                                }
                                                            </div>
                                                            <div className="order-card-wrapper">
                                                                {sl.ShipmentItems.map((si, itemIndex) => {
                                                                    return (
                                                                        <div key={si.ProductId} className="d-flex align-items-center justify-between flex-wrap position-relative mb-3">
                                                                            <div className="card-image">
                                                                                <Link to={`/product/${si.ProductId}`} className="product-image">
                                                                                    <img className='d-inline-block-important' style={{display: "block!important"}} src={si.PictureUrl ? si.PictureUrl : noImage} alt={si.Name} draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                                                                </Link>
                                                                            </div>
                                                                            <div className="card-title-brand d-inline-flex flex-column">
                                                                                <Link to={`/product/${si.ProductId}`} className="product-title text-center decoration-none text-gray-500 fs-16 fw-500" title={si.Name}>{si.Name}</Link>
                                                                            </div>
                                                                            <div className="card-price d-inline-flex flex-column" style={{direction: "ltr"}}>
                                                                                <span className="shipment-price-label fs-12 mb-1 text-gray-400 fw-400" ><T>Price</T></span>
                                                                                <div className="product-price d-inline-flex text-center align-items-center">
                                                                                    <span className="product-currency text-gray-500 fs-16 fw-500" style={{paddingRight: "4px"}}>SAR</span>
                                                                                    <span className="product-amount text-secondary fs-16 fw-500 ml-1"> {si.Discount ? (si.Price-si.Discount).toFixed(2) : si.Price.toFixed(2)}</span>
                                                                                </div>
                                                                                {si.Discount ? <span className="product-amount text-gray-400 text-center fs-14 fw-400"><s>SAR { (si.Price).toFixed(2) }</s></span> : <></>}
                                                                            </div>
                                                                            <div className="card-quantity text-center">
                                                                                <span className="fs-12 mb-1 text-gray-400 fw-400 d-block"><T>Qty</T></span>
                                                                                <span className="product-quanity fs-16 fw-500 text-gray-500">{si.Quantity}</span>
                                                                            </div>
                                                                            <div className="total-price text-right position-relative">
                                                                                <span className="fs-12 mb-1 text-gray-400 fw-400 d-block mb-1"><T>Sub Total</T></span>
                                                                                <span className="product-currency text-gray-500 fs-16 fw-500 d-block">SAR <span className="fs-16 text-secondary">{si.Total}</span></span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}


                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </> :
                                        <>
                                            <p className="btn btn-alternative w-100"><T>No Shipment Found</T></p>
                                        </>
                                }
                            </div>
                        </div>

                        <div className={showTab === 2 ? "pending-items-section" : "pending-items-section d-none"}>
                            <div className="d-flex justify-between flex-wrap">
                                {
                                    pendingItemsList.length ?
                                        <>
                                            <div className="order-details-container order-product-container d-flex justify-between flex-wrap">
                                                <div className="shipment-heading">
                                                    <h1 className='fs-18 text-gray-500 fw-500 mt-0 mb-0'><i className="ri-time-line"></i> <T>Pending Items</T></h1>
                                                </div>
                                                <div className="order-card">
                                                    <div className="order-card-wrapper">
                                                        {pendingItemsList.map((pi, index) => {
                                                            return (
                                                                <div key={index} className={"pending-single-item-container d-flex align-items-center justify-start gap-20 flex-wrap position-relative mb-3"}>
                                                                    <div className="card-image">
                                                                        <Link to={`/products`} className="product-image">
                                                                            <img src={pi.PictureUrl ? pi.PictureUrl : noImage} alt={"item.ProductName"} draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="card-title-brand d-inline-flex text-center flex-column">
                                                                        <Link to={`/products`} className="product-title decoration-none text-gray-500 fs-16 fw-500" title={pi.Name}>{pi.Name}</Link>
                                                                    </div>
                                                                    {/* <div className="card-price d-inline-flex flex-column">
                                                                        <div className="product-price d-inline-flex justify-center align-items-center">
                                                                            <span className="product-currency text-gray-500 text-center fs-16 fw-500">SAR</span>
                                                                            <span className="product-amount text-secondary fs-16 fw-500 ml-1">{pi.Discount ? (pi.Price-(pi.Discount / (pi.Quantity ? pi.Quantity : 1))).toFixed(2) : pi.Price.toFixed(2)}</span>
                                                                        </div>
                                                                        {pi.Discount ? <span className="product-amount text-gray-400 text-center fs-14 fw-400"><s>SAR { (pi.Price).toFixed(2) }</s></span> : <></>}
                                                                    </div> */}
                                                                    {/* <div className="card-quantity">
                                                                        <span className="fs-12 mb-1 text-gray-400 fw-400 d-block">{"Qty"}</span>
                                                                        <span className="product-quanity fs-16 fw-500 text-gray-500">{pi.Quantity}</span>
                                                                    </div> */}
                                                                    {/* <div className="total-price text-right position-relative">
                                                                        <span className="product-currency text-gray-500 fs-16 fw-500 d-block">SAR <span className="fs-16 text-secondary">{pi.Total}</span></span>
                                                                    </div> */}

                                                                    {/* <span className="pending-message text-gray-500 fs-16 fw-500 d-block">SAR <span className="fs-16 text-secondary">{pi.Total}</span></span> */}
                                                                    {pi.DeliveryDate == null && pi.RefundedAmount > 0 ? <><div className="pending-message-container position-relative w-100 text-center"><p className="btn btn-alternative h-100 fs-14 fw-500 mt-0 mb-0"><T>This item has been removed from your order</T>. SAR {pi.RefundedAmount} <T>is refunded to you</T>.</p></div></> :
                                                                        pi.DeliveryDate != null && pi.RefundedAmount == 0 ? <><div className="pending-message-container position-relative w-100 text-center"><p className="btn btn-alternative h-100 fs-14 fw-500 mt-0 mb-0"><T>This item will be shipped later. Estimated delivery date</T> <span>{pi.DeliveryDate}.</span></p></div></> :
                                                                            <></>}

                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={`/account/orders/remove-order-items/${orderID}`} className="btn btn-primary ml-auto d-none"><T>Remove items from the order</T></Link>
                                        </>
                                        :
                                        <>
                                            <p className="btn btn-alternative w-100"><T>No Pending Items Found</T></p>
                                        </>
                                }
                            </div>
                        </div>
                    </>
            }
        </section>
    )
}

export default ViewOrderShipment
