import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import T from "../../locale/translations-main";

function OccasionInvitedCard({ item }) {

    return (
        <div className="occasion-card single-occasion-card single-occasion-invited-card">
            <div className="occasion-tag">
                <span>{item.Event}</span>
            </div>
            <div className="days-remaining">
                <p className="text-gray-200 fw-500 fs-12 m-0 LTR">{item.EventDate}</p>
            </div>
            <div className="occasion-body d-flex align-items-center gap-1">
                <div className="left">
                    <div className="occasion-img">
                        <img src={item.Picture} alt="" />
                    </div>
                </div>
                <div className="right">
                    <div className="occasion-user-name">
                        <p className="text-gray-500 fw-500 fs-16 m-0">{item.Title}</p>
                    </div>
                    <div className="dates d-flex gap-1 mb-2">
                        <div className="gift-remaining">
                            <p className="text-gray-400 fw-400 fs-14 m-0"> <span className="text-primary">{item.GiftsRemaining}</span> <T>Gift Remaining</T></p>
                        </div>
                        <div className="gift-purchased">
                            <p className="text-gray-400 fw-400 fs-14 m-0"> <span className="text-primary">{item.GiftsPurchased}</span> <T>Gift Purchased</T></p>
                        </div>
                    </div>
                    <div className="view-gifts">
                        <Link to={"/account/occasions/invite/"+item.Id} className="link"><T>View Occasion</T></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OccasionInvitedCard;