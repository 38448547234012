import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { getUserData, setProfilePicture } from "../helper/backend-methods";
import { useState } from "react";
import loadingPicture from "../assets/images/picture-loading.gif"
import defaultAvatar from "../assets/images/no-avatar.jpg"
import "../assets/css/--component-profile-sidebar.css";
import Skeleton from "react-loading-skeleton";
import T from "../locale/translations-main";

function Sidebar(){
    
    const [name,setName] = useState(<Skeleton height="14px" style={{width: "150px"}} />);
    const [email,setEmail] = useState(<Skeleton height="12px" style={{width: "120px"}} />);
    const [picture,setPicture] = useState(loadingPicture);
    const [errors, setErrors] = useState({ occasionImage: "" })
    useEffect(() => {
        getUserData().then(({data})=>{
            if(data.Status){
                setName(data.Data.FirstName+' '+data.Data.LastName)
                setEmail(data.Data.Email)
                setPicture(data.Data.Picture && data.Data.Picture.length ? data.Data.Picture : defaultAvatar)
            }
            else{
                setPicture(defaultAvatar)
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])

    const updateProfilePicture = (e) => {
        var allowedExtension = ['jpeg', 'jpg', 'png'];
        const errors = { occasionImage: "" }
        if (e.target.files && e.target.files[0]) {
            if(!allowedExtension.includes(e.target.value.split('.').pop().toLowerCase())){
                errors.occasionImage = <T>Allowed image types are jpeg, jpg, png only.</T>;
            }
            else{
                setPicture(loadingPicture)
                const formData = new FormData();
                formData.append("picture", e.target.files[0]);
                setProfilePicture(formData).then(({data})=>{
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        setPicture(e.target.result)
                    };
                    reader.readAsDataURL(e.target.files[0]);
                }).catch((e)=>{
                    console.log(e)
                })
            }
        }
        setErrors(errors);
    }
    
    return (
        <aside className="profile-siebar">
            <div className="sidebar-header">
                <label className="" htmlFor="profilePicture" data-text="Change">
                    <img className="profile-avatar" src={picture} alt=""/>
                    <input className="d-none" type={"file"} id="profilePicture" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=defaultAvatar; }} onChange={updateProfilePicture}/>
                </label>
                {
                    errors.occasionImage ?
                    <span className="text-primary text-center w-100 d-block fs-12 fw-400"><T>Only jpg, jpeg, png files are allowed.</T></span> : <></>
                }
                <h2 className="profile-title" id="profileName">{name && name !== "null null" ? name : ""}</h2>
                <h4 className="profile-email" id="profileEmail">{email ? email : ""}</h4>
            </div>
            <div className="sidebar-navigation">
                <NavLink to={"/account/profile"}><i className="ri-user-3-line"></i><T>Profile</T></NavLink>
                <NavLink to={"/account/orders"}><i className="ri-gift-line"></i><T>Orders</T></NavLink>
                <NavLink to={"/account/returns"}><i className="ri-arrow-go-back-line"></i><T>Returns</T></NavLink>
                <NavLink to={"/account/addresses"}><i className="ri-map-pin-line"></i><T>Addresses</T></NavLink>
                <NavLink to={"/account/payment-methods"}><i className="ri-bank-card-line"></i><T>Payment Method</T></NavLink>
                <NavLink to={"/account/wishlist"}><i className="ri-heart-2-line"></i><T>Wishlist</T></NavLink>
                <NavLink to={"/account/review"}><i className="ri-star-line"></i><T>Reviews</T></NavLink>
                <NavLink to={"/account/occasions"}><i className="ri-calendar-line"></i><T>Occasions</T></NavLink>
                <NavLink to={"/logout"}><i className="ri-logout-circle-line"></i><T>Logout</T></NavLink>
            </div>
        </aside>
    )
}

export default Sidebar; 