const translations = {
    "Saudi Arabia": {
      "en": "Saudi Arabia",
      "ar": "المملكة العربية السعودية"
    },
    "Free Delivery For Order Over 100 SAR": {
      "en": "Free Delivery For Order Over 100 SAR",
      "ar": "توصيل مجاني للطلبات بقيمة أكثر من 100 ريال "
    },
    "Daily Mega Discounts": {
      "en": "Daily Mega Discounts",
      "ar": "خصومات يومية ضخمة "
    },
    "Best Price On The Market": {
      "en": "Best Price On The Market",
      "ar": "أفضل سعر في السوق"
    },
    "Shop online for Kids & Baby Toys at ClickToys. Choose among the widest range of toys and toy brands that guarantees endless fun and games! Our educational toys have a strong added value in the development of your child.": {
      "en": "Shop online for Kids & Baby Toys at ClickToys. Choose among the widest range of toys and toy brands that guarantees endless fun and games! Our educational toys have a strong added value in the development of your child.",
      "ar": "تسوق أونلاين لشراء ألعاب الأطفال والرضع في كليك تويز. اختر من بين أكبر مجموعة من الألعاب والماركات للألعاب التي تضمن متعة لا نهاية لها! ألعابنا التعليمية متميزة في تنمية وتقوية مهارات طفلك."
    },
    "Categories": {
      "en": "Categories",
      "ar": "الفئات"
    },
    "Useful Links": {
      "en": "Useful Links",
      "ar": "روابط مفيدة"
    },
    "Help Center": {
      "en": "Help Center",
      "ar": "مركز المساعدة"
    },
    "Contact Us": {
      "en": "Contact Us",
      "ar": "اتصل بنا"
    },
    "Hotline 24/7": {
      "en": "Hotline 24/7",
      "ar": "نستقبل المكالمات ٢٤ ساعة خلال الاسبوع"
    },
    "Email Address": {
      "en": "Email Address",
      "ar": "عنوان البريد الالكترونى"
    },
    "Store Location": {
      "en": "Store Location",
      "ar": "موقع المتجر"
    },
    "ClickToys 2023 © All Rights Reserved.": {
      "en": "ClickToys 2023 © All Rights Reserved.",
      "ar": "كليك تويز 2023 © جميع الحقوق محفوظة"
    },
    "Home": {
      "en": "Home",
      "ar": "الصفحة الرئيسية"
    },
    "Store": {
      "en": "Store",
      "ar": "المتجر"
    },
    "Contact": {
      "en": "Contact",
      "ar": "للتواصل"
    },
    "Account": {
      "en": "Account",
      "ar": "الحساب "
    },
    "Terms & Conditions": {
      "en": "Terms & Conditions",
      "ar": "الشروط والأحكام"
    },
    "Privacy Policy": {
      "en": "Privacy Policy",
      "ar": "سياسة الخصوصية"
    },
    "Return Policy": {
      "en": "Return Policy",
      "ar": "سياسة الاسترجاع"
    },
    "Terms of Service": {
      "en": "Terms of Service",
      "ar": "شروط الخدمة"
    }
  }
export default translations