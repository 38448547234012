import React, { useEffect } from 'react'
import '../../assets/css/--page-account-shipment-tracking.css'
import OrderDelivered from '../../assets/images/order-delivered.jpg'
import noImage from "../../assets/images/no-image.jpg"
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { getTrackShipment } from '../../helper/backend-methods'
import T from '../../locale/translations-main'

const ViewTrackShipment = (props) => {
    let params = useParams();
    const shipmentID = params.id
    const orderID = params.orderID
    const [loading, setLoading] = useState(true);
    const [trackDetail, setTrackDetail] = useState(null);

    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [language, setLanguage] = useState("en")
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Orders", link: "/account/orders" },
        { title: "Order Details", link: "/account/orders/" + orderID },
        { title: "Order Shipment", link: `/account/orders/shipments/${orderID}` },
        { title: "Track Shipment", link: "" },
    ]
    useEffect(() => {
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            setLanguage(lang)
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        configurePageTitle("Order Details")
        configureBreadcrumb(breadcrumbItems)
    }, [])

    useEffect(() => {
        setLoading(true)
        getTrackShipment({
            'shipmentId': shipmentID
        }).then(({ data }) => {
            if (data.Status) {
                setTrackDetail(data.Data)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        });
    }, [shipmentID]);

    const [iscopied, setIsCopied] = useState(false);
    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        loading ? <p className="btn btn-alternative w-100"><T>Please wait while the shipment is loading...</T></p> : <>
            <section id="track-shipment-section">
                <div className="track-header-container">
                    <div className="track-status-card d-flex justify-start align-items-center">
                        <div className="card-img">
                            <img src={OrderDelivered} alt="Order Status Image" />
                        </div>
                        <div className="card-meta">
                            <div className="card-status">
                                <h4 className='fs-16 text-primary fw-600 mt-1 mb-1'>{trackDetail.ShipmentStatus}</h4>
                            </div>
                            {/* <div className="card-status-description">
                                <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>This item was delivered on 16 June, 2023.</p>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="shipment-info-container mt-5">
                    <div className="shipment-info d-flex align-items-center justify-between flex-wrap mt-4 mb-4">
                        <div className="courier-logo-container">
                            <img src={trackDetail.CourierImage ? trackDetail.CourierImage : noImage} alt="courier-logo" draggable={false} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} />
                        </div>
                        <div className="courier-title-container">
                            <span className="courier-label text-secondary fs-14 fw-400"><T>Courier Name</T></span>
                            <p className="courier-info mt-1 mb-1">{trackDetail.CourierName}</p>
                        </div>
                        <div className="tracking-number-container">
                            <span className="courier-label text-secondary fs-14 fw-400"><T>Tracking Number</T></span>
                            <p className="courier-info mt-1 mb-1">{trackDetail.TrackingNumber} {iscopied ? <i className="ri-checkbox-circle-fill text-primary"></i> : <i style={{ cursor: "pointer" }} onClick={() => handleCopyClick(trackDetail.TrackingNumber)} className="ri-file-copy-line text-primary"></i>}</p>
                        </div>
                        <div className="shipment-status-container">
                            <span className="courier-label text-secondary fs-14 fw-400"><T>Shipping Address</T></span>
                            <p className="courier-info mt-1 mb-1 w-85">{trackDetail.ShippingAddress}</p>
                        </div>
                    </div>
                </div>

                {trackDetail.TrackingStatus.length ?
                    <div className="track-timeline-container">
                        <div className="track-timeline">
                            {trackDetail.TrackingStatus.map((ts, index) => {
                                return (
                                    ts.IsCompleted ?
                                        <div key={ts.Id} className="track-timeline-card d-flex justify-start align-items-center mb-5">
                                            <div className={"track-status-current d-flex justify-center align-items-center " + language}>
                                                <i className="ri-check-line fs-22 fw-600 text-light"></i>
                                            </div>
                                            <div className="track-timeline-meta">
                                                <div className="track-timeline-heading fs-14 text-primary fw-600 mt-1 mb-1">{ts.Status}</div>
                                                {/* <div className="track-timeline-description">
                                            <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>Your Package has been delivered</p>
                                        </div> */}
                                                <div className="track-timeline-date">
                                                    <p className='fs-12 text-gray-300 fw-400 mt-1 mb-1 LTR text-left'>{ts.Timestamp}</p>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div key={ts.Id} className="track-timeline-card d-flex justify-start align-items-center mb-5">
                                            <div className="track-status-past">
                                            </div>
                                            <div className="track-timeline-meta">
                                                <div className="track-timeline-heading fs-14 text-primary fw-600 mt-1 mb-1">{ts.Status}</div>
                                                {/* <div className="track-timeline-description">
                                            <p className='fs-14 text-gray-500 fw-400 mt-1 mb-1'>Your Package has been delivered</p>
                                        </div> */}
                                                <div className="track-timeline-date">
                                                    <p className='fs-12 text-gray-300 fw-400 mt-1 mb-1 LTR text-left'>{ts.Timestamp}</p>
                                                </div>
                                            </div>
                                        </div>
                                )
                            })}
                        </div>
                    </div>
                    : <></>
                }

            </section>
        </>
    )
}

export default ViewTrackShipment
