import React, { useState, useEffect } from "react";
import ProductCard from "../products/product-card";
import rightbanner from "../../assets/images/featuredbanner.png";
import { Link } from "react-router-dom";
import { getProductsByTag } from "../../helper/backend-methods";
import Skeleton from 'react-loading-skeleton'
import { analyticsTrackEvent } from "../../components/Analytics";
import T from "../../locale/translations-main";

function FeaturedProducts({consent,banners,bannerLoading,products,loading}) {

  const [productList, setProductList] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [bannerData, setBannerData] = useState(null);
  const [ language, setLanguage ] = useState("en");


  useEffect(()=>{
    if(loading === false && products){
      setProductList(products.Products);
    }
  },[products,loading]);

  useEffect(()=>{
    if(bannerLoading === false){
        setBannerData(banners.featured);
    }
  },[banners,bannerLoading]);

  useEffect(()=>{
    const lang = localStorage.getItem("LANG")
    if(lang && lang.length){
        setLanguage(lang)
    }
  }, [])

  const isExternalLink = (url) => {
    const tmp = document.createElement('a');
    tmp.href = url;
    return tmp.host !== window.location.host;
  };


  return (
    !loading && !productList.length ? null :
    <section id="featured_product_section" className="mb-4">
      <div className="category-title d-flex justify-between align-items-end mt-5 mb-5">
        <div className="category-heading">
          <h2 className="fw-500 text-gray-500 fs-22 m-0 text-capitalize">{ loading ? <Skeleton height={20} width={100}/> : products.Name }</h2>
          {/* <p className="text-gray-300 m-0"><T>View some of our</T> {products.Name} <T>products</T></p> */}
        </div>
        <div className="category-button">
          { loading ? <Skeleton height={14} width={60}/> : <Link className="link" to={`/products?tag=${products?.Id}`}><T>View All</T></Link> }
        </div>
      </div>
        {
          loading ?
            <div className="featured_product_section">
              <div className="d-flex justify-between">
                <div className="featured_product_section featured-product-card">
                  <div className="d-flex flex-wrap justify-between">
                    <div className="product-card border-none">
                      <Skeleton height="400px" width="100%" />
                    </div>
                    <div className="product-card border-none">
                      <Skeleton height="400px" width="100%" />
                    </div>
                    <div className="product-card border-none">
                      <Skeleton height="400px" width="100%" />
                    </div>
                    <div className="product-card border-none">
                      <Skeleton height="400px" width="100%" />
                    </div>
                    <div className="product-card border-none">
                      <Skeleton height="400px" width="100%" />
                    </div>
                    <div className="product-card border-none">
                      <Skeleton height="400px" width="100%" />
                    </div>
                    <div className="product-card border-none">
                      <Skeleton height="400px" width="100%" />
                    </div>
                    <div className="product-card border-none">
                      <Skeleton height="400px" width="100%" />
                    </div>
                  </div>
                </div>
                <div className="featured-right-banner demo">
                  <Skeleton height="100%" width="100%" />
                </div>
              </div>
            </div>
            :
            <div className="d-flex justify-between flex-wrap mb-5 ">
              <div className={`featured-product-card d-flex flex-wrap ${8 - productList.length === 2 ? "fill-two" : "fill-one"}`}>
                {productList.slice(0,8).map((item, i) => (
                  <ProductCard key={i} item={item} pageTitle="Home" permissions={consent}/>
                ))}
              </div>
              {
                bannerData ?
                isExternalLink(bannerData.HyperLink) ?
                <div className="featured-right-banner" style={{backgroundImage: "url("+bannerData.Image.replaceAll(' ','%20')+")"}} onClick={()=>{analyticsTrackEvent("CTA Clicked", { cta_name: "Recent Products Banner", screen_name: "Home Page" }, consent.tracking ); window.open(bannerData.HyperLink, '_blank').focus() }}>
                  <div className="featured-right-banner-content">
                    { bannerData.Description ? <h2 className="text-light">{bannerData.Description}</h2> : <></> }
                  </div>
                </div> : 
                <Link className="featured-right-banner" to={bannerData.HyperLink} style={{backgroundImage: "url("+bannerData.Image.replaceAll(' ','%20')+")"}} onClick={()=>{analyticsTrackEvent("CTA Clicked", { cta_name: "Recent Products Banner", screen_name: "Home Page" }, consent.tracking ) }}>
                  <div className="featured-right-banner-content">
                    { bannerData.Description ? <h2 className="text-light">{bannerData.Description}</h2> : <></> }
                  </div>
                </Link> : <></>
              }
            </div>
        }
    </section>
  );
}

export default FeaturedProducts;
