const translations = {
    "Contact Us": {
      "en": "Contact Us",
      "ar": "اتصل بنا"
    },
    "Full Name": {
      "en": "Full Name",
      "ar": "الاسم الكامل"
    },
    "Enter your full name": {
      "en": "Enter your full name",
      "ar": "ادخل الاسم الكامل"
    },
    "Email Address": {
      "en": "Email Address",
      "ar": "عنوان البريد الالكترونى"
    },
    "Enter your email address": {
      "en": "Enter your email address",
      "ar": "ادخل عنوان البريد الالكترونى"
    },
    "Message": {
      "en": "Message",
      "ar": "الرسالة"
    },
    "Write your message here..": {
      "en": "Write your message here..",
      "ar": "اكتب الرسالة هنا"
    },
    "Submit": {
      "en": "Submit",
      "ar": "ارسال"
    },
    "Hotline 24/7": {
      "en": "Hotline 24/7",
      "ar": "نستقبل المكالمات ٢٤ ساعة خلال الاسبوع"
    },
    "800 244 0339 (Toll Free)": {
      "en": "800 244 0339 (Toll Free)",
      "ar": "800 244 0339 (اتصال مجاني)"
    },
    "Email Address:": {
      "en": "Email Address:",
      "ar": "عنوان البريد الإلكتروني:"
    },
    "support@clicktoys.com": {
      "en": "support@clicktoys.com",
      "ar": "support@clicktoys.com"
    },
    "Location": {
      "en": "Location",
      "ar": "الموقع"
    },
    "King Fahd, Riyadh 12272, Saudi Arabia": {
      "en": "King Fahd, Riyadh 12272, Saudi Arabia",
      "ar": "الملك فهد الرياض 12272 المملكة العربية السعودية"
    }
  }
  export default translations