const translations = {

  "My Reviews": {
    "en": "My Reviews",
    "ar": "تقييماتي"
  },
  "My reviews about the product": {
    "en": "My reviews about the product",
    "ar": "ملاحظاتي حول المنتج"
  },
  "Reviewed": {
    "en": "Reviewed",
    "ar": "تم "
  },
  "Pending Reviews": {
    "en": "Pending Reviews",
    "ar": "التقييمات المعلقة"
  },
  "You haven't bought any products yet.": {
    "en": "You haven't bought any products yet.",
    "ar": "لم تشترِ أي منتجات بعد."
  },
  "Fetching your reviews. Please wait...": {
    "en": "Fetching your reviews. Please wait...",
    "ar": "تحميل بيانات التقييمات. أرجو الإنتظار..."
  },
  "Brand: ": {
    "en": "Brand: ",
    "ar": "ماركة:"
  },
  "Reviewed: ": {
    "en": "Reviewed: ",
    "ar": "تمت المراجعة:"
  },
  "Pending Approval": {
    "en": "Pending Approval",
    "ar": "الموافقات المعلقة"
  },
  "Review Approved": {
    "en": "Review Approved",
    "ar": "تمت الموافقة على المراجعة"
  },
  "Write your review here": {
    "en": "Write your review here",
    "ar": "اكتب رأيك هنا"
  },
  "You currently have no pending reviews.": {
    "en": "You currently have no pending reviews.",
    "ar": "ليس لديك حاليا أي مراجعات معلقة."
  },
  "Purchased on: ": {
    "en": "Purchased on: ",
    "ar": "تم شراؤها :"
  },
  "Write A Review": {
    "en": "Write A Review",
    "ar": "أكتب رأيك"
  },
  "Your Rating": {
    "en": "Your Rating",
    "ar": "تقييمك"
  },
  "Product Review": {
    "en": "Product Review",
    "ar": "تقييم المنتج"
  },
  "Please Wait": {
    "en": "Please Wait",
    "ar": "انتظر من فضلك"
  },
  "Post Review": {
    "en": "Post Review",
    "ar": "انشر رأيك"
  },
  "Cancel": {
    "en": "Cancel",
    "ar": "إلغاء"
  },
  
}
  export default translations