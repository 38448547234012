const translations = {
    "Please wait while the return list is loading...": {
      "en": "Please wait while the return list is loading...",
      "ar": "يرجى الانتظار حتى يتم تحميل قائمة المسترجعات"
    },
    "Return Order No": {
      "en": "Return Order No",
      "ar": "رقم طلب الإسترجاع"
    },
    "Return Requests": {
      "en": "Return Requests",
      "ar": "طلبات الإسترجاع"
    },
    "Status": {
      "en": "Status",
      "ar": "حالة"
    },
    "Request Date": {
      "en": "Request Date",
      "ar": "التاريخ المطلوب"
    },
    "Items": {
      "en": "Items",
      "ar": "المنتجات"
    },
    "Return Details": {
      "en": "Return Details",
      "ar": "تفاصيل الإسترجاع"
    },
    "No Return Request Found": {
      "en": "No Return Request Found",
      "ar": "لم يتم العثور على طلب الإسترجاع"
    },
    "Order Number": {
      "en": "Order Number",
      "ar": "رقم الطلب"
    },
    "Please wait while the return list is loading......": {
      "en": "Please wait while the return list is loading......",
      "ar": "يرجى الانتظار حتى يتم تحميل قائمة المسترجعات..."
    },
    "Sorry! No Data Found": {
      "en": "Sorry! No Data Found",
      "ar": "لم يتم العثور على طلب الإسترجاع"
    },
    "Total": {
      "en": "Total",
      "ar": "المجموع"
    },
    "Discount": {
      "en": "Discount",
      "ar": "الخصم"
    },
    "Cancel Return": {
      "en": "Cancel Return",
      "ar": "إلغاء طلب الإسترجاع"
    },
    "Track Return": {
      "en": "Track Return",
      "ar": "مسار الإسترجاع"
    },
    "Are you sure you want to cancel the return request?": {
      "en": "Are you sure you want to cancel the return request?",
      "ar": "هل أنت متأكد أنك تريد إلغاء طلب الإرجاع؟"
    },
    "Yes": {
      "en": "Yes",
      "ar": "نعم"
    },
    "No": {
      "en": "No",
      "ar": "لا"
    },
    "Cancel return request submitted successfully.": {
      "en": "Cancel return request submitted successfully.",
      "ar": "تم إرسال طلب إلغاء الإرجاع بنجاح"
    },
    "Please show this QR code to Saudi Post driver": {
      "en": "Please show this QR code to Saudi Post driver",
      "ar": "يرجى إظهار رمز الاستجابة السريعة هذا لسائق البريد السعودي"
    },
    "Ok": {
      "en": "Ok",
      "ar": "موافق"
    },
    "View QR Code": {
      "en": "View QR Code",
      "ar": "عرض رمز الاستجابة السريعة"
    },
    "Return has been": {
      "en": "Return has been",
      "ar": "تم الإسترجاع"
    },
    "click here to view the reason": {
      "en": "click here to view the reason",
      "ar": "انقر هنا لعرض السبب"
    },
    "Refunded Amount": {
      "en": "Refunded Amount",
      "ar": "المبلغ المسترد"
    },
    "Reason": {
      "en": "Reason",
      "ar": "السبب"
    },
  }
  export default translations