import { Link } from "react-router-dom"
import ReactStars from "react-rating-stars-component";
import "../../assets/css/--component-wishlist-card.css"
import popupImage from "../../assets/images/popup-icon.png"
import { useContext, useEffect,useState } from 'react';
import { addCart } from "../../helper/backend-methods";
import noImage from "../../assets/images/no-image.jpg"
import { GlobalContext } from "../../context";
import { analyticsTrackEvent } from "../../components/Analytics";
import T from "../../locale/translations-main";

function WishlistCard(properties){
    const { cartCount, updateCartCount } = useContext(GlobalContext);
    const props = properties.item;
    const wishlistId = properties.WishlistId;
    const [popup, setPopup] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const addToCart = (product) => {
        setLoadingButton(true)
        addCart({Quantity:1, ProductId : [product.Id]}).then(({data}) =>{
            if(data.Status){
                analyticsTrackEvent("Added to Cart", { screen_name: "Account Wishlist", product_id: props.Id, product_name: props.Name, brand: props.ManufacturerName, category: props.Category, sku: "", price: props.UnitPriceDiscount ? (props.Price - props.UnitPriceDiscount).toFixed(2) : props.Price.toFixed(2), quantity: 1 },properties.permissons.tracking)
                updateCartCount(data.Data.CartItemCount);
                setPopup(true)
            }
            setLoadingButton(false)
        }).catch((e)=>{
            setLoadingButton(false)
        })
    }
        

      
    return (
        <>
        <div className="wishlist-card d-flex justify-between flex-wrap">
            <button className="remove-card" title="Remove from wishlist" type={"button"} onClick={()=>{properties.removeWishlist(props, wishlistId)}}><i className="ri-close-line"></i></button>
            <div className="card-image">
                <Link to={`/product/${props.Id}?title=${encodeURIComponent(props.Name).replaceAll('%20','+')}`} className="product-image">
                    <img src={props.PicturURL ? props.PicturURL : noImage} draggable={false} alt={props.Name} onError={(e) => { e.target.onerror = null; e.target.src = noImage }}/>
                </Link>
            </div>
            <div className="card-title-brand d-inline-flex flex-column">
                <Link to={`/product/${props.Id}?title=${encodeURIComponent(props.Name).replaceAll('%20','+')}`} className="product-title decoration-none text-gray-500 fs-16 fw-500" title={props.Name}>{props.Name.length > 45 ? props.Name.slice(0,42)+"..." : props.Name}</Link>
                { props.ManufacturerName ? <span className="fs-14 fw-400 text-gray-400"><T>Brand</T><span className="product-brand decoration-none ml-1 mt-0 mb-0 fw-500 text-primary">{props.ManufacturerName}</span></span> : <></> }
            </div>
            <div className="card-price d-inline-flex flex-column">
                <span className="fs-12 mb-1 text-gray-400 fw-400 hide-vertical"><T>Price</T></span>
                <div className="product-price d-inline-flex align-items-center">
                    <span className="product-currency text-gray-500 fs-16 fw-500">SAR</span>
                    <span className="product-amount text-secondary fs-16 fw-500 ml-1">{props.UnitPriceDiscount ? (props.Price - props.UnitPriceDiscount).toFixed(2) : props.Price.toFixed(2)}</span>
                    { props.UnitPriceDiscount ? <span className="product-old-amount fs-14 fw-400 ml-2"><s>SAR{props.UnitPriceDiscount ? props.Price : <></>}</s></span> : <></> }
                </div>
                { props.UnitPriceDiscount ? <span className="price-drop text-light fs-14 fw-400 bg-link text-center"><T>Saved</T>: SAR { (props.UnitPriceDiscount).toFixed(2) }</span> : <></> }
            </div>
            <div className="card-rating">
                <span className="fs-12 mb-2 text-gray-400 fw-400 hide-vertical"><T>Rating</T></span>
                <ReactStars size={18} count={5} edit={false} color="#FFB300" activeColor="#FFB300" value={props.Rating} a11y={true} isHalf={true} emptyIcon={<i className="ri-star-line" />} halfIcon={<i className="ri-star-half-line" />} filledIcon={<i className="ri-star-fill" />}/>
                <span className="card-rating-value fs-12 fw-500 text-gray-500">{props.Rating} (<T>reviews</T>)</span>
            </div>
            { 
                props.InStock ?
                <button onClick={()=>{ loadingButton ? console.log("") : addToCart(props)}} type="button" className="product-action btn btn-primary">{loadingButton ? <T>Please Wait...</T> : <T>Add to Cart</T>}</button> :
                <button type="button" className="product-action btn btn-outline-primary-disabled"><T>Out of Stock</T></button>
            }
            <div className={ popup ? "product-card-popup active" : "product-card-popup"}>
                <div className="popup-wrapper">
                    <h2 className="popup-title">{props.Name.length > 40 ? props.Name.slice(0,37)+"..." : props.Name}</h2>
                    <span className="popup-subheading"><T>successfully added to cart</T></span>
                    <div className="popup-image-container">
                        <img src={popupImage} alt=""/>
                    </div>
                    <Link className="btn btn-primary" to="/cart"><T>Go to Cart</T></Link>
                    <button className="btn btn-outline-secondary" onClick={()=>{properties.setUpdate((prev)=>{ return prev + 1 })}}><T>Continue</T></button>
                </div>
            </div>
        </div>
        </>
    )
}

export default WishlistCard;